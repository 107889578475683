import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import numeral from 'numeraljs'
import _ from 'lodash'

import Button, { CopyButton } from './button'
import BulkDeleteModal from './bulk-delete-modal'
import BulkEdit from './bulk-edit-modal'
import BulkImportHistorical from './bulk-import-modal'
import ButtonDropdown, {
  DropdownButtonItem,
  DropdownLabel,
} from './button-dropdown'
import { ButtonRow } from './button-row'
import { CustomLinkFull } from './custom-link-fields'
import HeaderPanel, { Panel } from './header-panel'
import Input, { SearchInput } from './input'
import { Toggle } from './input-v2'
import Link from './link'
import Loader, { LoadingLabel, Preloader } from './loader'
import Modal from './modal'
import ObservepointModal from './observepoint-modal'
import QRCodeModal from './qr-code-modal'
import { SelectBoxChecklist } from './select-box'
import ShareCampaignCodesModal, {
  ShareModalState,
} from './share-campaign-codes-button'
import { LimitsProgressBars } from './subscription-comparison'
import Table, { TableHeaderColumn } from './table-v2'
import Tooltip from './tooltip'
import { EditHistoryModal } from './track-view-edit-history'
import { BoldText, BoxedText, ErrorMessage, SuccessText } from './typography'
import { UrlStatus, UrlValidationMessage } from './url-validation-message'
import {
  currentUserDetails,
  dataSourceReactive,
  hasCreatedLinksReactive,
  linkOrCode,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import { sendFeatureRequest } from '../api/graphql/support-client'
import {
  getCampaignCodeGenerator,
  getUniqueParamCurrentTotal,
} from '../api/graphql/track-create-client'
import {
  addShortLinkExistingCode,
  addTrackValidationIgnore,
  AVAILABLE_CODE_IDS,
  deleteTrackValidationIgnore,
  getDeepLinkDetails,
  getMinCodesQuick,
  getStoredCodeStatsQuick,
  getTrackValidationIgnoreList,
  getUrlValidationStatus,
  updateCode,
} from '../api/graphql/track-view-client'
import { getEmailNotifications } from '../api/graphql/user-client'
import {
  DownloadCodes,
  downloadCodesByAccount,
  getAdobeCodesDownloadLink,
} from '../api/REST/track-client'
import EditIcon from '../assets/edit.svg'
import { ReportIcon } from '../assets/svgs/menu/module-icons'
import { PeopleIcon, PersonIcon } from '../assets/svgs/people-icons'
import ObservepointLogo from '../assets/logos/observepoint-logo.png'
import {
  allUplifterMetrics,
  codeStatus,
  dateFormatShort,
  messages,
  supportEmail,
} from '../core/constants'
import {
  copyString,
  getItemByKeyValue,
  getUrlQuery,
  isAdminUser,
  isValidUrl,
} from '../helpers'
import { CustomLinkAliasDetails, useAliases } from '../helpers/custom-links'
import { saveTrackCreateFormData } from '../helpers/track-create'
import {
  downloadSpecificCodes,
  formatMetricValue,
  getAnchorFromString,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'
import {
  cloneAndEditBuildForm,
  MetricDropdownItem,
  TableLinkWithData,
  TrackViewFilterProps,
  buildSearchTypeList,
  buildTableLinks,
  filterToMinCodesVars,
  getEarliestDataDate,
  getWorkspaceParams,
  MinimalParamDetails,
} from '../helpers/track-view'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import useUrlValidation from '../hooks/useUrlValidation'
import { getUserData, saveUserData } from '../helpers/local-client'
import styles from '../styles/track-view-table.module.scss'
import {
  GetStoredCodesStatsQuickQuery,
  GetUniqueParamCurrentTotalQuery,
  LandingPageIgnore,
} from '../__gql-types__/graphql'

type EditLinkValueModalProps =
  | { visible: false }
  | {
      visible: true
      paramID?: string
      fullLink: TableLinkWithData
      cacheIndex?: number
    }

interface EditLinkParameterModalProps {
  paramID: string
  linkToEdit: TableLinkWithData
  onToggle: () => void
  cacheID?: string
  cacheIndex?: number
}

const EditLinkParameterModal = ({
  paramID,
  linkToEdit,
  onToggle,
  cacheID,
  cacheIndex,
}: EditLinkParameterModalProps) => {
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const [editCode, { loading, error }] = useMutation(updateCode)

  const { linkID, params } = linkToEdit || {}

  const initialValue =
    getItemByKeyValue(params, 'paramID', paramID)?.paramValue || ''

  const [updatedValue, setUpdatedValue] = useState(initialValue)

  const saveValue = useCallback(
    async (value: string) => {
      await editCode({
        variables: {
          codeID: linkID,
          paramDefs: params?.map(({ paramID: fieldID, paramValue }) => ({
            fieldID,
            optionName: fieldID === paramID ? value : paramValue,
          })),
        },
        update: (cache, { data }) => {
          if (cacheID && cacheIndex !== undefined && data) {
            cache.modify({
              id: cacheID,
              fields: {
                codeDef: (existingCodeDef) => {
                  if (!existingCodeDef) return existingCodeDef

                  const currentCodeDef = [
                    ...existingCodeDef[cacheIndex],
                  ] as string[]

                  const editedParamIndex = currentCodeDef.indexOf(initialValue)

                  if (editedParamIndex > -1) {
                    currentCodeDef.splice(editedParamIndex, 1, value)
                  }

                  const newCodeDef = _.cloneDeep(existingCodeDef)

                  newCodeDef.splice(cacheIndex, 1, currentCodeDef)

                  return newCodeDef
                },
              },
            })
          }
        },
      })

      await logAction({
        variables: {
          action: `update-code-parameter`,
          functionName: 'saveValue',
          extra: JSON.stringify({
            codeID: linkID,
            fieldID: paramID,
            newName: updatedValue,
            prevName: initialValue,
          }),
          pagePath: '/track/view-links',
          websiteSection: 'track',
        },
      })

      onToggle()
    },
    [linkID, initialValue],
  )

  return (
    <Modal
      setIsOpen={() => onToggle()}
      isWarning
      headerColor="pink"
      modalHeader="Edit this parameter?"
      noText="Cancel"
      yesText="Save"
      yesButtonDisabled={initialValue === updatedValue}
      yesButtonLoading={loading}
      onYes={async () => {
        await saveValue(updatedValue)
      }}
      beforeClose={() => {
        logAction({
          variables: {
            action: 'cancel-edit-modal-parameter',
            pagePath: '/track/view-links',
            functionName: 'closeModal',
            websiteSection: 'track',
          },
        })
      }}
      footerContent={
        error ? (
          <p className={styles.footNoteError}>
            Error updating parameter. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </p>
        ) : undefined
      }
    >
      <p>
        This action will recategorise all data associated with this link{' '}
        <strong>
          in this tool
          {dataSource && dataSource.connectionSource === 'adobe'
            ? ' and Adobe Analytics'
            : ', but not Google Analytics'}
        </strong>
        .
      </p>
      <p>
        It will not automatically change the landing page with campaign code,
        however you can edit this separately.
      </p>
      <p>If you understand the impact, edit below and save:</p>
      <Input
        id={`link-${linkID}`}
        name="name"
        value={updatedValue}
        onKeyUp={async (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            await saveValue(updatedValue)
          }
        }}
        beforeChange={(newValue) => newValue.replace(/\n|\r/gi, '')}
        onValueChange={(value) => setUpdatedValue(value)}
      />
    </Modal>
  )
}

interface EditLinkUrlModalProps {
  linkToEdit: TableLinkWithData
  onToggle: (refetchData?: boolean) => Promise<void>
  masterPrefix?: string
  uplifterIdData?: GetUniqueParamCurrentTotalQuery
  cacheID?: string
  cacheIndex?: number
}

const EditLinkUrlModal = ({
  linkToEdit,
  onToggle,
  masterPrefix = '?',
  uplifterIdData,
  cacheID,
  cacheIndex,
}: EditLinkUrlModalProps) => {
  const { linkID, fullLink, shortLink, deepLinkServiceID: deepLinkDomain } =
    linkToEdit || {}

  const logAction = useLogAction()
  const { availableShortLinkDomains } = useCustomLinks()
  const {
    quick,
    intensive,
    validateUrls,
    validationResults,
  } = useUrlValidation()

  const [
    fetchDeepLinkDetails,
    {
      data: deepLinkDetailsData,
      loading: fetchingDeepLinkDetails,
      error: deepLinkDetailsFetchError,
    },
  ] = useLazyQuery(getDeepLinkDetails)

  const [editCode, { loading, error }] = useMutation(updateCode)

  const [updatedValue, setUpdatedValue] = useState('')
  const [uplifterIDMismatch, setUplifterIDMismatch] = useState(false)

  // Get short link domain ID
  const customDomainID = useMemo(() => {
    const domainIDToFind = getCustomDomainID(getDomain(shortLink || '')) || ''

    return availableShortLinkDomains.find(
      (dom) => dom.domainName === domainIDToFind.replace('https://', ''),
    )?.domainID
  }, [shortLink, availableShortLinkDomains])

  // Get full deep link data if deepLinkServiceID is present
  useEffect(() => {
    if (deepLinkDomain && linkID) {
      fetchDeepLinkDetails({
        variables: {
          codeID: linkID,
          deepLinkServiceID: deepLinkDomain,
        },
      })
    }
  }, [deepLinkDomain, linkID])

  const deepLinkConfig = useMemo(() => {
    if (!deepLinkDetailsData) return null

    return deepLinkDetailsData.track.deepLinkQueries.getDeeplinkDetails
  }, [deepLinkDetailsData])

  const initialLinkValue = useMemo(() => {
    if (fetchingDeepLinkDetails) return null

    // If link is a deep link, the value for `fullLink` contains unnecessary text
    // Need to build the link from deepLinkConfig
    return deepLinkConfig
      ? `${deepLinkConfig.fallBackURL}${masterPrefix}${deepLinkConfig.analyticsContext}`
      : fullLink
  }, [deepLinkConfig])

  // Initialise field values and reset when updated
  useEffect(() => {
    if (initialLinkValue) {
      setUpdatedValue(initialLinkValue)
    }
  }, [initialLinkValue])

  // Prevents editing UplifterID if present in initialLinkValue
  const formatInput = useCallback(
    (input: string) => {
      setUplifterIDMismatch(false)

      // Remove new line if present
      const cleanedInput = input.replace(/\n|\r/gi, '')

      if (!uplifterIdData) return cleanedInput

      // Do not allow users to edit the Uplifter ID
      const {
        isEnabled,
        prefix,
        acctPrefix,
      } = uplifterIdData.track.currentSequentialCodeID

      // URL being edited did not have an Uplifter ID
      if (
        !isEnabled ||
        !prefix ||
        updatedValue.indexOf(`${prefix}${acctPrefix}`) === -1
      ) {
        return cleanedInput
      }

      if (cleanedInput.indexOf(prefix) === -1) {
        setUplifterIDMismatch(true)
        return updatedValue
      }

      const origSplitVals = updatedValue.split(prefix)
      const newSplitVals = cleanedInput.split(prefix)

      if (newSplitVals.length > 1 && origSplitVals.length > 1) {
        const useOrigAnchor = getAnchorFromString(updatedValue)

        const useNewAnchor = getAnchorFromString(cleanedInput)

        const origFullIdString = `${prefix}${
          origSplitVals[origSplitVals.length - 1]
        }`.replace(useOrigAnchor, '')

        const newFullIdString = `${prefix}${
          newSplitVals[newSplitVals.length - 1]
        }`.replace(useNewAnchor, '')

        // Do not add change if trying to update Uplifter ID
        if (origFullIdString !== newFullIdString) {
          setUplifterIDMismatch(true)
          return updatedValue
        }
      }

      return cleanedInput
    },
    [uplifterIdData, updatedValue],
  )

  const saveValue = useCallback(
    async (value: string) => {
      const splitUrl = value.split(masterPrefix)

      await editCode({
        variables: {
          codeID: linkID,
          fullCode: deepLinkConfig ? undefined : value,
          customDomainID: customDomainID || undefined,
          deepLinkConfig: deepLinkConfig
            ? {
                appGroupID: deepLinkConfig.appGroupID,
                deepLinkServiceID: deepLinkConfig.deepLinkServiceID,
                deepLinkShortLink: deepLinkConfig.deepLinkShortLink,
                fallBackURL: splitUrl[0],
                analyticsContext: splitUrl[1],
                // ! Redirect context needs an array of optionIDs from the appGroup's Context options
                // Can only fetch that based on the optionValue, from the redirectContext in getDeepLinkDetails, which is a stringified JSON object
                // redirectContext: ['<Array of selected optionIDs>'],
              }
            : undefined,
        },
        update: (cache, { data }) => {
          if (data) {
            if (deepLinkConfig) {
              const deepLinkCacheID = `DeepLink:{"campaignCodeID":"${linkID}"}`

              cache.modify({
                id: deepLinkCacheID,
                fields: {
                  fallBackURL: () => splitUrl[0],
                  analyticsContext: () => splitUrl[1],
                },
              })

              return
            }

            if (cacheID && cacheIndex) {
              cache.modify({
                id: cacheID,
                fields: {
                  fullLink: (existingFullLink) => {
                    if (!existingFullLink) return existingFullLink

                    const newCodeDef = _.cloneDeep(existingFullLink)

                    newCodeDef.splice(cacheIndex, 1, value)

                    return newCodeDef
                  },
                },
              })
            }
          }
        },
      })

      await logAction({
        variables: {
          action: `update-code-link`,
          functionName: 'saveValue',
          extra: JSON.stringify({
            codeID: linkID,
            newLink: value,
            prevLink: initialLinkValue,
          }),
          pagePath: '/track/view-links',
          websiteSection: 'track',
        },
      })

      await onToggle(!!deepLinkConfig)
    },
    [linkID, customDomainID, deepLinkConfig, masterPrefix],
  )

  return (
    <Modal
      setIsOpen={() => onToggle()}
      isWarning
      headerColor="pink"
      modalHeader={
        deepLinkDomain
          ? 'Edit the fallback url for this app link?'
          : 'Edit this destination with parameters?'
      }
      noText="Cancel"
      yesText="Save"
      yesButtonDisabled={initialLinkValue === updatedValue}
      yesButtonLoading={loading}
      onYes={async () => {
        await saveValue(updatedValue)
      }}
      beforeClose={() => {
        logAction({
          variables: {
            action: `cancel-edit-modal-link`,
            pagePath: '/track/view-links',
            functionName: 'closeModal',
            websiteSection: 'track',
          },
        })
      }}
      footerContent={
        error ? (
          <ErrorMessage>
            Error updating link. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
          </ErrorMessage>
        ) : undefined
      }
    >
      <p>This action will change:</p>
      <ul>
        <li>
          {deepLinkDomain
            ? 'The fallback URL for this app link'
            : 'The landing page for live short links'}
        </li>
        {deepLinkDomain && <li>The campaign data passed to the app</li>}
        <li>The metric data matched and returned in this tool</li>
      </ul>
      <p>If this link has been used, we recommend creating a new link.</p>
      <p>If you understand the impact, edit below and save:</p>
      {fetchingDeepLinkDetails ? (
        <Preloader />
      ) : (
        <>
          {deepLinkDetailsFetchError ? (
            <p className={styles.footNoteError}>
              Error fetching link details. Please contact{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>.
            </p>
          ) : (
            <>
              <Input
                id={`link-${linkID}`}
                name="name"
                value={updatedValue}
                delay={800}
                type="textArea"
                multilineInput
                maxLength={2048}
                beforeChange={(newValue) => formatInput(newValue)}
                onValueChange={(value) => {
                  setUpdatedValue(value)

                  const landingPage = value.split(masterPrefix)

                  // Check landing page status
                  if (isValidUrl(landingPage[0])) {
                    const newUrl = landingPage[0]

                    validateUrls([newUrl])
                  }
                }}
                onKeyUp={async (
                  event: React.KeyboardEvent<HTMLInputElement>,
                ) => {
                  if (event.key === 'Enter') {
                    await saveValue(updatedValue)
                  }
                }}
              />
              {uplifterIDMismatch && (
                <ErrorMessage>Can't edit Smart link ID.</ErrorMessage>
              )}
              {isValidUrl(updatedValue) &&
                initialLinkValue !== updatedValue &&
                (quick || intensive) && (
                  <UrlValidationMessage
                    key={updatedValue}
                    url={updatedValue.split(masterPrefix)[0]}
                    validationDetails={
                      validationResults[updatedValue.split(masterPrefix)[0]]
                    }
                  />
                )}
              {/* TODO: Add deepLinkConfig fields (redirectContext) */}
              {/* {deepLinkConfig && (
            <>
              <p>App link TBC</p>
              <p>
                <code>{JSON.stringify(deepLinkConfig, null, 2)}</code>
              </p>
            </>
          )} */}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

interface UpdateLandingPageAlertsStatusModalProps {
  onToggle: () => void
  loading: boolean
  /** If the user clicked from an email link, the modal should always be in 'disable' state */
  fromEmail?: boolean
  landingPage?: string
  landingPageAlertEmailIgnoreList?: { [landingPage: string]: LandingPageIgnore }
  onUpdateAlertStatus: (newStatus?: LandingPageIgnore) => void
}

const UpdateLandingPageAlertsStatusModal = ({
  onToggle,
  loading,
  fromEmail,
  landingPage = '',
  landingPageAlertEmailIgnoreList = {},
  onUpdateAlertStatus,
}: UpdateLandingPageAlertsStatusModalProps) => {
  const logAction = useLogAction()

  const { data: emailNotificationsData } = useQuery(getEmailNotifications, {
    fetchPolicy: 'cache-first',
  })

  // If the current status exists, the landing page is already ignored
  const updateToDisabled =
    fromEmail || !landingPageAlertEmailIgnoreList[landingPage]

  const [
    disableLandingPageAlerts,
    { loading: disablingAlerts, error: disablingError },
  ] = useMutation(addTrackValidationIgnore)
  const [
    enableLandingPageAlerts,
    { loading: enablingAlerts, error: enablingError },
  ] = useMutation(deleteTrackValidationIgnore)

  const [updateSuccess, setUpdateSuccess] = useState(false)

  if (!landingPage) return null

  if (updateSuccess) {
    return (
      <Modal
        setIsOpen={onToggle}
        modalHeader={`${
          updateToDisabled ? 'Disable' : 'Enable'
        } alerts for this landing page?`}
        noText="Close"
      >
        <SuccessText>Landing page alerts status updated.</SuccessText>
      </Modal>
    )
  }

  if (
    emailNotificationsData?.currentUser.allTrackValidationAlertEmail === false
  ) {
    return (
      <Modal
        setIsOpen={onToggle}
        modalHeader={`${
          updateToDisabled ? 'Disable' : 'Enable'
        } alerts for this landing page?`}
      >
        <p>
          You have already disabled all landing page alert emails. You can
          manage this on the{' '}
          <BoxedText>
            <Link href="/settings?show=preferences">Settings page</Link>
          </BoxedText>
          .
        </p>
      </Modal>
    )
  }

  if (fromEmail && !!landingPageAlertEmailIgnoreList[landingPage]) {
    return (
      <Modal
        setIsOpen={onToggle}
        modalHeader="Disable alerts for this landing page?"
        yesText="Re-enable alerts"
        onYes={async () => {
          await enableLandingPageAlerts({
            variables: {
              ignoreID: landingPageAlertEmailIgnoreList[landingPage].ignoreID,
            },
          })

          logAction({
            variables: {
              action: 'enable-landing-page-alerts',
              pagePath: '/track/view-links',
              functionName: 'updateLandingPageAlertsStatus',
              websiteSection: 'track',
              extra: landingPage,
            },
          })

          onUpdateAlertStatus()

          setUpdateSuccess(true)
        }}
      >
        <p>
          You have already disabled alert emails for the landing page{' '}
          <BoldText>{landingPage}</BoldText>.
        </p>
        <p>Would you like to re-enable them?</p>
      </Modal>
    )
  }

  return (
    <Modal
      setIsOpen={onToggle}
      modalHeader={`${
        updateToDisabled ? 'Disable' : 'Enable'
      } alerts for this landing page?`}
      loading={loading}
      noText={updateSuccess ? 'Close' : 'Cancel'}
      yesText={`${updateToDisabled ? 'Disable' : 'Enable'} alerts`}
      yesButtonLoading={disablingAlerts || enablingAlerts}
      yesButtonDisabled={!!disablingError || !!enablingError}
      onYes={async () => {
        let newStatus: LandingPageIgnore | undefined

        if (updateToDisabled) {
          const { data } = await disableLandingPageAlerts({
            variables: {
              landingPage,
              applyToAccount: false,
              applyToUser: true,
              validURL: true,
            },
          })

          newStatus = data?.track?.addTrackValidationIgnore[0]
        } else {
          await enableLandingPageAlerts({
            variables: {
              ignoreID: landingPageAlertEmailIgnoreList[landingPage].ignoreID,
            },
          })
        }

        logAction({
          variables: {
            action: `${
              updateToDisabled ? 'disable' : 'enable'
            }-landing-page-alerts`,
            pagePath: '/track/view-links',
            functionName: 'updateLandingPageAlertsStatus',
            websiteSection: 'track',
            extra: landingPage,
          },
        })

        onUpdateAlertStatus(newStatus)

        setUpdateSuccess(true)
      }}
      footerContent={
        !!disablingError || !!enablingError ? (
          <ErrorMessage showSupport>Error updating alerts.</ErrorMessage>
        ) : undefined
      }
    >
      <p>
        You will {updateToDisabled ? 'no longer ' : ''}
        receive email alerts for <BoldText>{landingPage}</BoldText> if the page
        stops working.
      </p>
    </Modal>
  )
}

interface TrackViewSearchProps {
  filter: TrackViewFilterProps
  setFilter: React.Dispatch<React.SetStateAction<TrackViewFilterProps>>
  refetchData: (
    queryVars: TrackViewFilterProps,
    recalculateTotals?: boolean,
  ) => Promise<void>
  loading?: boolean
  searchTypeList: { name: string; value: string }[]
  totalLinks?: number
  currentLinks?: number | null
  hasUsedGlobalFilter?: boolean
}

export const TrackViewSearch = memo(
  ({
    filter,
    setFilter,
    refetchData,
    loading = false,
    searchTypeList,
    totalLinks = 0,
    currentLinks = null,
    hasUsedGlobalFilter,
  }: TrackViewSearchProps) => {
    const logAction = useLogAction()

    const [currentSearchFilter, setCurrentSearchFilter] = useState<
      Pick<TrackViewFilterProps, 'searchType' | 'searchTerm' | 'isMetric'>
    >({ searchType: filter.searchType, searchTerm: filter.searchTerm })

    useEffect(() => {
      setCurrentSearchFilter((curr) => ({
        ...curr,
        searchType: filter.searchType,
        searchTerm: filter.searchTerm,
      }))
    }, [filter])

    // Check if a filter has been applied
    const isFiltered = !!filter.searchTerm || filter.searchType !== 'any'

    // Filters can only be updated if they have been changed
    const currentFilterMatchesSetFilter =
      currentSearchFilter.searchTerm === filter.searchTerm &&
      currentSearchFilter.searchType === filter.searchType

    // A non-empty filter has been applied and the new one being set is empty
    const currentFilterIsEmpty =
      (filter.searchType !== 'any' || !!filter.searchTerm) &&
      currentSearchFilter.searchTerm === '' &&
      currentSearchFilter.searchType === 'any'

    // Button should be enabled to clear the current filter or apply a changed filter
    const searchButtonDisabled =
      loading ||
      (!isFiltered && currentFilterMatchesSetFilter) ||
      (!currentFilterMatchesSetFilter && !currentSearchFilter.searchTerm)

    const showClear =
      loading ||
      (isFiltered && currentFilterMatchesSetFilter) ||
      currentFilterIsEmpty

    const applyFilter = () => {
      const filterToApply = showClear
        ? {
            searchType: 'any',
            searchTerm: '',
          }
        : currentSearchFilter

      // Need to fetch metric data first in this case
      if (filterToApply.searchType === 'status') {
        filterToApply.isMetric = true
      }

      // Reset currentfilter
      if (showClear) {
        setCurrentSearchFilter(filterToApply)
      }

      setFilter((curr) => ({
        ...curr,
        ...filterToApply,
        activePage: 1,
      }))

      refetchData({
        ...filter,
        ...filterToApply,
        activePage: 1,
        sortBy: filter.bidirectionalSortKey || filter.sortBy,
      })
    }

    return (
      <SearchInput
        id="track-view-search"
        searchTypeList={searchTypeList}
        selectValue={currentSearchFilter.searchType}
        onChangeSearchType={(searchType) => {
          setCurrentSearchFilter((curr) => ({
            ...curr,
            searchType,
          }))
        }}
        value={currentSearchFilter.searchTerm}
        onChange={(newValue) => {
          setCurrentSearchFilter((curr) => ({
            ...curr,
            searchTerm: newValue || '',
          }))
        }}
        onKeyUp={(e) => {
          if (
            !searchButtonDisabled &&
            !currentFilterMatchesSetFilter &&
            e.key === 'Enter'
          ) {
            applyFilter()
          }
        }}
      >
        <div className={styles.searchSummary}>
          <Button
            variant="secondary"
            className={styles.searchButton}
            isDisabled={searchButtonDisabled}
            onPress={applyFilter}
          >
            {showClear ? 'Clear' : 'Search'}
          </Button>
          {loading ? (
            <>
              <Preloader className={styles.searchPreloader} />
              <LoadingLabel label="Retrieving links" />
            </>
          ) : (
            <div className={styles.switchContainer}>
              <Toggle
                checked={!!filter.filterByCurrentUser}
                checkedIcon={<PersonIcon className={styles.toggleIcon} />}
                uncheckedIcon={<PeopleIcon className={styles.toggleIcon} />}
                onChange={async () => {
                  const newFilterByCurrentUser = !filter.filterByCurrentUser

                  saveUserData({
                    trackViewYourLinks: newFilterByCurrentUser,
                  })

                  const newFilter = {
                    ...filter,
                    activePage: 1,
                    sortBy: filter.bidirectionalSortKey || filter.sortBy,
                    filterByCurrentUser: newFilterByCurrentUser,
                  }

                  setFilter(newFilter)

                  // Refetch
                  await refetchData(
                    newFilter,
                    // Only refetch totals if the 'All links' option has not been previously set
                    !newFilterByCurrentUser && !hasUsedGlobalFilter,
                  )

                  logAction({
                    variables: {
                      action: 'track-view-toggle-your-links',
                      pagePath: '/track/view-links',
                      functionName: 'yourLinksToggle',
                      websiteSection: 'track',
                      extra: newFilterByCurrentUser
                        ? 'Filter by user'
                        : 'All users',
                    },
                  })
                }}
              />
              <p className={styles.searchCount}>
                {filter.filterByCurrentUser ? 'Your' : 'All'} links{' '}
                {typeof currentLinks === 'number' && currentLinks < totalLinks
                  ? `${numeral(currentLinks).format('0,0')}/`
                  : ''}
                {numeral(totalLinks).format('0,0')}
              </p>
            </div>
          )}
        </div>
      </SearchInput>
    )
  },
)

interface TrackViewMetricsProps {
  codesMetricData?: GetStoredCodesStatsQuickQuery
  loading?: boolean
  selectedMetrics: MetricDropdownItem[]
  setSelectedMetrics: React.Dispatch<React.SetStateAction<MetricDropdownItem[]>>
}

export const TrackViewMetrics = memo(
  ({
    codesMetricData,
    loading = false,
    selectedMetrics,
    setSelectedMetrics,
  }: TrackViewMetricsProps) => {
    const [metricsList, setMetricList] = useState<MetricDropdownItem[]>([])
    const [earliestDataDate, setEarliestDataDate] = useState<number | null>(
      null,
    )

    const earliestClickDate = codesMetricData
      ? codesMetricData.track.storedCodeStatsQuick?.earliestClickDate || null
      : null

    const earliestMetricDate = codesMetricData
      ? codesMetricData.track.storedCodeStatsQuick?.earliestMetricDate || null
      : null

    const earliestDeepLinkDate = codesMetricData
      ? codesMetricData.track.storedCodeStatsQuick?.earliestDeepLinkDate || null
      : null

    useEffect(() => {
      if (!codesMetricData || Object.keys(codesMetricData).length === 0) return

      const {
        track: { storedCodeStatsQuick },
      } = codesMetricData

      const metrics = storedCodeStatsQuick.metricValues
        .map(({ metricID, displayName, helpText }) => ({
          metricID,
          displayName,
          helpText,
        }))
        // Don't show the following metrics while they're broken
        .filter(
          ({ metricID }) =>
            [
              'shortLinkInitialClicks',
              'shortLinkReturningClicks',
              'shortLinkReturningClicksPercentage',
            ].indexOf(metricID) === -1,
        )
        // Only show metrics if data exists for them
        .filter(({ metricID }) => {
          const metricsToShow: string[] = []

          if (earliestClickDate) metricsToShow.push('shortLinkClicks')
          if (earliestDeepLinkDate) {
            metricsToShow.push('deepLinkClicks')
            metricsToShow.push('deepLinkInAppClicks')
            metricsToShow.push('deepLinkClicksToAppStore')
          }

          if (!earliestMetricDate) return metricsToShow.includes(metricID)

          // Show all metrics, unless Uplifter metric has no data
          return allUplifterMetrics.includes(metricID)
            ? metricsToShow.includes(metricID)
            : true
        })

      setMetricList(metrics)
      setEarliestDataDate(getEarliestDataDate(codesMetricData))
    }, [codesMetricData])

    let placeholder = 'No metrics available'

    if (loading) {
      placeholder = 'Loading metrics'
    } else if (earliestDataDate) {
      placeholder = `Metrics (from ${moment(new Date(earliestDataDate)).format(
        dateFormatShort,
      )})`
    }

    return (
      <SelectBoxChecklist
        id="select-key-metrics"
        className={styles.metricsSelector}
        allLabel="All metrics"
        isLoading={loading}
        isDisabled={metricsList.length === 0 && !loading}
        placeholder={placeholder}
        labelKey="displayName"
        valueKey="metricID"
        value={metricsList.filter((metric) =>
          selectedMetrics.find(({ metricID }) => metricID === metric.metricID),
        )}
        options={metricsList}
        onChange={(newValue) => {
          setSelectedMetrics(newValue as MetricDropdownItem[])
        }}
      />
    )
  },
)

interface TrackViewActionsProps {
  selectedLinks: string[]
  filter: TrackViewFilterProps
  tableRows: TableLinkWithData[]
  loadingLinks?: boolean
  loadingStats?: boolean
  refetchData: () => Promise<void>
  workspaceID: string
  masterPrefix?: string
  paramDefs: MinimalParamDetails[]
  cacheID?: string
  cacheIndexOffset?: number
  rowsPerPage?: number
}

interface ActionStatus {
  action: string
  loading: boolean
  success?: string
  error?: string
}

export const TrackViewActions = memo(
  ({
    selectedLinks,
    filter,
    tableRows,
    loadingLinks,
    loadingStats,
    refetchData,
    workspaceID,
    masterPrefix,
    paramDefs,
    cacheID,
    cacheIndexOffset = 0,
    rowsPerPage,
  }: TrackViewActionsProps) => {
    const { userPermission } = useReactiveVar(currentUserDetails)

    const dataSource = useReactiveVar(dataSourceReactive)

    const logAction = useLogAction()

    const { quick, intensive } = useUrlValidation()

    const history = useHistory()

    const [requestFeature] = useMutation(sendFeatureRequest)

    const [actionStatus, setActionStatus] = useState<ActionStatus | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showBulkEditModal, setShowBulkEditModal] = useState(false)
    const [showBulkImportModal, setShowBulkImportModal] = useState(false)
    const [showQrModal, setShowQrModal] = useState(false)
    const [showObservepointModal, setShowObservepointModal] = useState(false)

    const [shareModalState, setShareModalState] = useState<ShareModalState>({
      active: false,
      shared: false,
      typedValue: '',
      shareEmails: [],
      note: '',
      subject: '',
    })

    const selectedLinksFull = tableRows
      .filter((row) => selectedLinks.includes(row.linkID))
      .map(
        ({
          linkID,
          deepLinkServiceID,
          fullLink,
          shortLink,
          author,
          params,
        }) => ({
          cID: linkID,
          sL: shortLink || '',
          deepLinkServiceID,
          c: fullLink,
          e: author,
          params,
        }),
      )

    const selectedLinksIndexes = useMemo(() => {
      const _selectedLinksIndexes: number[] = []

      selectedLinks.forEach((linkID) => {
        const index = tableRows.findIndex((row) => row.linkID === linkID)

        if (index > -1) {
          _selectedLinksIndexes.push(index + cacheIndexOffset)
        }
      })

      return _selectedLinksIndexes
    }, [cacheIndexOffset, selectedLinks, tableRows])

    return (
      <>
        <div>
          <ButtonDropdown
            buttonText="Actions"
            containerClassName={styles.actionsButton}
            loading={actionStatus?.loading}
          >
            {/* Download codes */}
            <DropdownButtonItem
              key="downloadCodes"
              isDisabled={
                loadingLinks ||
                loadingStats ||
                (actionStatus?.action === 'downloadCodes' &&
                  actionStatus?.loading)
              }
              onPress={async () => {
                setActionStatus({
                  action: 'downloadCodes',
                  loading: true,
                })

                const data: DownloadCodes = {
                  includeLandingPageStatus: quick || intensive,
                }

                if (selectedLinks.length > 0) {
                  data.codeIDList = [...selectedLinks]
                } else {
                  const {
                    dimensionFilter,
                    orderBy,
                    filterByCurrentUser,
                  } = filterToMinCodesVars(filter)

                  data.dimensionFilter = dimensionFilter
                  data.orderBy = orderBy
                    ? {
                        ...orderBy,
                        sortField:
                          filter.bidirectionalSortKey || orderBy?.sortField,
                      }
                    : undefined
                  data.filterByCurrentUser = filterByCurrentUser
                }

                const csv = await downloadCodesByAccount(data, true)

                if (!csv) {
                  setActionStatus({
                    action: 'downloadCodes',
                    loading: false,
                    error:
                      'Unable to download codes. Please try again or contact support.',
                  })

                  return
                }

                await downloadSpecificCodes(csv, true)

                logAction({
                  variables: {
                    action: 'download-codes',
                    functionName: 'downloadCodesByAcciont',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra:
                      selectedLinks.length > 0
                        ? JSON.stringify(selectedLinks)
                        : 'All codes',
                  },
                })

                setActionStatus(null)
              }}
            >
              {selectedLinks.length === 0 ? (
                <>Download all {filter.searchTerm ? '(filtered)' : ''}</>
              ) : (
                `Download selected (${numeral(selectedLinks.length).format(
                  '0,0',
                )})`
              )}
            </DropdownButtonItem>
            {/* Share codes */}
            <DropdownButtonItem
              key="shareCodes"
              isDisabled={selectedLinks.length === 0}
              onPress={() => {
                setShareModalState({
                  active: true,
                  shared: false,
                  typedValue: '',
                  shareEmails: [],
                  note: '',
                  subject: '',
                })
              }}
            >
              <Tooltip
                id="share-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Share selected ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Delete codes */}
            <DropdownButtonItem
              key="deleteCodes"
              isDisabled={selectedLinks.length === 0}
              onPress={() => setShowDeleteModal(true)}
            >
              <Tooltip
                id="delete-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Delete selected ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Clone and edit */}
            <DropdownButtonItem
              key="cloneAndEdit"
              isDisabled={selectedLinks.length === 0}
              onPress={() => {
                const {
                  urls,
                  generatorParameterValues,
                } = cloneAndEditBuildForm(
                  tableRows,
                  selectedLinks,
                  masterPrefix,
                  paramDefs,
                )

                const data: {
                  [paramID: string]: { optionValue: string[] }
                } = {}

                Object.entries(generatorParameterValues).forEach(
                  ([paramID, paramValue]) => {
                    data[paramID] = { optionValue: paramValue }
                  },
                )

                saveTrackCreateFormData(workspaceID, 'web', {
                  linkTo:
                    selectedLinksFull.length > 1 ||
                    !selectedLinksFull[0].deepLinkServiceID
                      ? 'url'
                      : 'app',
                  url: urls,
                  generatorParameterValues,
                  // TODO: Add app values when cloning app links
                  // Might be tricky, don't have easy access to that data here
                  // appValues: null,
                })

                logAction({
                  variables: {
                    action: 'clicked-clone-and-edit',
                    functionName: 'cloneAndEdit',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })

                history.push('/track/create-links')
              }}
            >
              <Tooltip
                id="clone-links-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Clone and edit
              </Tooltip>
            </DropdownButtonItem>
            <React.Fragment key="adminActions">
              {isAdminUser(userPermission) && (
                <>
                  {/* Bulk edit */}
                  <DropdownButtonItem
                    onPress={() => setShowBulkEditModal(true)}
                  >
                    Bulk edit
                  </DropdownButtonItem>
                  {/* Import historical codes */}
                  <DropdownButtonItem
                    onPress={() => setShowBulkImportModal(true)}
                  >
                    Import historical links
                  </DropdownButtonItem>
                </>
              )}
            </React.Fragment>
            {/* Download QR code */}
            <DropdownButtonItem
              key="qrCode"
              isDisabled={selectedLinks.length !== 1}
              onPress={() => setShowQrModal(true)}
            >
              <Tooltip
                id="qr-code-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select a single link first to perform this action.'
                    : ''
                }
              >
                Download QR code
              </Tooltip>
            </DropdownButtonItem>
            {/* Copy short links */}
            <DropdownButtonItem
              key="copyShortLinks"
              isDisabled={
                selectedLinksFull.filter(({ sL }) => !!sL).length === 0
              }
              onPress={() => {
                const selectedShortLinks = selectedLinksFull.map(
                  (selCode) => selCode.sL,
                )

                copyString(
                  selectedShortLinks.length < 2
                    ? selectedShortLinks[0]
                    : selectedShortLinks.join('\n'),
                )

                logAction({
                  variables: {
                    action: 'copy-codes-short-links',
                    functionName: '',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })
              }}
            >
              <Tooltip
                id="short-link-copy-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Copy short links ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            {/* Copy full links */}
            <DropdownButtonItem
              key="copyFullLinks"
              isDisabled={selectedLinksFull.length === 0}
              onPress={() => {
                const selectedLongLinks = selectedLinksFull.map(
                  (selCode) => selCode.c,
                )

                copyString(
                  selectedLongLinks.length < 2
                    ? selectedLongLinks[0]
                    : selectedLongLinks.join('\n'),
                )

                logAction({
                  variables: {
                    action: 'copy-codes-full-links',
                    functionName: '',
                    pagePath: '/track/view-links',
                    websiteSection: 'track',
                    extra: JSON.stringify(selectedLinks),
                  },
                })
              }}
            >
              <Tooltip
                id="full-link-copy-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinks.length === 0
                    ? 'Select some links first to perform this action.'
                    : ''
                }
              >
                Copy full links ({numeral(selectedLinks.length).format('0,0')})
              </Tooltip>
            </DropdownButtonItem>
            <DropdownLabel key="observepointLabel">
              <img
                src={ObservepointLogo}
                className={styles.observepointIcon}
                alt="Observepoint"
              />
            </DropdownLabel>
            {/* Observepoint page scan */}
            <DropdownButtonItem
              key="observepointScan"
              isDisabled={selectedLinksFull.length !== 1}
              onPress={() => {
                setShowObservepointModal(true)

                logAction({
                  variables: {
                    action: 'open-observepoint-scan-modal',
                    functionName: 'observePointScan',
                    pagePath: window.location.pathname,
                    websiteSection: 'track',
                    extra: selectedLinksFull[0].c,
                  },
                })
              }}
            >
              <Tooltip
                id="scan-page-tooltip"
                tooltipPosition="left"
                maxWidth={200}
                tooltipMessage={
                  selectedLinksFull.length === 0
                    ? 'Select a single link first to perform this action.'
                    : ''
                }
              >
                Scan page for issues
              </Tooltip>
            </DropdownButtonItem>
            <React.Fragment key="adobeActions">
              {dataSource && dataSource.connectionSource === 'adobe' && (
                <>
                  <DropdownLabel key="adobeLabel">
                    <img
                      src="/aa-logo.svg"
                      alt="AA"
                      style={{
                        marginTop: -2,
                        width: 18,
                        verticalAlign: 'middle',
                        marginRight: 8,
                      }}
                    />
                    <>Adobe Analytics</>
                  </DropdownLabel>
                  {/* Export to Adobe */}
                  <DropdownButtonItem
                    key="adobeExport"
                    className={styles.softDisable}
                    onPress={async () => {
                      // ! V1 endpoint no longer used
                      // await make.get({ endpoint: 'generate-saint-file' })

                      logAction({
                        variables: {
                          action: 'click-generate-saint-file',
                          websiteSection: 'track',
                          pagePath: window.location.pathname,
                          functionName: 'clickGenerateSaintFile',
                          extra: 'form',
                        },
                      })

                      requestFeature({
                        variables: {
                          page: 'track>view',
                          message:
                            'API V1 request triggered: generateSaintFile',
                        },
                      })
                    }}
                  >
                    Export to Adobe
                  </DropdownButtonItem>
                  {/* Download Adobe file */}
                  <DropdownButtonItem
                    key="adobeFile"
                    onPress={async () => {
                      setActionStatus({
                        action: 'adobeFile',
                        loading: true,
                      })

                      await getAdobeCodesDownloadLink()

                      setActionStatus(null)
                    }}
                  >
                    Download Adobe file
                  </DropdownButtonItem>
                </>
              )}
            </React.Fragment>
          </ButtonDropdown>
          {actionStatus?.error && (
            <p className={classNames(styles.actionMsg, styles.error)}>
              {actionStatus.error}
            </p>
          )}
          {actionStatus?.success && (
            <p className={classNames(styles.actionMsg, styles.success)}>
              {actionStatus.success}
            </p>
          )}
        </div>
        {showDeleteModal && (
          <BulkDeleteModal
            setShowModal={setShowDeleteModal}
            selectedCodes={selectedLinksFull}
            refetchData={refetchData}
            cacheID={cacheID}
            cacheIndexes={selectedLinksIndexes}
            cacheIndexOffset={cacheIndexOffset}
            rowsPerPage={rowsPerPage}
          />
        )}
        {shareModalState.active && selectedLinks.length > 0 && (
          <ShareCampaignCodesModal
            selectedCodes={selectedLinks}
            shareModalState={shareModalState}
            setShareModalState={setShareModalState}
          />
        )}
        {showBulkEditModal && <BulkEdit setShowModal={setShowBulkEditModal} />}
        {showBulkImportModal && (
          <BulkImportHistorical
            setShowModal={setShowBulkImportModal}
            refetchData={refetchData}
          />
        )}
        {showQrModal && selectedLinks.length === 1 && (
          <QRCodeModal
            code={selectedLinksFull[0].sL || selectedLinksFull[0].c}
            setShowModal={setShowQrModal}
            section="track-view"
          />
        )}
        {showObservepointModal && (
          <ObservepointModal
            setShowModal={setShowObservepointModal}
            link={selectedLinksFull[0].c}
          />
        )}
      </>
    )
  },
)

interface ShortLinkCellProps {
  codeID: string
  index: number
  shortLinkEditActiveIndex: number | null
  setShortLinkEditActiveIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >
  setCurrentShortLink: React.Dispatch<React.SetStateAction<string | null>>
}

const ShortLinkEditCell = ({
  codeID,
  index,
  shortLinkEditActiveIndex,
  setShortLinkEditActiveIndex,
  setCurrentShortLink,
}: ShortLinkCellProps) => {
  const logAction = useLogAction()

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )

  const [addShortLink, { loading, error }] = useMutation(
    addShortLinkExistingCode,
  )

  const [shortLinkAliasDetails, setShortLinkAliasDetails] = useState<
    CustomLinkAliasDetails
  >({
    key: 'current-short-link-to-add',
    alias: '',
    isCustom: false,
    isBatch: false,
    status: '',
    error: false,
  })

  if (index === shortLinkEditActiveIndex) {
    return (
      <div>
        <div className={styles.addShortLink}>
          <div>
            <CustomLinkFull
              domainSelectorClassName={styles.domainSelector}
              aliasClassName={styles.aliasField}
              onAliasChange={(alias, status, isCustom) =>
                setShortLinkAliasDetails((curr) => ({
                  ...curr,
                  alias,
                  status,
                  isCustom: isCustom || false,
                }))
              }
            />
          </div>
          <ButtonRow className={styles.actionButtons}>
            <Button
              loading={
                loading ||
                shortLinkAliasDetails.status === 'refetching' ||
                shortLinkAliasDetails.alias === ''
              }
              isDisabled={
                loading ||
                !!error ||
                shortLinkAliasDetails.alias === '' ||
                (!!shortLinkAliasDetails.status &&
                  shortLinkAliasDetails.status !== 'valid')
              }
              variant="text"
              color="pink"
              onPress={async () => {
                const { data } = await addShortLink({
                  variables: {
                    newShortLinkID: shortLinkAliasDetails.alias,
                    customDomainID: getCustomDomainID(selectedShortLinkDomain),
                    codeID,
                  },
                })

                if (data) {
                  setShortLinkEditActiveIndex(null)

                  // Make sure the link can't be used again
                  useAliases(
                    [shortLinkAliasDetails.alias],
                    selectedShortLinkDomain,
                  )

                  setCurrentShortLink(data.track.addShortLinkExistingCode.sL)

                  logAction({
                    variables: {
                      action: 'add-short-link-existing-code',
                      websiteSection: 'track',
                      pagePath: window.location.pathname,
                      functionName: 'addShortLink',
                      extra: `${selectedShortLinkDomain}/${shortLinkAliasDetails.alias}`,
                    },
                  })
                }
              }}
            >
              Save
            </Button>
            <Button
              variant="text"
              color="grey"
              onPress={() => setShortLinkEditActiveIndex(null)}
            >
              Cancel
            </Button>
          </ButtonRow>
        </div>
        {!!error && (
          <ErrorMessage className={styles.shortLinkError}>
            Error adding short link. Please reload the page and try again or
            contact support: <Link href={supportEmail}>{supportEmail}</Link>.
          </ErrorMessage>
        )}
      </div>
    )
  }

  return (
    <span className={styles.emptyShortLink}>
      <Button
        className={styles.addShortLinkButton}
        variant="secondary"
        onPress={() => setShortLinkEditActiveIndex(index)}
      >
        Create
      </Button>
    </span>
  )
}

/** Hack to change the name of the fullLink column for this workspace only. Also reduces column width */
const schrodersWorkspaceID = '7badaff1-855d-430c-ae68-405d35507b1f'

interface TrackViewLinkRowProps {
  link: TableLinkWithData
  linkIndex: number
  cacheIndex: number
  isSelected?: boolean
  setIsSelected: (checked: boolean) => void
  loadingInfo: boolean
  loadingStats: boolean
  showShortLinkColumn: boolean
  showStatusColumn: boolean
  selectedMetrics: MetricDropdownItem[]
  showUplifterIdColumn: boolean
  urlValidation: { quick: boolean; intensive: boolean }
  canEdit: boolean
  setEditModal: React.Dispatch<React.SetStateAction<EditLinkValueModalProps>>
  setViewEditHistoryModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean
      linkID?: string
    }>
  >
  logAction: (vars: any) => void
  workspaceID: string
  shortLinkEditActiveIndex: number | null
  setShortLinkEditActiveIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >
  isAdobe?: boolean
  /** Sets if link row clicks through to report page */
  reportModuleAvailable?: boolean
  hideAlertStatus?: boolean
  /** Whether email notifications are sent to the user/account for issues with this landing page */
  alertStatus?: LandingPageIgnore
  showUpdateAlertStatusModal: () => void
}

export const TrackViewLinkRow = memo(
  ({
    link,
    linkIndex,
    cacheIndex,
    isSelected = false,
    setIsSelected,
    loadingInfo = false,
    loadingStats = false,
    showShortLinkColumn = true,
    showStatusColumn = true,
    selectedMetrics = [],
    showUplifterIdColumn = false,
    urlValidation,
    canEdit = false,
    setEditModal,
    setViewEditHistoryModal,
    logAction,
    workspaceID,
    shortLinkEditActiveIndex,
    setShortLinkEditActiveIndex,
    isAdobe = false,
    reportModuleAvailable = true,
    hideAlertStatus,
    alertStatus,
    showUpdateAlertStatusModal,
  }: TrackViewLinkRowProps) => {
    const { quick, intensive } = urlValidation || {}

    const {
      linkID,
      fullLink,
      createdTime,
      versionNumber,
      author,
      hasNoLandingPage,
      shortLink,
      params,
      uplifterIDValue,
      status,
      linkValidation,
      metricData,
      deepLinkServiceID,
    } = link

    // Used to spoof immediate update of short link
    // TODO: Update cached value in Apollo instead
    const [currentShortLink, setCurrentShortLink] = useState(shortLink)

    // Prevent report page from switching to short link as metric if it has no data
    const hideShortLinkMetric =
      metricData?.find((m) => m.metricID === 'shortLinkClicks')?.metricValue ===
        0 || false

    // Should not allow newly live links to have reports created
    const createdToday =
      moment.unix(parseInt(createdTime, 10)).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')

    const alertsDisabled =
      alertStatus?.applyToAccount || alertStatus?.applyToUser

    return (
      <>
        <td className={styles.checkboxCell}>
          <Input
            type="checkbox"
            name={`checkbox-${linkID}`}
            id={`checkbox-${linkID}`}
            className={styles.checkboxItem}
            checked={isSelected}
            label=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target as HTMLInputElement

              setIsSelected(checked)
            }}
          />
        </td>
        <td className={styles.createdCell}>
          <p className={styles.createdDateLine}>
            <span className={styles.createdDate}>
              {moment.unix(parseInt(createdTime, 10)).format(dateFormatShort)}
            </span>
            <span className={styles.createdTime}>
              {moment.unix(parseInt(createdTime, 10)).format('HH:mm')}
            </span>
          </p>
          <span className={styles.createdUser}>
            {versionNumber > 1 ? (
              <Button
                variant="text"
                color="pink"
                className={styles.editHistoryButton}
                onPress={() =>
                  setViewEditHistoryModal({ visible: true, linkID })
                }
              >
                Edited
              </Button>
            ) : (
              ''
            )}
            <span>{author || 'deleted user'}</span>
          </span>
        </td>
        {showShortLinkColumn && (
          <td
            className={classNames(styles.shortLinkCell, {
              [styles.shortLinkEditActive]:
                linkIndex === shortLinkEditActiveIndex,
            })}
          >
            <div className={styles.cellWrapper}>
              {currentShortLink ? (
                <>
                  <span className={styles.linkCode}>{currentShortLink}</span>{' '}
                  <div className={styles.cellActions}>
                    <CopyButton
                      className={styles.copyButton}
                      value={currentShortLink}
                    />
                  </div>
                </>
              ) : (
                <ShortLinkEditCell
                  codeID={linkID}
                  index={linkIndex}
                  shortLinkEditActiveIndex={shortLinkEditActiveIndex}
                  setShortLinkEditActiveIndex={setShortLinkEditActiveIndex}
                  setCurrentShortLink={setCurrentShortLink}
                />
              )}
            </div>
          </td>
        )}
        <td
          className={styles.fullLinkCell}
          // Annoying hack for one workspace
          style={
            workspaceID === schrodersWorkspaceID
              ? { minWidth: 450, maxWidth: 450 }
              : undefined
          }
        >
          <div className={styles.fullLinkContainer}>
            <div className={styles.cellWrapper}>
              <span style={{ whiteSpace: 'pre-line' }}>{fullLink}</span>
              <div className={styles.cellActions}>
                <CopyButton value={fullLink} />
                {canEdit && (
                  <Button
                    variant="iconOnly"
                    className={styles.editButton}
                    icon={{
                      src: EditIcon,
                      alt: 'Edit',
                      imgHeight: 20,
                    }}
                    onPress={() => {
                      setEditModal({
                        visible: true,
                        fullLink: link,
                        cacheIndex,
                      })

                      logAction({
                        variables: {
                          action: 'open-edit-modal-parameter',
                          functionName: '',
                          pagePath: '/track/view-links',
                          websiteSection: 'track',
                        },
                      })
                    }}
                  />
                )}
              </div>
            </div>
            {linkValidation && !deepLinkServiceID && (quick || intensive) && (
              <UrlStatus
                url={fullLink}
                validatedUrl={linkValidation}
                createdToday={createdToday}
                hasMetricData={!!(metricData && metricData.length > 0)}
                hasNoLandingPage={!!hasNoLandingPage}
              />
            )}
            {!hideAlertStatus && !hasNoLandingPage && linkValidation && (
              <Tooltip
                id={`alerts-status-${linkID}`}
                className={styles.alertsBellTooltip}
                tooltipMessage={
                  <p>
                    You <BoldText>will{alertsDisabled ? ' not' : ''}</BoldText>{' '}
                    receive email notifications when we detect an issue with
                    this page.
                  </p>
                }
              >
                <Button
                  variant="iconOnly"
                  className={classNames(styles.alertsBell, {
                    [styles.inactive]: alertsDisabled,
                  })}
                  onPress={showUpdateAlertStatusModal}
                />
              </Tooltip>
            )}
          </div>
        </td>
        {showStatusColumn && (
          <td className={styles.statusCell}>
            {loadingInfo || loadingStats ? (
              <Loader className={styles.loadingText} />
            ) : (
              <>
                {status ? (
                  <Button
                    variant="plainBox"
                    className={classNames(styles.button, {
                      [styles.unknownButton]: status === codeStatus.na,
                      [styles.newButton]:
                        status === codeStatus.new ||
                        (status === codeStatus.live && createdToday),
                      [styles.unusedButton]: status === codeStatus.unused,
                      [styles.liveButton]:
                        status === codeStatus.live && !createdToday,
                      [styles.endedButton]: status === codeStatus.ended,
                    })}
                    onPress={() => {
                      if (!reportModuleAvailable) return

                      if (
                        [codeStatus.live, codeStatus.ended].indexOf(status) >
                          -1 &&
                        !(codeStatus.live && createdToday)
                      ) {
                        logAction({
                          variables: {
                            action: 'view-one-click-report-link',
                            functionName: '',
                            pagePath: '/track/view-links',
                            websiteSection: 'track',
                            extra: linkID,
                          },
                        })

                        window.open(
                          `/report/performance?linkID=${linkID}&accountID=${workspaceID}${
                            hideShortLinkMetric
                              ? '&hideShortLinkMetric=true'
                              : ''
                          }`,
                          '_blank',
                        )
                      }
                    }}
                  >
                    <Tooltip
                      id={`${linkID}-status-tooltip`}
                      className={styles.statusTooltip}
                      maxWidth={250}
                      tooltipPosition="top"
                      tooltipMessage={
                        status === 'Live' && createdToday
                          ? `Created in the last 30 days, but does not yet have ${
                              isAdobe ? 'Adobe ' : 'Google '
                            }Analytics data.`
                          : `${messages.linkStatus[status]}${
                              reportModuleAvailable &&
                              ((status === codeStatus.live && !createdToday) ||
                                status === codeStatus.ended)
                                ? ' Click to view report.'
                                : ''
                            }`
                      }
                    >
                      {status === 'Live' && createdToday ? 'New' : status}
                    </Tooltip>
                    {reportModuleAvailable &&
                      [codeStatus.live, codeStatus.ended].indexOf(status) >
                        -1 &&
                      !(codeStatus.live && createdToday) && <ReportIcon />}
                  </Button>
                ) : (
                  ''
                )}
              </>
            )}
          </td>
        )}
        {selectedMetrics.map(({ metricID }) => {
          if (loadingStats) {
            return (
              <td key={`${linkID}-${metricID}`}>
                <Loader className={styles.loadingText} />
              </td>
            )
          }

          const foundMetricData = metricData?.find(
            (metricDataItem) => metricDataItem.metricID === metricID,
          )

          if (!foundMetricData) {
            return <td key={`${linkID}-${metricID}`}>N/A</td>
          }

          const { metricName, metricValue, units } = foundMetricData

          return (
            <td key={`${linkID}-${metricID}`}>
              {metricName === 'Short link clickthroughs' && !shortLink
                ? 'N/A'
                : formatMetricValue(metricValue || 0, units)}
            </td>
          )
        })}
        {params.map(({ paramID, paramName, paramValue, isHidden }) => {
          // Do not add columns not present in the header
          if (isHidden) return null

          if (loadingInfo) {
            return (
              <td key={`${linkID}-${paramID}`}>
                <Loader className={styles.loadingText} />
              </td>
            )
          }

          return (
            <td key={`${linkID}-${paramID}`}>
              <div className={styles.cellWrapper}>
                <span>{paramValue}</span>
                <div className={styles.cellActions}>
                  {reportModuleAvailable && !!paramValue && (
                    <Button
                      variant="plainBox"
                      className={styles.createParameterReportButton}
                      aria-label="Create report from parameter value"
                      onPress={() => {
                        if (!reportModuleAvailable) return

                        window.open(
                          `/report/performance?parameterID=${paramID}&parameterName=${paramName}&parameterValue=${paramValue}&accountID=${workspaceID}`,
                          '_blank',
                        )

                        logAction({
                          variables: {
                            action: 'view-one-click-report-parameter',
                            functionName: '',
                            pagePath: '/track/view-links',
                            websiteSection: 'track',
                            extra: JSON.stringify({
                              paramID,
                              paramValue,
                            }),
                          },
                        })
                      }}
                    >
                      <Tooltip
                        id={`${linkID}-${paramID}-report-tooltip`}
                        tooltipMessage={
                          <p>Create a report for this {paramName}.</p>
                        }
                      >
                        <ReportIcon />
                      </Tooltip>
                    </Button>
                  )}
                  {canEdit && (
                    <Tooltip
                      id={`edit-${linkID}-${paramID}`}
                      tooltipMessage={`Edit this ${paramName}.`}
                      className={styles.editTooltip}
                    >
                      <Button
                        variant="iconOnly"
                        className={styles.editButton}
                        icon={{
                          src: EditIcon,
                          alt: 'Edit',
                          imgHeight: 20,
                        }}
                        onPress={() => {
                          setEditModal({
                            visible: true,
                            paramID,
                            fullLink: link,
                            cacheIndex,
                          })

                          logAction({
                            variables: {
                              action: 'open-edit-modal-parameter',
                              functionName: '',
                              pagePath: '/track/view-links',
                              websiteSection: 'track',
                            },
                          })
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </td>
          )
        })}
        {showUplifterIdColumn && <td>{uplifterIDValue || ''}</td>}
      </>
    )
  },
)

const TrackViewTable = () => {
  const client = useApolloClient()

  const referToLinks = useReactiveVar(linkOrCode)

  const {
    workspaceID,
    userPermission,
    userEmail,
    reportAvailable,
  } = useReactiveVar(currentUserDetails)

  const hasCreatedLinks = useReactiveVar(hasCreatedLinksReactive)

  const dataSource = useReactiveVar(dataSourceReactive)

  const isAdmin = isAdminUser(userPermission)

  const { availableAppLinkDomains } = useCustomLinks()

  const { quick, intensive } = useUrlValidation()

  const logAction = useLogAction()

  const [fetchPolicy, setFetchPolicy] = useState<
    'network-only' | 'cache-first'
  >('network-only')
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  /** User interaction (e.g. change page, sort, filter) should only fetch from the network if data is >2mins old */
  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setFetchPolicy('network-only')
    }, 2 * 60 * 1000) // 2 minutes
  }, [])

  // Should only be updated after the first fetch
  const [totalLinks, setTotalLinks] = useState(0)
  const [currentLinks, setCurrentLinks] = useState(0)
  const [selectedMetrics, setSelectedMetrics] = useState<MetricDropdownItem[]>(
    [],
  )
  const [filter, setFilter] = useState<TrackViewFilterProps>({
    codeIDList: undefined,
    activePage: 1,
    rowsPerPage: 10,
    sortBy: 'createdTime',
    sortDirection: 'DESC',
    searchType: 'any',
    searchTerm: '',
    filterByCurrentUser: true,
  })
  const [filterInitialised, setFilterInitialised] = useState(false)
  const [hasUsedGlobalFilter, setHasUsedGlobalFilter] = useState(false)
  const [selectedLinks, setSelectedLinks] = useState<string[]>([])
  const [editModal, setEditModal] = useState<EditLinkValueModalProps>({
    visible: false,
  })
  const [viewEditHistoryModal, setViewEditHistoryModal] = useState<{
    visible: boolean
    linkID?: string
  }>({ visible: false })
  const [tableHasNoData, setTableHasNoData] = useState(false)
  const [tableRows, setTableRows] = useState<TableLinkWithData[]>([])
  const [shortLinkEditActiveIndex, setShortLinkEditActiveIndex] = useState<
    number | null
  >(null)
  const [createdLinksCheck, setCreatedLinksCheck] = useState(false)
  const [initialFetchComplete, setInitialFetchComplete] = useState(false)
  const [refetchStatusComplete, setRefetchStatusComplete] = useState(true)
  const [metricQueryIsFirst, setMetricQueryIsFirst] = useState(false)

  const {
    data: emailNotificationsData,
    loading: loadingNotificationsData,
  } = useQuery(getEmailNotifications)
  const { data: generatorData, loading: loadingGenerator } = useQuery(
    getCampaignCodeGenerator,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const { data: uplifterIdData } = useQuery(getUniqueParamCurrentTotal, {
    variables: { fieldType: 'up_id' },
  })

  const [
    fetchMinCodes,
    { data: codesData, loading: loadingCodes, error: minCodesError },
  ] = useLazyQuery(getMinCodesQuick, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    codeUrlValidation,
    { data: codeValidationData, loading: loadingValidationStatus },
  ] = useLazyQuery(getUrlValidationStatus, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    getLandingPageValidationIgnoreList,
    { data: landingPageValidationIgnoreListData, loading: loadingIgnoreList },
  ] = useLazyQuery(getTrackValidationIgnoreList)
  const [
    fetchMetricData,
    {
      data: codesMetricData,
      loading: loadingStatsStatus,
      error: codeMetricsError,
    },
  ] = useLazyQuery(getStoredCodeStatsQuick, {
    notifyOnNetworkStatusChange: true,
  })

  // Fetch the list of landing pages which are marked as excluded from alert emails
  useEffect(() => {
    if (!emailNotificationsData) return

    const {
      currentUser: { allTrackValidationAlertEmail },
    } = emailNotificationsData

    if (allTrackValidationAlertEmail) {
      getLandingPageValidationIgnoreList()
    }
  }, [emailNotificationsData])

  const forceShortLinkRule = useMemo(() => {
    if (!generatorData) {
      return null
    }

    return generatorData.campaignCodeGenerator.validationChecks.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )
  }, [generatorData])

  /** Blocks calls to GetUrlValidationStatus and GetStoredCodesStatsQuick if false */
  const { showValidationData, showMetricData } = useMemo(() => {
    if (referToLinks === 'code') {
      return {
        showValidationData: false,
        showMetricData: false,
      }
    }

    let _showMetricData = true

    // Do not fetch metrics if basic links are forced and analytics is not connected
    // The only other metrics are short links, which conflicts with the workspace rule
    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      const shortLinkValue = JSON.parse(forceShortLinkRule.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      if (selectedRule === 'force-long-links') {
        _showMetricData =
          !!dataSource && dataSource.connectionSource !== 'not-connected'
      }
    }

    return {
      showValidationData: quick || intensive,
      showMetricData: _showMetricData,
    }
  }, [referToLinks, forceShortLinkRule, dataSource, quick, intensive])

  /** Short link column should only show if forceShortLink rule value is not 'force-long-links' */
  const showShortLinkColumn = useMemo(() => {
    if (
      !forceShortLinkRule ||
      !forceShortLinkRule.enabled ||
      !forceShortLinkRule.value
    ) {
      return true
    }

    const shortLinkValue = JSON.parse(forceShortLinkRule.value)

    const selectedRule = shortLinkValue.find((option: any) => option.selected)
      .optionValue

    return selectedRule !== 'force-long-links'
  }, [forceShortLinkRule])

  const showUplifterIdColumn = !!(
    uplifterIdData && uplifterIdData.track.currentSequentialCodeID.isEnabled
  )

  // Check if user has created links before
  // To set initial state of 'Your links' toggle
  useEffect(() => {
    if (!userEmail || hasCreatedLinks === null) {
      return
    }

    const savedState = getUserData()
    const savedYourLinks = savedState?.trackViewYourLinks

    // Set if initial filter should be 'Your links' or 'All links'
    setFilter((curr) => ({
      ...curr,
      filterByCurrentUser:
        typeof savedYourLinks === 'boolean' ? savedYourLinks : hasCreatedLinks,
    }))
    // If 'All links', then they have used the global filter
    setHasUsedGlobalFilter(
      typeof savedYourLinks === 'boolean' ? !savedYourLinks : !hasCreatedLinks,
    )

    setCreatedLinksCheck(true)
  }, [hasCreatedLinks, userEmail])

  /** Used to check and update Apollo cache on delete/update */
  const currentCacheID = useMemo(() => {
    if (filter.isMetric) {
      return `MinimalCodeList:{"filteredByCurrentUser":false,"dimensionFilter":null,"sortDirection":"${
        filter.sortDirection || 'DESC'
      }","sortField":"${
        filter.bidirectionalSortKey || filter.sortBy || 'createdTime'
      }","isCodeIDList":true}`
    }

    return `MinimalCodeList:{"filteredByCurrentUser":${
      typeof filter.filterByCurrentUser === 'boolean'
        ? filter.filterByCurrentUser
        : true
    },"dimensionFilter":${
      filter.searchTerm
        ? `"${JSON.stringify({
            dimensionFilter: {
              dimensionName: filter.searchTerm,
              dimensionParameterID: filter.searchType,
              dimensionOptions: [],
            },
          })}"`
        : 'null'
    },"sortDirection":"${filter.sortDirection || 'DESC'}","sortField":"${
      filter.bidirectionalSortKey || filter.sortBy || 'createdTime'
    }","versionHistoryLinkID":null,"isCodeIDList":false}`
  }, [filter])

  /** Object with keys for landing pages that have had alert emails disabled, either by the account or user */
  const [
    landingPageAlertEmailIgnoreList,
    setLandingPageAlertEmailIgnoreList,
  ] = useState<{
    [landingPage: string]: LandingPageIgnore
  }>({})
  const [
    updateLandingPageAlertStatusModal,
    setUpdateLandingPageAlertStatusModal,
  ] = useState<{
    visible: boolean
    landingPage?: string
    fromEmail?: boolean
  }>({ visible: false })

  // Update list of ignored landing page email alerts
  useEffect(() => {
    if (!landingPageValidationIgnoreListData) return

    const fullList = landingPageValidationIgnoreListData.track.getTrackValidationIgnoreList.reduce(
      (acc, item) => {
        if (!item.landingPage) return acc

        acc[item.landingPage] = item
        return acc
      },
      {} as { [landingPage: string]: LandingPageIgnore },
    )

    setLandingPageAlertEmailIgnoreList(fullList)
  }, [landingPageValidationIgnoreListData])

  // Show landing page alert status modal if query parameter is present
  useEffect(() => {
    const query = getUrlQuery()

    const validation = query.get('validation')
    const landingPage = query.get('landingPage')

    if (!!validation && !!landingPage) {
      setUpdateLandingPageAlertStatusModal({
        visible: true,
        landingPage,
        fromEmail: true,
      })

      return
    }

    if (!validation && !!landingPage) {
      setFilter((curr) => ({
        ...curr,
        searchType: 'fullLink',
        searchTerm: landingPage,
        filterByCurrentUser: true,
      }))
    }

    setFilterInitialised(true)
  }, [])

  // Initial data fetch - only runs once
  useEffect(() => {
    if (
      !workspaceID ||
      !filterInitialised ||
      !createdLinksCheck ||
      !userEmail ||
      initialFetchComplete
    ) {
      return
    }

    const initialFetch = async () => {
      const { data, error } = await fetchMinCodes({
        variables: filterToMinCodesVars(filter),
        fetchPolicy: 'network-only',
      })

      if (!data || error) {
        setTableHasNoData(true)
        return
      }

      setTotalLinks(data.track.minCodesQuick.totalCodes)
      setCurrentLinks(data.track.minCodesQuick.totalCodes)

      if (data.track.minCodesQuick.codeID.length === 0) {
        setTableHasNoData(true)
        return
      }

      const codeIDList = data.track.minCodesQuick.codeID
        .slice(0, 10)
        .filter((c) => !!c)

      if (showValidationData) {
        await codeUrlValidation({
          variables: {
            codeIDList,
          },
          fetchPolicy: 'network-only',
        })
      }

      if (showMetricData) {
        await fetchMetricData({
          variables: {
            codeIDList,
          },
          fetchPolicy: 'network-only',
        })
      }

      setInitialFetchComplete(true)
    }

    initialFetch()
  }, [
    workspaceID,
    filterInitialised,
    createdLinksCheck,
    filter,
    initialFetchComplete,
    showValidationData,
    showMetricData,
  ])

  const refetchData = useCallback(
    async (queryVars: TrackViewFilterProps, recalculateTotals = false) => {
      setTableRows([])
      setTableHasNoData(false)
      setSelectedLinks([])
      setRefetchStatusComplete(false)

      const variables = filterToMinCodesVars(queryVars)

      let fetchPolicyToUse = fetchPolicy

      const cachedData = client.cache.readFragment({
        id: currentCacheID,
        fragment: AVAILABLE_CODE_IDS,
      })

      const cachedLength =
        cachedData?.codeID?.slice(variables.offset, variables.limit)?.length ||
        0

      // More links required - fetch from network
      if (cachedLength < (variables.limit || 10)) {
        fetchPolicyToUse = 'network-only'
      }

      // Fetching stats first is based on the sortBy value
      if (queryVars.isMetric) {
        setMetricQueryIsFirst(true)

        const { data } = await fetchMetricData({
          variables,
          fetchPolicy: fetchPolicyToUse,
        })

        if (!data) return

        // Recalculate totals is only set by the 'Your links' toggle
        // As an extra check, only recalculate if they haven't viewed 'All links'
        if (
          data.track.storedCodeStatsQuick.totalCodes > totalLinks ||
          (recalculateTotals && !hasUsedGlobalFilter)
        ) {
          setTotalLinks(data.track.storedCodeStatsQuick.totalCodes)
          setHasUsedGlobalFilter(true)
        }

        setCurrentLinks(data.track.storedCodeStatsQuick.totalCodes)

        const codeIDList = data.track.storedCodeStatsQuick.codeIDs
          .slice(
            variables.offset,
            (variables.offset || 0) + (variables.limit || 0),
          )
          .filter((c) => !!c)

        if (codeIDList.length === 0) {
          setTableHasNoData(true)
          return
        }

        if (showValidationData) {
          await codeUrlValidation({
            variables: {
              codeIDList,
            },
            fetchPolicy: 'network-only',
          })
        }

        await fetchMinCodes({
          variables: {
            codeIDList,
            limit: undefined,
            offset: undefined,
            orderBy: undefined,
            dimensionFilter: undefined,
          },
          fetchPolicy: 'network-only',
        })

        setRefetchStatusComplete(true)

        return
      }

      setMetricQueryIsFirst(false)

      const { data } = await fetchMinCodes({
        variables,
        fetchPolicy: fetchPolicyToUse,
      })

      if (!data) return

      // Recalculate totals is only set by the 'Your links' toggle
      // As an extra check, only recalculate if they haven't viewed 'All links'
      if (
        data.track.minCodesQuick.totalCodes > totalLinks ||
        (recalculateTotals && !hasUsedGlobalFilter)
      ) {
        setTotalLinks(data.track.minCodesQuick.totalCodes)
        setHasUsedGlobalFilter(true)
      }

      setCurrentLinks(data.track.minCodesQuick.totalCodes)

      const codeIDList = data.track.minCodesQuick.codeID
        .slice(
          variables.offset,
          (variables.offset || 0) + (variables.limit || 0),
        )
        .filter((c) => !!c)

      if (codeIDList.length === 0) {
        setTableHasNoData(true)
        return
      }

      if (showValidationData) {
        await codeUrlValidation({
          variables: {
            codeIDList,
          },
          fetchPolicy: 'network-only',
        })
      }

      if (showMetricData) {
        await fetchMetricData({
          variables: {
            codeIDList,
            limit: undefined,
            offset: undefined,
            orderBy: undefined,
            dimensionFilter: undefined,
          },
          fetchPolicy: 'network-only',
        })
      }

      setRefetchStatusComplete(true)

      // Reset timeout for next fetch
      if (fetchPolicy === 'network-only') {
        setFetchPolicy('cache-first')
        resetTimeout()
      }
    },
    [
      fetchPolicy,
      hasUsedGlobalFilter,
      codesData,
      codesMetricData,
      codeValidationData,
      totalLinks,
      resetTimeout,
      client,
      currentCacheID,
      showValidationData,
      showMetricData,
    ],
  )

  // Filters out parameters that aren't available to the current workspace
  const params = getWorkspaceParams(workspaceID, generatorData)

  // Full list of columns the data can be searched on
  const searchTypeList = buildSearchTypeList(params)

  const headerColumns = useMemo(() => {
    const headers: TableHeaderColumn<TableLinkWithData>[] = [
      {
        id: 'checkbox',
        className: styles.checkboxCell,
        content: (
          <Input
            type="checkbox"
            name="allNone"
            id="allNone"
            className={styles.checkboxItem}
            checked={selectedLinks.length === filter.rowsPerPage}
            label=" "
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target

              setSelectedLinks(
                checked ? tableRows.map(({ linkID }) => linkID) : [],
              )
            }}
          />
        ),
      },
      {
        id: 'createdTime',
        className: styles.createdCell,
        content: 'Created',
        columnSortKey: 'createdTime',
      },
    ]

    if (showShortLinkColumn) {
      headers.push({
        id: 'shortLink-cell',
        className: styles.shortLinkCell,
        content: 'Short link',
        tooltip:
          'A short, memorable link that redirects to the destination with parameters, capturing valuable clickthrough data.\n\nWhen available, use this link rather than the destination with parameters.',
      })
    }

    headers.push({
      id: 'fullLink',
      className:
        workspaceID !== schrodersWorkspaceID ? styles.fullLinkCell : undefined,
      content:
        workspaceID === schrodersWorkspaceID
          ? 'Campaign ID'
          : 'Destination with parameters',
      tooltip:
        workspaceID === schrodersWorkspaceID
          ? 'A unique identifier for each campaign applied across all marketing touch points, enabling cross-channel analytics and reporting.'
          : "The full website URL which will be seen in the visitor's browser. The symbol to the right shows its status after we checked the landing page with a bot.",
      columnSortKey: 'fullLink',
    })

    if (showMetricData) {
      headers.push({
        id: 'status',
        className: styles.statusCell,
        content: 'Link status',
        tooltip:
          'Shows if this link has received either short link clickthroughs and/or onsite analytics metrics (Updated every hour).',
        columnSortKey: 'status',
        bidirectionalSortKeys: ['Live', 'Ended', 'New', 'Unused'],
      })

      if (selectedMetrics.length > 0) {
        selectedMetrics.forEach(({ metricID, displayName, helpText }) => {
          if (metricID === 'shortLinkClicks') {
            headers.push({
              id: metricID,
              className: styles.metricCell,
              content: (
                <div className={styles.shortLinkMetricHeader}>
                  <Tooltip
                    id="shortLink-tooltip"
                    useIcon
                    tooltipPosition="bottom"
                    tooltipPositionStrategy="fixed"
                    tooltipMessage={helpText}
                  >
                    {displayName}
                  </Tooltip>
                  <Tooltip
                    id="shortLink-tooltip-warning"
                    className={styles.warningTooltip}
                    tooltipPosition="bottom"
                    tooltipPositionStrategy="fixed"
                    tooltipMessage={`\n\n**Apologies:** Due to an error, short link
                      clickthrough data is under-reported between 17th Jun 2024 -
                      2nd Jul 2024 and 9th Nov 2024 - 4th Feb 2025.`}
                  >
                    !
                  </Tooltip>
                </div>
              ),
              // 'tableMetric' is used to identify the column for sorting
              columnSortKey: `tableMetric-${metricID}`,
              customSortFn: (row) =>
                row.metricData?.find((m) => m.metricID === metricID)
                  ?.metricValue || 0,
            })

            return
          }

          headers.push({
            id: metricID,
            content: displayName,
            tooltip: helpText,
            // 'tableMetric' is used to identify the column for sorting
            columnSortKey: `tableMetric-${metricID}`,
            customSortFn: (row) =>
              row.metricData?.find((m) => m.metricID === metricID)
                ?.metricValue || 0,
          })
        })
      }
    }

    params.forEach(({ fieldID, fieldName, helpText, hideInTrackView }) => {
      if (hideInTrackView) return

      headers.push({
        id: fieldID,
        content: fieldName,
        tooltip: helpText,
        columnSortKey: fieldID,
        customSortFn: (row) =>
          row.params.find((p) => p.paramID === fieldID)?.paramValue || '',
      })
    })

    if (showUplifterIdColumn) {
      headers.push({
        id: 'uplifterID',
        content: 'Uplifter ID',
      })
    }

    return headers
  }, [
    workspaceID,
    selectedLinks,
    showShortLinkColumn,
    selectedMetrics,
    params,
    showMetricData,
  ])

  // Reset table rows whenever basic values change
  useEffect(() => {
    if (tableHasNoData) {
      setTableRows([])
      return
    }

    const _tableRows = buildTableLinks({
      limit: filter.rowsPerPage,
      offset: (filter.activePage - 1) * filter.rowsPerPage,
      metricFirst: filter.isMetric,
      params,
      minCodes: codesData?.track.minCodesQuick,
      codeStats: codesMetricData?.track.storedCodeStatsQuick,
      codeValidationData: codeValidationData?.track.trackValidationResults,
      masterPrefix: generatorData?.campaignCodeGenerator.masterPrefix,
      uplifterIdPrefix:
        uplifterIdData?.track.currentSequentialCodeID.prefix || undefined,
      availableAppLinkDomains,
    })

    setTableRows(_tableRows)
  }, [
    filter,
    availableAppLinkDomains,
    codesData,
    codesMetricData,
    uplifterIdData,
    codeValidationData,
    generatorData,
    tableHasNoData,
    selectedMetrics,
    refetchStatusComplete,
  ])

  /** We only wait for stats data before showing table if stats is fetched before links */
  const loadingTableData = metricQueryIsFirst
    ? loadingGenerator ||
      loadingCodes ||
      loadingValidationStatus ||
      loadingStatsStatus
    : loadingGenerator || loadingCodes

  return (
    <>
      <HeaderPanel className={styles.headerPanel}>
        <Panel>
          <TrackViewSearch
            filter={filter}
            setFilter={setFilter}
            loading={!createdLinksCheck || loadingCodes}
            // Takes longer but waits for metric data too
            // loading={loadingTableData}
            searchTypeList={searchTypeList}
            totalLinks={totalLinks}
            currentLinks={currentLinks}
            refetchData={refetchData}
            hasUsedGlobalFilter={hasUsedGlobalFilter}
          />
        </Panel>
        <Panel className={styles.tableActions}>
          {showMetricData && (
            <TrackViewMetrics
              codesMetricData={codesMetricData}
              loading={loadingCodes || loadingStatsStatus}
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
            />
          )}
          <TrackViewActions
            selectedLinks={selectedLinks}
            filter={filter}
            tableRows={tableRows}
            loadingLinks={loadingCodes}
            loadingStats={loadingStatsStatus}
            refetchData={() => refetchData(filter)}
            workspaceID={workspaceID}
            masterPrefix={generatorData?.campaignCodeGenerator.masterPrefix}
            paramDefs={params}
            cacheID={currentCacheID}
            cacheIndexOffset={
              filter.isMetric ? 0 : (filter.activePage - 1) * filter.rowsPerPage
            }
            rowsPerPage={filter.rowsPerPage}
          />
        </Panel>
      </HeaderPanel>
      <Table
        tableClassName={styles.linksTable}
        tableRowClassName={styles.tableLinkRow}
        virtualized
        fixedHeader
        fixedFooter
        headerColumns={headerColumns}
        rowIDKey="linkID"
        loading={
          loadingTableData || (tableRows.length === 0 && !tableHasNoData)
        }
        error={
          tableHasNoData ||
          !!minCodesError ||
          (metricQueryIsFirst && !!codeMetricsError)
        }
        tableData={tableRows}
        noDataMsg={
          // Show different message if current user hasn't created links
          filter.filterByCurrentUser && !filter.searchTerm ? (
            <p style={{ margin: 0 }}>
              No links created.{' '}
              <Link type="arrowForward" href="/track/create" newTab={false}>
                Create your first link
              </Link>
            </p>
          ) : (
            <p style={{ margin: 0 }}>
              No links for this search.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/19342815392029-Why-can-t-I-see-any-data-in-my-reports-graphs-tables"
              >
                Get support
              </Link>
            </p>
          )
        }
        initialSort={{ sortKey: 'createdTime', sortAsc: false }}
        onSort={({ sortKey, sortAsc, bidirectionalSortKey }) => {
          const sortKeyToUse = sortKey.replace('tableMetric-', '')

          const newFilter: TrackViewFilterProps = {
            ...filter,
            activePage: 1,
            sortBy: sortKeyToUse,
            sortDirection: sortAsc ? 'ASC' : 'DESC',
            isMetric: sortKey === 'status' || sortKey?.includes('tableMetric-'),
          }

          setFilter({
            ...newFilter,
            bidirectionalSortKey: bidirectionalSortKey || undefined,
          })

          refetchData({
            ...newFilter,
            sortBy: bidirectionalSortKey || sortKeyToUse,
          })
        }}
        customPagination={{
          activePage: filter.activePage,
          forceTotalRows: currentLinks,
          onChangePage: (newPage) => {
            setFilter((curr) => ({
              ...curr,
              activePage: newPage,
            }))

            refetchData({
              ...filter,
              activePage: newPage,
              sortBy: filter.bidirectionalSortKey || filter.sortBy,
            })
          },
          onChangeRowsPerPage: (newRowsPerPage) => {
            setFilter((curr) => ({
              ...curr,
              rowsPerPage: newRowsPerPage,
              activePage: 1,
            }))

            refetchData({
              ...filter,
              rowsPerPage: newRowsPerPage,
              activePage: 1,
              sortBy: filter.bidirectionalSortKey || filter.sortBy,
            })
          },
        }}
        paginationMiddle={<LimitsProgressBars showLinks />}
      >
        {(link, linkIndex) => {
          const linkLandingPage = link.fullLink.split('?')[0]

          return (
            <TrackViewLinkRow
              link={link}
              linkIndex={linkIndex}
              cacheIndex={
                filter.isMetric
                  ? linkIndex
                  : linkIndex + (filter.activePage - 1) * filter.rowsPerPage
              }
              isSelected={selectedLinks.indexOf(link.linkID) > -1}
              setIsSelected={(checked) => {
                setSelectedLinks((currSelectedLinks) => {
                  if (checked) {
                    return [...currSelectedLinks, link.linkID]
                  }

                  const newSelectedLinks = [...currSelectedLinks]
                  const selectedLinkIndex = newSelectedLinks.indexOf(
                    link.linkID,
                  )

                  if (selectedLinkIndex !== -1) {
                    newSelectedLinks.splice(selectedLinkIndex, 1)
                  }

                  return newSelectedLinks
                })
              }}
              loadingInfo={loadingCodes}
              loadingStats={loadingStatsStatus}
              showShortLinkColumn={showShortLinkColumn}
              showStatusColumn={showMetricData}
              selectedMetrics={selectedMetrics}
              showUplifterIdColumn={showUplifterIdColumn}
              urlValidation={{ quick, intensive }}
              canEdit={
                isAdmin || userEmail.toLowerCase() === link.author.toLowerCase()
              }
              setEditModal={setEditModal}
              setViewEditHistoryModal={setViewEditHistoryModal}
              logAction={logAction}
              workspaceID={workspaceID}
              shortLinkEditActiveIndex={shortLinkEditActiveIndex}
              setShortLinkEditActiveIndex={setShortLinkEditActiveIndex}
              isAdobe={!!dataSource && dataSource.connectionSource === 'adobe'}
              reportModuleAvailable={reportAvailable}
              hideAlertStatus={
                emailNotificationsData?.currentUser
                  ?.allTrackValidationAlertEmail === false
              }
              alertStatus={landingPageAlertEmailIgnoreList[linkLandingPage]}
              showUpdateAlertStatusModal={() =>
                setUpdateLandingPageAlertStatusModal({
                  visible: true,
                  landingPage: linkLandingPage,
                })
              }
            />
          )
        }}
      </Table>
      {editModal.visible && (
        <>
          {editModal.paramID ? (
            <EditLinkParameterModal
              paramID={editModal.paramID}
              linkToEdit={editModal.fullLink}
              onToggle={async () => setEditModal({ visible: false })}
              cacheID={currentCacheID}
              cacheIndex={editModal.cacheIndex}
            />
          ) : (
            <EditLinkUrlModal
              linkToEdit={editModal.fullLink}
              onToggle={async (refetch = false) => {
                setEditModal({ visible: false })

                if (refetch) {
                  refetchData(filter)
                }
              }}
              masterPrefix={generatorData?.campaignCodeGenerator.masterPrefix}
              uplifterIdData={uplifterIdData}
              cacheID={currentCacheID}
              cacheIndex={editModal.cacheIndex}
            />
          )}
        </>
      )}
      {viewEditHistoryModal.visible && (
        <EditHistoryModal
          onToggle={() => setViewEditHistoryModal({ visible: false })}
          linkID={viewEditHistoryModal.linkID}
        />
      )}
      {updateLandingPageAlertStatusModal.visible && (
        <UpdateLandingPageAlertsStatusModal
          onToggle={() => {
            setUpdateLandingPageAlertStatusModal({ visible: false })
          }}
          loading={loadingNotificationsData || loadingIgnoreList}
          fromEmail={updateLandingPageAlertStatusModal.fromEmail}
          landingPage={updateLandingPageAlertStatusModal.landingPage}
          landingPageAlertEmailIgnoreList={landingPageAlertEmailIgnoreList}
          onUpdateAlertStatus={(newStatus) => {
            setLandingPageAlertEmailIgnoreList((curr) => {
              const newList = _.cloneDeep(curr)

              if (!newStatus) {
                delete newList[
                  updateLandingPageAlertStatusModal.landingPage || ''
                ]
              } else {
                newList[
                  updateLandingPageAlertStatusModal.landingPage || ''
                ] = newStatus
              }

              return newList
            })
          }}
        />
      )}
    </>
  )
}

export default TrackViewTable
