import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Button from './button'
import { ImgUploader } from './img-uploader'
import Input, { Label } from './input'
import { InputLabel, ChangeInput } from './input-v2'
import Modal from './modal'
import { FieldSlot, FormRow, LabelSlot } from './row'
import { Heading } from './typography'
import {
  LastLoginMethods,
  currentUserDetails,
  loggedInState,
  loginForm,
} from '../api/apollo/variables'
import { getProfileInfo, updateProfileInfo } from '../api/graphql/user-client'
import { updateUserImage } from '../api/REST/account-client'
import {
  GoogleInitialAuth,
  MicrosoftInitialAuth,
  OktaInitialAuth,
  updateEmail,
  updatePassword,
} from '../api/REST/auth-client'
import { validatePassword, validateEmail } from '../helpers/forms'
import useAuthenticate from '../hooks/useAuthenticate'
import useOnboarding from '../hooks/useOnboarding'
import { getLocalItem, removeLocalItem } from '../helpers/local-client'
import styles from '../styles/profile-settings.module.scss'

interface UpdatePasswordModalProps {
  setChangePasswordModal: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdatePasswordModal = ({
  setChangePasswordModal,
}: UpdatePasswordModalProps) => {
  const { userEmail } = useReactiveVar(currentUserDetails)

  const [password, setPassword] = useState({
    current: '',
    new: '',
    new2: '',
    message: '',
    loading: false,
  })

  const onUpdatePassword = async () => {
    if (password.new !== password.new2) {
      setPassword((curr) => ({
        ...curr,
        message: 'New passwords do not match',
      }))

      return
    }

    if (password.current === password.new) {
      setPassword((curr) => ({
        ...curr,
        message: 'New password cannot be the same as old password',
      }))

      return
    }

    const validationMessage = validatePassword(password.new)

    if (validationMessage !== '') {
      setPassword((curr) => ({
        ...curr,
        message: validationMessage,
      }))

      return
    }

    setPassword((curr) => ({
      ...curr,
      loading: true,
    }))

    try {
      await updatePassword({
        username: userEmail,
        oldPassword: password.current,
        newPassword: password.new,
      })

      setPassword({
        current: '',
        new: '',
        new2: '',
        message: 'Password updated successfully',
        loading: false,
      })

      window.setTimeout(() => {
        setChangePasswordModal(false)
      }, 2000)
    } catch (err) {
      setPassword((curr) => ({
        ...curr,
        // @ts-ignore
        message: err.message || 'Failed to update password',
        loading: false,
      }))
    }
  }

  return (
    <Modal
      modalHeader="Change password"
      setIsOpen={setChangePasswordModal}
      yesButtonDisabled={
        password.current === '' || password.new === '' || password.new2 === ''
      }
      yesText="Update password"
      yesButtonLoading={password.loading}
      onYes={onUpdatePassword}
      footerContent={
        <span
          className={classNames(styles.updatePasswordMessage, {
            [styles.success]:
              password.message === 'Password updated successfully',
          })}
        >
          {password.message}
        </span>
      }
    >
      <p style={{ marginBottom: 0 }}>
        Please enter your current password and the new password you would like
        to use.
      </p>
      <FormRow>
        <LabelSlot>
          <Label id="current-password">Current password</Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            type="password"
            placeholder="Enter current password"
            name="current-password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword((curr) => ({
                ...curr,
                current: event.target.value,
                message: '',
              }))
            }}
          />
        </FieldSlot>
      </FormRow>
      <FormRow>
        <LabelSlot>
          <Label id="new-password">New password</Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            type="password"
            placeholder="Enter new password"
            name="new-password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword((curr) => ({
                ...curr,
                new: event.target.value,
                message: '',
              }))
            }}
          />
        </FieldSlot>
      </FormRow>
      <FormRow>
        <LabelSlot>
          <Label id="new-password-2">Re-enter new password</Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            type="password"
            placeholder="Re-enter new password"
            name="new-password-2"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword((curr) => ({
                ...curr,
                new2: event.target.value,
                message: '',
              }))
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                onUpdatePassword()
              }
            }}
          />
        </FieldSlot>
      </FormRow>
    </Modal>
  )
}

interface UpdateEmailModalProps {
  setChangeEmailModal: React.Dispatch<React.SetStateAction<boolean>>
  ssoEmailJourney: string | null
}

const UpdateEmailModal = ({
  setChangeEmailModal,
  ssoEmailJourney,
}: UpdateEmailModalProps) => {
  const { userEmail } = useReactiveVar(currentUserDetails)

  const login = useReactiveVar(loginForm)
  const { policy } = useReactiveVar(loggedInState)

  const { getPolicy, ssoReauth } = useAuthenticate()

  const [fields, setFields] = useState({
    email: '',
    password: '',
    message: '',
    loading: false,
  })
  const [ssoLoading, setSSOLoading] = useState(false)

  // Set policy in loggedInState reactive var
  useEffect(() => {
    // Set login form to use userEmail
    loginForm({
      ...login,
      email: userEmail,
    })

    const fetchPolicy = async () => {
      await getPolicy(userEmail)
    }

    fetchPolicy()
  }, [])

  // Check if ssoEmailJourney is set
  // All info is already present for the email to be updated
  useEffect(() => {
    const emailToUpdate = getLocalItem('email-to-update')

    const ssoUpdateEmail = async (type: string) => {
      setSSOLoading(true)

      let loginMethod: LastLoginMethods = ''
      let loginParameters:
        | MicrosoftInitialAuth
        | GoogleInitialAuth
        | OktaInitialAuth
        | null = null

      switch (type) {
        case 'ms':
          loginMethod = 'microsoft'
          loginParameters = {
            id_token: getLocalItem('ms-id-token'),
            nonce: getLocalItem('ms-nonce'),
          }
          break
        case 'ga':
          loginMethod = 'google'
          loginParameters = {
            code: getLocalItem('ga-code'),
            nonce: getLocalItem('google-sso-nonce'),
          }
          break
        case 'okta':
          loginMethod = 'okta'
          loginParameters = {
            code: getLocalItem('okta-code'),
            clientID: getLocalItem('okta-client-id'),
          }
          break
        default:
          break
      }

      if (loginMethod && loginParameters) {
        try {
          // @ts-ignore
          await updateEmail({
            oldEmail: userEmail,
            newEmail: emailToUpdate,
            loginMethod,
            loginParameters,
          })

          setFields({
            email: '',
            password: '',
            message: 'Email updated successfully',
            loading: false,
          })

          // Update cached user details
          currentUserDetails({
            ...currentUserDetails(),
            userEmail: emailToUpdate,
          })
        } catch (err) {
          setFields((curr) => ({
            ...curr,
            // @ts-ignore
            message: err.message || 'Failed to update email',
            loading: false,
          }))
        } finally {
          // Remove local items
          removeLocalItem('email-to-update')
          removeLocalItem('ms-id-token')
          removeLocalItem('ga-code')
          removeLocalItem('okta-code')

          // Remove update_email param
          const urlParams = new URLSearchParams(window.location.search)

          // Remove the desired query parameter (e.g., 'paramToRemove')
          urlParams.delete('update_email')
          urlParams.delete('show')
          const newUrl = `${window.location.origin}${
            window.location.pathname
          }?${urlParams.toString()}`

          // Update the URL
          window.history.replaceState({}, '', newUrl)
        }
      }

      setSSOLoading(false)
    }

    if (emailToUpdate && ssoEmailJourney) {
      ssoUpdateEmail(ssoEmailJourney)
    }
  }, [ssoEmailJourney])

  // On submitting a new email address
  const onUpdateEmail = async () => {
    // Must have a previous login method
    // i.e. must be logged in
    if (
      !policy ||
      policy.lastMethod === '' ||
      policy.lastMethod === 'inviteOpen'
    ) {
      return
    }

    if (fields.email === userEmail) {
      setFields((curr) => ({
        ...curr,
        message: 'New email cannot be the same as old email',
      }))

      return
    }

    const isValidEmail = validateEmail(fields.email)

    if (!isValidEmail) {
      setFields((curr) => ({
        ...curr,
        message: 'New email is not valid',
      }))

      return
    }

    setFields((curr) => ({
      ...curr,
      loading: true,
    }))

    try {
      if (policy.lastMethod === 'microsoft') {
        await ssoReauth('ms', userEmail, fields.email)
      } else if (policy.lastMethod === 'google') {
        await ssoReauth('ga', userEmail, fields.email)
      } else if (policy.lastMethod === 'okta') {
        await ssoReauth('okta', userEmail, fields.email)
      } else {
        // This is the only case that requires the password field
        // It also does not need any page reloads
        // @ts-ignore
        await updateEmail({
          oldEmail: userEmail,
          newEmail: fields.email,
          loginMethod: policy?.lastMethod || 'password',
          loginParameters: {
            username: userEmail,
            password: fields.password,
          },
        })

        setFields({
          email: '',
          password: '',
          message: 'Email updated successfully',
          loading: false,
        })

        // Update cached user details
        currentUserDetails({
          ...currentUserDetails(),
          userEmail: fields.email,
        })

        window.setTimeout(() => {
          setChangeEmailModal(false)
        }, 2000)
      }
    } catch (err) {
      setFields((curr) => ({
        ...curr,
        // @ts-ignore
        message: err.message || 'Failed to update email',
        loading: false,
      }))
    }
  }

  return (
    <Modal
      setIsOpen={setChangeEmailModal}
      modalHeader="Change email"
      loading={!policy || ssoLoading}
      yesButtonDisabled={
        fields.email === '' ||
        (policy?.lastMethod === 'password' && fields.password === '')
      }
      yesText="Update email"
      yesButtonLoading={fields.loading}
      onYes={onUpdateEmail}
      footerContent={
        <span
          className={classNames(styles.updatePasswordMessage, {
            [styles.success]: fields.message === 'Email updated successfully',
          })}
        >
          {fields.message}
        </span>
      }
    >
      <p>Set your new email address and enter your password to confirm.</p>
      <p style={{ margin: 0 }}>
        <strong>Current email:</strong> {userEmail}
      </p>
      <FormRow>
        <LabelSlot>
          <Label id="email">New email</Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            placeholder="Enter new email"
            name="email"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFields((curr) => ({
                ...curr,
                email: event.target.value,
                message: '',
              }))
            }}
          />
        </FieldSlot>
      </FormRow>
      {policy?.lastMethod === 'password' && (
        <FormRow>
          <LabelSlot>
            <Label id="password">Password</Label>
          </LabelSlot>
          <FieldSlot>
            <Input
              type="password"
              placeholder="Enter password"
              name="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFields((curr) => ({
                  ...curr,
                  password: event.target.value,
                  message: '',
                }))
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  onUpdateEmail()
                }
              }}
            />
          </FieldSlot>
        </FormRow>
      )}
    </Modal>
  )
}

interface ProfileSettingsProps {
  className?: string
}

const ProfileSettings = ({ className }: ProfileSettingsProps) => {
  const { data: profileData, refetch: refetchProfileInfo } = useQuery(
    getProfileInfo,
  )

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const [updateUserProfile] = useMutation(updateProfileInfo)

  const [changeEmailModal, setChangeEmailModal] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [ssoEmailJourney, setSsoEmailJourney] = useState<string | null>(null)

  const {
    telNum,
    firstName,
    lastName,
    userName,
    publicJobTitle,
    profileImgLink,
  } = profileData
    ? profileData.currentUser
    : {
        telNum: '',
        firstName: '',
        lastName: '',
        userName: '',
        publicJobTitle: '',
        profileImgLink: '',
      }

  const hasUpdatedProfile = useMemo(() => {
    const completeProfileSection = fullOnboardingSections.user?.find(
      (section) => section.onboardingSectionID === 'completeProfile',
    )

    return !!(completeProfileSection && completeProfileSection.sectionCompleted)
  }, [fullOnboardingSections])

  const callUpdate = useCallback(() => {
    if (!hasUpdatedProfile) {
      // Updates the backend cache for onboarding state
      updateOnboardingSection('completeProfile', 'user')
    }
  }, [hasUpdatedProfile])

  // Check if changeEmail SSO journey is in progress
  // Should open the 'change email' journey and automate updating it
  useEffect(() => {
    const { search } = window.location
    const searchQuery = new URLSearchParams(search)

    const _ssoUpdateEmailJourney = searchQuery.get('update_email')

    if (
      _ssoUpdateEmailJourney &&
      ['ms', 'ga', 'okta'].indexOf(_ssoUpdateEmailJourney) > -1
    ) {
      setSsoEmailJourney(_ssoUpdateEmailJourney)
      setChangeEmailModal(true)
    }
  }, [])

  return (
    <>
      <div className={className}>
        <Heading type={3} align="left">
          Your contact information
        </Heading>
        <p className={styles.byline}>
          Change the information we can contact you on for support and services.
        </p>
        <div className={styles.subsection}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <InputLabel htmlFor="first-name">First name</InputLabel>
              <ChangeInput
                id="first-name"
                name="first-name"
                initialValue={firstName || ''}
                onConfirm={async (nextFirstname) => {
                  await updateUserProfile({
                    variables: { firstName: nextFirstname },
                    errorPolicy: 'all',
                  })

                  currentUserDetails({
                    ...currentUserDetails(),
                    userFirstName: nextFirstname,
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <InputLabel htmlFor="last-name">Last name</InputLabel>
              <ChangeInput
                id="last-name"
                name="last-name"
                initialValue={lastName || ''}
                onConfirm={async (nextLastname) => {
                  await updateUserProfile({
                    variables: { lastName: nextLastname },
                    errorPolicy: 'all',
                  })

                  currentUserDetails({
                    ...currentUserDetails(),
                    userLastName: nextLastname,
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <InputLabel htmlFor="phone-number">Phone number</InputLabel>
              <ChangeInput
                id="phone-number"
                name="phone-number"
                initialValue={telNum || ''}
                onConfirm={async (nextTelNum) => {
                  await updateUserProfile({
                    variables: { phone: nextTelNum },
                    errorPolicy: 'all',
                  })
                }}
              />
            </div>
          </div>
        </div>

        <Heading type={3} align="left">
          Your public information
        </Heading>
        <p className={styles.byline}>
          Change the public information available to other users.
        </p>
        <div className={styles.subsection}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <InputLabel htmlFor="username">Username</InputLabel>
              <ChangeInput
                id="username"
                name="username"
                initialValue={userName || ''}
                onConfirm={async (nextUserName) => {
                  await updateUserProfile({
                    variables: { username: nextUserName },
                    errorPolicy: 'all',
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <InputLabel htmlFor="job-title">Job title</InputLabel>
              <ChangeInput
                id="job-title"
                name="job-title"
                initialValue={publicJobTitle || ''}
                onConfirm={async (nextJobTitle) => {
                  await updateUserProfile({
                    variables: { jobTitle: nextJobTitle },
                    errorPolicy: 'all',
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.subsection}>
          <InputLabel htmlFor="profile-image-link">Profile picture</InputLabel>
          <ImgUploader
            id="profile-image-link"
            imgSrc={profileImgLink || ''}
            uploadFn={async (file: File) => {
              const res = await updateUserImage({ file })

              if (res) {
                refetchProfileInfo()

                callUpdate()
                return true
              }

              return false
            }}
          />
        </div>

        <div className={styles.subsection}>
          <div className={styles.fieldRow}>
            <Button
              variant="secondary"
              onPress={() => setChangeEmailModal(true)}
            >
              Change email
            </Button>
            <Button
              variant="secondary"
              onPress={() => setChangePasswordModal(true)}
            >
              Change password
            </Button>
          </div>
        </div>
      </div>

      {changeEmailModal && (
        <UpdateEmailModal
          setChangeEmailModal={setChangeEmailModal}
          ssoEmailJourney={ssoEmailJourney}
        />
      )}
      {changePasswordModal && (
        <UpdatePasswordModal setChangePasswordModal={setChangePasswordModal} />
      )}
    </>
  )
}

export default ProfileSettings
