import React from 'react'
import { useReactiveVar } from '@apollo/client'

import { CopyButton } from './button'
import { BoldText, Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import styles from '../styles/account-support-settings.module.scss'

const AccountSupportSettings = () => {
  const { userID, workspaceID, companyID } = useReactiveVar(currentUserDetails)

  return (
    <>
      {/* Uplifter employees (support users) only */}
      <div className={styles.subsection}>
        <Heading type={3} align="left" className={styles.hiddenButton}>
          <span>IDs for debugging</span>
          <CopyButton
            value={` uid:\n${userID}\naccountID:\n${workspaceID}\ncompanyID:\n${companyID}`}
          >
            Copy all
          </CopyButton>
        </Heading>
        <div className={styles.idSection}>
          <p>
            <BoldText>User ID:</BoldText> {userID}{' '}
          </p>
          <CopyButton value={`uid:\n${userID}`} />
        </div>
        <div className={styles.idSection}>
          <p>
            <BoldText>Workspace ID:</BoldText> {workspaceID}{' '}
          </p>
          <CopyButton value={`workspaceID:\n${workspaceID}`} />
        </div>
        <div className={styles.idSection}>
          <p>
            <BoldText>Company ID:</BoldText> {companyID}{' '}
          </p>
          <CopyButton value={`companyID:\n${companyID}`} />
        </div>
      </div>
    </>
  )
}

export default AccountSupportSettings
