import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'

import Button from './button'
import { BetaLabel } from './counter'
import ProgressBar from './progress-bar'
import { Heading } from './typography'
import WorkspaceSelector from './workspace-selector'
import UpgradeIcon from '../assets/svgs/upgrade-rocket.svg'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/intro.module.scss'

interface IntroProps {
  showWorkspaceInfo?: boolean
  title?: string | React.ReactElement
  showUpgrade?: boolean
  isBeta?: boolean
  className?: string
  children?: React.ReactNode | string
}

const Intro = ({
  showWorkspaceInfo = true,
  title,
  showUpgrade = true,
  isBeta = false,
  className,
  children,
}: IntroProps) => {
  const history = useHistory()

  const logAction = useLogAction()

  const { isEnterprise, paddleData } = useSubscriptionLevel()

  const { trialDaysLeft, fullTrialLength } = useMemo(() => {
    if (!paddleData || isEnterprise) return {}

    const {
      paddleSubscriptionStatus,
      paddleSubscriptionTrialStartDate,
      paddleSubscriptionTrialEndDate,
    } = paddleData.currentCompany

    if (
      paddleSubscriptionStatus !== 'trialing' ||
      !paddleSubscriptionTrialEndDate
    ) {
      return {}
    }

    const trialStartMoment = moment(paddleSubscriptionTrialStartDate)
    const trialEndMoment = moment(paddleSubscriptionTrialEndDate)
    const trialLength = trialEndMoment.diff(trialStartMoment, 'days')
    const daysLeft = trialEndMoment.diff(moment(), 'days')

    if (daysLeft < 0) return {}

    return {
      trialDaysLeft: daysLeft,
      fullTrialLength: trialLength,
    }
  }, [isEnterprise, paddleData])

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.headerWrapper}>
        <Heading className={styles.header} type={1} align="left">
          {title}
          {isBeta && <BetaLabel className={styles.beta} />}
        </Heading>
        {showWorkspaceInfo && (
          <div className={styles.selectorWrapper}>
            {typeof trialDaysLeft === 'number' && (
              <div className={styles.trialWrapper}>
                <p className={styles.trial}>
                  {trialDaysLeft} day
                  {trialDaysLeft === 1 ? '' : 's'} left of trial
                </p>
                <ProgressBar
                  className={styles.progressBar}
                  percentage={(trialDaysLeft / fullTrialLength) * 100}
                  showPercent={false}
                  thin
                  superThin
                  useUrgency
                  urgencyReversed
                />
              </div>
            )}
            {showUpgrade && !isEnterprise && (
              <Button
                style={{ height: 40 }}
                icon={{
                  src: UpgradeIcon,
                  alt: 'Upgrade',
                  iconAfter: true,
                }}
                onPress={() => {
                  // @ts-ignore
                  if (window.dataLayer && window.dataLayer.push) {
                    // @ts-ignore
                    window.dataLayer.push({
                      event: 'click-upgrade-header-button',
                    })
                  }

                  logAction({
                    variables: {
                      action: 'click-upgrade-header-button',
                      websiteSection: 'upgrade',
                      pagePath: window.location.pathname,
                      functionName: 'clickUpgrade',
                    },
                  })

                  history.push('/upgrade', {
                    from: window.location.pathname,
                  })
                }}
              >
                Upgrade
              </Button>
            )}
            <div className={styles.siteSelector}>
              <WorkspaceSelector />
            </div>
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

export default Intro
