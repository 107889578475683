import {
  ApolloClient,
  FieldMergeFunction,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import _ from 'lodash'

import { currentUserDetails } from './variables'
import { GRAPHQL_API_BASE_DOMAIN, TOKEN } from '../constants'
import { getLocalItem } from '../../helpers/local-client'

export const httpLink = createHttpLink({
  uri: `${GRAPHQL_API_BASE_DOMAIN}graphql`,
})

export const authLink = setContext((unused, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getLocalItem(TOKEN)

  const context = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }

  // Apply staging override
  if (
    process.env.REACT_APP_STAGING_OVERRIDE === 'true' &&
    process.env.REACT_APP_NODE_ENV !== 'production'
  ) {
    context.headers['override-staging-database'] = 'true'
  }

  // return the headers to the context so httpLink can read them
  return context
})

// List of GQL operations that should be applied under the below retry policy
const operationsToRetry = ['GetUplifterIDCurrentTotal']

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => {
      const { operationName } = _operation

      return !!error && operationsToRetry.indexOf(operationName) > -1
    },
  },
})

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

/** Function to insert newly fetched links into full links array at correctly sorted position */
const mergeCodeListItems: FieldMergeFunction = (
  existing: any[] = [],
  incoming: any[],
  { variables },
) => {
  const { limit, offset, codeIDList } = variables || { limit: 0, offset: 0 }

  // If codeIDList is present, codes are fetched based on result of a previous query
  // These have a single cache item (ID: isCodeIDList = true) and should be overwritten
  if (codeIDList) {
    return incoming
  }

  if (!existing) {
    const fillArray = new Array(offset).fill(null)

    const initial = incoming.slice(0, limit)

    return [...fillArray, ...initial]
  }

  const newLinks = _.cloneDeep(existing)

  // Pad array if offset is greater than current length
  if (offset >= newLinks.length) {
    const fillArray = new Array(offset - newLinks.length).fill(null)

    newLinks.push(...fillArray, ...incoming.slice(0, limit))
  } else {
    // Insert new links into existing array
    newLinks.splice(offset, limit, ...incoming.slice(0, limit))
  }

  return newLinks
}

export const cache = new InMemoryCache({
  typePolicies: {
    CurrentUser: {
      keyFields: ['userID'],
    },
    Account: {
      fields: {
        userAccountProfiles: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
      keyFields: ['accountID'],
    },
    Company: {
      keyFields: ['companyID'],
    },
    TrackQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    DeepLinkQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    DeepLinkAppGroup: {
      keyFields: ['appGroupID'],
    },
    CampaignCodeGenerator: {
      fields: {
        accountID: {
          read(x, { variables }) {
            // If query is requesting by accountID
            // Instead of current account
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'accountID')
            ) {
              return variables.accountID
            }

            return currentUserDetails().workspaceID
          },
        },
        paramDefs: {
          merge(existing: any[] = [], incoming: any[], { variables }) {
            return incoming
          },
        },
      },
      keyFields: ['accountID'],
    },
    // TODO: Update caching so this doesn't have to be nested in CampaignCodeGenerator
    // ParamDef: {
    //   keyFields: ['fieldID'],
    // },
    CampaignLinkDashboard: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    MinimalCodeList: {
      // Default policy for fields here is to override them
      // This is to ensure data is fetched from network every time
      // Exception is on the addCodes mutation, where we want to add to the cache
      // All fields below are configured to do this.
      fields: {
        filteredByCurrentUser: {
          read(x, { variables }) {
            // codeList variable only appears on createLink mutation - new links should be prepended to current user cached item
            if (variables?.codeList) {
              return true
            }

            // codeIDList has length one if fetching from report page
            if (variables?.codeIDList?.length > 1) {
              return true
            }

            return variables?.filterByCurrentUser || false
          },
        },
        dimensionFilter: {
          read(x, { variables }) {
            if (!variables?.dimensionFilter) return null

            const { dimensionName } = variables.dimensionFilter

            // Dimension filter is present, but not filtering
            if (dimensionName === '') {
              return null
            }

            return JSON.stringify(variables.dimensionFilter)
          },
        },
        sortDirection: {
          read(x, { variables }) {
            return variables?.orderBy?.sortDirection || 'DESC'
          },
        },
        sortField: {
          read(x, { variables }) {
            return variables?.orderBy?.sortField || 'createdTime'
          },
        },
        // LinkID is used to fetch version history for a single link
        versionHistoryLinkID: {
          read(x, { variables }) {
            // If query is requesting by accountID
            // Instead of current account
            if (
              variables &&
              Object.prototype.hasOwnProperty.call(variables, 'codeID')
            ) {
              return variables.codeID
            }

            return null
          },
        },
        isCodeIDList: {
          read(x, { variables }) {
            return !!variables?.codeIDList
          },
        },
        author: {
          merge: mergeCodeListItems,
        },
        codeDef: {
          merge: mergeCodeListItems,
        },
        codeID: {
          merge: mergeCodeListItems,
        },
        createdTime: {
          merge: mergeCodeListItems,
        },
        fullLink: {
          merge: mergeCodeListItems,
        },
        shortLink: {
          merge: mergeCodeListItems,
        },
        versionNumber: {
          merge: mergeCodeListItems,
        },
        // Total codes should always be the same for the set filter - pagination does not change it
        totalCodes: {
          merge: (existing, incoming: number) => {
            return incoming
          },
        },
      },
      keyFields: [
        'filteredByCurrentUser',
        'dimensionFilter',
        'sortDirection',
        'sortField',
        'versionHistoryLinkID',
        'isCodeIDList',
      ],
    },
    CodeMetricData: {
      fields: {
        filteredByCurrentUser: {
          read(x, { variables }) {
            return variables?.filterByCurrentUser || false
          },
        },
        dimensionFilter: {
          read(x, { variables }) {
            if (!variables?.dimensionFilter) return null

            const {
              dimensionName,
              dimensionParameterID,
              dimensionOptions,
            } = variables.dimensionFilter

            // Dimension filter is present, but not filtering
            if (
              dimensionName === '' &&
              dimensionParameterID === 'any' &&
              dimensionOptions.length === 0
            ) {
              return null
            }

            return JSON.stringify(variables.dimensionFilter)
          },
        },
        sortDirection: {
          read(x, { variables }) {
            return variables?.orderBy?.sortDirection || 'DESC'
          },
        },
        sortField: {
          read(x, { variables }) {
            return variables?.orderBy?.sortField || 'createdTime'
          },
        },
        isCodeIDList: {
          read(x, { variables }) {
            return !!variables?.codeIDList
          },
        },
        codeIDs: {
          merge: mergeCodeListItems,
        },
        earliestClickMetricDate: {
          merge: (existing, incoming) => {
            if (!existing) return incoming
            if (!incoming) return existing

            const existingDate = new Date(existing)
            const incomingDate = new Date(incoming)

            return incomingDate < existingDate ? incoming : existing
          },
        },
        earliestMetricDate: {
          merge: (existing, incoming) => {
            if (!existing) return incoming
            if (!incoming) return existing

            const existingDate = new Date(existing)
            const incomingDate = new Date(incoming)

            return incomingDate < existingDate ? incoming : existing
          },
        },
        earliestDeepLinkDate: {
          merge: (existing, incoming) => {
            if (!existing) return incoming
            if (!incoming) return existing

            const existingDate = new Date(existing)
            const incomingDate = new Date(incoming)

            return incomingDate < existingDate ? incoming : existing
          },
        },
        metricValues: {
          merge: (existing, incoming) => {
            // TODO
            return incoming
          },
        },
        status: {
          merge: mergeCodeListItems,
        },
        // Total codes should always be the same for the set filter - pagination does not change it
        totalCodes: {
          merge: (existing, incoming: number) => {
            return incoming
          },
        },
      },
      keyFields: [
        'filteredByCurrentUser',
        'dimensionFilter',
        'sortDirection',
        'sortField',
        'isCodeIDList',
      ],
    },
    DeepLink: {
      keyFields: ['campaignCodeID'],
    },
    LinkAuditQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    LinkAuditTable: {
      keyFields: ['pageData', ['destination']],
    },
    UplifterIDSequentialTotal: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    ReportQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
        listSavedLinkPerformanceReportsGQL: {
          keyArgs: () => 'ListSavedLinkPerformanceReportsGQL',
        },
        listSavedLostLinksReports: {
          keyArgs: () => 'ListSavedLostLinksReports',
        },
      },
      keyFields: ['accountID'],
    },
    MarketingFunnelQueries: {
      fields: {
        accountID: {
          read() {
            return currentUserDetails().workspaceID
          },
        },
      },
      keyFields: ['accountID'],
    },
    MarketingFunnelReport: {
      keyFields: ['boardID'],
    },
  },
})

const client = new ApolloClient({
  link: from([errorLink, authLink, retryLink, httpLink]),
  cache,
  connectToDevTools: process.env.REACT_APP_NODE_ENV !== 'production',
})

export default client
