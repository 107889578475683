import React from 'react'
import classNames from 'classnames'

import Light from '../assets/light-tag.svg'
import styles from '../styles/tag.module.scss'

interface TagProps {
  icon?: string
  text?: string
  className?: string
  children?: React.ReactNode
  inline?: boolean
  multiLine?: boolean
}

const Tag = ({
  icon = Light,
  text = 'INSTANT INTEGRATION',
  className,
  children,
  inline,
  multiLine,
}: TagProps) => {
  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.inline]: inline,
        [styles.multiLine]: multiLine,
      })}
    >
      <div className={styles.inner}>
        <img src={icon} alt="text" />
        {children ? <span>{children}</span> : <span>{text}</span>}
      </div>
    </div>
  )
}

export default Tag
