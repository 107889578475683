import React from 'react'
import classNames from 'classnames'

import styles from '../styles/layout.module.scss'
import { siteContainerWidth } from '../core/constants'

interface Props {
  children?: React.ReactNode
  width?: number
  style?: object
  type?: 'flex' | 'full-screen' | ''
  className?: string
}

export default function Layout({
  type = '',
  className,
  style,
  width = 1200,
  children,
}: Props): React.ReactElement {
  const c = classNames(className, {
    [styles.containerFlex]: type === 'flex',
    [styles.containerFullScreen]: type === 'full-screen',
    [styles.container]: type === '',
  })
  return (
    <div className={c} style={{ maxWidth: `${width}px`, ...style }}>
      {children}
    </div>
  )
}

Layout.defaultProps = {
  children: '',
  width: siteContainerWidth,
  style: {},
}
