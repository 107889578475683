import React, { useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import {
  currentUserDetails,
  dataSourceReactive,
  linkOrCode,
} from '../api/apollo/variables'
import { updateAccountDescription } from '../api/graphql/company-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import adobeAnalytics from '../assets/logos/adobe-analytics.svg'
import googleAnalytics from '../assets/logos/google-analytics-icon.svg'
import SiteWrapper from '../components/site-wrapper'
import Layout from '../components/layout'
import Intro from '../components/intro'
import { NavigateButton } from '../components/button'
import TrackIntroContent from '../components/track-intro-content'
import { Toggle } from '../components/input-v2'
import TrackLearnAccordion from '../components/track-learn-accordion'
import { isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/track-learn-accordion.module.scss'

const TrackLearn = () => {
  const { workspaceID, userPermission } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)
  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const history = useHistory()

  const { data: dataSourceData } = useQuery(getAccountDataSource)

  const [updateTrackLearnCopy] = useMutation(updateAccountDescription)

  const dataSourceCopyToShow = useMemo(() => {
    if (!!dataSource && dataSource.connectionSource !== 'not-connected') {
      return dataSource.connectionSource === 'adobe'
        ? 'adobe'
        : 'googleAnalytics'
    }

    return dataSourceData?.currentAccount.trackLearnCopy === 'AA_REPORT'
      ? 'adobe'
      : 'googleAnalytics'
  }, [dataSourceData])

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <Intro title="Learn how to track campaigns">
          <TrackIntroContent
            tagLine="Click on a topic to start learning and take our test to get a
              LinkedIn certificate."
            className={styles.introContainer}
          >
            {/* User should only be able to toggle if not connected to a data source */}
            {isAdminUser(userPermission) &&
              (!dataSource ||
                dataSource.connectionSource === 'not-connected') && (
                <div className={styles.switchContainer}>
                  <p className={styles.toggleLabel}>
                    {dataSourceCopyToShow === 'adobe'
                      ? 'Adobe Analytics'
                      : 'Google Analytics'}
                  </p>
                  <Toggle
                    checked={dataSourceCopyToShow === 'adobe'}
                    onColor="#cbd5e0"
                    offColor="#cbd5e0"
                    checkedIcon={
                      <img
                        src={adobeAnalytics}
                        className={styles.toggleIcon}
                        alt="Adobe Analytics"
                      />
                    }
                    uncheckedIcon={
                      <img
                        src={googleAnalytics}
                        className={styles.toggleIcon}
                        alt="Google Analytics"
                      />
                    }
                    onChange={async (isAdobe) => {
                      await updateTrackLearnCopy({
                        variables: {
                          accountID: workspaceID,
                          trackLearnCopy: isAdobe ? 'AA_REPORT' : 'GA4_PROP',
                        },
                      })

                      logAction({
                        variables: {
                          action: 'track-learn-switch-data-source',
                          pagePath: '/track/learn',
                          functionName: 'dataSourceToggle',
                          websiteSection: 'track',
                          extra: isAdobe ? 'AA_REPORT' : 'GA4_PROP',
                        },
                      })
                    }}
                  />
                </div>
              )}
          </TrackIntroContent>
        </Intro>
        <TrackLearnAccordion adobe={dataSourceCopyToShow === 'adobe'} />
        <NavigateButton onPress={() => history.push('/track/create-links')}>
          Create {linkCopy}s
        </NavigateButton>
      </Layout>
    </SiteWrapper>
  )
}

export default TrackLearn
