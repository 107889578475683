import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import {
  AddWorkspaceBlockerModal,
  EnterpriseFeatureBlockerModal,
} from './feature-blocker-modals'
import Input, { Label } from './input'
import Link from './link'
import Modal from './modal'
import Row, { FieldSlot, LabelSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { BoxedText, ErrorMessage } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { getUserAccounts } from '../api/graphql/user-client'
import {
  addNewWorkspace,
  getCompanyDetails,
} from '../api/graphql/company-client'
import { dynamicTextValues, supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import useSwitchWorkspace from '../hooks/useSwitchWorkspace'
import styles from '../styles/add-workspace-modal.module.scss'

interface AddWorkspaceModalProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>
}

const AddWorkspaceModal = ({ closeModal }: AddWorkspaceModalProps) => {
  const { workspaceID, companyID: currentCompanyID } = useReactiveVar(
    currentUserDetails,
  )

  const linkCopy = useReactiveVar(linkOrCode)

  const {
    data: accountProfilesData,
    loading: loadingWorkspacesData,
  } = useQuery(getUserAccounts)
  const { data: companyData, loading: loadingCompanyData } = useQuery(
    getCompanyDetails,
  )

  const [
    createNewWorkspace,
    { loading: creatingWorkspace, error: createWorkspaceError },
  ] = useMutation(addNewWorkspace)

  const logAction = useLogAction()

  const {
    loadingSubscriptionInfo,
    subscriptionCategory,
    isEnterprise,
    isMicrosoftMarketplace,
    featureLimits: { workspaces: maxWorkspaces },
  } = useSubscriptionLevel()

  const { switchWorkspace, loading: switchingWorkspace } = useSwitchWorkspace()

  const validWorkspaces = useMemo(() => {
    if (!accountProfilesData) return []

    return accountProfilesData.currentUser.userAccountProfiles
      .filter(({ companyID }) => companyID === currentCompanyID)
      .map(({ accountID, accountName }) => ({ accountID, accountName }))
  }, [accountProfilesData])

  const userAccountNames = useMemo(() => {
    return validWorkspaces.map(({ accountName }) => accountName)
  }, [validWorkspaces])

  const [newWorkspaceName, setNewWorkspaceName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [copyWorkspaceSettings, setCopyWorkspaceSettings] = useState({
    copyGenerator: true,
    copyGeneratorID: workspaceID,
    copyQRSettings: true,
    copyQRSettingsID: workspaceID,
  })

  const workspaceCount = useMemo(() => {
    return companyData?.currentCompany.accountCount || 0
  }, [companyData])

  const canAddWorkspaces = useMemo(() => {
    return workspaceCount < maxWorkspaces
  }, [workspaceCount, maxWorkspaces])

  useEffect(() => {
    if (!canAddWorkspaces) {
      // @ts-ignore
      if (window.dataLayer && window.dataLayer.push) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'add-workspaces-upgrade-blocker',
          subscription_level: subscriptionCategory,
        })
      }

      logAction({
        variables: {
          action: 'add-workspaces-upgrade-blocker',
          websiteSection: 'settings',
          pagePath: '/settings',
          functionName: 'clickUpgrade',
          extra: JSON.stringify({
            subscriptionCategory,
          }),
        },
      })
    }
  }, [canAddWorkspaces])

  if (loadingWorkspacesData || loadingCompanyData || loadingSubscriptionInfo) {
    return <Modal setIsOpen={closeModal} loading />
  }

  // Show upgrade blocker if user can't add workspaces
  if (!canAddWorkspaces) {
    if (isEnterprise && !isMicrosoftMarketplace) {
      return (
        <EnterpriseFeatureBlockerModal
          onHideModal={closeModal}
          feature="workspace"
        />
      )
    }

    return <AddWorkspaceBlockerModal onHideModal={closeModal} />
  }

  return (
    <Modal
      width="wide"
      setIsOpen={closeModal}
      modalHeader="Create new workspace"
      yesText="Create new workspace"
      yesButtonDisabled={newWorkspaceName === '' || nameError}
      yesButtonLoading={creatingWorkspace || switchingWorkspace}
      onYes={async () => {
        const { data } = await createNewWorkspace({
          variables: {
            workspaceName: newWorkspaceName,
            copyGeneratorAccountID: copyWorkspaceSettings.copyGenerator
              ? copyWorkspaceSettings.copyGeneratorID || workspaceID
              : undefined,
            copyAccountLogoID: copyWorkspaceSettings.copyQRSettings
              ? copyWorkspaceSettings.copyQRSettingsID || workspaceID
              : undefined,
            copyQRSettingsAccountID: copyWorkspaceSettings.copyQRSettings
              ? copyWorkspaceSettings.copyQRSettingsID || workspaceID
              : undefined,
            copyQRLogoAccountID: copyWorkspaceSettings.copyQRSettings
              ? copyWorkspaceSettings.copyQRSettingsID || workspaceID
              : undefined,
          },
        })

        if (data) {
          switchWorkspace(
            data.userAccountSettings.addAccount.accountID,
            '/welcome',
          )
        }
      }}
      footerContent={
        <>
          {createWorkspaceError && (
            <ErrorMessage showSupport>
              There was an issue creating the workspace.
            </ErrorMessage>
          )}
        </>
      }
    >
      <p>
        Create a new workspace for a separate company, brand, website or
        channel. Each workspace can only be connected to a single web analytics
        profile.
      </p>
      <ul>
        <li>
          <strong>Agencies</strong> create separate workspaces for different
          clients, so each client can have different users, link taxonomies,
          reports and integrations.
        </li>
        <li>
          <strong>Large companies</strong> create separate workspaces for
          different brands or websites. By default each workspace is
          partitioned, so you manage each one separately.
        </li>
        <li>
          <strong>A few companies</strong> create different workspaces for
          different marketing channels. We don't recommend it because it makes
          comparing channels and managing users more difficult.
        </li>
      </ul>
      <Row align="flex-start" className={styles.formRow}>
        <LabelSlot>
          <Label id="workspaceName">
            <Tooltip
              id="workspaceName"
              useIcon
              tooltipMessage="What your new workspace will be called."
              tooltipPosition="right"
            >
              Workspace name
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <Input
            showClear
            id="workspaceName"
            name="workspaceName"
            placeholder="Workspace name"
            value={newWorkspaceName}
            error={nameError}
            onValueChange={(val: string) => {
              setNewWorkspaceName(val)

              if (userAccountNames.indexOf(val) > -1) setNameError(true)
              else if (nameError) setNameError(false)
            }}
          />
          {nameError && (
            <ErrorMessage>
              You already have an account with the name "{newWorkspaceName}"
            </ErrorMessage>
          )}
        </FieldSlot>
      </Row>
      <Row align="flex-start" className={styles.formRow}>
        <FieldSlot>
          <Input
            type="checkbox"
            name="copy-qr-settings-from"
            id="copy-qr-settings-from"
            className={styles.checkboxItem}
            checked={copyWorkspaceSettings.copyQRSettings}
            label="Copy logo and QR code settings"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target as HTMLInputElement

              setCopyWorkspaceSettings((curr) => {
                return {
                  ...curr,
                  copyQRSettings: checked,
                }
              })
            }}
          >
            {copyWorkspaceSettings.copyQRSettings && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={styles.ruleDropdown}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <SelectBox
                  id="copy-generator-from-id"
                  menuPlacement="auto"
                  labelKey="accountName"
                  valueKey="accountID"
                  value={validWorkspaces.find(
                    ({ accountID }) =>
                      accountID === copyWorkspaceSettings.copyQRSettingsID,
                  )}
                  options={validWorkspaces}
                  onChange={(newValue) => {
                    if (!newValue) return

                    setCopyWorkspaceSettings((curr) => {
                      return {
                        ...curr,
                        copyQRSettingsID: newValue.accountID,
                      }
                    })
                  }}
                />
              </div>
            )}
          </Input>
        </FieldSlot>
      </Row>
      <Row align="flex-start" className={styles.formRow}>
        <FieldSlot>
          <Input
            type="checkbox"
            name="copy-generator-from"
            id="copy-generator-from"
            className={styles.checkboxItem}
            checked={copyWorkspaceSettings.copyGenerator}
            label="Copy link taxonomy, parameters, dropdowns and rules"
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              const { checked } = e.target as HTMLInputElement

              setCopyWorkspaceSettings((curr) => {
                return {
                  ...curr,
                  copyGenerator: checked,
                }
              })
            }}
          >
            <Tooltip
              id="copy-generator-from"
              useIcon
              tooltipMessage={
                <p>
                  If checked, this will copy your current{' '}
                  <BoxedText>
                    {dynamicTextValues.trackCreatePage[linkCopy]}
                  </BoxedText>{' '}
                  generator parameters, dropdowns and rules into a new generator
                  in your new workspace. Editing parameters and dropdowns will
                  only make changes to the workspace you are in. To sync
                  parameters, dropdowns and rules across workspaces, email{' '}
                  <Link
                    className={styles.supportLink}
                    href={`mailto:${supportEmail}`}
                  >
                    {supportEmail}
                  </Link>
                  .
                </p>
              }
              tooltipPosition="right"
            />
            {copyWorkspaceSettings.copyGenerator && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={styles.ruleDropdown}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <SelectBox
                  id="copy-generator-from-id"
                  menuPlacement="auto"
                  labelKey="accountName"
                  valueKey="accountID"
                  value={validWorkspaces.find(
                    ({ accountID }) =>
                      accountID === copyWorkspaceSettings.copyGeneratorID,
                  )}
                  options={validWorkspaces}
                  onChange={(newValue) => {
                    if (!newValue) return

                    setCopyWorkspaceSettings((curr) => {
                      return {
                        ...curr,
                        copyGeneratorID: newValue.accountID,
                      }
                    })
                  }}
                />
              </div>
            )}
          </Input>
        </FieldSlot>
      </Row>
      <Row align="flex-start" className={styles.formRow}>
        <FieldSlot>
          <Input
            type="checkbox"
            name="sync-company-generators"
            id="sync-company-generators"
            className={styles.checkboxItem}
            disabled
            checked={false}
            label="Sync link taxonomy, parameters, dropdowns and rules"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              console.log('TBC')
            }}
          >
            <Tooltip
              id="sync-company-generators"
              useIcon
              tooltipMessage={
                <p>
                  Email{' '}
                  <Link
                    className={styles.supportLink}
                    href={`mailto:${supportEmail}`}
                  >
                    {supportEmail}
                  </Link>{' '}
                  to use one link generator with the same taxonomy, parameters,
                  dropdowns and rules across workspaces.
                </p>
              }
            />
          </Input>
        </FieldSlot>
      </Row>
    </Modal>
  )
}

export default AddWorkspaceModal
