import React, { useEffect, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Button from './button'
import { FormField, FormLabel, FormRow } from './form'
import { RadioGroup } from './input-v2'
import { CustomLinkFull } from './custom-link-fields'
import Tooltip from './tooltip'
import QRCodePreviewRow from './track-create-qr-preview-row'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { messages, uplifterWebsite } from '../core/constants'
import { saveUserData } from '../helpers/local-client'
import { CustomLinkAliasDetails } from '../helpers/custom-links'
import { maxBatchShortLinks } from '../helpers/track-module'
import { LinkType } from '../helpers/track-create'
import styles from '../styles/track-create-submit.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

interface TrackCreateSubmitFieldsProps {
  validationChecks:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['validationChecks']
    | null
  linkType: LinkType
  setLinkType: React.Dispatch<React.SetStateAction<LinkType>>
  customLinkAliasDetails: CustomLinkAliasDetails
  setCustomLinkAliasDetails: React.Dispatch<
    React.SetStateAction<CustomLinkAliasDetails>
  >
  linkCount?: number
  submitLoading?: boolean
  /** Allows submit button to be clickable, showing errors */
  softDisable?: boolean
  submitDisabled?: boolean
  previewLink?: string
  qrData?: { fgColour: string; bgColour: string; logoImage: string }
  onChangeQrData?: (type: 'fg' | 'bg' | 'logo', value: string) => void
  children?: React.ReactNode
}

/** Submit button for Track>Create forms, plus shortLink fields if required */
const TrackCreateSubmitFields = ({
  validationChecks,
  linkType,
  setLinkType,
  customLinkAliasDetails,
  setCustomLinkAliasDetails,
  linkCount = 1,
  submitLoading,
  softDisable,
  submitDisabled,
  previewLink,
  qrData,
  onChangeQrData,
  children,
}: TrackCreateSubmitFieldsProps) => {
  const { workspaceHomepage } = useReactiveVar(currentUserDetails)

  const referToLinks = useReactiveVar(linkOrCode)

  /** Options and order for URL link presentation: 'shortLink' or 'basic' */
  const shortLinkOptions = useMemo(() => {
    const options = [
      {
        label: (
          <>
            Short link <span className={styles.recommended}>(recommended)</span>
          </>
        ),
        value: 'shortLink',
      },
      {
        label: 'Basic link',
        value: 'basic',
      },
    ]

    const forceShortLinkRule = validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      const shortLinkValue = JSON.parse(forceShortLinkRule.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
        case 'force-long-links':
          return []
        case 'recommend-long-links':
          return [
            {
              label: (
                <>
                  Basic link{' '}
                  <span className={styles.recommended}>(recommended)</span>
                </>
              ),
              value: 'basic',
            },
            {
              label: 'Short link',
              value: 'shortLink',
            },
          ]
        default:
          break
      }
    }

    return options
  }, [validationChecks])

  /** Ensures preview QR code doesn't show if e.g. on the email form */
  const previewLinkToShow = useMemo(() => {
    if (previewLink === undefined) return null

    return previewLink || workspaceHomepage || uplifterWebsite
  }, [previewLink])

  // Reset custom link alias details if number of valid link combinations changes
  useEffect(() => {
    if (linkCount > 1 && !customLinkAliasDetails?.isBatch) {
      setCustomLinkAliasDetails((curr) => ({
        ...curr,
        key: nanoid(),
        isBatch: true,
      }))
    } else if (linkCount === 1 && customLinkAliasDetails?.isBatch) {
      setCustomLinkAliasDetails((curr) => ({
        ...curr,
        key: nanoid(),
        isBatch: false,
      }))
    }
  }, [linkCount, customLinkAliasDetails?.isBatch])

  let tooltip = ''

  if (linkType === 'shortLink') {
    tooltip = messages.customiseShortLink
  } else if (linkType === 'appLink') {
    tooltip = messages.customiseDeepLink
  }

  return (
    <>
      {/* First field should only be visible if not using appLinks and user can choose short/basic link type */}
      {shortLinkOptions.length > 0 && linkType !== 'appLink' && (
        <FormRow
          heading={
            <Tooltip
              id="presentation-heading-tooltip"
              useIcon
              tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
            >
              Presentation
            </Tooltip>
          }
        >
          <FormLabel id="link-type" tooltip={messages.linkTypes}>
            Link type
          </FormLabel>
          <FormField>
            <RadioGroup
              groupName="link-type"
              horizontal
              options={shortLinkOptions}
              optionsClassName={styles.radioButton}
              selectedValue={linkType}
              onChange={(option) => {
                setLinkType(option as 'shortLink' | 'basic')
                saveUserData({ linkType: option })
              }}
            />
          </FormField>
        </FormRow>
      )}
      {linkType !== 'basic' && (
        <FormRow
          // Only show the heading if the previous FormRow is not present
          heading={
            linkType === 'appLink' ||
            (linkType === 'shortLink' && shortLinkOptions.length === 0) ? (
              <Tooltip
                id="presentation-heading-tooltip"
                useIcon
                tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
              >
                Presentation
              </Tooltip>
            ) : undefined
          }
        >
          <FormLabel id="custom-link" tooltip={tooltip}>
            {linkType === 'shortLink' ? 'Short' : 'App'} link
          </FormLabel>
          <FormField>
            <CustomLinkFull
              key={customLinkAliasDetails?.key || 'custom-link'}
              customLinkType={linkType}
              // We don't know how many links will be created until the button is pressed. So request the max allowed if > 1
              batchCount={linkCount > 1 ? maxBatchShortLinks : 1}
              domainIsEditable={linkType !== 'appLink'}
              showCustomiseMessage
              onAliasChange={(alias, status, isCustom) =>
                setCustomLinkAliasDetails((curr) => ({
                  ...curr,
                  alias,
                  status,
                  isCustom: isCustom || false,
                }))
              }
            />
          </FormField>
        </FormRow>
      )}
      {referToLinks === 'link' && qrData && previewLinkToShow && (
        <QRCodePreviewRow
          optional
          small
          customiseWithinCanvas
          linkToShow={previewLinkToShow}
          qrData={qrData}
          onChangeQrData={onChangeQrData}
        />
      )}
      <FormRow>
        <FormLabel />
        <FormField>
          <Button
            type="submit"
            className={classNames(styles.submitButton, {
              // [styles.softDisableButton]: softDisable,
            })}
            loading={submitLoading}
            isDisabled={
              submitDisabled ||
              ['invalid', 'refetching', 'validating'].includes(
                customLinkAliasDetails?.status || '',
              )
            }
          >
            {linkCount > 1
              ? `Review ${referToLinks}s`
              : `Create ${referToLinks}`}
          </Button>
          {children}
        </FormField>
      </FormRow>
    </>
  )
}

export default TrackCreateSubmitFields
