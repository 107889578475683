import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { activeConnectors } from '../api/apollo/variables'
import EloquaLogo from '../assets/logos/eloqua-logo.png'
import Button from '../components/button'
import ConnectorBox from '../components/connector-template'
import { SearchInput } from '../components/input'
import Intro from '../components/intro'
import Layout from '../components/layout'
import ProgressBar from '../components/progress-bar'
import RequestNewConnectorModal from '../components/request-new-connector-modal'
import SiteWrapper from '../components/site-wrapper'
import integrationsData, {
  integrationCategories,
} from '../connections/connectors-data'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/connect.module.scss'

// ! Making changes?
// Do any of them need to be applied to the public site version of this content?
// https://uplifter.ai/integrations
// https://github.com/uplifter-limited/public-site-connect-tiles
export default function ConnectPage() {
  const activeConnectorsCount = useReactiveVar(activeConnectors).length

  const {
    featureLimits: { connectors: maxConnectors },
  } = useSubscriptionLevel()

  const [searchType, setSearchType] = useState('any')
  const [searchTerm, setSearchTerm] = useState('')
  const [requestConnectorModal, setRequestConnectorModal] = useState(false)
  const [requestConnectorDetails, setRequestConnectorDetails] = useState<{
    connectorName?: string
    connectorLogo?: string
  }>({})

  // Eloqua is special - it uses basic auth instead of OAuth
  // Users must request support when setting up the integration
  // Which is handled in this effect
  // Only support users can see the Settings tab in the modal
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    const connectorRequest = query.get('connectorRequest')

    if (connectorRequest && connectorRequest === 'eloqua') {
      setRequestConnectorModal(true)
      setRequestConnectorDetails({
        connectorName: 'eloqua',
        connectorLogo: EloquaLogo,
      })
    }
  }, [window])

  const searchTypeList = useMemo(() => {
    return [
      { name: 'All', value: 'any' },
      ...integrationCategories.map((category) => ({
        name: category,
        value: category,
      })),
    ]
  }, [])

  return (
    <>
      <SiteWrapper>
        <Layout>
          <Intro title="Connect">
            <p>
              Integrate with other applications for better data, workflows and
              insights.
            </p>
          </Intro>
          <div className={styles.headerPanel}>
            <SearchInput
              value={searchTerm}
              selectValue={searchType}
              onChange={(value) => setSearchTerm(value || '')}
              searchTypeList={searchTypeList}
              onChangeSearchType={(type) => setSearchType(type)}
            />
            <div className={styles.progressIndicator}>
              <p>
                <strong>{activeConnectorsCount}</strong> of{' '}
                <strong>{maxConnectors} connectors</strong> used
              </p>
              <ProgressBar
                className={styles.progressBar}
                percentage={(activeConnectorsCount / maxConnectors) * 100}
                showPercent={false}
                thin
                superThin
                useUrgency
              />
            </div>
            <Button onPress={() => setRequestConnectorModal(true)}>
              Request new connector
            </Button>
          </div>
          <div className={styles.connectorBoxesContainer}>
            {integrationsData.map((connectionData) => {
              if (searchTerm) {
                // Fuzzy search
                const searchPattern = searchTerm
                  .replace(/[^a-zA-Z0-9]/g, '')
                  .split('')
                  .join('.*')

                if (
                  connectionData.title
                    .toLowerCase()
                    .match(new RegExp(searchPattern, 'i')) === null
                )
                  return null
              }

              if (searchType !== 'any') {
                const tagFound = connectionData.tags.find(
                  (tag) => tag === searchType,
                )

                if (!tagFound) return null
              }

              return (
                <ConnectorBox
                  key={connectionData.title}
                  connectionData={connectionData}
                  connectorLimitReached={activeConnectorsCount >= maxConnectors}
                />
              )
            })}
          </div>
        </Layout>
      </SiteWrapper>
      {requestConnectorModal && (
        <RequestNewConnectorModal
          toggleActive={setRequestConnectorModal}
          connectorName={requestConnectorDetails.connectorName}
          connectorLogo={requestConnectorDetails.connectorLogo}
        />
      )}
    </>
  )
}
