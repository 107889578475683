import { gql } from '../../__gql-types__/gql'

export const sendFeatureRequest = gql(`
	mutation SendFeatureRequest (
		$message: String!,
		$page: String!
	) {
		sendFeatureRequest (
			message: $message,
			page: $page,
		)
	}
`)

export const getEnterpriseCompanyDetails = gql(`
	query GetEnterpriseCompanyDetails {
		currentCompany {
			companyID
			whitelabel
			companyName
			businessDivision
			sponsorList {
				email
				name
			}
			legalEntityName
			contractStartDate
			breakClauseExists
			breakClause
			breakClauseDate
			contractEndDate
			totalContractValue
			revenuePerMonth
			customTandC
			autoRenew
			domains
			domainLimit
			namedUserLimit
			userLimit
			activeUserLimit
			userCount
			linkMonitorLimit
			connectionLimit
			workspaceLimit
			origDataSource
			trackAvailable
			reportAvailable
			planAvailable
			connectAvailable
			companyNotes
			aboutToExpireCheckbox
			hasExpiredCheckbox
    	isSuspendedCheckbox
		}
	}
`)

export const addSupportUser = gql(`
	mutation AddSupportUser (
		$email: String!
		$whitelabel: String
		$permissionLevel: String!
	) {
		userAccountSettings {
			makeUserSupportUser(
				email: $email
				whitelabel: $whitelabel
				permissionLevel: $permissionLevel
			) {
					userID
					email
					inviteOpen
					userAccountProfiles {
						userEmail
						accountID
						accountName
						userPermission
						companyID
						inviteOpen
					}
			}
		}
	}
`)

export const createEnterpriseCompany = gql(`
	mutation CreateEnterpriseCompany (
		$whitelabel: String
		$companyName: String!
		$businessDivision: String!
		$sponsorList: [GenericEmailInput!]!
		$legalEntityName: String!
		$contractStartDate: String!
		$breakClauseDate: String
		$contractEndDate: String!
		$totalContractValue: Float!
		$revenuePerMonth: Float!
		$customTandC: Boolean!
		$autoRenew: Boolean!
		$domains: String!
		$domainLimit: Int!
		$namedUserLimit: Int
		$userLimit: Int!
		$linkMonitorLimit: Int
		$connectionLimit: Int
		$workspaceLimit: Int
		$dataSourceType: String!
		$trackAvailable: Boolean!
		$reportAvailable: Boolean!
		$planAvailable: Boolean!
		$connectAvailable: Boolean!
		$companyNotes: String!
		$aboutToExpireCheckbox: Boolean
		$hasExpiredCheckbox: Boolean
		$isSuspendedCheckbox: Boolean
	) {
		userAccountSettings {
			updateUplifterAdminSettings {
				createEnterpriseCompany(
					whitelabel: $whitelabel
					companyName: $companyName
					businessDivision: $businessDivision
					accountManagerList: [{name: "Alexander Holman-Butt", email: "alex@uplifter.ai"}]
					sponsorList: $sponsorList
					legalEntityName: $legalEntityName
					contractStartDate: $contractStartDate
					breakClauseDate: $breakClauseDate
					contractEndDate: $contractEndDate
					totalContractValue: $totalContractValue
					revenuePerMonth: $revenuePerMonth
					customTandC: $customTandC
					autoRenew: $autoRenew
					domains: $domains
					domainLimit: $domainLimit
					namedUserLimit: $namedUserLimit
					userLimit: $userLimit
					linkMonitorLimit: $linkMonitorLimit
					connectionLimit: $connectionLimit
					workspaceLimit: $workspaceLimit
					dataSourceType: $dataSourceType
					trackAvailable: $trackAvailable
					reportAvailable: $reportAvailable
					planAvailable: $planAvailable
					connectAvailable: $connectAvailable
					explainAvailable: false
					auditAvailable: false
					companyNotes: $companyNotes
					aboutToExpireCheckbox: $aboutToExpireCheckbox
					hasExpiredCheckbox: $hasExpiredCheckbox
					isSuspendedCheckbox: $isSuspendedCheckbox
				) {
					companyID
					whitelabel
					companyName
					businessDivision
					sponsorList {
						email
						name
					}
					legalEntityName
					contractStartDate
					breakClauseExists
					breakClause
					breakClauseDate
					contractEndDate
					totalContractValue
					revenuePerMonth
					customTandC
					autoRenew
					domains
					domainLimit
					namedUserLimit
					userLimit
					activeUserLimit
					userCount
					linkMonitorLimit
					connectionLimit
					workspaceLimit
					origDataSource
					trackAvailable
					reportAvailable
					planAvailable
					connectAvailable
					companyNotes
					aboutToExpireCheckbox
					hasExpiredCheckbox
					isSuspendedCheckbox
				}
			}
		}
	}
`)

export const updateEnterpriseCompany = gql(`
	mutation UpdateEnterpriseCompany (
		$companyID: String!
		$companyName: String
		$businessDivision: String
		$sponsorList: [GenericEmailInput!]
		$legalEntityName: String
		$contractStartDate: String
		$breakClauseDate: String
		$contractEndDate: String
		$totalContractValue: Float
		$revenuePerMonth: Float
		$customTandC: Boolean
		$autoRenew: Boolean
		$domains: String
		$domainLimit: Int
		$namedUserLimit: Int
		$userLimit: Int
		$activeUserLimit: Int
		$linkMonitorLimit: Int
		$connectionLimit: Int
		$workspaceLimit: Int
		$dataSourceType: String
		$trackAvailable: Boolean
		$reportAvailable: Boolean
		$planAvailable: Boolean
		$connectAvailable: Boolean
		$companyNotes: String
		$aboutToExpireCheckbox: Boolean
		$hasExpiredCheckbox: Boolean
		$isSuspendedCheckbox: Boolean
	) {
		userAccountSettings {
			updateUplifterAdminSettings {
				updateEnterpriseCompany(
					companyID: $companyID
					companyName: $companyName
					businessDivision: $businessDivision
					accountManagerList: [{name: "Alexander Holman-Butt", email: "alex@uplifter.ai"}]
					sponsorList: $sponsorList
					legalEntityName: $legalEntityName
					contractStartDate: $contractStartDate
					breakClauseDate: $breakClauseDate
					contractEndDate: $contractEndDate
					totalContractValue: $totalContractValue
					revenuePerMonth: $revenuePerMonth
					customTandC: $customTandC
					autoRenew: $autoRenew
					domains: $domains
					domainLimit: $domainLimit
					namedUserLimit: $namedUserLimit
					userLimit: $userLimit
					activeUserLimit: $activeUserLimit
					linkMonitorLimit: $linkMonitorLimit
					connectionLimit: $connectionLimit
					workspaceLimit: $workspaceLimit
					dataSourceType: $dataSourceType
					trackAvailable: $trackAvailable
					reportAvailable: $reportAvailable
					planAvailable: $planAvailable
					connectAvailable: $connectAvailable
					companyNotes: $companyNotes
					aboutToExpireCheckbox: $aboutToExpireCheckbox
					hasExpiredCheckbox: $hasExpiredCheckbox
					isSuspendedCheckbox: $isSuspendedCheckbox
				) {
					companyID
					whitelabel
					companyName
					businessDivision
					sponsorList {
						email
						name
					}
					legalEntityName
					contractStartDate
					breakClauseExists
					breakClause
					breakClauseDate
					contractEndDate
					totalContractValue
					revenuePerMonth
					customTandC
					autoRenew
					domains
					domainLimit
					namedUserLimit
					userLimit
					activeUserLimit
					userCount
					linkMonitorLimit
					connectionLimit
					workspaceLimit
					origDataSource
					trackAvailable
					reportAvailable
					planAvailable
					connectAvailable
					companyNotes
					aboutToExpireCheckbox
					hasExpiredCheckbox
					isSuspendedCheckbox
				}
			}
		}
	}
`)
