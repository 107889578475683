import React, { useRef } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import Button from './button'
import { BiLine } from './counter'
import StyledDatePicker from './date-picker'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import Link from './link'
import Tooltip from './tooltip'
import QRCodePreviewRow from './track-create-qr-preview-row'
import { ErrorMessage } from './typography'
import { vEventFieldDefs, WebLinkForm } from '../helpers/track-create'
import { UpdateFormOptions } from '../hooks/useTrackCreateSavedValues'
import styles from '../styles/track-create-parameter-fields.module.scss'

/** Calculated once and used in datepicker field */
const minDate = moment().startOf('month').toDate()
const maxDate = moment().add(10, 'years').toDate()

interface TrackCreateVEventFieldsProps {
  formValues?: WebLinkForm['vEventFieldValues']
  onChange: (
    fieldName: string,
    newVal: string,
    options?: UpdateFormOptions,
  ) => void
  showErrorMessages?: boolean
  fieldsWithErrors: string[]
  qrData: { fgColour: string; bgColour: string; logoImage: string }
  onChangeQrData: (type: 'fg' | 'bg' | 'logo', value: string) => void
  submitDisabled?: boolean
}

const TrackCreateVEventFields = ({
  formValues = {},
  onChange,
  showErrorMessages,
  fieldsWithErrors,
  qrData,
  onChangeQrData,
  submitDisabled,
}: TrackCreateVEventFieldsProps) => {
  const formRowRefs = useRef<(HTMLDivElement | null)[]>([])

  return (
    <>
      <FormRow includePaddingBottom>
        <FormLabel />
        <FormField>
          <BiLine
            className={classNames(styles.contactBiline, styles.vEvent)}
            arrowTop
          >
            Event QR codes are not saved or tracked to protect PII.{' '}
            <Link
              type="arrowForward"
              // TODO: Update this
              href="https://support.uplifter.ai/hc/en-us/articles/24586963673501-How-do-I-make-a-QR-code-to-share-a-contact-vCard"
            >
              Learn more
            </Link>
          </BiLine>
        </FormField>
      </FormRow>
      {Object.keys(vEventFieldDefs).map((fieldName, fieldIndex) => {
        const {
          required,
          label,
          tooltip,
          placeholder,
          fieldType,
        } = vEventFieldDefs[fieldName]

        let dateValue: null | Date = null

        if (
          formValues[fieldName] !== '' &&
          moment(formValues[fieldName], 'YYYYMMDD').isValid()
        ) {
          const dateFormatted = moment(
            formValues[fieldName],
            'YYYYMMDD',
          ).toDate()
          dateValue = dateFormatted
        }

        return (
          <FormRow
            key={fieldName}
            ref={(el) => {
              formRowRefs.current[fieldIndex] = el
            }}
            heading={
              fieldIndex === 0 ? (
                <Tooltip
                  id="fields-heading-tooltip"
                  useIcon
                  tooltipMessage="The contact information you want users to access and save when scanning a QR code."
                >
                  Fields
                </Tooltip>
              ) : undefined
            }
            includePaddingBottom={
              fieldIndex === Object.keys(vEventFieldDefs).length - 1
            }
          >
            <FormLabel id={`vevent-${fieldName}`} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormField>
              {fieldType === 'date' && (
                <StyledDatePicker
                  key={fieldName}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  // className="date-picker"
                  isClearable
                  minDate={minDate}
                  maxDate={maxDate}
                  selected={dateValue}
                  onChange={(date) => {
                    // The value can never be a null
                    // only empty string is permitted in this case
                    let val = ''

                    if (date !== null) {
                      const dateF = moment(date.toString()).format('YYYYMMDD')
                      val = dateF
                    }

                    onChange(
                      fieldName,
                      val,
                      required && !val
                        ? { errorsToAdd: [fieldName] }
                        : { errorsToRemove: [fieldName] },
                    )
                  }}
                />
              )}
              {(!fieldType || fieldType === 'text') && (
                <Input
                  id={`vevent-${fieldName}`}
                  name={`vevent-${fieldName}`}
                  className={styles.inputField}
                  placeholder={`${required ? '' : '(Optional) '}${
                    placeholder || `Type ${label.toLowerCase()}`
                  }`}
                  showClear
                  value={formValues[fieldName] || ''}
                  error={
                    showErrorMessages && fieldsWithErrors.includes(fieldName)
                  }
                  onValueChange={(newVal) => {
                    onChange(
                      fieldName,
                      newVal,
                      required && !newVal
                        ? { errorsToAdd: [fieldName] }
                        : { errorsToRemove: [fieldName] },
                    )
                  }}
                  onPaste={(e) => {
                    const newPastedText = e.clipboardData.getData('Text')

                    onChange(
                      fieldName,
                      newPastedText,
                      required && !newPastedText
                        ? { errorsToAdd: [fieldName] }
                        : { errorsToRemove: [fieldName] },
                    )
                  }}
                  onKeyDown={(e) => {
                    // If form is not ready to submit, tab to the next field instead
                    if (
                      e.key === 'Enter' &&
                      fieldsWithErrors &&
                      fieldsWithErrors.length > 0
                    ) {
                      e.preventDefault()

                      let nextRowIndex = fieldIndex + 1
                      let nextRow = formRowRefs.current[nextRowIndex]

                      while (
                        nextRowIndex < Object.keys(vEventFieldDefs).length
                      ) {
                        if (nextRow) {
                          break
                        }

                        nextRowIndex += 1
                        nextRow = formRowRefs.current[nextRowIndex]
                      }

                      if (nextRow) {
                        const nextRowInput = nextRow.querySelector(
                          'input',
                        ) as HTMLInputElement | null

                        if (nextRowInput) {
                          nextRowInput.focus()
                        }
                      }
                    }
                  }}
                />
              )}
              {showErrorMessages && fieldsWithErrors.includes(fieldName) && (
                <ErrorMessage>
                  You must enter a value for this field.
                </ErrorMessage>
              )}
            </FormField>
          </FormRow>
        )
      })}
      <QRCodePreviewRow
        linkToShow="Example QR code"
        optional
        small
        customiseWithinCanvas
        formRowTitle={
          <Tooltip
            id="presentation-heading-tooltip"
            useIcon
            tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
          >
            Presentation
          </Tooltip>
        }
        qrData={qrData}
        onChangeQrData={onChangeQrData}
      />
      <FormRow>
        <FormLabel />
        <FormField>
          <Button
            type="submit"
            className={classNames(styles.submitButton, {
              [styles.softDisableButton]: fieldsWithErrors.length > 0,
            })}
            isDisabled={submitDisabled}
          >
            Create event
          </Button>
        </FormField>
      </FormRow>
    </>
  )
}

export default TrackCreateVEventFields
