import React, { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import numeral from 'numeraljs'

import { EnterpriseFeatureBlockerModal } from './feature-blocker-modals'
import Link from './link'
import Modal from './modal'
import ProgressBar from './progress-bar'
import { BoxedText } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { dynamicTextValues, supportEmail } from '../core/constants'
import {
  EmailForm,
  ExceedCharacterLimitModalType,
  ExceedsLinksLimitModalType,
  SoftWarning,
  SoftWarningModalType,
  WebLinkForm,
} from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'

interface CreateLinkWarningModalProps {
  setCreateLinkWarning: React.Dispatch<
    React.SetStateAction<
      | SoftWarningModalType
      | ExceedCharacterLimitModalType
      | ExceedsLinksLimitModalType
      | null
    >
  >
}

/** Form can still be submitted for softWarning modals */
interface SoftWarningModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: SoftWarningModalType
  buildLinks: (warningsToIgnore?: SoftWarning[]) => Promise<void>
}

interface ExceedsCharacterLimitModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: ExceedCharacterLimitModalType
}

interface ExceedsLinksLimitModalProps extends CreateLinkWarningModalProps {
  createLinkWarning: ExceedsLinksLimitModalType
}

const CreateLinkAnchorWarningModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader="Page anchor detected"
      noText="Cancel"
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-anchor-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        {hasMultipleLinks ? 'One of your links' : 'Your link'} contains a page
        anchor '#' which will be moved to the end of the link. This is required
        to make sure campaign parameters are captured and the link can auto
        scroll.{' '}
        <Link href="https://support.uplifter.ai/hc/en-us/articles/4469479289873-Can-I-use-anchor-tags-or-in-my-landing-page-URL-with-query-string-parameters-utms-">
          Find out more
        </Link>
        .
      </p>
    </Modal>
  )
}

const CreateLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Invalid landing page URL${hasMultipleLinks ? '(s)' : ''}`}
      noText={`Back and update landing page URL${
        hasMultipleLinks ? '(s)' : ''
      }`}
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        {hasMultipleLinks ? 'One of y' : 'Y'}our landing page URL
        {hasMultipleLinks && 's'} is either empty, invalid or missing https:// -
        users could be directed to an error page.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on editing the landing page URL{hasMultipleLinks && '(s)'}{' '}
        in the{' '}
        <BoxedText>{dynamicTextValues.trackViewPage[linkCopy]}</BoxedText> table
        before the campaign goes live.
      </p>
    </Modal>
  )
}

const CreateShortLinkNoUrlModal = ({
  createLinkWarning,
  setCreateLinkWarning,
  buildLinks,
}: SoftWarningModalProps) => {
  const { fullLinks, ignoreWarnings } = createLinkWarning

  const linkCopy = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const hasMultipleLinks = fullLinks.length > 1

  const [loading, setLoading] = useState(false)

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      modalHeader={`Short link${
        hasMultipleLinks ? '(s)' : ''
      } with no landing page URL`}
      noText={`Back and use full link${hasMultipleLinks ? '(s)' : ''}`}
      yesText={`Continue creating link${hasMultipleLinks ? 's' : ''}`}
      yesButtonLoading={loading}
      onYes={async () => {
        setLoading(true)

        await logAction({
          variables: {
            action: 'build-links-ignore-shortlink-no-url-warning',
            websiteSection: 'track-create',
            functionName: 'ignoreWarning',
            pagePath: '/track/create',
          },
        })

        await buildLinks(ignoreWarnings)

        setLoading(false)
      }}
    >
      <p>
        You are using {hasMultipleLinks ? 'short links' : 'a short link'} but
        have not set a landing page URL.
      </p>
      <p>
        You should only create {hasMultipleLinks ? 'these links' : 'this link'}{' '}
        if you plan on adding the landing page URL{hasMultipleLinks && '(s)'} in
        the <BoxedText>{dynamicTextValues.trackViewPage[linkCopy]}</BoxedText>{' '}
        table before the campaign goes live.
      </p>
    </Modal>
  )
}

const CreateLinkInvalidLengthModal = ({
  createLinkWarning,
  setCreateLinkWarning,
}: ExceedsCharacterLimitModalProps) => {
  const {
    type,
    fullLinks,
    characterLimit,
    charactersOverLimit,
  } = createLinkWarning

  const hasMultipleLinks = fullLinks.length > 1

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      isWarning
      modalHeader={`Link${hasMultipleLinks ? '(s) are' : ' is'} too long`}
      noText="Back and edit parameters"
    >
      <p>
        The resulting{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'}
        {hasMultipleLinks && 's'} will be too long to work in most web browsers
        or marketing platforms.
      </p>
      <p>
        Your{hasMultipleLinks ? ' longest' : ''}{' '}
        {type === 'invalid-landing-page-length' ? 'link' : 'query string'} is{' '}
        {charactersOverLimit} characters longer than the maximum recommended{' '}
        {characterLimit} characters.
      </p>
      {type === 'invalid-landing-page-length' ? (
        <p>
          Try <strong>changing the landing page</strong> to a shorter URL.
        </p>
      ) : (
        <>
          <p>
            Type <strong>fewer characters into the parameter values</strong> for
            a shorter string.
          </p>
          <p>
            For help email{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          </p>
        </>
      )}
    </Modal>
  )
}

const CreateLinkTooManyLinksModal = ({
  createLinkWarning,
  setCreateLinkWarning,
}: ExceedsLinksLimitModalProps) => {
  const { existingLinksCount, linksLimit } = createLinkWarning

  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const history = useHistory()

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-links-limit-modal',
        pagePath: window.location.pathname,
        websiteSection: 'track',
        functionName: 'requestCustomDomain',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  return (
    <Modal
      setIsOpen={() => setCreateLinkWarning(null)}
      isWarning
      headerColor="pink"
      modalHeader="Upgrade to monitor more links"
      noText="Delete links"
      onNo={() => history.push('/track/view-links')}
      yesText="Upgrade"
      onYes={() => history.push('/upgrade')}
    >
      <p>
        {existingLinksCount >= linksLimit
          ? 'You have reached'
          : 'Creating these links will exceed'}{' '}
        the limit for the amount of links you can monitor with your current
        plan. Upgrade plan, add more users, or delete old links to create more
        links.
      </p>
      <p style={{ margin: 0 }}>
        Current link count:{' '}
        <strong>{numeral(existingLinksCount).format('0,0')}</strong> of{' '}
        <strong>{numeral(linksLimit).format('0,0')}</strong>
      </p>
      <ProgressBar
        style={{ marginBottom: 0 }}
        percentage={(existingLinksCount / linksLimit) * 100}
        showPercent={false}
        thin
        superThin
        useUrgency
      />
    </Modal>
  )
}

interface TrackCreateWarningModalsProps<Form extends WebLinkForm | EmailForm>
  extends CreateLinkWarningModalProps {
  createLinkWarning:
    | SoftWarningModalType
    | ExceedCharacterLimitModalType
    | ExceedsLinksLimitModalType
    | null
  formValues: Form
  onSubmit: (formVals: Form, ignoreWarnings?: SoftWarning[]) => Promise<void>
}

const TrackCreateWarningModals = <Form extends WebLinkForm | EmailForm>({
  createLinkWarning,
  setCreateLinkWarning,
  formValues,
  onSubmit,
}: TrackCreateWarningModalsProps<Form>) => {
  const { isEnterprise, isMicrosoftMarketplace } = useSubscriptionLevel()

  if (!createLinkWarning) return null

  switch (createLinkWarning.type) {
    case 'has-anchor':
      return (
        <CreateLinkAnchorWarningModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('has-anchor')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )
    case 'no-url':
      return (
        <CreateLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('no-url')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )
    case 'no-url-shortlink':
      return (
        <CreateShortLinkNoUrlModal
          createLinkWarning={createLinkWarning}
          setCreateLinkWarning={setCreateLinkWarning}
          buildLinks={async (alreadyIgnoredWarnings) => {
            const warningsToIgnore = alreadyIgnoredWarnings || []

            warningsToIgnore.push('no-url-shortlink')

            await onSubmit(formValues, [...new Set(warningsToIgnore)])
          }}
        />
      )
    case 'invalid-landing-page-length':
    case 'invalid-query-length':
      return (
        <CreateLinkInvalidLengthModal
          createLinkWarning={createLinkWarning as ExceedCharacterLimitModalType}
          setCreateLinkWarning={setCreateLinkWarning}
        />
      )
    case 'exceeds-links-limit':
      return (
        <>
          {isEnterprise && !isMicrosoftMarketplace ? (
            <EnterpriseFeatureBlockerModal
              onHideModal={() => setCreateLinkWarning(null)}
              feature="link"
            />
          ) : (
            <CreateLinkTooManyLinksModal
              createLinkWarning={createLinkWarning}
              setCreateLinkWarning={setCreateLinkWarning}
            />
          )}
        </>
      )
    default:
      return null
  }
}

export default TrackCreateWarningModals
