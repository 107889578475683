import React, { useMemo } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Button from './button'
import { BetaLabel } from './counter'
import Link from './link'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText } from './typography'
import { linkOrCode } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import chrisSupport from '../assets/ChrisFormalCircleGrey.png'
import latestFeature from '../assets/latest-feature-vcard.jpg'
import {
  calendarBookingLink,
  dynamicTextValues,
  uplifterWebsite,
} from '../core/constants'
import styles from '../styles/welcome-rhs.module.scss'

/** This should be updated with new major releases */
const WelcomeLatestFeature = () => {
  const linkCopy = useReactiveVar(linkOrCode)

  return (
    <InnerBox className={classNames(styles.innerBox, styles.latestFeatures)}>
      <h2>
        <BetaLabel className={styles.newPill} title="New" />
        <span>Share contacts with QR codes</span>
      </h2>
      <p>
        Create contact links (vCards) on the{' '}
        <BoxedText>
          <Link href="/track/create-links">
            {dynamicTextValues.trackCreatePage[linkCopy]}
          </Link>
        </BoxedText>{' '}
        page.
        <br />
        <Link
          type="arrowForward"
          href="https://support.uplifter.ai/hc/en-us/articles/24586963673501-How-do-I-make-a-QR-code-to-share-a-contact-vCard"
        >
          Learn more
        </Link>
      </p>
      <img
        className={styles.featureImg}
        // Remove this when changing from vCard feature
        style={{ borderWidth: 0 }}
        src={latestFeature}
        alt="latest feature"
      />
      <Link type="arrowForward" href={`${uplifterWebsite}whats-new`}>
        More latest features
      </Link>
    </InnerBox>
  )
}

const WelcomeRightModule = () => {
  const { data: companyData } = useQuery(getCompanyDetails)

  const internalContactEmail = useMemo(() => {
    if (!companyData) return null

    return companyData.currentCompany.internalContactEmail
  }, [companyData])

  return (
    <OuterBox className={styles.outerBox}>
      <InnerBox className={classNames(styles.innerBox, styles.helpSection)}>
        <div className={styles.helpButtons}>
          <h2>Get help</h2>
          <Button
            className={styles.helpButton}
            onPress={() => window.open(calendarBookingLink, '_blank')}
          >
            Book free training
          </Button>
          <Button
            color="grey"
            className={styles.helpButton}
            onPress={() =>
              window.open('https://support.uplifter.ai/hc/en-us', '_blank')
            }
          >
            Visit help centre
          </Button>
        </div>
        <img
          src={chrisSupport}
          className={styles.helpImage}
          alt="Uplifter support"
        />
      </InnerBox>
      {internalContactEmail && (
        <InnerBox className={styles.innerBox}>
          <p style={{ margin: 0 }}>
            <Tooltip
              id="internal-support-tooltip"
              useIcon
              tooltipPosition="left"
              tooltipMessage="Your admin to request additional users, query your company's processes or setup."
            >
              Internal support:{' '}
              <Link href={`mailto:${internalContactEmail}`}>
                {internalContactEmail}
              </Link>
            </Tooltip>
          </p>
        </InnerBox>
      )}
      <WelcomeLatestFeature />
    </OuterBox>
  )
}

export default WelcomeRightModule
