import React, { useMemo, useState, useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { updateCachedOnboardingSectionsProgress } from '../api/graphql/custom-queries'
import { getUserInfo } from '../api/graphql/user-client'
import amazon from '../assets/amazon-voucher.png'
import quicklink from '../assets/svgs/quicklink.svg'
import Accordion from '../components/accordion'
import Button from '../components/button'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import ProgressBar from '../components/progress-bar'
import SiteWrapper from '../components/site-wrapper'
import TwoColumns, { Column } from '../components/two-columns'
import UploadExistingUtmSpreadsheetModal from '../components/upload-existing-utm-spreadsheet-modal'
import WelcomeChecklist from '../components/welcome-checklist'
import WelcomeFeedbackForm from '../components/welcome-feedback'
import WelcomeRightModule from '../components/welcome-rhs'
import {
  brandName,
  onboardingSectionData,
  uplifterWebsite,
  whitelabelDomain,
} from '../core/constants'
import { timeOfDayGreeting } from '../helpers/dates'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/welcome-page.module.scss'

export const WelcomeChecklistContainer = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [loading, setLoading] = useState(true)
  const [onboardingOpenState, setOnboardingOpenState] = useState(false)

  const {
    mergedOnboardingSections,
    setFullOnboardingSections,
  } = useOnboarding()

  const [completedSections, completedPercent] = useMemo(() => {
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const _completedSections = mergedOnboardingSections.filter((section) => {
        return (
          (section.sectionCompleted &&
            (section.sectionSkipped || !section.sectionSkippedBefore)) ||
          section.sectionSkipped
        )
      }).length

      const _completedPercent = Math.ceil(
        (_completedSections / mergedOnboardingSections.length) * 100,
      )

      if (_completedPercent < 100) {
        setOnboardingOpenState(true)
      }

      setLoading(false)

      return [_completedSections, _completedPercent]
    }
    return [null, 0]
  }, [mergedOnboardingSections])

  return (
    <Accordion
      id="onboardingChecklist"
      className={styles.accordionContainer}
      headerClassName={styles.accordionHeader}
      initialOpenState={[onboardingOpenState]}
      onToggle={(_, state) => setOnboardingOpenState(state === 'open')}
      accordionItems={[
        {
          key: 'onboardingChecklist',
          header: {
            icon: '📝',
            title: 'Onboarding checklist',
            rightContent: (
              <>
                {!loading &&
                  !onboardingOpenState &&
                  mergedOnboardingSections &&
                  completedSections !== null && (
                    <div className={styles.collapsedProgressBar}>
                      <ProgressBar
                        percentage={completedPercent}
                        showPercent={false}
                        thin
                        superThin
                        fixedColor="green"
                      />
                      <p>{completedPercent}%</p>
                    </div>
                  )}
              </>
            ),
          },
          expandedContent: (
            <>
              {onboardingOpenState &&
                mergedOnboardingSections &&
                completedSections !== null && (
                  <>
                    {completedPercent < 100 && (
                      <p style={{ margin: '8px 0' }}>
                        Your personal guide for making the most of {brandName}.
                        Let's get you set up and creating links!
                      </p>
                    )}
                    <p>
                      You've completed{' '}
                      {completedPercent === 100 ? (
                        'all'
                      ) : (
                        <>
                          <strong>{completedSections.toString()}</strong> of{' '}
                          <strong>
                            {mergedOnboardingSections.length.toString()}
                          </strong>
                        </>
                      )}{' '}
                      steps
                      {completedPercent === 100 ? ' - congrats!' : '.'}
                    </p>
                    <ProgressBar
                      percentage={completedPercent}
                      showPercent={false}
                      thin
                      fixedColor="green"
                    />
                  </>
                )}
              {mergedOnboardingSections && completedSections !== null && (
                <>
                  <WelcomeChecklist />
                  {completedPercent < 100 && (
                    <Button
                      variant="text"
                      color="grey"
                      className={styles.skipAll}
                      onPress={() => {
                        const copy: typeof mergedOnboardingSections = JSON.parse(
                          JSON.stringify(mergedOnboardingSections),
                        )

                        copy.forEach(async (_, index) => {
                          if (!copy[index].sectionSkipped) {
                            copy[index].sectionSkipped = true
                            copy[index].sectionSkippedBefore = true

                            await logAction({
                              variables: {
                                ...onboardingSectionData[
                                  copy[index].onboardingSectionID
                                ].skipAction,
                                pagePath: '/welcome',
                              },
                            })
                          }
                        })

                        setFullOnboardingSections((curr) => {
                          const newFull: typeof curr = JSON.parse(
                            JSON.stringify(curr),
                          )

                          newFull.user.forEach((_, sectionIndex) => {
                            newFull.user[sectionIndex].sectionSkipped = true
                            newFull.user[
                              sectionIndex
                            ].sectionSkippedBefore = true
                          })

                          newFull.account.forEach((_, sectionIndex) => {
                            newFull.account[sectionIndex].sectionSkipped = true
                            newFull.account[
                              sectionIndex
                            ].sectionSkippedBefore = true
                          })

                          // Update local state
                          updateCachedOnboardingSectionsProgress(
                            workspaceID,
                            'user',
                            newFull.user,
                          )
                          updateCachedOnboardingSectionsProgress(
                            workspaceID,
                            'account',
                            newFull.account,
                          )

                          return newFull
                        })

                        setOnboardingOpenState(false)
                      }}
                    >
                      <em>I'm an expert - don't show me these steps</em>
                    </Button>
                  )}
                </>
              )}
            </>
          ),
        },
      ]}
    />
  )
}

export const QuickAccess = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  return (
    <Accordion
      id="quickAccess"
      className={styles.accordionContainer}
      headerClassName={styles.accordionHeader}
      initialOpenState={[true]}
      accordionItems={[
        {
          key: 'quickAccess',
          header: {
            icon: (
              <div className={styles.quickAccessIcon}>
                <img src={quicklink} alt="Quick access" />
              </div>
            ),
            title: 'Quick access',
          },
          expandedContent: (
            <>
              {workspaceID && (
                <>
                  <Link
                    type="arrowForward"
                    className={styles.quickAccessButton}
                    href="/track/create-links"
                    newTab={false}
                  >
                    Create {linkCopy}s
                  </Link>
                  <Link
                    type="arrowForward"
                    className={styles.quickAccessButton}
                    href="/track/create-links"
                    newTab={false}
                  >
                    Create QR codes
                  </Link>
                  <Link
                    type="arrowForward"
                    className={styles.quickAccessButton}
                    href="/track/view-links"
                    newTab={false}
                  >
                    View all {linkCopy}s
                  </Link>
                  <Link
                    type="arrowForward"
                    className={styles.quickAccessButton}
                    href="/report/performance"
                    newTab={false}
                  >
                    Create a report
                  </Link>
                </>
              )}
            </>
          ),
        },
      ]}
    />
  )
}

export const ReferralVoucher = () => {
  return (
    <Accordion
      id="referrals"
      className={styles.accordionContainer}
      headerClassName={styles.accordionHeader}
      accordionItems={[
        {
          key: 'referrals',
          header: {
            icon: '💰',
            title: 'Earn a £100 voucher',
          },
          expandedContent: (
            <>
              <p>Recommend us and earn up to £100 of Amazon vouchers*</p>
              <div className={styles.voucherContainer}>
                <img
                  src={amazon}
                  alt="Amazon voucher"
                  className={styles.amazon}
                />
                <div className={styles.voucherText}>
                  <Button
                    style={{ display: 'block', marginBottom: 8 }}
                    onPress={() =>
                      window.open(`${uplifterWebsite}referral/`, '_blank')
                    }
                  >
                    Recommend us
                  </Button>
                  <span>*terms and conditions apply</span>
                </div>
              </div>
            </>
          ),
        },
      ]}
    />
  )
}

export const WelcomeFeedback = () => {
  return (
    <Accordion
      id="feedback"
      className={styles.accordionContainer}
      headerClassName={styles.accordionHeader}
      accordionItems={[
        {
          key: 'feedback',
          header: {
            icon: '💬',
            title: 'Give feedback',
          },
          expandedContent: <WelcomeFeedbackForm />,
        },
      ]}
    />
  )
}

export default function WelcomePage() {
  const { data: userData } = useQuery(getUserInfo)

  const [showUtmSheetUploadModal, setShowUtmSheetUploadModal] = useState(false)

  const firstName = useMemo(() => {
    return userData ? userData.currentUser.firstName : ''
  }, [userData])

  const [welcomeName, setWelcomeName] = useState(
    `${timeOfDayGreeting(new Date(Date.now()))}${
      firstName ? ` ${firstName} 👋` : ''
    }`,
  )

  // Show utm spreadsheet modal if first session after company creation
  // Set in useAuthenticate hook
  useEffect(() => {
    if (window.sessionStorage.getItem('showUtmSheetUploaderModal') === 'true') {
      setShowUtmSheetUploadModal(true)
      window.sessionStorage.removeItem('showUtmSheetUploaderModal')
    }
  }, [])

  useEffect(() => {
    if (firstName) {
      setWelcomeName(
        `${timeOfDayGreeting(new Date(Date.now()))} ${firstName} 👋`,
      )
    }
  }, [firstName])

  return (
    <>
      <SiteWrapper>
        <Layout>
          <Intro title={welcomeName}>
            <p>Welcome to your personalised dashboard.</p>
          </Intro>
          <TwoColumns>
            <Column main>
              <div className={styles.container}>
                <WelcomeChecklistContainer />
                <QuickAccess />
                <WelcomeFeedback />
                {whitelabelDomain === 'uplifter' && <ReferralVoucher />}
              </div>
            </Column>
            <Column side fixed className={styles.rightColumn}>
              <WelcomeRightModule />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      {showUtmSheetUploadModal && (
        <UploadExistingUtmSpreadsheetModal
          onHideModal={setShowUtmSheetUploadModal}
        />
      )}
    </>
  )
}
