import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { usePDF } from 'react-to-pdf'
import moment from 'moment'
import classNames from 'classnames'

import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import {
  getCampaignLinkDashboardGraphClickData,
  getCampaignLinkDashboardGraphData,
  getCampaignLinkDashboardMeta,
  getCampaignLinkDashboardMetricClickData,
  getCampaignLinkDashboardMetricData,
  getCampaignLinkDashboardTable,
  listSavedLinkPerformanceReportsGQL,
  removeSavedLinkPerformanceReport,
  saveLinkPerformanceReport,
  updateLinkPerformanceUserPrefs,
  updateSavedLinkPerformanceReport,
} from '../api/graphql/report-client'
import { sendFeatureRequest } from '../api/graphql/support-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { getMinCodesQuick } from '../api/graphql/track-view-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import { getCampaignLinkRawData } from '../api/REST/report-client'
import Stars from '../assets/star-cluster-yellow.svg'
import warning from '../assets/icon-check-orange.svg'
import { NavigateButton } from '../components/button'
import ConnectAnalyticsBlockerModal from '../components/connect-analytics-blocker'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import PerformanceReportLinksTable from '../components/performance-report-links-table'
import MetricDataBanner from '../components/metric-data-banner'
import PerformanceReportGraph from '../components/performance-report-graph'
import PerformanceReportManageMetricsModal from '../components/performance-report-manage-metrics'
import ReportControls from '../components/report-controls'
import ReportSavedTabs, {
  ManageSavedReportsModal,
  SaveEditReportModal,
} from '../components/report-save'
import ReportSummary, { FilterListItem } from '../components/report-summary'
import SelectBox, { SelectBoxChecklist } from '../components/select-box'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import {
  matchTypesDropDown,
  siteContainerWidth,
  dateFormatShort,
  messages,
} from '../core/constants'
import { getItemByKeyValue, sortData, getUrlQuery } from '../helpers'
import {
  getDateRangeLabel,
  setGranularityByDateRange,
  yesterday,
} from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import useResize from '../hooks/useResize'
import styles from '../styles/performance-report-page.module.scss'
import {
  Granularity,
  PerformanceReportDataConfig,
  PerformanceReportRefetchOptions,
  ReportFilterItem,
  SavedPerformanceReportDataConfig,
} from '../types/report-module'
import { AvailableDimension, AvailableMetric } from '../__gql-types__/graphql'

interface EditableGraphTitleProps {
  loading: boolean
  breakdownDimension: string
  updateStackDimension: (newDim: string) => void
  startDate?: string
  endDate?: string
  availableDimensions: AvailableDimension[]
  successMetricDisplayName: string
}

export function EditableGraphTitle({
  loading,
  breakdownDimension,
  updateStackDimension,
  startDate,
  endDate,
  availableDimensions,
  successMetricDisplayName,
}: EditableGraphTitleProps) {
  const orderedDimensions: {
    optionID: string
    optionName: string
    optionValue: string
  }[] = useMemo(() => {
    return availableDimensions
      ? [
          {
            optionID: '',
            optionName: 'No breakdown',
            optionValue: '',
          },
          ...sortData(
            availableDimensions.map((item) => {
              const { dimensionName, dimensionParameterID } = item
              return {
                optionID: dimensionParameterID,
                optionName: dimensionName,
                optionValue: dimensionName,
              }
            }),
            'optionName',
            true,
            null,
            true,
          ),
        ]
      : []
  }, [availableDimensions])

  if (loading || successMetricDisplayName === '') {
    return null
  }

  return (
    <h1 className={styles.graphTitle}>
      <span>
        {successMetricDisplayName} {getDateRangeLabel(startDate, endDate)}
        {breakdownDimension === '' ? '' : ` split by `}
      </span>
      <span
        className={classNames(styles.titleSelectWrapper, {
          [styles.hideBreakdown]: breakdownDimension === '',
        })}
        data-html2canvas-ignore
      >
        <SelectBox
          id="breakdownDimension"
          className={styles.successMetricSelector}
          labelKey="optionName"
          valueKey="optionValue"
          placeholder="drilldown"
          value={
            orderedDimensions.find(
              (dim) =>
                breakdownDimension &&
                (dim.optionValue === breakdownDimension ||
                  dim.optionID === breakdownDimension),
            ) || null
          }
          options={orderedDimensions}
          onChange={(newValue) => {
            if (!newValue) return

            const { optionValue } = newValue

            const newDim = matchTypesDropDown.find(
              (matchItem) => matchItem.value === optionValue,
            )
              ? optionValue
              : orderedDimensions.find((dim) => dim.optionValue === optionValue)
                  ?.optionID || ''

            updateStackDimension(newDim)
          }}
        />
      </span>
    </h1>
  )
}

export const initialDataConfig: PerformanceReportDataConfig = {
  startDate: 'l90d',
  endDate: '',
  selectedMetric: 'sessions',
  granularity: 'weekly',
  matchType: matchTypesDropDown[0].value,
  applyFilters: null,
  stackDimension: null,
}

const PerformanceReportPage = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const screenWidth = useResize()

  const tableRef = useRef<HTMLTableElement>(null)

  const { data: dataSourceData } = useQuery(getAccountDataSource)
  const {
    data: campaignLinkDashboardMetaData,
    loading: loadingMetaData,
    error: errorLoadingMetaData,
  } = useQuery(getCampaignLinkDashboardMeta)
  const { data: campaignCodeGeneratorData } = useQuery(getCampaignCodeGenerator)
  const {
    data: savedReportsData,
    loading: loadingSavedReports,
    error: errorFetchingSavedReports,
  } = useQuery(listSavedLinkPerformanceReportsGQL, {
    fetchPolicy: 'cache-first',
  })

  const [getUrlLinkDetails] = useLazyQuery(getMinCodesQuick, {
    fetchPolicy: 'network-only',
  })
  const [
    getMetricClickData,
    {
      data: metricClickData,
      loading: metricClickDataLoading,
      error: metricClickDataError,
    },
  ] = useLazyQuery(getCampaignLinkDashboardMetricClickData)
  const [
    getMetricData,
    { data: metricData, loading: metricDataLoading, error: metricDataError },
  ] = useLazyQuery(getCampaignLinkDashboardMetricData)
  const [
    getGraphClickData,
    {
      data: fullGraphClickData,
      loading: loadingGraphClickData,
      error: graphClickDataError,
    },
  ] = useLazyQuery(getCampaignLinkDashboardGraphClickData)
  const [
    getGraphData,
    { data: fullGraphData, loading: loadingGraphData, error: graphDataError },
  ] = useLazyQuery(getCampaignLinkDashboardGraphData)
  const [
    getTableData,
    {
      data: campaignLinkDashboardTableRowsData,
      loading: tableDataIsLoading,
      error: tableDataError,
    },
  ] = useLazyQuery(getCampaignLinkDashboardTable)

  const [saveReport, { loading: savingNewReport }] = useMutation(
    saveLinkPerformanceReport,
    {
      refetchQueries: [listSavedLinkPerformanceReportsGQL],
    },
  )
  const [updateSavedReport, { loading: updatingReport }] = useMutation(
    updateSavedLinkPerformanceReport,
    {
      refetchQueries: [listSavedLinkPerformanceReportsGQL],
    },
  )
  const [updateReportPrefs] = useMutation(updateLinkPerformanceUserPrefs, {
    refetchQueries: [listSavedLinkPerformanceReportsGQL],
  })
  const [removeSavedReport] = useMutation(removeSavedLinkPerformanceReport, {
    refetchQueries: [listSavedLinkPerformanceReportsGQL],
  })
  const [requestFeature] = useMutation(sendFeatureRequest)

  // Used to check if a specific report filter is being viewed via the URL links from Track>View use this
  const [urlParamsFilter, setUrlParamsFilter] = useState<{
    checked: boolean
    filter: {
      id: string
      link: string
    } | null
    error: boolean
  }>({ checked: false, filter: null, error: false })
  const [resetInitialDate, setResetInitialDate] = useState(true)
  const [initialFetchComplete, setInitialFetchComplete] = useState(false)
  const [dataConfig, setDataConfig] = useState<PerformanceReportDataConfig>(
    initialDataConfig,
  )
  const [hiddenTableColumns, setHiddenTableColumns] = useState<string[]>([])

  const [width, setWidth] = useState(0)
  const [usePDFDims, setUsePDFDims] = useState(false)

  const pdfOrientation = useMemo(() => {
    if (!tableRef.current?.offsetWidth) return 'landscape'

    return width > 1200 ||
      (tableRef.current?.offsetWidth || 0) - (width || 0) > 100
      ? 'landscape'
      : 'portrait'
  }, [hiddenTableColumns, tableRef, width])

  const { toPDF, targetRef: pdfTargetRef } = usePDF({
    filename: `link-performance-report-${moment().format('YYYY-MM-DD')}.pdf`,
    page: {
      margin: 5,
      orientation: pdfOrientation,
    },
  })

  const [linksTablePerformanceOrder, setLinksTablePerformanceOrder] = useState<
    'topToBottom' | 'bottomToTop'
  >('topToBottom')

  // Weird. Would like to get rid of this
  const graphWidth = useMemo(() => {
    let res: number

    if (width) {
      res = width
    } else if (screenWidth > 1260) {
      res = 1108
    } else if (screenWidth < siteContainerWidth) {
      res = screenWidth - 40
    } else if (screenWidth <= siteContainerWidth + 150) {
      res = screenWidth - 150
    } else {
      res = 960
    }

    return res - 34 - 40
  }, [width, screenWidth])

  // Used for positioning/width of elements, specifically the graph
  const { isMobile, graphOffset } = useMemo(() => {
    const _isMobile = screenWidth <= 768

    let offset =
      dataConfig.granularity === 'monthly' ||
      dataConfig.granularity === 'quarterly'
        ? 0
        : 40

    if (_isMobile && offset > 0) {
      offset = 20
    }

    return {
      isMobile: _isMobile,
      graphOffset: offset,
    }
  }, [screenWidth, dataConfig])

  // Get available configuration options from dashboardMeta
  const {
    availableDimensions,
    availableMetrics,
    availableClickMetrics,
    onloadDefaultOptions,
    allAvailableMetrics,
  } = useMemo(() => {
    if (!campaignLinkDashboardMetaData) {
      return {
        availableDimensions: [],
        availableClickMetrics: [] as AvailableMetric[],
        availableMetrics: [] as AvailableMetric[],
        onloadDefaultOptions: [],
        allAvailableMetrics: [] as AvailableMetric[],
      }
    }

    const {
      availableDimensions: _availableDimensions,
      availableMetrics: _availableMetrics,
      availableClickMetrics: _availableClickMetrics,
      ...rest
    } = campaignLinkDashboardMetaData.campaignLinkDashboardMeta

    // Add required state to filters. Allows "(not set)" value to be added to dropdowns
    const availableDimensionsWithRequiredState =
      _availableDimensions?.map((dim) => {
        if (!campaignCodeGeneratorData) return dim

        const foundDimension = campaignCodeGeneratorData.campaignCodeGenerator.paramDefs.find(
          (param) => param.fieldID === dim.dimensionParameterID,
        )

        return {
          ...dim,
          required: foundDimension ? foundDimension.required : undefined,
        }
      }) || []

    const filteredAvailableClickMetrics = (_availableClickMetrics?.filter(
      (item) =>
        item.metricID !== 'shortLinkReturningClicksPercentage' &&
        item.metricID !== 'shortLinkReturningClicks' &&
        item.metricID !== 'shortLinkInitialClicks',
    ) || []) as AvailableMetric[]

    return {
      ...rest,
      availableDimensions: availableDimensionsWithRequiredState,
      availableMetrics: (_availableMetrics || []) as AvailableMetric[],
      // Some shortlink metrics should be hidden from the data
      availableClickMetrics: filteredAvailableClickMetrics,
      allAvailableMetrics: [
        ...(_availableMetrics || []),
        ...(filteredAvailableClickMetrics || []),
      ].filter((i) => i.setupComplete) as AvailableMetric[],
    }
  }, [campaignLinkDashboardMetaData, campaignCodeGeneratorData])

  // List of primary breakdown values
  const availableMatchTypes = useMemo(() => {
    if (!availableDimensions || availableDimensions.length === 0) {
      return [...matchTypesDropDown]
    }

    return [
      ...matchTypesDropDown,
      ...sortData(
        availableDimensions.map((item) => {
          const { dimensionParameterID, dimensionName, helpText } = item
          return {
            name: dimensionName,
            shortName: dimensionName,
            value: dimensionParameterID,
            tooltip: helpText,
            id: dimensionParameterID,
          }
        }),
        'name',
        true,
        null,
        true,
      ),
    ] as MatchTypeProps[]
  }, [availableDimensions])

  // Get full details for selected metric
  const {
    successMetricDisplayName,
    successMetricTooltip,
    isUplifterClickMetric,
    units,
    increasePositive,
  } = useMemo(() => {
    const result: AvailableMetric | -1 = getItemByKeyValue(
      allAvailableMetrics,
      'metricID',
      dataConfig.selectedMetric,
    )

    return {
      increasePositive: result !== -1 ? result.increasePositive : true,
      successMetricDisplayName: result !== -1 ? result.displayName : '',
      successMetricTooltip: result !== -1 ? result.description : '',
      isUplifterClickMetric:
        result !== -1 ? result.isUplifterClickMetric : false,
      units: result !== -1 ? result.units : 'int',
    }
  }, [dataConfig.selectedMetric, allAvailableMetrics])

  // Used for metric banners/callouts
  const dashboardMetricData = useMemo(() => {
    if (
      metricClickData &&
      (isUplifterClickMetric || dataConfig.selectedMetric === 'shortLinkClicks')
    ) {
      return metricClickData.campaignLinkDashboardMetricDataClickData
    }

    if (metricData && !isUplifterClickMetric) {
      return metricData.campaignLinkDashboardMetricData
    }

    return null
  }, [
    metricData,
    metricClickData,
    isUplifterClickMetric,
    dataConfig.selectedMetric,
  ])

  // Graph data
  const {
    graphDataToShow,
    /**
     * The data window of the graph should not have empty columns at the start or end
     * graphDataToShow strips out zero-values from start and end of the fetched data
     */
    condensedGraphData,
    dateTotalRows: tableTotals,
    graphType,
  } = useMemo(() => {
    if (
      fullGraphClickData &&
      (isUplifterClickMetric || dataConfig.selectedMetric === 'shortLinkClicks')
    ) {
      const {
        dateRange,
        graphData,
        dateTotalRows,
        graphType: _graphType,
      } = fullGraphClickData.campaignLinkDashboardGraphClickData

      const _graphDataToShow = {
        graphDateRange: dateRange,
        graphData,
      }

      return {
        dateTotalRows,
        graphType: _graphType,
        graphDataToShow: _graphDataToShow,
        // ! We are no longer truncating data
        // // Strip out zero-values from start and end of the fetched data
        // // ! But ONLY for one-click link reports from Track>View
        // condensedGraphData: resetInitialDate
        //   ? stripZeroValuesFromGraphData(_graphDataToShow)
        //   : _graphDataToShow,
        condensedGraphData: _graphDataToShow,
      }
    }

    if (fullGraphData && !isUplifterClickMetric) {
      const {
        dateRange,
        graphData,
        dateTotalRows,
        graphType: _graphType,
      } = fullGraphData.campaignLinkDashboardGraph

      const _graphDataToShow = {
        graphDateRange: dateRange,
        graphData,
      }

      return {
        dateTotalRows,
        graphType: _graphType,
        graphDataToShow: _graphDataToShow,
        // ! We are no longer truncating data
        // // Strip out zero-values from start and end of the fetched data
        // // ! But ONLY for one-click link reports from Track>View
        // condensedGraphData: resetInitialDate
        //   ? stripZeroValuesFromGraphData(_graphDataToShow)
        //   : _graphDataToShow,
        condensedGraphData: _graphDataToShow,
      }
    }

    return {
      graphDataToShow: null,
      condensedGraphData: null,
      dateTotalRows: null,
      graphType: 'bar',
    }
  }, [
    fullGraphData,
    fullGraphClickData,
    isUplifterClickMetric,
    dataConfig.selectedMetric,
    // resetInitialDate,
  ])

  // Set date range shown in report, after stripping out zero-data
  const dataBasedDateRange = useMemo(() => {
    if (tableDataIsLoading || !dashboardMetricData) return ''

    let start: string
    let end: string

    if (condensedGraphData && condensedGraphData.graphDateRange.length > 0) {
      start = moment(condensedGraphData.graphDateRange[0], 'YYYY-MM-DD').format(
        dateFormatShort,
      )
      end = moment(
        condensedGraphData.graphDateRange[
          condensedGraphData.graphDateRange.length - 1
        ],
        'YYYY-MM-DD',
      ).format(dateFormatShort)
    } else {
      const { queryEndDate: eDate, queryStartDate: sDate } = dashboardMetricData
      const queryStartDate = sDate
      const queryEndDate = eDate

      start = moment(queryStartDate, 'YYYY-MM-DD').format(dateFormatShort)
      end = moment(queryEndDate, 'YYYY-MM-DD').format(dateFormatShort)
    }

    return `${start} - ${end}`
  }, [
    dashboardMetricData,
    isUplifterClickMetric,
    tableDataIsLoading,
    condensedGraphData,
  ])

  // Used in the graph title
  const breakdownDimensionName = useMemo(() => {
    return (
      availableDimensions.find(
        (dim) =>
          dim.dimensionParameterID === dataConfig.stackDimension ||
          dim.dimensionName === dataConfig.stackDimension,
      )?.dimensionName || ''
    )
  }, [dataConfig.stackDimension, availableDimensions])

  // Table data
  const campaignLinkDashboardTableRows = useMemo(() => {
    if (!campaignLinkDashboardTableRowsData) return null

    const {
      campaignLinks,
      tableData,
    } = campaignLinkDashboardTableRowsData.campaignLinkDashboardTable

    // Some shortlink metrics should be hidden from the table
    const filteredTableData = tableData.filter((item) => {
      return (
        item.metricID !== 'shortLinkReturningClicksPercentage' &&
        item.metricID !== 'shortLinkReturningClicks' &&
        item.metricID !== 'shortLinkInitialClicks'
      )
    })

    return { campaignLinks, tableData: filteredTableData }
  }, [campaignLinkDashboardTableRowsData])

  // Special case for duration-based metrics in data callouts/graph
  const showDurationUnits = useMemo(() => {
    return (
      units === 'float' &&
      dataConfig.selectedMetric.toLowerCase().indexOf('duration') > -1
    )
  }, [dataConfig.selectedMetric, units])

  /** Short link clicks had a data outage in July 2024 - show this if metric is selected and date range is relevant */
  const showDataWarningMessage = useMemo(() => {
    if (dataConfig.selectedMetric !== 'shortLinkClicks') {
      return false
    }

    const [start, end] = dataBasedDateRange.split(' - ')

    // 17/6/2024-7/7/2024 or 9/11/2024-4/2/2025
    const dataStart = moment(start, 'Do/MMM/YYYY')
    const dataEnd = moment(end, 'Do/MMM/YYYY')
    const error1Start = moment('17/06/2024', 'DD/MM/YYYY')
    const error1End = moment('02/07/2024', 'DD/MM/YYYY')
    const error2Start = moment('09/11/2024', 'DD/MM/YYYY')
    const error2End = moment('04/02/2025', 'DD/MM/YYYY')

    return (
      error1Start.isBetween(dataStart, dataEnd, 'date', '[]') ||
      error1End.isBetween(dataStart, dataEnd, 'date', '[]') ||
      dataStart.isBetween(error1Start, error1End, 'date', '[]') ||
      dataEnd.isBetween(error1Start, error1End, 'date', '[]') ||
      error2Start.isBetween(dataStart, dataEnd, 'date', '[]') ||
      error2End.isBetween(dataStart, dataEnd, 'date', '[]') ||
      dataStart.isBetween(error2Start, error2End, 'date', '[]') ||
      dataEnd.isBetween(error2Start, error2End, 'date', '[]')
    )
  }, [dataBasedDateRange, dataConfig.selectedMetric])

  // Check URL for a specific link ID/parameter to filter on
  useEffect(() => {
    if (!workspaceID) return

    const urlParams = getUrlQuery()

    /**
     * Overrides swapping success metric to shortLinks
     * If no metric data found on Track>View page
     */
    const hideShortLinkMetric = urlParams.get('hideShortLinkMetric')

    const urlLinkID = urlParams.get('linkID')
    const parameterID = urlParams.get('parameterID')
    const parameterName = urlParams.get('parameterName')
    const parameterValue = urlParams.get('parameterValue')

    // No need to add filters - allow data to load
    if (
      !urlParams ||
      (!urlLinkID && (!parameterID || !parameterValue || !parameterName))
    ) {
      setUrlParamsFilter({
        checked: true,
        filter: null,
        error: false,
      })
      return
    }

    const createUrlLinkFilter = async (linkID: string) => {
      try {
        const { data } = await getUrlLinkDetails({
          variables: { codeIDList: [linkID] },
        })

        if (!data) {
          throw new Error('Failed to fetch link data')
        }

        // codeDef is an array of the filters that must be applied
        const {
          codeDef,
          minGenDef,
          createdTime,
          shortLink,
          fullLink,
        } = data.track.minCodesQuick

        const linkStartDate = moment
          .unix(parseInt(createdTime[0], 10))
          .format('YYYY-MM-DD')

        const newDataConfig: Partial<PerformanceReportDataConfig> = {
          startDate: linkStartDate,
          endDate: moment(yesterday).format('YYYY-MM-DD'),
          granularity: setGranularityByDateRange(
            new Date(linkStartDate),
            yesterday,
          ),
        }

        const newApplyFilters: ReportFilterItem[] = []

        // Add link param values to filter
        codeDef[0].forEach((paramValue, index) => {
          if (paramValue === '') return

          const { paramName, paramID } = minGenDef[index]

          newApplyFilters.push({
            dimensionName: paramName,
            dimensionParameterID: paramID,
            dimensionOptions: [paramValue],
          })
        })

        newDataConfig.applyFilters = newApplyFilters

        // Show short link click data instead of sessions data
        if (!hideShortLinkMetric && shortLink && shortLink[0]) {
          newDataConfig.selectedMetric = 'shortLinkClicks'
        }

        setDataConfig((curr) => ({
          ...curr,
          ...newDataConfig,
        }))

        setUrlParamsFilter({
          checked: true,
          filter: {
            id: linkID,
            link: !shortLink ? fullLink[0] : shortLink[0] || fullLink[0],
          },
          error: false,
        })
      } catch {
        setUrlParamsFilter({
          checked: false,
          error: true,
          filter: null,
        })
      }
    }

    if (urlLinkID) {
      // Build filter based on URL
      createUrlLinkFilter(urlLinkID)
    } else if (parameterID && parameterName && parameterValue) {
      // Filter on parameter value
      setDataConfig((curr) => ({
        ...curr,
        applyFilters: [
          {
            dimensionName: parameterName,
            dimensionParameterID: parameterID,
            dimensionOptions: [parameterValue],
          },
        ],
      }))

      setUrlParamsFilter((curr) => ({ ...curr, checked: true }))
    }
  }, [workspaceID])

  // Initial report data fetch
  useEffect(() => {
    const initialFetch = async () => {
      if (
        !workspaceID ||
        !dataSourceData ||
        !dataSourceData.currentAccount.dataSource
      ) {
        return
      }

      const {
        startDate,
        endDate,
        matchType: _matchType,
        granularity,
        selectedMetric: _selectedMetric,
        stackDimension,
        applyFilters,
      } = dataConfig

      /** Set selected metric according to available metric if only 1 is available */
      let dataSourceDefaultSelectedMetric =
        allAvailableMetrics.length > 1 ? _selectedMetric : ''

      // Adobe uses 'entries' instead of 'sessions' (default for this page) - need to use correct metric based on Data source
      if (!!dataSource && dataSource.connectionSource === 'adobe') {
        dataSourceDefaultSelectedMetric = 'entries'
      }

      const campaignCodeID = urlParamsFilter.filter
        ? urlParamsFilter.filter.id
        : undefined
      let matchType = _matchType || matchTypesDropDown[0].value
      let selectedMetric =
        dataSourceDefaultSelectedMetric ||
        allAvailableMetrics[0]?.metricID ||
        ''
      const filterList = applyFilters
        ? {
            dimensionFilters: applyFilters,
          }
        : undefined

      if (onloadDefaultOptions && onloadDefaultOptions.length > 0) {
        matchType = onloadDefaultOptions[0].value || matchTypesDropDown[0].value
      }

      const urlFilterValue = new URLSearchParams(window.location.search).get(
        'compare',
      )

      if (urlFilterValue) {
        matchType = urlFilterValue

        if (matchType === 'shortuplcode') {
          selectedMetric = 'shortLinkClicks'
        }
      }

      setDataConfig((curr) => ({
        ...curr,
        matchType,
        selectedMetric,
      }))

      const varsToUse = {
        startDate,
        endDate,
        campaignCodeID,
        matchType,
        selectedMetric,
        granularity,
        filterList,
      }

      if (isUplifterClickMetric || selectedMetric === 'shortLinkClicks') {
        getMetricClickData({
          variables: varsToUse,
        })
        getGraphClickData({
          variables: {
            ...varsToUse,
            stackDimension,
          },
        })
      } else {
        getMetricData({
          variables: varsToUse,
        })
        getGraphData({
          variables: {
            ...varsToUse,
            stackDimension,
          },
        })
      }

      getTableData({
        variables: varsToUse,
      })

      setInitialFetchComplete(true)
    }

    // Don't fetch data until URL has been checked for filters to apply
    if (
      campaignLinkDashboardMetaData &&
      urlParamsFilter.checked &&
      !initialFetchComplete
    ) {
      initialFetch()
    }
  }, [
    workspaceID,
    dataSourceData,
    dataConfig,
    campaignLinkDashboardMetaData,
    urlParamsFilter.checked,
    initialFetchComplete,
  ])

  const refetchData = useCallback(
    async (
      data: PerformanceReportDataConfig & { campaignCodeID?: string },
      {
        getClickData,
        refetchMetrics,
        refetchGraph,
        refetchTable,
      }: PerformanceReportRefetchOptions = {
        refetchMetrics: true,
        refetchGraph: true,
        refetchTable: true,
      },
    ) => {
      const {
        startDate,
        endDate,
        matchType: _matchType,
        granularity,
        selectedMetric: _selectedMetric,
        stackDimension,
        applyFilters,
      } = data

      const matchType = _matchType || matchTypesDropDown[0].value
      const selectedMetric =
        _selectedMetric || allAvailableMetrics[0]?.metricID || ''
      const filterList =
        applyFilters && applyFilters.length > 0
          ? {
              dimensionFilters: applyFilters,
            }
          : undefined

      const varsToUse = {
        startDate,
        endDate,
        matchType,
        selectedMetric,
        stackDimension,
        granularity,
        filterList,
      }

      if (refetchMetrics !== false) {
        if (
          getClickData !== false &&
          (isUplifterClickMetric || selectedMetric === 'shortLinkClicks')
        ) {
          getMetricClickData({
            variables: varsToUse,
          })
        } else {
          getMetricData({
            variables: varsToUse,
          })
        }
      }

      if (refetchGraph !== false) {
        if (
          getClickData !== false &&
          (isUplifterClickMetric || selectedMetric === 'shortLinkClicks')
        ) {
          getGraphClickData({
            variables: varsToUse,
          })
        } else {
          getGraphData({
            variables: varsToUse,
          })
        }
      }

      if (refetchTable !== false) {
        getTableData({
          variables: varsToUse,
        })
      }
    },
    [isUplifterClickMetric],
  )

  const reportFilters = useMemo(() => {
    const filterList: FilterListItem[] = []

    if (urlParamsFilter.filter) {
      filterList.push({
        dimensionName: 'Link',
        dimensionID: 'link',
        optionName: urlParamsFilter.filter.link,
        optionID: urlParamsFilter.filter.id,
        onRemove: () => {
          setUrlParamsFilter((curr) => ({ ...curr, filter: null }))
          setResetInitialDate(false)

          refetchData({ ...dataConfig, campaignCodeID: undefined })
        },
      })
    }

    if (dataConfig.applyFilters) {
      // Add other filter options
      dataConfig.applyFilters.forEach(
        ({ dimensionName, dimensionParameterID, dimensionOptions }) => {
          if (dimensionOptions && dimensionOptions.length > 0) {
            dimensionOptions.forEach((option) => {
              filterList.push({
                dimensionName,
                dimensionID: dimensionParameterID,
                optionName: option,
                optionID: option,
              })
            })
          }
        },
      )
    }

    return filterList
  }, [dataConfig, urlParamsFilter, refetchData])

  const [
    currentReport,
    setCurrentReport,
  ] = useState<SavedPerformanceReportDataConfig | null>(null)
  const [
    reportToEdit,
    setReportToEdit,
  ] = useState<SavedPerformanceReportDataConfig | null>(null)

  const [showEditMetricsModal, setShowEditMetricsModal] = useState(false)
  const [showSaveReportModal, setShowSaveReportModal] = useState(false)
  const [showManageReportsModal, setShowManageReportsModal] = useState(false)
  const [exporting, setExporting] = useState(false)

  // Get saved reports
  const { savedReports, userPreferredOrder } = useMemo(() => {
    if (!savedReportsData) {
      return {
        savedReports: [] as SavedPerformanceReportDataConfig[],
        userPreferredOrder: [] as string[],
      }
    }

    return {
      ...savedReportsData.report.listSavedLinkPerformanceReports,
      savedReports: savedReportsData.report.listSavedLinkPerformanceReports.savedReports.map(
        (report) => {
          return {
            ...report,
            applyFilters:
              report.applyFilters?.filter(
                (aF) => aF.dimensionOptions.length > 0,
              ) || [],
          } as SavedPerformanceReportDataConfig
        },
      ),
    }
  }, [savedReportsData])

  const switchCurrentReport = useCallback(
    (resetToReport: SavedPerformanceReportDataConfig | null) => {
      setCurrentReport(resetToReport)

      let newDataConfig: PerformanceReportDataConfig = {
        startDate: 'l90d',
        endDate: '',
        matchType: 'full',
        selectedMetric: 'sessions',
        granularity: 'weekly' as Granularity,
      }

      if (resetToReport !== null) {
        const {
          startDate,
          endDate,
          selectedMetric,
          applyFilters,
        } = resetToReport

        newDataConfig = {
          ...dataConfig,
          startDate,
          endDate,
          selectedMetric,
          granularity: resetToReport.granularity || 'monthly',
          applyFilters: applyFilters
            ? applyFilters.map(
                ({
                  dimensionParameterID,
                  dimensionName,
                  dimensionOptions,
                }) => ({
                  dimensionParameterID,
                  dimensionName,
                  dimensionOptions,
                }),
              )
            : undefined,
        }
      } else {
        // Check URL filter options
        const urlFilterValue = new URLSearchParams(window.location.search).get(
          'compare',
        )

        if (urlFilterValue) {
          newDataConfig.matchType = urlFilterValue

          if (newDataConfig.matchType === 'shortuplcode') {
            newDataConfig.selectedMetric = 'shortLinkClicks'
          }
        }
      }

      setDataConfig(newDataConfig)
      setResetInitialDate(false)
      refetchData(newDataConfig, {
        getClickData: resetToReport !== null,
        refetchGraph: true,
        refetchMetrics: true,
        refetchTable: true,
      })
    },
    [dataConfig],
  )

  // Allow the data config to be reset when user changes the report via the nav
  // This doesn't cause a page reload so refetch must be triggered
  useEffect(() => {
    if (!workspaceID || !initialFetchComplete) return

    switchCurrentReport(null)
  }, [window.location.search])

  // Check if current report has any hidden table columns
  useEffect(() => {
    if (!currentReport || !campaignLinkDashboardTableRows?.tableData) {
      return
    }

    const { hideColumnIndexList } = currentReport

    const hiddenColumnIds: string[] = []

    hideColumnIndexList?.forEach((columnIndex) => {
      hiddenColumnIds.push(
        campaignLinkDashboardTableRows.tableData[columnIndex].metricID,
      )
    })

    setHiddenTableColumns(hiddenColumnIds)
  }, [campaignLinkDashboardTableRows, currentReport])

  // State to show if not connected to analytics
  const [
    showConnectAnalyticsBlocker,
    setShowConnectAnalyticsBlocker,
  ] = useState(false)

  useEffect(() => {
    if (
      dataSourceData &&
      !dataSourceData.currentAccount.dataSource?.connected &&
      campaignLinkDashboardMetaData &&
      availableMetrics.length === 0 &&
      // Report page should show if workspace has shortlink clickthrough data, even if they are not connected to analytics
      (availableClickMetrics === null || availableClickMetrics.length === 0)
    ) {
      setShowConnectAnalyticsBlocker(true)
    }
  }, [
    dataSourceData,
    campaignLinkDashboardMetaData,
    availableMetrics,
    availableClickMetrics,
  ])

  return (
    <>
      <SiteWrapper>
        <Layout width={1600}>
          <div
            ref={(element) => {
              if (element) {
                const container = element.getBoundingClientRect()
                setWidth(container.width - 42)
              }
            }}
          >
            <Intro title="Performance report" className={styles.intro}>
              <p>Create custom reports which compare marketing performance.</p>
              {dataSourceData &&
                !dataSourceData.currentAccount.dataSource?.connected && (
                  <Tag inline icon={Stars}>
                    <p>
                      <Link href="/connect" newTab={false}>
                        Connect your analytics platform
                      </Link>{' '}
                      to compare metrics for each campaign link.
                    </p>
                  </Tag>
                )}
              {errorFetchingSavedReports && (
                <p className={styles.errorMsg}>
                  Error retrieving saved reports.
                </p>
              )}
              {urlParamsFilter.error && (
                <p className={styles.errorMsg}>
                  Error creating filtered report for selected link.
                </p>
              )}
            </Intro>
            <ReportControls
              virtualizedFilter
              interactionLogReportName="performance-report"
              loadingData={loadingMetaData}
              controlsPrefix="Compare"
              currentDataConfig={dataConfig}
              setCurrentDataConfig={setDataConfig}
              primaryBreakdownID="matchType"
              includePrimaryBreakdown
              fullPrimaryBreakdownList={availableMatchTypes}
              initialDate={
                resetInitialDate
                  ? {
                      isCustom: !!urlParamsFilter.filter,
                      startDate: dataConfig.startDate,
                      endDate: dataConfig.endDate,
                    }
                  : undefined
              }
              includeSuccessMetric
              loadingMetrics={
                !errorLoadingMetaData && !campaignLinkDashboardMetaData
              }
              fullMetricsList={allAvailableMetrics}
              successMetricsButtons={
                <NavigateButton
                  className={styles.editMetrics}
                  onPressStart={() => setShowEditMetricsModal(true)}
                >
                  Edit metrics
                </NavigateButton>
              }
              includeFilter
              loadingFilters={
                loadingMetaData || availableDimensions.length === 0
              }
              filterData={availableDimensions}
              filterError={!!errorLoadingMetaData}
              onChange={async (newDataConfig, changeType) => {
                const alteredNewDataConfig = { ...newDataConfig }

                // User is manually selecting the date
                if (changeType === 'date') {
                  setResetInitialDate(false)
                }

                if (changeType === 'successMetric') {
                  setLinksTablePerformanceOrder('topToBottom')
                }

                if (changeType === 'matchType') {
                  // Set graph breakdown back to default
                  alteredNewDataConfig.stackDimension = null

                  // Switch metric when comparing short links
                  if (newDataConfig.matchType === 'shortuplcode') {
                    alteredNewDataConfig.selectedMetric = 'shortLinkClicks'

                    setDataConfig(alteredNewDataConfig)
                  }
                }

                await refetchData(alteredNewDataConfig, {
                  getClickData: [
                    'shortLinkClicks',
                    'shortLinkInitialClicks',
                    'shortLinkReturningClicks',
                    'shortLinkReturningClicksPercentage',
                  ].includes(alteredNewDataConfig.selectedMetric),
                  // No need to refetch metrics when matchType changes (unless it changes to shortuplcode - see above)
                  refetchMetrics:
                    changeType !== 'matchType' ||
                    (changeType === 'matchType' &&
                      newDataConfig.matchType === 'shortuplcode'),
                  // No need to refetch metrics when matchType changes (unless it changes to shortuplcode - see above)
                  refetchGraph:
                    changeType !== 'matchType' ||
                    (changeType === 'matchType' &&
                      newDataConfig.matchType === 'shortuplcode'),
                  refetchTable: changeType !== 'granularity',
                })
              }}
            />
            <ReportSavedTabs
              reportIDKey="savedReportID"
              reportNameKey="reportName"
              currentDataConfig={dataConfig}
              currentSavedReport={currentReport}
              setShowSaveReportModal={setShowSaveReportModal}
              loading={loadingSavedReports || updatingReport || savingNewReport}
              savedReports={savedReports}
              onResetReport={switchCurrentReport}
              onSaveReport={async (newReport) => {
                await updateSavedReport({
                  variables: {
                    ...newReport,
                    filterList: newReport.applyFilters,
                  },
                })
              }}
              actionButtons={[
                {
                  key: 'manageReports',
                  onPress: () => {
                    setShowManageReportsModal(true)
                  },
                  buttonText: 'Manage reports',
                },
                {
                  key: 'save',
                  isDisabled: loadingSavedReports,
                  onPress: async () => {
                    if (currentReport === null) {
                      setShowSaveReportModal(true)
                    } else {
                      await updateSavedReport({
                        variables: {
                          ...currentReport,
                          ...dataConfig,
                          filterList:
                            currentReport.applyFilters &&
                            currentReport.applyFilters.length > 0
                              ? currentReport.applyFilters
                              : dataConfig.applyFilters,
                        },
                      })
                    }
                  },
                  buttonText:
                    currentReport === null ? 'Save report' : 'Save changes',
                },
                {
                  key: 'createInsights',
                  softDisable: true,
                  onPress: async () => {
                    const message = `Perfomance dashboard "Create insight" feature request`
                    await requestFeature({
                      variables: {
                        page: 'report/performance',
                        message,
                      },
                    })
                  },
                  buttonText: 'Create insight',
                },
                {
                  key: 'downloadPDF',
                  buttonText: 'Download PDF',
                  isDisabled:
                    metricClickDataLoading ||
                    metricDataLoading ||
                    loadingGraphClickData ||
                    loadingGraphData ||
                    tableDataIsLoading,
                  onPress: () => {
                    setUsePDFDims(true)

                    setTimeout(() => {
                      toPDF()

                      setUsePDFDims(false)

                      logAction({
                        variables: {
                          action: 'download-report-pdf',
                          extra: JSON.stringify(dataConfig),
                          websiteSection: 'report',
                          functionName: 'downloadAsPdf',
                          pagePath: window.location.pathname,
                        },
                      })
                    }, 2000)
                  },
                },
                {
                  key: 'downloadExcel',
                  loading: exporting,
                  onPress: async () => {
                    setExporting(true)

                    const {
                      startDate,
                      endDate,
                      matchType,
                      selectedMetric,
                      granularity,
                      stackDimension,
                      applyFilters,
                    } = dataConfig

                    const rawExcelData = {
                      startDate,
                      endDate,
                      granularity,
                      selectedMetric,
                      stackDimension: stackDimension || '',
                      matchType,
                      applyFilters: applyFilters || [],
                    }

                    await getCampaignLinkRawData(
                      rawExcelData,
                      currentReport?.reportTitle || 'Link_Performance_Export',
                    )

                    logAction({
                      variables: {
                        action: 'download-data-excel',
                        extra: JSON.stringify(rawExcelData),
                        websiteSection: 'report',
                        functionName: 'getCampaignLinkRawData',
                        pagePath: window.location.pathname,
                      },
                    })
                    setExporting(false)
                  },
                  buttonText: 'Download Excel',
                },
                {
                  key: 'share',
                  onPress: async () => {
                    const message = `Perfomance dashboard "Share" feature request`

                    await requestFeature({
                      variables: {
                        page: 'report/performance',
                        message,
                      },
                    })
                  },
                  buttonText: 'Share',
                },
                {
                  key: 'editMetrics',
                  onPress: () => setShowEditMetricsModal(true),
                  buttonText: 'Add/Edit metrics',
                },
              ]}
            />
            <div
              ref={pdfTargetRef}
              className={styles.paper}
              style={
                usePDFDims
                  ? {
                      width: (tableRef.current?.offsetWidth || 0) + 25,
                      borderWidth: 0,
                    }
                  : undefined
              }
            >
              <div
                id="pdfLandscapePage1"
                // Make this content the height of an A4 page when making PDF
                // style={
                //   usePDFDims
                //     ? {
                //         height: pdfDims.page1Height,
                //         pageBreakAfter: 'always',
                //       }
                //     : undefined
                // }
              >
                <ReportSummary
                  loading={
                    loadingMetaData ||
                    metricClickDataLoading ||
                    metricDataLoading ||
                    loadingGraphClickData ||
                    loadingGraphData ||
                    tableDataIsLoading
                  }
                  error={
                    !!tableDataError &&
                    !!metricClickDataError &&
                    !!metricDataError
                  }
                  reportTitle={
                    currentReport?.reportTitle ||
                    messages.defaultReportTitle_performance
                  }
                  onUpdateTitle={async (newTitle) => {
                    if (currentReport !== null) {
                      updateSavedReport({
                        variables: {
                          savedReportID: currentReport.savedReportID,
                          reportName: newTitle,
                          reportTitle: newTitle,
                        },
                      })
                    } else {
                      setShowSaveReportModal(true)
                    }
                  }}
                  dateRange={dataBasedDateRange}
                  showDataSource
                  successMetric={{
                    name: successMetricDisplayName,
                    tooltip: successMetricTooltip,
                  }}
                  filterList={reportFilters}
                  onRemoveFilterOption={async (
                    dimensionParameterID,
                    optionValue,
                  ) => {
                    // Shouldn't be possible - this function only removes existing filters
                    if (!dataConfig.applyFilters) return

                    const newApplyFilters = [...dataConfig.applyFilters]

                    const filterListIndex = dataConfig.applyFilters.findIndex(
                      (filter) =>
                        filter.dimensionParameterID === dimensionParameterID,
                    )

                    if (filterListIndex === -1) return

                    const dimensionToEdit =
                      dataConfig.applyFilters[filterListIndex]

                    const newDimensionOptions = dimensionToEdit.dimensionOptions.filter(
                      (option) => option !== optionValue,
                    )

                    if (newDimensionOptions.length > 0) {
                      newApplyFilters.splice(filterListIndex, 1, {
                        ...dimensionToEdit,
                        dimensionOptions: dimensionToEdit.dimensionOptions.filter(
                          (option) => option !== optionValue,
                        ),
                      })
                    } else {
                      newApplyFilters.splice(filterListIndex, 1)
                    }

                    const newDataConfig = {
                      ...dataConfig,
                      applyFilters:
                        newApplyFilters.length > 0
                          ? newApplyFilters
                          : undefined,
                    }

                    setDataConfig(newDataConfig)

                    setResetInitialDate(false)

                    await refetchData(newDataConfig, {
                      getClickData: [
                        'shortLinkClicks',
                        'shortLinkInitialClicks',
                        'shortLinkReturningClicks',
                        'shortLinkReturningClicksPercentage',
                      ].includes(newDataConfig.selectedMetric),
                    })
                  }}
                >
                  <>
                    {!!successMetricDisplayName && showDataWarningMessage && (
                      <Tag inline icon={warning}>
                        <p style={{ marginBottom: 0 }}>
                          <strong>Apologies:</strong> Due to an error, short
                          link clickthrough data is under-reported between 17th
                          Jun 2024 - 2nd Jul 2024 and 9th Nov 2024 - 4th Feb
                          2025.
                        </p>
                      </Tag>
                    )}
                  </>
                </ReportSummary>
                <MetricDataBanner
                  loading={
                    isUplifterClickMetric
                      ? metricClickDataLoading
                      : metricDataLoading
                  }
                  error={
                    isUplifterClickMetric
                      ? !!metricClickDataError
                      : !!metricDataError
                  }
                  granularity={dataConfig.granularity}
                  dateRange={dataBasedDateRange}
                  data={dashboardMetricData}
                  successMetric={successMetricDisplayName}
                  units={units}
                  showDurationUnits={showDurationUnits}
                />
                <div
                  className={styles.graphWrapper}
                  style={
                    usePDFDims ? undefined : { minWidth: '100%', width: '100%' }
                  }
                >
                  <PerformanceReportGraph
                    title={
                      breakdownDimensionName === ''
                        ? 'No breakdown'
                        : breakdownDimensionName
                    }
                    loading={
                      (!isUplifterClickMetric &&
                        !fullGraphData &&
                        !graphDataError) ||
                      (isUplifterClickMetric &&
                        !fullGraphClickData &&
                        !graphClickDataError) ||
                      loadingGraphData ||
                      loadingGraphClickData
                    }
                    units={units || 'float'}
                    showDurationUnits={showDurationUnits}
                    width={width}
                    graphWidth={graphWidth}
                    offset={graphOffset}
                    granularity={dataConfig.granularity}
                    isMobile={isMobile}
                    totalsData={tableTotals}
                    graphType={graphType}
                    availableDimensions={availableDimensions}
                    breakdownDimension={breakdownDimensionName}
                    successMetricDisplayName={successMetricDisplayName}
                    graphDataToShow={graphDataToShow}
                    condensedGraphData={condensedGraphData}
                  >
                    <EditableGraphTitle
                      loading={loadingGraphData || loadingGraphClickData}
                      breakdownDimension={breakdownDimensionName}
                      updateStackDimension={async (newDim) => {
                        await refetchData(
                          { ...dataConfig, stackDimension: newDim || null },
                          {
                            refetchMetrics: false,
                            refetchGraph: true,
                            refetchTable: false,
                          },
                        )

                        setDataConfig((curr) => ({
                          ...curr,
                          stackDimension: newDim || null,
                        }))

                        logAction({
                          variables: {
                            action: 'graph-breakdown-updated',
                            extra: newDim,
                            websiteSection: 'report',
                            functionName: 'updateGraphBreakdown',
                            pagePath: '/report/performance',
                          },
                        })
                      }}
                      successMetricDisplayName={successMetricDisplayName}
                      startDate={
                        condensedGraphData
                          ? condensedGraphData.graphDateRange[0]
                          : dataConfig.startDate
                      }
                      endDate={
                        condensedGraphData
                          ? condensedGraphData.graphDateRange[
                              condensedGraphData.graphDateRange.length - 1
                            ]
                          : dataConfig.endDate
                      }
                      availableDimensions={availableDimensions}
                    />
                  </PerformanceReportGraph>
                </div>
              </div>
              <div data-html2canvas-ignore>
                <SelectBoxChecklist
                  id="supportMetrics"
                  className={styles.successMetricSelector}
                  isClearable={false}
                  showOnlyButtons
                  allLabel="All"
                  controlLabel="Support metrics"
                  placeholder="Support metrics: None"
                  labelKey="displayName"
                  valueKey="metricID"
                  isDisabled={allAvailableMetrics.length === 0}
                  value={allAvailableMetrics.filter(
                    (option) => !hiddenTableColumns.includes(option.metricID),
                  )}
                  options={allAvailableMetrics}
                  onChange={(newValue) => {
                    const newHideIndex: number[] = []
                    const newHiddenTableColumns: string[] = []

                    campaignLinkDashboardTableRows?.tableData.forEach(
                      ({ metricID }, index) => {
                        if (
                          !newValue.find((val) => val.metricID === metricID)
                        ) {
                          newHideIndex.push(index)
                          newHiddenTableColumns.push(metricID)
                        }
                      },
                    )

                    setHiddenTableColumns(newHiddenTableColumns)

                    // If on a saved report, update that report
                    if (currentReport) {
                      updateSavedReport({
                        variables: {
                          savedReportID: currentReport.savedReportID,
                          hideColumnIndexList: newHideIndex,
                        },
                      })
                    }
                  }}
                />
              </div>
              <div className={styles.tableWrapper}>
                <PerformanceReportLinksTable
                  ref={tableRef}
                  fullMatchType={
                    availableMatchTypes.find(
                      (mT) =>
                        mT.id === dataConfig.matchType ||
                        mT.value === dataConfig.matchType,
                    ) || dataConfig.matchType
                  }
                  savedReportID={currentReport?.savedReportID}
                  savedReportColumnOrder={
                    currentReport?.updatedTableColumnIndexOrderList
                  }
                  showDataWarningMessage={showDataWarningMessage}
                  hiddenTableColumns={hiddenTableColumns}
                  data={campaignLinkDashboardTableRows}
                  loading={
                    (!campaignLinkDashboardTableRowsData && !tableDataError) ||
                    tableDataIsLoading
                  }
                  error={!!tableDataError}
                  successMetric={dataConfig.selectedMetric}
                  increasePositive={increasePositive}
                  linksTablePerformanceOrder={linksTablePerformanceOrder}
                  setLinksTablePerformanceOrder={setLinksTablePerformanceOrder}
                  successMetricDisplayName={successMetricDisplayName}
                />
              </div>
            </div>
          </div>
        </Layout>
      </SiteWrapper>
      {showEditMetricsModal && (
        <PerformanceReportManageMetricsModal
          active={showEditMetricsModal}
          toggleActive={setShowEditMetricsModal}
        />
      )}
      {showSaveReportModal && (
        <SaveEditReportModal
          reportIDKey="savedReportID"
          reportNameKey="reportName"
          reportTitleKey="reportTitle"
          toggleActive={setShowSaveReportModal}
          reportToEdit={reportToEdit}
          setReportToEdit={setReportToEdit}
          loading={savingNewReport || updatingReport}
          onSuccess={async (newReport) => {
            let newReportID = newReport.savedReportID || ''

            if (reportToEdit === null) {
              const { data: newReportData } = await saveReport({
                variables: {
                  ...dataConfig,
                  ...newReport,
                  filterList: dataConfig.applyFilters || [],
                  stackDimension: newReport.stackDimension || '',
                },
              })

              newReportID =
                newReportData?.report.saveLinkPerformanceReport.savedReportID ||
                ''
            } else {
              updateSavedReport({
                variables: {
                  ...newReport,
                  filterList: newReport.applyFilters,
                },
              })
            }

            setCurrentReport({
              ...dataConfig,
              ...newReport,
              savedReportID: newReportID,
            })

            setShowSaveReportModal(false)
          }}
        />
      )}
      {showManageReportsModal && (
        <ManageSavedReportsModal
          toggleActive={setShowManageReportsModal}
          reportIDKey="savedReportID"
          reportNameKey="reportName"
          loading={loadingSavedReports || savingNewReport || updatingReport}
          savedReports={savedReports}
          setReportToEdit={setReportToEdit}
          setShowSaveReportModal={setShowSaveReportModal}
          userPreferredOrder={userPreferredOrder}
          onUpdateOrder={async (newOrder) => {
            await updateReportPrefs({
              variables: {
                newOrder,
              },
            })
          }}
          /**
           * Hidden reports are provided as a separate list.
           * This is different to ReportMarketingJourneysPage.
           * TODO: Need Tom to update the response object to use a `reportHidden` field on each report instead of a separate array
           */
          // reportHiddenKey="boardHidden"
          // onHideReport={async (reportsOrder, hiddenReports) => {
          //   await updateBoardOrder({
          //     variables: {
          //       newOrder: reportsOrder,
          //       hideReports: hiddenReports,
          //     },
          //   })
          // }}
          onDeleteReport={async (reportIdToDelete) => {
            await removeSavedReport({
              variables: {
                savedReportID: reportIdToDelete,
              },
            })

            // If current report is deleted, switch back to default report
            if (reportIdToDelete === currentReport?.savedReportID) {
              switchCurrentReport(null)
            }
          }}
        />
      )}
      {showConnectAnalyticsBlocker && (
        <ConnectAnalyticsBlockerModal
          setIsOpen={setShowConnectAnalyticsBlocker}
          reconnect={
            dataSourceData?.currentAccount.dataSource?.kind !== 'BLANK'
          }
        />
      )}
    </>
  )
}

export default PerformanceReportPage
