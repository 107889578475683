import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import Button from './button'
import Row, { Slot } from './row'
import SelectBox from './select-box'
import { ErrorMessage, Heading } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  getCampaignLinkDashboardMeta,
  setDashboardSettings,
} from '../api/graphql/report-client'
import { getUserInfo } from '../api/graphql/user-client'
import { toggleModuleAvailability } from '../api/graphql/workspace-client'
import { matchTypesDropDown } from '../core/constants'
import { sortData, isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-preferences.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

export default function WorkspacePreferences({
  className,
}: WorkspaceDetailsProps) {
  const {
    workspaceID: accountID,
    userPermission,
    trackAvailable,
    reportAvailable,
    planAvailable,
    connectAvailable,
    companyID,
  } = useReactiveVar(currentUserDetails)

  const referToLinks = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const { data: companyModuleData } = useQuery(getUserInfo)
  const { data: reportData, error: reportDataError } = useQuery(
    getCampaignLinkDashboardMeta,
  )

  const [updateDashboardSettings, { loading: loadingSettings }] = useMutation(
    setDashboardSettings,
  )
  const [updateModuleAvailability] = useMutation(toggleModuleAvailability)

  const campaignLinkDashboardMeta = useMemo(() => {
    if (!reportData) return null

    return reportData.campaignLinkDashboardMeta
  }, [reportData])

  const [performance, setPerfomance] = useState<MatchTypeProps | null>(null)

  const isAdmin = isAdminUser(userPermission)

  const matchTypeMerged: MatchTypeProps[] = useMemo(() => {
    if (
      !campaignLinkDashboardMeta ||
      !campaignLinkDashboardMeta.availableDimensions ||
      campaignLinkDashboardMeta.availableDimensions.length === 0
    )
      return [...matchTypesDropDown]

    return [
      ...matchTypesDropDown,
      ...sortData(
        campaignLinkDashboardMeta.availableDimensions.map((item) => {
          const { dimensionParameterID, dimensionName, helpText } = item
          return {
            name: dimensionName,
            shortName: dimensionName,
            value: dimensionName,
            tooltip: helpText,
            id: dimensionParameterID,
          }
        }),
        'name',
        true,
        null,
        true,
      ),
    ]
  }, [campaignLinkDashboardMeta])

  // Update report matchType based on saved preference
  useEffect(() => {
    if (!campaignLinkDashboardMeta || matchTypeMerged.length === 0) return

    const mT = campaignLinkDashboardMeta?.onloadDefaultOptions?.find(
      (item) => item.name === 'matchType',
    )

    if (!mT) return

    const savedValue = matchTypeMerged.find(({ id, value }) => {
      if (
        mT.value !== 'full' &&
        mT.value !== 'partial' &&
        mT.value !== 'lpag' &&
        mT.value !== 'shortuplcode'
      ) {
        return id === mT.value
      }

      return value === mT.value
    })

    setPerfomance(savedValue || matchTypesDropDown[0])
  }, [campaignLinkDashboardMeta, matchTypeMerged])

  /**
   * For enterprise companies, modules can be blocked at the support level.
   * Account holders should not be able to toggle visibility for blocked modules
   */
  const modulesVisibility = useMemo(() => {
    if (!companyModuleData) {
      return { track: true, report: true, connect: true, plan: true }
    }

    return {
      track: companyModuleData.currentCompany.trackAvailable,
      report: companyModuleData.currentCompany.reportAvailable,
      connect: companyModuleData.currentCompany.connectAvailable,
      plan: companyModuleData.currentCompany.planAvailable,
    }
  }, [companyModuleData])

  if (
    !modulesVisibility.track &&
    !modulesVisibility.report &&
    !modulesVisibility.connect &&
    !modulesVisibility.plan
  ) {
    return null
  }

  return (
    <div className={className}>
      <h3>Features</h3>
      <p className={styles.byline}>
        Change which features your users can access.
      </p>
      <div className={styles.subsection}>
        <div className={styles.wrapper}>
          <div className={styles.body}>
            {modulesVisibility.track && (
              <Row
                className={styles.bodyItem}
                vAlign="center"
                align="flex-start"
              >
                <Slot
                  align="flex-start"
                  vAlign="center"
                  className={styles.bodyItemSlot}
                >
                  <Heading type={3} align="left">
                    Track
                  </Heading>
                  <p>
                    Make and manage campaign {referToLinks}s, to optimise media
                    spend.
                  </p>
                </Slot>
                <Slot align="flex-end" vAlign="center" width={100}>
                  <Button
                    variant="secondary"
                    color={trackAvailable ? 'green' : 'red'}
                    onPress={async () => {
                      await updateModuleAvailability({
                        variables: {
                          accountID,
                          companyID,
                          trackModule: !trackAvailable,
                        },
                      })

                      logAction({
                        variables: {
                          action: `${
                            trackAvailable ? '' : 'de'
                          }activate-track-module`,
                          extra: '',
                          websiteSection: 'settings',
                          functionName: 'showHideTrackModule',
                          pagePath: '/settings',
                        },
                      })
                    }}
                  >
                    {trackAvailable ? 'Enabled' : 'Disabled'}
                  </Button>
                </Slot>
              </Row>
            )}
            {modulesVisibility.report && (
              <Row
                className={styles.bodyItem}
                vAlign="center"
                align="flex-start"
              >
                <Slot
                  align="flex-start"
                  vAlign="center"
                  className={styles.bodyItemSlot}
                >
                  <Heading type={3} align="left">
                    Report
                  </Heading>
                  <p>
                    Create and share reports which explain your websites
                    performance.
                  </p>
                </Slot>
                <Slot align="flex-end" vAlign="center" width={100}>
                  <Button
                    variant="secondary"
                    color={reportAvailable ? 'green' : 'red'}
                    onPress={async () => {
                      await updateModuleAvailability({
                        variables: {
                          accountID,
                          companyID,
                          reportModule: !reportAvailable,
                        },
                      })

                      logAction({
                        variables: {
                          action: `${
                            reportAvailable ? '' : 'de'
                          }activate-report-module`,
                          extra: '',
                          websiteSection: 'settings',
                          functionName: 'showHideReportModule',
                          pagePath: '/settings',
                        },
                      })
                    }}
                  >
                    {reportAvailable ? 'Enabled' : 'Disabled'}
                  </Button>
                </Slot>
              </Row>
            )}
            {modulesVisibility.connect && (
              <Row
                className={styles.bodyItem}
                vAlign="center"
                align="flex-start"
              >
                <Slot
                  align="flex-start"
                  vAlign="center"
                  className={styles.bodyItemSlot}
                >
                  <Heading type={3} align="left">
                    Connect
                  </Heading>
                  <p>
                    Integrate with other applications for better data, workflows
                    and insights.
                  </p>
                </Slot>
                <Slot align="flex-end" vAlign="center" width={100}>
                  <Button
                    variant="secondary"
                    color={connectAvailable ? 'green' : 'red'}
                    onPress={async () => {
                      await updateModuleAvailability({
                        variables: {
                          accountID,
                          companyID,
                          connectModule: !connectAvailable,
                        },
                      })
                    }}
                  >
                    {connectAvailable ? 'Enabled' : 'Disabled'}
                  </Button>
                </Slot>
              </Row>
            )}
            {modulesVisibility.plan && (
              <Row
                className={styles.bodyItem}
                vAlign="center"
                align="flex-start"
              >
                <Slot
                  align="flex-start"
                  vAlign="center"
                  className={styles.bodyItemSlot}
                >
                  <Heading type={3} align="left">
                    Plan
                  </Heading>
                  <p>
                    Plan what advertising mix you want to use in your next
                    campaigns and estimate costs, clickthrough's and
                    conversions.
                  </p>
                </Slot>
                <Slot align="flex-end" vAlign="center" width={100}>
                  <Button
                    variant="secondary"
                    color={connectAvailable ? 'green' : 'red'}
                    onPress={async () => {
                      await updateModuleAvailability({
                        variables: {
                          accountID,
                          companyID,
                          planModule: !planAvailable,
                        },
                      })
                    }}
                  >
                    {planAvailable ? 'Enabled' : 'Disabled'}
                  </Button>
                </Slot>
              </Row>
            )}
          </div>
        </div>
      </div>

      <h3>Report preferences</h3>
      <p className={styles.byline}>Set your default performance report view.</p>
      {reportDataError ? (
        <ErrorMessage showSupport>
          Failed to fetch available metrics.
        </ErrorMessage>
      ) : (
        <div className={styles.subsection} style={{ marginBottom: 16 }}>
          <SelectBox
            className={styles.matchTypeDropdown}
            isLoading={!campaignLinkDashboardMeta || loadingSettings}
            isDisabled={!isAdmin}
            labelKey="name"
            valueKey="value"
            options={matchTypeMerged}
            value={performance}
            onChange={async (newValue) => {
              if (newValue) {
                setPerfomance(newValue)

                await updateDashboardSettings({
                  variables: {
                    onloadDefaultOptions: [
                      {
                        name: 'matchType',
                        enabled: false,
                        value: newValue.id || newValue.value,
                      },
                    ],
                  },
                })

                logAction({
                  variables: {
                    action: 'update-performance-report-default-match-type',
                    functionName: 'updateDashboardSettings',
                    pagePath: '/settings',
                    websiteSection: 'Workspace preferences',
                    extra: newValue.id || newValue.value,
                  },
                })
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
