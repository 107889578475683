import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'

import Button from './button'
import { InputLabel, ChangeInput } from './input-v2'
import SubscriptionComparison from './subscription-comparison'
import { CancelSubscriptionModal } from './subscription-management-modals'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getCompanyDetails,
  updateCompanyInternalContact,
  updateCompanyName,
} from '../api/graphql/company-client'
import { getUserAccounts, getUserInfo } from '../api/graphql/user-client'
import { emailRegex } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/company-billing-and-details.module.scss'

interface CompanyBillingAndDetailsProps {
  className?: string
}

const CompanyBillingAndDetails = ({
  className,
}: CompanyBillingAndDetailsProps) => {
  const { companyName } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    isEnterprise,
    isMicrosoftMarketplace,
    paddleData,
    subscriptionID,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)

  const [updateInternalContact] = useMutation(updateCompanyInternalContact)
  const [updateOrgName] = useMutation(updateCompanyName)

  const [
    showCancelSubscriptionModal,
    setShowCancelSubscriptionModal,
  ] = useState(false)

  const internalContactEmail = useMemo(() => {
    if (!companyData) return null

    return companyData.currentCompany.internalContactEmail
  }, [companyData])

  const {
    paddleUpdateUrl,
    paddlePaymentMethodURL,
    paddleCancelUrl,
    paddleScheduledCancelDate,
  } = useMemo(() => {
    if (!paddleData) {
      return {}
    }

    const { paddleSubscriptionScheduledCancel } = paddleData.currentCompany

    return {
      paddleUpdateUrl: paddleData.currentCompany.paddleUpdateUrl,
      paddlePaymentMethodURL: paddleData.currentCompany.paddlePaymentMethodURL,
      paddleCancelUrl: paddleData.currentCompany.paddleCancelUrl,
      paddleScheduledCancelDate: moment(
        paddleSubscriptionScheduledCancel,
      ).isAfter(moment())
        ? paddleSubscriptionScheduledCancel
        : null,
    }
  }, [paddleData])

  return (
    <>
      <div className={className}>
        <Heading type={3} align="left">
          Company details
        </Heading>

        <div className={styles.subsection}>
          <div className={styles.fieldRow}>
            <div className={styles.field}>
              <InputLabel
                htmlFor="company-name"
                tooltip="This is the name of your organisation or company."
              >
                Company name
              </InputLabel>
              <ChangeInput
                id="company-name"
                name="company-name"
                initialValue={companyName}
                onConfirm={async (newName) => {
                  await updateOrgName({
                    variables: {
                      newName,
                    },
                    refetchQueries: [getUserInfo, getUserAccounts],
                  })

                  currentUserDetails({
                    ...currentUserDetails(),
                    companyName: newName,
                  })
                }}
              />
            </div>
            <div className={styles.field}>
              <InputLabel
                htmlFor="internal-contact-email"
                tooltip="Your organisation's internal support contact. Will be shown on the Welcome page for all users."
              >
                Internal contact email
              </InputLabel>
              <ChangeInput
                id="internal-contact-email"
                name="internal-contact-email"
                initialValue={internalContactEmail || ''}
                validateChange={(newEmail) => emailRegex.test(newEmail)}
                onConfirm={async (newEmail) => {
                  await updateInternalContact({
                    variables: {
                      newEmail,
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>

        <Heading type={3} align="left">
          Plan details
        </Heading>
        <SubscriptionComparison
          // MS Marketplace subscribers should be able to see all subscription levels
          // They can downgrade their enterprise accounts without involvement from Uplifter support
          hideStartupTier={isEnterprise && !isMicrosoftMarketplace}
          hideBusinessTier={isEnterprise && !isMicrosoftMarketplace}
          hideBillingPeriodToggle
          highlightCurrent
          hideIcons
          hideTierPrices={isEnterprise && !isMicrosoftMarketplace}
          showLimits
          showSubscriptionDetails
          showDowngradeActions
          hideFeatures
        />
        {!isMicrosoftMarketplace && !isEnterprise && (
          <>
            <div className={styles.spacer} />
            <div className={styles.paddleManagementLinks}>
              {paddleUpdateUrl && (
                <Button
                  variant="secondary"
                  onPress={() => {
                    logAction({
                      variables: {
                        action: 'click-paddle-customer-portal',
                        websiteSection: 'upgrade',
                        pagePath: window.location.pathname,
                        functionName: 'clickDowngrade',
                      },
                    })

                    window.open(paddleUpdateUrl, '_blank')
                  }}
                >
                  View invoices
                </Button>
              )}
              {!paddleScheduledCancelDate && paddlePaymentMethodURL && (
                <Button
                  variant="secondary"
                  onPress={() => {
                    window.open(paddlePaymentMethodURL, '_blank')

                    logAction({
                      variables: {
                        action: 'update-paddle-subscription-payment-method',
                        extra: JSON.stringify({
                          paddleSubscriptionId: subscriptionID,
                        }),
                        websiteSection: 'upgrade',
                        pagePath: '/upgrade',
                        functionName: 'updatePaddleSubscription',
                      },
                    })
                  }}
                >
                  Change payment method
                </Button>
              )}
              {!paddleScheduledCancelDate && paddleCancelUrl && (
                <Button
                  variant="secondary"
                  onPress={() => {
                    logAction({
                      variables: {
                        action: 'click-cancel-subscription',
                        websiteSection: 'upgrade',
                        pagePath: window.location.pathname,
                        functionName: 'clickDowngrade',
                      },
                    })

                    setShowCancelSubscriptionModal(true)
                  }}
                >
                  Cancel subscription
                </Button>
              )}
            </div>
          </>
        )}
      </div>
      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal setIsOpen={setShowCancelSubscriptionModal} />
      )}
    </>
  )
}

export default CompanyBillingAndDetails
