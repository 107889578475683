import React, { useMemo, useState } from 'react'
import { nanoid } from 'nanoid'

import Button from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import Input, { ClickEditInput } from './input'
import Table from './table-v2'
import styles from '../styles/multi-list.module.scss'

interface ListItem {
  col1Value: string
  col2Value: string
}

interface AddNewValueProps {
  onChange: (newValue: ListItem) => void
  ItemOneLabel: string
  ItemTwoLabel: string
}

const AddNewValue = ({
  onChange,
  ItemOneLabel,
  ItemTwoLabel,
}: AddNewValueProps) => {
  const [newValue, setNewValue] = useState({ col1Value: '', col2Value: '' })

  const id = useMemo(() => {
    return nanoid()
  }, [])

  return (
    <tr>
      <td>
        <Input
          id={`${id}-id-one`}
          name={`${id}-name-one`}
          placeholder={ItemOneLabel}
          value={newValue[0]}
          autoComplete="off"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: val } = e.target as HTMLInputElement
            setNewValue({ ...newValue, col1Value: val })
          }}
        />
      </td>
      <td>
        <Input
          id={`${id}-id-two`}
          name={`${id}-name-two`}
          value={newValue[1]}
          autoComplete="off"
          placeholder={ItemTwoLabel}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            const { value: val } = e.target as HTMLInputElement
            setNewValue({ ...newValue, col2Value: val })
          }}
        />
      </td>
      <td>
        <Button
          isDisabled={!(newValue[0] !== '' && newValue[1] !== '')}
          color="blue"
          onPress={() => onChange(newValue)}
        >
          Add
        </Button>
      </td>
    </tr>
  )
}

interface MultiListRowProps {
  id: string
  index: number
  value: ListItem
  tableRowRef: React.RefObject<HTMLElement>
  onChange: (col: 'col1Value' | 'col2Value', newValue: string) => void
  onDelete: () => void
}

const MultiListRow = ({
  id,
  index,
  value,
  tableRowRef,
  onChange,
  onDelete,
}: MultiListRowProps) => {
  return (
    <>
      <td>
        <ClickEditInput
          id={`${id}-${value.col1Value}-${index}`}
          name={`${id}-${value.col1Value}-${index}`}
          value={value.col1Value}
          onChange={(newValue) => {
            onChange('col1Value', newValue)
          }}
        />
      </td>
      <td>
        <ClickEditInput
          id={`${id}-${value.col2Value}-${index}`}
          name={`${id}-${value.col2Value}-${index}`}
          value={value.col2Value}
          onChange={(newValue) => {
            onChange('col2Value', newValue)
          }}
        />
      </td>
      <td className={styles.deleteColumn}>
        <DeleteButtonWithConfirmation
          containerRef={tableRowRef}
          confirmationClassName={styles.deleteConfirmContainer}
          confirmMessage="Remove this?"
          onConfirm={onDelete}
        />
      </td>
    </>
  )
}

interface MultiListProps {
  id: string
  list: ListItem[]
  ItemOneLabel: string
  ItemTwoLabel: string
  onChange: (val: ListItem[]) => void
}

const MultiList = ({
  id,
  list = [],
  ItemOneLabel,
  ItemTwoLabel,
  onChange,
}: MultiListProps) => {
  return (
    <Table
      headerColumns={[
        { id: ItemOneLabel, content: ItemOneLabel },
        { id: ItemTwoLabel, content: ItemTwoLabel },
        { id: 'delete', content: '', className: styles.deleteColumn },
      ]}
      rowIDKey="col1Value"
      tableData={list}
      footerRows={
        <AddNewValue
          key={nanoid()}
          ItemOneLabel={ItemOneLabel}
          ItemTwoLabel={ItemTwoLabel}
          onChange={(newValue) => {
            const val = list.concat()
            val.push(newValue)
            onChange(val)
          }}
        />
      }
    >
      {(value, index, tableRowRef) => (
        <MultiListRow
          id={id}
          index={index}
          value={value}
          tableRowRef={tableRowRef}
          onChange={(colToUpdate, newValue) => {
            const val = list.concat()

            val[index][colToUpdate] = newValue

            onChange(val)
          }}
          onDelete={() => {
            const val = list.concat()

            val.splice(index, 1)

            onChange(val)
          }}
        />
      )}
    </Table>
  )
}

export default MultiList
