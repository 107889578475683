import { useEffect, useState } from 'react'
import { initializePaddle, Paddle, PaddleEventData } from '@paddle/paddle-js'

export const usePaddleSandboxAccount = !(
  process.env.REACT_APP_PROD === 'TRUE' ||
  process.env.REACT_APP_STAGING_OVERRIDE
)

export const paddleVendorID = usePaddleSandboxAccount ? 12541 : 106860

interface UsePaddleProps {
  eventCallback?: (data: PaddleEventData) => void
}

const usePaddle = ({ eventCallback }: UsePaddleProps = {}) => {
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState<Paddle>()
  const [paddleCheckoutLoading, setPaddleCheckoutLoading] = useState(true)

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    const paddleToken = process.env.REACT_APP_PADDLE_TOKEN

    if (!paddleToken) {
      console.error('Paddle token is not defined')
      return
    }

    initializePaddle({
      environment: usePaddleSandboxAccount ? 'sandbox' : 'production',
      token: paddleToken,
      eventCallback: (data) => {
        if (data.name === 'checkout.loaded') {
          setPaddleCheckoutLoading(false)
        }

        if (eventCallback) {
          eventCallback(data)
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance)
      }
    })
  }, [eventCallback])

  return { Paddle: paddle, paddleCheckoutLoading }
}

export default usePaddle
