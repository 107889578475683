import React, { useState, useMemo, useEffect } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import Button from './button'
import Input from './input'
import Modal from './modal'
import Row, { Slot } from './row'
import { BoldText, ErrorMessage, Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getEmailNotifications,
  getPreferredHomepage,
  getProfileInfo,
  toggleEmailNotifications,
  updateMarketingPermissions,
  updatePreferredHomepage,
} from '../api/graphql/user-client'
import {
  PlanIcon,
  ReportIcon,
  TrackIcon,
  WelcomeIcon,
} from '../assets/svgs/menu/module-icons'
import { getUrlQuery, isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/preferences.module.scss'

interface PreferencesProps {
  className?: string
}

const Preferences = ({ className }: PreferencesProps) => {
  const { userPermission, workspaceID } = useReactiveVar(currentUserDetails)

  const { data: profileData } = useQuery(getProfileInfo)
  const { data: homepageData } = useQuery(getPreferredHomepage)
  const { data: notificationsData } = useQuery(getEmailNotifications)

  const [updateUserPreferredHomepage] = useMutation(updatePreferredHomepage)
  const [updateMarketingPermission] = useMutation(updateMarketingPermissions)
  const [
    updateEmailNotifications,
    { loading: updatingEmailNotifications },
  ] = useMutation(toggleEmailNotifications)

  const logAction = useLogAction()

  const acceptedMarketing = useMemo(() => {
    if (!profileData) return false

    return !!JSON.parse(profileData.currentUser.acceptedMarketing)
  }, [profileData])

  const preferredHomepage = useMemo(() => {
    if (!homepageData) return 'welcome'

    return homepageData.currentUser.preferredHomepage
  }, [homepageData])

  const availableModules = useMemo(() => {
    if (!homepageData)
      return {
        track: false,
        report: false,
        plan: false,
      }

    return {
      track: homepageData.currentAccount.trackAvailable,
      report: homepageData.currentAccount.reportAvailable,
      plan: homepageData.currentAccount.planAvailable,
    }
  }, [homepageData])

  const emailSettings = useMemo(() => {
    if (!notificationsData)
      return {
        trackEmail: false,
        // explainEmail: false,
        reportEmail: false,
        allTrackValidationAlertEmail: true,
      }

    const landingPageAlerts =
      notificationsData.currentUser.allTrackValidationAlertEmail

    const currentAccountProfile = notificationsData.currentUser.userAccountProfiles.find(
      (profile) => profile.accountID === workspaceID,
    )

    if (!currentAccountProfile)
      return {
        trackEmail: false,
        // explainEmail: false,
        reportEmail: false,
        allTrackValidationAlertEmail: landingPageAlerts,
      }

    return {
      trackEmail: currentAccountProfile.trackEmail,
      // explainEmail: currentAccountProfile.explainEmail,
      reportEmail: currentAccountProfile.reportEmail,
      allTrackValidationAlertEmail: landingPageAlerts,
    }
  }, [notificationsData, workspaceID])

  const [homepageError, setHomepageError] = useState(false)
  const [emailNotificationsError, setEmailNotificationsError] = useState(false)

  const homepageLinks = ['welcome', 'track', 'report', 'plan']

  const [showUpdateAlertsModal, setShowUpdateAlertsModal] = useState(false)

  useEffect(() => {
    const query = getUrlQuery()

    const autoUpdatePreferences = query.get('autoUpdatePreferences')

    if (autoUpdatePreferences === 'landingPageAlerts') {
      setShowUpdateAlertsModal(true)
    }
  }, [])

  return (
    <div className={className}>
      <Heading type={3} align="left">
        Homepage
      </Heading>
      <p className={styles.byline}>Change the page you see after you login.</p>
      <div className={styles.subsection}>
        <div className={styles.updateHomepageWrapper}>
          {homepageLinks.map((page) => {
            if (page !== 'welcome' && !availableModules[page]) return null

            let icon: React.ReactElement | null = null

            switch (page) {
              case 'welcome':
                icon = <WelcomeIcon />
                break
              case 'track':
                icon = <TrackIcon />
                break
              case 'report':
                icon = <ReportIcon />
                break
              case 'plan':
                icon = <PlanIcon />
                break
              default:
                break
            }

            return (
              <Input
                className={classNames(styles.pageSelector, {
                  [styles.checked]: preferredHomepage === page,
                })}
                name="select-homepage-welcome"
                id="select-homepage-welcome"
                label={
                  <>
                    {icon}
                    <span
                      className={styles.pageName}
                    >{`${page[0].toUpperCase()}${page.substring(1)}`}</span>
                  </>
                }
                type="radio"
                checked={preferredHomepage === page}
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  setHomepageError(false)

                  if (preferredHomepage !== page) {
                    const { errors } = await updateUserPreferredHomepage({
                      variables: {
                        newPage: page,
                      },
                    })

                    currentUserDetails({
                      ...currentUserDetails(),
                      workspaceHomepage: page,
                    })

                    if (errors) {
                      setHomepageError(true)
                    }
                  }
                }}
              />
            )
          })}
        </div>
        {homepageError && (
          <ErrorMessage showSupport>Update failed.</ErrorMessage>
        )}
      </div>

      <Heading type={3} align="left">
        Email notifications
      </Heading>
      <p className={styles.byline}>Change which emails you receive from us.</p>
      <div className={styles.subsection}>
        <div className={styles.box}>
          <Row className={styles.notifs}>
            <Slot>
              <Heading type={3} align="left">
                Product updates
              </Heading>
              <p>
                <strong>
                  {acceptedMarketing ? "You'll receive" : "You won't receive"}
                </strong>{' '}
                an email explaining new features and tips.
              </p>
            </Slot>
            <Slot width={100} align="center" vAlign="center">
              <Button
                variant="secondary"
                color={acceptedMarketing ? 'green' : 'red'}
                onPress={async () => {
                  try {
                    setEmailNotificationsError(false)

                    const allow = !acceptedMarketing

                    await updateMarketingPermission({
                      variables: {
                        allowMarketing: allow,
                      },
                    })

                    logAction({
                      variables: {
                        action: allow ? 'allow-marketing' : 'deny-marketing',
                        extra: '',
                        websiteSection: 'settings',
                        functionName: 'acceptMarketing',
                        pagePath: '/settings',
                      },
                    })
                  } catch {
                    setEmailNotificationsError(true)
                  }
                }}
              >
                {acceptedMarketing ? 'Enabled' : 'Disabled'}
              </Button>
            </Slot>
          </Row>

          <Row className={styles.notifs}>
            <Slot>
              <Heading type={3} align="left">
                Landing page alerts
              </Heading>
              <p>
                <strong>
                  {/* TODO: Use new flag when Tom adds it */}
                  {emailSettings.allTrackValidationAlertEmail
                    ? "You'll receive"
                    : "You won't receive"}
                </strong>{' '}
                an email when a working landing page stops working.
              </p>
            </Slot>
            <Slot width={100} align="center" vAlign="center">
              <Button
                variant="secondary"
                color={
                  emailSettings.allTrackValidationAlertEmail ? 'green' : 'red'
                }
                onPress={async () => {
                  try {
                    setEmailNotificationsError(false)

                    const allow = !emailSettings.allTrackValidationAlertEmail

                    await updateEmailNotifications({
                      variables: {
                        accountID: workspaceID,
                        allTrackValidationAlerts: !emailSettings.allTrackValidationAlertEmail,
                      },
                    })

                    logAction({
                      variables: {
                        action: `${
                          allow ? '' : 'de'
                        }activate-landing-page-alerts`,
                        extra: '',
                        websiteSection: 'settings',
                        functionName: 'landingPageAlertsToggle',
                        pagePath: '/settings',
                      },
                    })
                  } catch {
                    setEmailNotificationsError(true)
                  }
                }}
              >
                {emailSettings.allTrackValidationAlertEmail
                  ? 'Enabled'
                  : 'Disabled'}
              </Button>
            </Slot>
          </Row>

          {isAdminUser(userPermission) && (
            <Row className={styles.notifs}>
              <Slot padding="0 16px 0 0">
                <Heading type={3} align="left">
                  Track requests
                </Heading>
                <p>
                  <BoldText>
                    {emailSettings.trackEmail
                      ? "You'll receive"
                      : "You won't receive"}
                  </BoldText>{' '}
                  new requests from teammates to add new values to your campaign
                  code creator.
                </p>
              </Slot>
              <Slot width={100} align="center" vAlign="center">
                <Button
                  variant="secondary"
                  color={emailSettings.trackEmail ? 'green' : 'red'}
                  onPress={async () => {
                    try {
                      setEmailNotificationsError(false)

                      await updateEmailNotifications({
                        variables: {
                          accountID: workspaceID,
                          track: !emailSettings.trackEmail,
                        },
                      })

                      logAction({
                        variables: {
                          action: `${
                            emailSettings.trackEmail ? 'de' : ''
                          }activate-track-requests`,
                          extra: '',
                          websiteSection: 'settings',
                          functionName: 'trackRequestsToggle',
                          pagePath: '/settings',
                        },
                      })
                    } catch {
                      setEmailNotificationsError(true)
                    }
                  }}
                >
                  {emailSettings.trackEmail ? 'Enabled' : 'Disabled'}
                </Button>
              </Slot>
            </Row>
          )}
        </div>
      </div>
      {emailNotificationsError && (
        <ErrorMessage showSupport>Update failed.</ErrorMessage>
      )}
      {showUpdateAlertsModal && (
        <Modal
          setIsOpen={setShowUpdateAlertsModal}
          isWarning
          modalHeader="Disable landing page alerts"
          loading={!notificationsData}
          noText="Back"
          yesText="Yes, disable"
          yesButtonLoading={updatingEmailNotifications}
          onYes={
            emailSettings.allTrackValidationAlertEmail
              ? async () => {
                  await updateEmailNotifications({
                    variables: {
                      accountID: workspaceID,
                      allTrackValidationAlerts: false,
                    },
                  })

                  setShowUpdateAlertsModal(false)
                }
              : undefined
          }
        >
          <p>
            {emailSettings.allTrackValidationAlertEmail
              ? 'Are you sure you want to disable all landing page alerts? You will no longer receive emails when a working landing page stops working.'
              : 'You have already disabled all landing page alerts.'}
          </p>
        </Modal>
      )}
    </div>
  )
}

export default Preferences
