import Make from '.'
import { TOKEN } from '../constants'
import { removeLocalItem } from '../../helpers/local-client'

const make = Make('auth/')

export const logout = (redirectTo?: string) => {
  removeLocalItem([
    TOKEN,
    // Credentials
    'CRD',
    // Datasource
    'DTS',
    // 'track-create',
    // 'user-session-data',
    'ms-microsoftKey',
    'ga-googleKey',
    'okta-oktaKey',
  ])

  if (redirectTo) {
    window.location.assign(redirectTo)

    return
  }

  window.location.reload()
}

export const isLoggedIn = async (): Promise<boolean> => {
  const req = await make.post({
    endpoint: 'logged-in',
  })

  return !!req.success
}

interface PolicyReturn {
  code: string
  lastMethod: 'inviteOpen' | 'microsoft' | 'google' | 'okta' | 'password' | ''
  notFound: boolean
  domainExists: boolean
}

export const fetchPolicy = async (username: string) => {
  const req = await make.post<PolicyReturn>({
    endpoint: 'policy',
    data: {
      username,
    },
  })

  return req
}

export interface PasswordData {
  username: string
  password: string
}

export const loginWithPassword = async ({
  username,
  password,
}: PasswordData) => {
  const req = await make.post<{
    code: number
    token: string
    runningDebug: boolean
    isNewlyCreated?: boolean
  }>({
    endpoint: 'login',
    data: {
      username,
      password,
    },
  })

  if (!req.success) {
    return req || false
  }

  return req
}

interface Link {
  email: string
  nonce: string
  state: string
}

export const createGoogleLoginLink = async (data: Link): Promise<any> => {
  const req = await make.post({
    endpoint: 'create-gsuite-login-link',
    data,
  })

  if (!req.success) {
    return false
  }

  return req
}

export interface GoogleInitialAuth {
  code: string
  nonce: string
}

export interface GoogleReauth {
  googleKey: string
}

export type GoogleData = GoogleInitialAuth | GoogleReauth

export const loginGoogleSSO = async (data: GoogleData) => {
  const req = await make.post<
    GoogleInitialAuth & GoogleReauth & { runningDebug?: boolean }
  >({
    endpoint: 'gsuite-login',
    data,
  })

  return req
}

export const createO365LoginLink = async (data: Link): Promise<any> => {
  const req = await make.post({
    endpoint: 'create-o365-login-link',
    data,
  })

  if (!req.success) {
    return false
  }

  return req
}

export interface MicrosoftInitialAuth {
  id_token: string
  nonce: string
}

export interface MicrosoftReauth {
  microsoftKey: string
}

export type MicrosoftData = MicrosoftInitialAuth | MicrosoftReauth

export const loginMicrosoftSSO = async (data: MicrosoftData) => {
  const req = await make.post<
    MicrosoftInitialAuth & MicrosoftReauth & { runningDebug?: boolean }
  >({
    endpoint: 'o365-login',
    data,
  })

  return req
}

export interface OktaDomain {
  emailDomain: string
  tenantName: string
  loginLink: string
}

interface OktaDomainsResponse {
  tenantList: OktaDomain[]
}

export const getOktaDomainsUnauth = async (emailDomain: string) => {
  const req = await make.post<OktaDomainsResponse>({
    endpoint: 'okta-tenant-list',
    data: {
      emailDomain,
    },
  })

  if (!req.success || !req.res.data) {
    return []
  }

  return req.res.data.tenantList
}

export interface OktaInitialAuth {
  code: string
  clientID: string
}

export interface OktaReauth {
  oktaKey: string
}

export type OktaData = OktaInitialAuth | OktaReauth

export const loginOktaSSO = async (data: OktaData) => {
  const req = await make.post<
    OktaInitialAuth & OktaReauth & { runningDebug?: boolean }
  >({
    endpoint: 'okta-login',
    data,
  })

  return req
}

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  const req = await make.post({
    endpoint: 'forgot-password',
    data: { email },
  })

  if (!req.success) {
    return false
  }

  return true
}

export const resetPassword = async (token: string, password: string) => {
  const req = await make.post({
    endpoint: 'reset-password',
    data: { token, password },
  })

  if (!req.success) {
    return false
  }

  return true
}

export const updatePassword = async ({
  username,
  oldPassword,
  newPassword,
}: {
  username: string
  oldPassword: string
  newPassword: string
}) => {
  try {
    const req = await make.post({
      endpoint: 'change-password',
      data: {
        username,
        oldPassword,
        newPassword,
      },
    })

    if (!req.success || req.res.status !== 200) {
      throw new Error(
        // @ts-ignore
        req.res?.error?.response?.data?.detail || 'Failed to update password',
      )
    }

    return req.res.data
  } catch (err) {
    // @ts-ignore
    return Promise.reject(new Error(err.message))
  }
}

interface PasswordLoginMethod {
  loginMethod: 'password'
  loginParameters: {
    username: string
    password: string
  }
}

interface GoogleLoginMethod {
  loginMethod: 'google'
  loginParameters: GoogleInitialAuth
}

interface MicrosoftLoginMethod {
  loginMethod: 'microsoft'
  loginParameters: MicrosoftInitialAuth
}

interface OktaLoginMethod {
  loginMethod: 'okta'
  loginParameters: OktaInitialAuth
}

export type LoginMethod =
  | PasswordLoginMethod
  | GoogleLoginMethod
  | MicrosoftLoginMethod
  | OktaLoginMethod

interface UpdateEmailParams {
  oldEmail: string
  newEmail: string
}

export const updateEmail = async ({
  oldEmail,
  newEmail,
  loginMethod,
  loginParameters,
}: UpdateEmailParams & LoginMethod) => {
  try {
    const req = await make.post({
      endpoint: 'change-email',
      data: {
        oldEmail,
        newEmail,
        loginMethod,
        loginParameters,
      },
    })

    if (!req.success || req.res.status !== 200) {
      throw new Error(
        // @ts-ignore
        req.res?.error?.response?.data?.detail || 'Failed to update email',
      )
    }

    return req.res.data
  } catch (err) {
    // @ts-ignore
    return Promise.reject(new Error(err.message))
  }
}
