import { useCallback, useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import { usePaddleSandboxAccount } from './usePaddle'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getCompanyDetails,
  getMicrosoftSubscriptionData,
  getPaddleSubscriptionDetails,
  getSubscriptionLinkCount,
} from '../api/graphql/company-client'
import SingleArrow from '../assets/logos/uplifter-arrow.png'
import TwoArrows from '../assets/logos/uplifter-two-arrows.png'
import ThreeArrows from '../assets/logos/uplifter-arrows.png'
import integrationsData from '../connections/connectors-data'
import { brandName } from '../core/constants'

export type BillingPeriods = 'monthly' | 'annual'

type PaddleSubscriptionTiers = 'startup' | 'business'

type PaddleSubscriptionStatuses =
  | 'active'
  | 'canceled'
  | 'past_due'
  | 'paused'
  | 'trialing'
  // Legacy values
  | 'deleted'

/** Used to determine content uses should see */
export type SubscriptionLevels = PaddleSubscriptionTiers | 'enterprise'

/** MS Marketplace permission levels returned from API */
export type MSMarketplaceSubscriptionLevels =
  | 'free_edition'
  | 'personal'
  | 'personal_edition'
  | 'team_edition'
  | 'enterprise_edition'
  | 'startup_edition'
  | 'business_edition'

/** Legacy, or used to set up testing/demo accounts */
export type LegacyPermissionLevels = 'free' | 'team' | 'preSetup' | 'beta'

export const paddleSubscriptionIDs: {
  [productID: string]: {
    tier: PaddleSubscriptionTiers
    billingPeriod: BillingPeriods
    includesTrial: boolean
  }
} = {
  pri_01jjw3k3tpdfymg3d1qns62v6b: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: true,
  },
  pri_01jkr3ay8ydcx4m52779cn58bk: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  pri_01jjw3ksn648px1cp92v7ehzrr: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: true,
  },
  pri_01jkr3bjeagy7vsrs1rwtdxfmb: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  pri_01jjw3nh1wwhxj85xw1nb86w4h: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: true,
  },
  pri_01jkr3cdpw262r2ph8pgwqhn69: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  pri_01jjw3p7vf97sykn67w8z4mgh0: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: true,
  },
  pri_01jkr3d9j2wdwkv4fmw8xn89s0: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  // Legacy
  580851: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  580852: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  901932: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  901933: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  // Sandbox price IDs
  pri_01jjp69k6wqppyzrva6vxwwq67: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: true,
  },
  pri_01jkdc713c8hnqdkc8gnvb563p: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  pri_01jjp6cmcjj8fagv0x3sd64wgc: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: true,
  },
  pri_01jkdc9qxp4k1dwa3ttahdazha: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  pri_01jjp6gpybtd612392dfgn1h2f: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: true,
  },
  pri_01jkdcqczg2n0wbedrajybbjs0: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  pri_01jjp6hfqgehsx62wxx4q63wpk: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: true,
  },
  pri_01jkdcrr4z5gy9fntfve4t00nv: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  // Legacy sandbox product IDs
  52360: {
    tier: 'startup',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  73177: {
    tier: 'startup',
    billingPeriod: 'annual',
    includesTrial: false,
  },
  73178: {
    tier: 'business',
    billingPeriod: 'monthly',
    includesTrial: false,
  },
  73179: {
    tier: 'business',
    billingPeriod: 'annual',
    includesTrial: false,
  },
}

/**
 * Combines all possible subscription levels (from different sources, e.g. MS Marketplace) into a shorter list
 * '[level]_edition' are levels that are returned from MS Marketplace
 * MS Marketplace subscriptions have the same permission levels, but don't see the Upgrade pages
 */
export const getSubscriptionCategory = (
  subscriptionLevel?: string,
): SubscriptionLevels => {
  if (!subscriptionLevel) return 'startup'

  if (['enterprise', 'enterprise_edition'].includes(subscriptionLevel)) {
    return 'enterprise'
  }

  // MS Marketplace's business edition
  if (subscriptionLevel === 'business_edition') {
    return 'business'
  }

  // Legacy subscription levels should be converted to 'startup'
  if (
    [
      // TODO: Review and amend MS Marketplace subscriptions
      // MS Marketplace
      'personal',
      'personal_edition',
      'free_edition',
      'team_edition',
      'startup_edition',
      // Legacy
      'free',
      'team',
      'startup',
      'preSetup',
      'beta',
    ].includes(subscriptionLevel)
  ) {
    return 'startup'
  }

  // Remaining subscriptionLevel values should be Paddle product IDs
  return paddleSubscriptionIDs[subscriptionLevel]?.tier || 'startup'
}

export type FeatureCategory =
  | 'users'
  | 'workspaces'
  | 'shortlinks'
  | 'app-deeplinks'
  | 'connectors'

interface SubscriptionLevelFeature {
  id?: string
  text: string
  tooltip?: string
}

export interface SubscriptionLevelDetails {
  title: string
  icon: string
  tagline: string
  monthlyID?: string
  monthlyNoTrialID?: string
  defaultMonthlyPrice?: string
  annualID?: string
  annualNoTrialID?: string
  defaultAnnualPrice?: string
  maxUsers: number
  maxWorkspaces: number
  /** Google/Adobe Analytics do not count as additional connectors */
  maxConnectors: number
  /** This is a per user limit. The company limit is linksLimit * no. active licences */
  linksLimit: number
  whatYouCanDo: SubscriptionLevelFeature[]
  whatYouGet: SubscriptionLevelFeature[]
}

const maxStartupUsers = 5
const maxStartupWorkspaces = 1
const maxBusinessUsers = 10
const maxBusinessWorkspaces = 2

export const subscriptionLevelDetails: Record<
  SubscriptionLevels,
  SubscriptionLevelDetails
> = {
  startup: {
    title: 'Startup',
    icon: SingleArrow,
    tagline:
      'Replace messy UTM spreadsheets and fiddly Google Analytics reports.',
    monthlyID: usePaddleSandboxAccount
      ? 'pri_01jjp69k6wqppyzrva6vxwwq67'
      : 'pri_01jjw3k3tpdfymg3d1qns62v6b',
    monthlyNoTrialID: usePaddleSandboxAccount
      ? 'pri_01jkdc713c8hnqdkc8gnvb563p'
      : 'pri_01jkr3ay8ydcx4m52779cn58bk',
    defaultMonthlyPrice: '£29.00',
    annualID: usePaddleSandboxAccount
      ? 'pri_01jjp6cmcjj8fagv0x3sd64wgc'
      : 'pri_01jjw3ksn648px1cp92v7ehzrr',
    annualNoTrialID: usePaddleSandboxAccount
      ? 'pri_01jkdc9qxp4k1dwa3ttahdazha'
      : 'pri_01jkr3bjeagy7vsrs1rwtdxfmb',
    defaultAnnualPrice: '£290.00',
    maxUsers: maxStartupUsers,
    maxWorkspaces: maxStartupWorkspaces,
    maxConnectors: 1,
    linksLimit: 250,
    whatYouCanDo: [
      {
        id: 'startup-utm-links',
        text: 'Create UTM links',
        tooltip: 'Create thousands of accurate, consistent links, fast.',
      },
      {
        id: 'startup-short-links',
        text: 'Create short links',
        tooltip:
          'Increase brand awareness and engagement by creating short, bespoke links for your campaigns. Count every clickthrough and compare performance.',
      },
      {
        id: 'startup-qr-codes',
        text: 'Create QR codes',
        tooltip:
          'Create branded QR codes that help you connect online and offline activity, from OOH, print and events to digital campaigns.',
      },
      {
        id: 'startup-custom-parameters',
        text: 'Custom parameters',
        tooltip:
          'Add parameters for business units, products, geographies and more. Concatenate them to existing UTMs or make your own.',
      },
      {
        id: 'startup-user-permissions',
        text: 'User permissions',
        tooltip:
          'Take the stress out of managing consistency. Regular users can request new dropdown values, for admins to on-click approve, reject or edit.) (Take the stress out of managing consistency. Regular users can request new dropdown values, for admins to on-click approve, reject or edit.',
      },
      {
        id: 'startup-one-click-reports',
        text: 'One-click reports',
        tooltip:
          "View metrics for each link, see what's working and what's not with our custom reports.",
      },
      {
        id: 'startup-landing-page-monitoring',
        text: 'Landing page monitoring',
        tooltip: '',
      },
    ],
    whatYouGet: [
      {
        id: 'startup-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'startup-link-limit',
        text: '250 monitored links (per user)',
      },
      {
        id: 'startup-workspace-limit',
        text: `${maxStartupWorkspaces} workspace`,
        tooltip:
          'A single workspace for one client, brand, website or channel. Upgrade to have multiple workspaces with different parameters, dropdowns, reports and integrations.',
      },
      {
        id: 'startup-connectors',
        text: 'GA4 Connector',
      },
      {
        id: 'startup-user-limit',
        text: `1-${maxStartupUsers} users`,
        tooltip:
          'Let other users self-serve and collaborate on consistent links, dropdowns, reports and insights.',
      },
    ],
  },
  business: {
    title: 'Business',
    icon: TwoArrows,
    tagline:
      'Let everyone self-serve consistent, branded links to web/app, QR codes, reports.',
    monthlyID: usePaddleSandboxAccount
      ? 'pri_01jjp6gpybtd612392dfgn1h2f'
      : 'pri_01jjw3nh1wwhxj85xw1nb86w4h',
    monthlyNoTrialID: usePaddleSandboxAccount
      ? 'pri_01jkdcqczg2n0wbedrajybbjs0'
      : 'pri_01jkr3cdpw262r2ph8pgwqhn69',
    defaultMonthlyPrice: '£49.00',
    annualID: usePaddleSandboxAccount
      ? 'pri_01jjp6hfqgehsx62wxx4q63wpk'
      : 'pri_01jjw3p7vf97sykn67w8z4mgh0',
    annualNoTrialID: usePaddleSandboxAccount
      ? 'pri_01jkdcrr4z5gy9fntfve4t00nv'
      : 'pri_01jkr3d9j2wdwkv4fmw8xn89s0',
    defaultAnnualPrice: '£490.00',
    maxUsers: maxBusinessUsers,
    maxWorkspaces: maxBusinessWorkspaces,
    maxConnectors: 1,
    linksLimit: 1000,
    whatYouCanDo: [
      {
        id: 'business-branded-links',
        text: 'Branded short links',
        tooltip:
          'Create memorable signposting by making shortened links people will remember and trust, increasing clickthrough rate by 39% compared with generic URLs. Branded domains are available at an additional cost',
      },
      {
        id: 'business-app-links',
        text: 'Create app deep links',
        tooltip:
          'Route mobile users to specific in-app screens and/or app stores. Track all marketing to your apps with consistent parameters.',
      },
      {
        id: 'business-journey-reports',
        text: 'Journey reports',
        tooltip: '',
      },
      {
        id: 'business-create-workspaces',
        text: 'Create workspaces',
        tooltip:
          'Create new workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and intergrations.',
      },
      {
        id: 'business-taxonomy-workshop',
        text: 'Taxonomy workshop',
        tooltip: `Onboarding workshop to make sure you and your team get the most of ${brandName}.`,
      },
      {
        id: 'business-sla-support',
        text: '48h SLA support',
        tooltip:
          'Get campaign problems solved with excellent email support, average response times of under 30 minutes and resolutions within 48 hours.',
      },
    ],
    whatYouGet: [
      {
        id: 'business-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'business-link-limit',
        text: '1k monitored links (per user)',
      },
      {
        id: 'business-workspace-limit',
        text: `${maxBusinessWorkspaces} workspaces`,
        tooltip:
          'Create multiple workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and integrations.',
      },
      {
        id: 'business-connectors',
        text: 'Any 1 connector',
        tooltip: 'Additional connectors are available at an additional cost.',
      },
      {
        id: 'business-user-limit',
        text: `1-${maxBusinessUsers} users`,
        tooltip:
          'Let other users self-serve and collaborate on consistent links, dropdowns, reports and insights.',
      },
    ],
  },
  enterprise: {
    title: 'Enterprise',
    icon: ThreeArrows,
    tagline:
      'Automate all UTM pain away with enhanced integrations, security, training and support.',
    monthlyID: usePaddleSandboxAccount
      ? 'pri_01jk63572ck9260wwh4k95j75r'
      : 'pri_01jk88xwc8mdxrxxrv0pyt1f60',
    defaultMonthlyPrice: '£69.00',
    annualID: usePaddleSandboxAccount
      ? 'pri_01jk635zgkrf41c0tx291ady2t'
      : 'pri_01jk88ygfkk4d4a0snfccnzcc0',
    defaultAnnualPrice: '£690.00',
    maxUsers: Infinity,
    maxWorkspaces: 3,
    // No limit to connectors for now. Subscription tier info says maximum of 2
    maxConnectors: integrationsData.length,
    linksLimit: Infinity,
    whatYouCanDo: [
      {
        id: 'enterprise-connectors',
        text: 'Multiple connectors',
        tooltip: '',
      },
      {
        id: 'enterprise-open-api',
        text: 'Open API',
        tooltip:
          'Retrieve the data you need, in the format you want. Automate your reports and processes with our API.',
      },
      {
        id: 'enterprise-sso',
        text: 'Single Sign-On',
        tooltip:
          'Increase security, save time and reduce the number of passwords you need to remember. Login with your Microsoft, Google or Okta account.',
      },
      {
        id: 'enterprise-custom-onboarding',
        text: 'Custom onboarding',
        tooltip:
          'Get consitent links and reports for everyone, with custom onboarding and unlimited training with a real person online, in any time zone.',
      },
      {
        id: 'enterprise-invoicing',
        text: 'Annual invoicing',
        tooltip: '',
      },
      {
        id: 'enterprise-sla-support',
        text: '2h SLA support',
        tooltip:
          'Get campaign problems solved with excellent email support, average response times of under 30 minutes and resolutions within 2 hours.',
      },
    ],
    whatYouGet: [
      {
        id: 'enterprise-click-limit',
        text: 'Unlimited clicks',
      },
      {
        id: 'enterprise-link-limit',
        text: 'Unlimited links',
      },
      {
        id: 'enterprise-workspace-limit',
        text: '3 workspaces',
        tooltip: 'Additional workspaces are available at an additional cost.',
      },
      {
        id: 'enterprise-connectors',
        text: 'Any 2 connectors',
        tooltip: 'Additional connectors are available at an additional cost.',
      },
      {
        id: 'enterprise-user-limit',
        text: 'Multiples of 10 users',
      },
    ],
  },
}

const useSubscriptionLevel = () => {
  const { isDemoAccount } = useReactiveVar(currentUserDetails)

  const { data: companyData, loading: loadingCompanyDetails } = useQuery(
    getCompanyDetails,
  )

  const [
    getPaddleDetails,
    { data: paddleData, loading: loadingPaddleData },
  ] = useLazyQuery(getPaddleSubscriptionDetails)
  const [
    fetchMicrosoftSubscriptionData,
    { data: microsoftSubscriptionData, loading: loadingMicrosoftData },
  ] = useLazyQuery(getMicrosoftSubscriptionData)

  const [fetchLinksLimit] = useLazyQuery(getSubscriptionLinkCount)

  // Fetch relevant subscription details
  useEffect(() => {
    if (!companyData) return

    if (companyData.currentCompany.paymentProcessor === 'microsoft') {
      fetchMicrosoftSubscriptionData()

      return
    }

    if (
      isDemoAccount ||
      companyData.currentCompany.subscriptionLevel === 'enterprise'
    ) {
      // Should not fetch any details in this case. Fetch should be triggered in specific cases
      return
    }

    // Only fetch full Paddle details if paddleSubscriptionStatus is found
    if (companyData.currentCompany.paddleSubscriptionStatus) {
      getPaddleDetails()
    }
  }, [companyData, isDemoAccount])

  const subscriptionDetails = useMemo(() => {
    const {
      subscriptionLevel,
      userCount,
      namedUserLimit,
      userLimit,
      linkMonitorLimit,
      connectionLimit,
      workspaceLimit,
      paymentProcessor,
    } = companyData?.currentCompany || {}

    const initialSubscriptionLevel =
      microsoftSubscriptionData?.currentCompany.microsoftSubscriptionData
        ?.planId || subscriptionLevel

    const _subscriptionCategory = getSubscriptionCategory(
      initialSubscriptionLevel,
    )

    let _subscriptionBillingPeriod: BillingPeriods | null = null

    if (
      initialSubscriptionLevel &&
      Object.keys(paddleSubscriptionIDs).includes(initialSubscriptionLevel)
    ) {
      _subscriptionBillingPeriod =
        paddleSubscriptionIDs[initialSubscriptionLevel].billingPeriod
    }

    const isEnterprise = isDemoAccount || _subscriptionCategory === 'enterprise'
    const isPaddle = !isDemoAccount && paymentProcessor === 'paddle'
    const isMicrosoftMarketplace =
      !isDemoAccount && paymentProcessor === 'microsoft'

    let currentLicenceLimit = userCount || null

    if (isPaddle) {
      currentLicenceLimit = paddleData?.currentCompany
        ?.paddleSubscriptionQuantity
        ? parseInt(paddleData?.currentCompany.paddleSubscriptionQuantity, 10)
        : null
    } else if (isMicrosoftMarketplace) {
      currentLicenceLimit =
        microsoftSubscriptionData?.currentCompany.microsoftSubscriptionData
          ?.quantity || null
    }

    const featureLimits = {
      workspaces: subscriptionLevelDetails[_subscriptionCategory].maxWorkspaces,
      users: subscriptionLevelDetails[_subscriptionCategory].maxUsers,
      connectors: subscriptionLevelDetails[_subscriptionCategory].maxConnectors,
      links:
        subscriptionLevelDetails[_subscriptionCategory].linksLimit *
        (currentLicenceLimit || 1),
    }

    // Enterprise accounts can have individually managed limits
    if (isEnterprise && !isMicrosoftMarketplace) {
      currentLicenceLimit = namedUserLimit || userLimit || featureLimits.users

      featureLimits.workspaces = workspaceLimit || featureLimits.workspaces
      featureLimits.users = currentLicenceLimit
      featureLimits.connectors = connectionLimit || featureLimits.connectors
      featureLimits.links = linkMonitorLimit || featureLimits.links
    }

    return {
      checkedSubscriptionLevel: !!initialSubscriptionLevel,
      subscriptionID: isDemoAccount ? 'enterprise' : initialSubscriptionLevel,
      subscriptionCategory: _subscriptionCategory as SubscriptionLevels,
      subscriptionBillingPeriod: _subscriptionBillingPeriod,
      isEnterprise,
      isBusiness: !isDemoAccount && _subscriptionCategory.includes('business'),
      isStartup: !isDemoAccount && _subscriptionCategory.includes('startup'),
      isPaddle,
      paddleSubscriptionStatus:
        (companyData?.currentCompany
          .paddleSubscriptionStatus as PaddleSubscriptionStatuses) || null,
      /** Microsoft accounts have same permissions as startup/business tiers, but should not see Upgrade pages */
      isMicrosoftMarketplace,
      /** The amount of users the account is allowed. For enterprise, this is the same as the featureLimit.users value */
      currentLicenceLimit,
      /** Feature limits are static for Startup and Business but can be increased for Enterprise */
      featureLimits,
    }
  }, [companyData, isDemoAccount, paddleData, microsoftSubscriptionData])

  const getCompanyLinksCount = useCallback(async () => {
    const { data } = await fetchLinksLimit({ fetchPolicy: 'network-only' })

    return data?.currentCompany.codeCount || 0
  }, [])

  return {
    loadingSubscriptionInfo:
      loadingCompanyDetails || loadingPaddleData || loadingMicrosoftData,
    ...subscriptionDetails,
    paddleData,
    getPaddleDetails,
    microsoftSubscriptionData,
    getCompanyLinksCount,
  }
}

export default useSubscriptionLevel
