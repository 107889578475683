import React, { forwardRef, TableHTMLAttributes } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import Loader from './loader'
import styles from '../styles/table.module.scss'

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  className?: string
  children: React.ReactNode
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, children }, ref) => {
    return (
      <table className={classNames(styles.table, className)} ref={ref}>
        {children}
      </table>
    )
  },
)

interface TableLoadingRowsProps {
  colCount?: number
}

export const TableLoadingRows = ({ colCount = 4 }: TableLoadingRowsProps) => {
  const placeholderRow = (key: string) => {
    return (
      <tr key={key} className={styles.placeholderRow}>
        {Array.from(new Array(colCount), (col, index) => index).map((item) => {
          return (
            <td key={`loadingCol-${item}`}>
              <Loader className={styles.loadingText} />
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <>
      {[0, 1, 2, 3, 4].map((item) => {
        return placeholderRow(`loadingRow-${item}`)
      })}
    </>
  )
}

export default Table
