import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import Button, { FakeButton } from './button'
import { FakeButtonDropdown } from './button-dropdown'
import Link from './link'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText } from './typography'
import { linkOrCode } from '../api/apollo/variables'
import { dynamicTextValues } from '../core/constants'
import styles from '../styles/track-create-clone-and-edit.module.scss'

const TrackCreateCloneAndEdit = () => {
  const linkCopy = useReactiveVar(linkOrCode)

  const history = useHistory()

  return (
    <OuterBox className={styles.outerBox}>
      <InnerBox className={classNames(styles.innerBox, styles.innerBoxContent)}>
        <p className={styles.intro}>
          <span>
            Clone existing {linkCopy} and edit parameters to make new links
            quickly.
          </span>
        </p>
        <div className={styles.cloneContent}>
          <ol>
            <li>
              Go to{' '}
              <BoxedText>
                <Link href="/track/view-links">
                  {dynamicTextValues.trackViewPage[linkCopy]}
                </Link>
              </BoxedText>
            </li>
            <li>
              In the left-hand column, tick the box next to the {linkCopy}(s)
              you want to clone
            </li>
            <li>
              Click on the{' '}
              <FakeButtonDropdown inlineText buttonText="Actions" /> button and
              select{' '}
              <FakeButton
                variant="plainBox"
                inlineText
                style={{ fontWeight: 600 }}
              >
                Clone and edit
              </FakeButton>
            </li>
            <li>Edit the parameters you want to change</li>
            <li>
              Review and click{' '}
              <FakeButton inlineText>Create {linkCopy}s</FakeButton>
            </li>
          </ol>
          <img
            alt="Clone &amp; Edit single codes"
            src="/clone-edit-multiple.gif"
          />
          <Button
            onPress={() => history.push('/track/view-links')}
            className={styles.cloneButton}
          >
            Clone existing {linkCopy}s
          </Button>
        </div>
      </InnerBox>
    </OuterBox>
  )
}

export default TrackCreateCloneAndEdit
