import { makeVar } from '@apollo/client'

import { getToken } from '../../helpers'

// Used to set the page seen when a user logs in
// If they tried to access a specific page in-account
export const pageRedirect = makeVar({
  pathname: '',
  search: '',
})

export type LastLoginMethods =
  | 'inviteOpen'
  | 'microsoft'
  | 'google'
  | 'okta'
  | 'password'
  | ''

interface Policy {
  lastMethod: LastLoginMethods
  domainExists: boolean
  notFound: boolean
}

interface LoggedInState {
  authenticated: boolean
  checked: boolean
  runningDebug: boolean
  token: string
  isNewlyCreated?: boolean
  policy: Policy | null
  /** Used to stop isLoggedIn running on Create Account page */
  blockLoginCheck?: boolean
  /** Used to ensure Paddle details are up to date on first page load after signup */
  paddleSubscriptionID?: string
}

export const loggedInState = makeVar<LoggedInState>({
  authenticated: false,
  checked: false,
  runningDebug: false,
  token: getToken(),
  policy: null,
})

/** Should be set on auth responses to show error messages on login/register/create account forms */
export const authError = makeVar('')

/* ********************************************************************** */
// TODO: Clean up auth variables
export interface LoginState {
  email: string
  password: string
  onFail: {
    message: string
    attempts: number
  }
}

export const loginForm = makeVar<LoginState>({
  email: '',
  password: '',
  onFail: {
    message: '',
    attempts: 0,
  },
})

export const registerForm = makeVar({
  success: false,
  onFail: {
    message: '',
    attempts: 0,
  },
})

interface PaddleData {
  code?: number
  compID: string
  test: boolean
  token: string
  userID: string
}

interface OnboardingState {
  onError: string
  createdBillingUser: boolean
  paddleData: PaddleData | {}
}

export const onboardingState = makeVar<OnboardingState>({
  onError: '',
  createdBillingUser: false,
  paddleData: {},
})

interface PasswordState {
  forgotPassword: {
    requested: boolean
    success: boolean
  }
  resetPassword: {
    requested: boolean
    success: boolean
  }
}

export const passwordState = makeVar<PasswordState>({
  forgotPassword: {
    requested: false,
    success: false,
  },
  resetPassword: {
    requested: false,
    success: false,
  },
})
/* ********************************************************************** */

// Set in router during authentication
// Used for fast querying/referencing
interface CurrentUser {
  userID: string
  userEmail: string
  userFirstName: string
  userLastName: string
  userPermission: PermissionLevel | ''
  workspaceID: string
  workspaceName: string
  workspaceHomepage?: string
  trackAvailable: boolean
  reportAvailable: boolean
  explainAvailable: boolean
  planAvailable?: boolean
  connectAvailable: boolean
  companyID: string
  companyName: string
  isDemoAccount: boolean
  /** Used for whitelabel accounts */
  whiteLabelAdminDomain?: string | null
}

export const currentUserDetails = makeVar<CurrentUser>({
  userID: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userPermission: '',
  workspaceID: '',
  workspaceName: '',
  trackAvailable: true,
  reportAvailable: true,
  explainAvailable: false,
  planAvailable: true,
  connectAvailable: true,
  companyID: '',
  companyName: '',
  isDemoAccount: false,
})

/** Used to prevent Windows Clarity value from being set multiple times */
export const hasCreatedLinksReactive = makeVar<boolean | null>(null)

/** Set based on 'ALLOW_APP_LINKS' validation rule via router.tsx */
export const showAppDeepLinkContent = makeVar(true)

/** Sets if copy should refer to 'links' or 'codes'. Set based on the 'SHOW_LANDING_PAGE' validationCheck */
export const linkOrCode = makeVar<'link' | 'code'>('link')

export interface RecentlyValidatedUrl {
  statusCode?: number
  intensiveStatusCode?: number
  badUrl: boolean
  clickedCookieConsent?: boolean
  noAnalyticsTag?: boolean
  redirectedLandingPage?: boolean
  slowLandingPage?: boolean
  validatorReturned?: boolean
}

export interface RecentlyValidatedUrls {
  [url: string]: RecentlyValidatedUrl
}

export const recentlyValidatedUrls = makeVar<RecentlyValidatedUrls>({})

export interface DataSourceDetails {
  connectionSource: 'not-connected' | 'adobe' | 'google'
  requiresReconnect?: boolean
}

/** Used to see which data source the workspace is connected to (if any) and if it needs reconnecting. Set on router.tsx */
export const dataSourceReactive = makeVar<DataSourceDetails | null>(null)

export const selectedShortLinkDomainReactive = makeVar('')
export const selectedAppLinkDomainReactive = makeVar('default')

export const activeConnectors = makeVar<string[]>([])
