import React, { useState, useEffect } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'

import {
  EnterpriseExpiredLicenceModal,
  EnterpriseExpiringLicenceModal,
  EnterpriseSuspendedLicenceModal,
} from './feature-blocker-modals'
import Footer from './footer'
import Navigation from './navigation'
import OnboardingProgressWidget from './onboarding-widget'
import {
  PaddlePastDuePaymentBlocker,
  PaddlePaymentBlocker,
} from './paddle-checkout'
import SSOModal from './sso-modal'
import { currentUserDetails } from '../api/apollo/variables'
import { getEnterpriseLicenceStatus } from '../api/graphql/company-client'
import useOnboarding from '../hooks/useOnboarding'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import { getLocalItem } from '../helpers/local-client'
import styles from '../styles/site-wrapper.module.scss'

interface SiteWrapperProps {
  className?: string
  justLogo?: boolean
  noFooter?: boolean
  /** Prevents payment blocker from showing. Usful on NonPrivate routes */
  alwaysAllow?: boolean
  children?: React.ReactNode
}

const SiteWrapper = ({
  className,
  justLogo,
  noFooter,
  alwaysAllow,
  children,
}: SiteWrapperProps) => {
  const { workspaceID, companyID } = useReactiveVar(currentUserDetails)

  const [checkEnterpriseLicenceStatus] = useLazyQuery(
    getEnterpriseLicenceStatus,
  )

  const [showOnboardingWidget, setShowOnboardingWidget] = useState(false)
  // Uses variable set on Paddle payment success to prevent payment blocker showing for newly paid accounts
  const [forceAllowAccess, setForceAllowAccess] = useState(false)
  const [enterpriseBlockerModal, setEnterpriseBlockerModal] = useState<
    false | 'expiring' | 'expired' | 'suspended'
  >(false)

  // Check for presence of sessionStorage variable indicating account was just paid for. If found, payment blockers should not be shown
  useEffect(() => {
    if (!companyID) return

    const paddlePaymentID = window.sessionStorage.getItem('paddle_payment_id')

    if (paddlePaymentID === companyID) {
      setForceAllowAccess(true)
    }
  }, [companyID])

  const { mergedOnboardingSections } = useOnboarding()

  const {
    isEnterprise,
    isMicrosoftMarketplace,
    paddleSubscriptionStatus,
  } = useSubscriptionLevel()

  const widgetMatch = useRouteMatch(['/welcome'])

  useEffect(() => {
    if (workspaceID) {
      let widgetHidden = getLocalItem('user-session-data')
      widgetHidden = widgetHidden === null ? false : widgetHidden[workspaceID]
      widgetHidden = widgetHidden ? widgetHidden.hideOnboardingWidget : false
      setShowOnboardingWidget(!widgetHidden)
    }
  }, [workspaceID])

  // Show blocker modals for Enterprise accounts based on support settings
  useEffect(() => {
    if (!isEnterprise) return

    const runEnterpriseStatusCheck = async () => {
      const { data } = await checkEnterpriseLicenceStatus()

      if (data) {
        const {
          aboutToExpireCheckbox,
          hasExpiredCheckbox,
          isSuspendedCheckbox,
        } = data.currentCompany

        if (isSuspendedCheckbox) {
          setEnterpriseBlockerModal('suspended')
        } else if (hasExpiredCheckbox) {
          setEnterpriseBlockerModal('expired')
        } else if (aboutToExpireCheckbox) {
          setEnterpriseBlockerModal('expiring')
        }
      }
    }

    runEnterpriseStatusCheck()
  }, [isEnterprise])

  // Every page should be blocked if payment is past_due
  // https://developer.paddle.com/build/lifecycle/subscription-renewal-dunning
  const showPastDuePaymentBlocker =
    !forceAllowAccess &&
    !alwaysAllow &&
    !isEnterprise &&
    !isMicrosoftMarketplace &&
    paddleSubscriptionStatus === 'past_due'

  // Every page should be blocked if payment has not been made
  const showPaymentBlocker =
    !showPastDuePaymentBlocker &&
    !forceAllowAccess &&
    !alwaysAllow &&
    !isEnterprise &&
    !isMicrosoftMarketplace &&
    (['deleted', 'canceled'].includes(paddleSubscriptionStatus) ||
      !paddleSubscriptionStatus)

  return (
    <>
      <div
        className={classNames(styles.site, {
          [styles.justLogoSite]: justLogo,
        })}
      >
        <Navigation justLogo={justLogo} />
        <main className={className}>
          <>
            {children}
            {workspaceID &&
              showOnboardingWidget &&
              !widgetMatch &&
              mergedOnboardingSections &&
              mergedOnboardingSections.length > 0 && (
                <OnboardingProgressWidget
                  currentAccount={workspaceID}
                  setShowOnboardingWidget={setShowOnboardingWidget}
                />
              )}
          </>
        </main>
        {!noFooter && <Footer />}
      </div>
      <SSOModal />
      {enterpriseBlockerModal === 'expiring' && (
        <EnterpriseExpiringLicenceModal />
      )}
      {enterpriseBlockerModal === 'expired' && (
        <EnterpriseExpiredLicenceModal />
      )}
      {enterpriseBlockerModal === 'suspended' && (
        <EnterpriseSuspendedLicenceModal />
      )}
      {showPastDuePaymentBlocker && <PaddlePastDuePaymentBlocker />}
      {showPaymentBlocker && <PaddlePaymentBlocker />}
    </>
  )
}

export default SiteWrapper
