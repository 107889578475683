import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import styles from '../styles/display-error.module.scss'

export interface DisplayErrorProps {
  className?: string
  attempts: number
  children: string
}

const DisplayError = React.forwardRef<HTMLElement, DisplayErrorProps>(
  ({ className, attempts, children }, fwdRef) => {
    const [shakeActive, setShakeActive] = useState(false)

    useEffect(() => {
      if (attempts <= 1) return

      setShakeActive(true)

      setTimeout(() => {
        setShakeActive(false)
      }, 500)
    }, [attempts])

    if (!children || attempts === 0) return null

    return (
      <section
        ref={fwdRef}
        data-testid="error-section"
        className={classNames(className, styles.errorContainer, {
          [styles.shake]: shakeActive,
        })}
      >
        <div className={styles.inner}>
          <ReactMarkdown plugins={[gfm]} linkTarget="_blank">
            {children}
          </ReactMarkdown>
        </div>
      </section>
    )
  },
)

export default DisplayError
