import { getItemByKeyValue } from '.'
import { GeneratorParameterValues } from './track-create'
import { getAnchorFromString } from './track-module'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import { CodeType } from '../api/types'
import { AvailableDomain } from '../hooks/useCustomLinks'
import {
  DimensionFilter,
  GetCampaignCodeGeneratorQuery,
  GetMinCodesQuickQuery,
  GetStoredCodesStatsQuickQuery,
  GetUrlValidationStatusQuery,
  SelectField,
} from '../__gql-types__/graphql'

/** Minimal dimensions that can be searched against in data fetches. Extended with the workspace's generator parameters */
export const minimumSearchTypeList = [
  { name: 'All', value: 'any' },
  {
    name: 'Created',
    value: 'createdTime',
  },
  {
    name: 'Created by',
    value: 'createdBy',
  },
  {
    name: 'Short link',
    value: 'shortLink',
  },
  {
    name: 'Destination with parameters',
    value: 'fullLink',
  },
  {
    name: 'Status',
    value: 'status',
  },
]

export interface TrackViewFilterProps {
  /** If codeIDList is set, other filter variables are ignored. Data is fetched for the list only */
  codeIDList?: string[]
  activePage: number
  rowsPerPage: number
  sortBy: string
  sortDirection?: 'ASC' | 'DESC'
  /** Overrides sortField in query variables, which usually uses sortBy */
  bidirectionalSortKey?: string
  /** Forces fetch of stats/metrics before links. Applies to status column and any column with 'tableMetric' in the ID */
  isMetric?: boolean
  searchType: string
  searchTerm: string
  filterByCurrentUser?: boolean
  // filterByEdited?: boolean
}

export interface RefetchCodesQueryVars {
  codeIDList?: string[]
  dimensionFilter?: DimensionFilter
  filterByCurrentUser?: boolean
  // filterByEdited?: boolean
  limit?: number
  offset?: number
  orderBy?: {
    sortDirection: 'ASC' | 'DESC'
    sortField: string
  }
}

/** Converts component's filter state to shape of variables used in data fetches */
export const filterToMinCodesVars = ({
  codeIDList,
  activePage,
  rowsPerPage,
  sortBy,
  bidirectionalSortKey,
  sortDirection,
  searchType,
  searchTerm,
  // filterByEdited = false,
  filterByCurrentUser,
}: TrackViewFilterProps): RefetchCodesQueryVars => {
  return {
    codeIDList,
    dimensionFilter: {
      dimensionName: searchTerm,
      dimensionParameterID: searchType,
      dimensionOptions: [],
    },
    filterByCurrentUser,
    // filterByEdited,
    limit: rowsPerPage,
    offset: (activePage - 1) * rowsPerPage,
    orderBy: {
      sortDirection: sortDirection || 'DESC',
      sortField: bidirectionalSortKey || sortBy,
    },
  }
}

/** Compares analytics & shortLink metrics to find earliest date to fetch data from */
export const getEarliestDataDate = (
  codesMetricData?: GetStoredCodesStatsQuickQuery,
) => {
  let earliestDataDate: number | null = null

  const earliestClickDate = codesMetricData
    ? codesMetricData.track.storedCodeStatsQuick.earliestClickDate
    : null

  const earliestMetricDate = codesMetricData
    ? codesMetricData.track.storedCodeStatsQuick.earliestMetricDate
    : null

  if (codesMetricData) {
    const clickDate =
      earliestClickDate && earliestClickDate !== 'None'
        ? Date.parse(earliestClickDate)
        : null
    const metricDate =
      earliestMetricDate && earliestMetricDate !== 'None'
        ? Date.parse(earliestMetricDate)
        : null

    if (clickDate && metricDate) {
      earliestDataDate = Math.min(clickDate, metricDate)
    } else if (clickDate) {
      earliestDataDate = clickDate
    } else if (metricDate) {
      earliestDataDate = metricDate
    }
  }

  return earliestDataDate
}

export interface MinimalParamDetails {
  fieldID: string
  fieldName: string
  helpText: string
  hideInTrackView?: boolean | null
  selectFields?: SelectField[] | null
}

/** Removes generator parameters that are blocked by 'onlyShowIf' rule based on workspace */
export const getWorkspaceParams = (
  workspaceID = '',
  generatorData?: GetCampaignCodeGeneratorQuery,
): MinimalParamDetails[] => {
  if (!generatorData) return []

  // Do not include fields blocked for the current workspace
  return generatorData.campaignCodeGenerator.paramDefs
    .filter(({ parameterDependsOn }) => {
      if (
        parameterDependsOn &&
        parameterDependsOn.parentFieldID === 'account' &&
        parameterDependsOn.parentOptionIDs.indexOf(workspaceID) === -1
      ) {
        return false
      }
      return true
    })
    .map(({ fieldID, fieldName, helpText, hideInTrackView, selectFields }) => ({
      fieldID,
      fieldName,
      helpText,
      hideInTrackView,
      selectFields,
    }))
}

export const buildSearchTypeList = (params: MinimalParamDetails[]) => {
  const searchTypeList = [...minimumSearchTypeList]

  searchTypeList.push(
    ...params.map(({ fieldID, fieldName }) => ({
      name: fieldName,
      value: fieldID,
    })),
  )

  return searchTypeList
}

export interface MetricDropdownItem {
  metricID: string
  displayName: string
  helpText?: string
}

export interface TableLinkWithData {
  linkID: string
  deepLinkServiceID: string | null
  createdTime: string
  author: string
  versionNumber: number
  hasNoLandingPage?: boolean
  fullLink: string
  shortLink: string | null
  params: {
    paramID: string
    paramName: string
    paramValue: string
    isHidden: boolean
  }[]
  uplifterIDValue?: string
  status?: CodeType | null
  linkValidation?: Pick<
    RecentlyValidatedUrl,
    | 'badUrl'
    | 'noAnalyticsTag'
    | 'redirectedLandingPage'
    | 'slowLandingPage'
    | 'statusCode'
  > | null
  metricData: {
    metricID: string
    metricName: string
    metricValue: number
    units: string
  }[]
}

interface BuildTableLinksProps {
  limit?: number
  offset?: number
  metricFirst?: boolean
  params: MinimalParamDetails[]
  minCodes?: GetMinCodesQuickQuery['track']['minCodesQuick']
  codeStats?: GetStoredCodesStatsQuickQuery['track']['storedCodeStatsQuick']
  codeValidationData?: GetUrlValidationStatusQuery['track']['trackValidationResults']
  masterPrefix?: string
  uplifterIdPrefix?: string
  availableAppLinkDomains: AvailableDomain[]
}

export const buildTableLinks = ({
  limit = 10,
  offset = 0,
  metricFirst = false,
  params,
  minCodes,
  codeStats,
  codeValidationData,
  masterPrefix = '?',
  uplifterIdPrefix,
  availableAppLinkDomains,
}: BuildTableLinksProps) => {
  const paramIDs = params.map(({ fieldID }) => fieldID)

  // Build table rows
  // Should have same number of params and metrics as header
  const tableRows: TableLinkWithData[] = []

  if (minCodes) {
    const {
      codeID,
      createdTime,
      author,
      versionNumber,
      fullLink,
      shortLink,
      minGenDef,
      codeDef,
    } = minCodes

    // If metrics were fetched first, the code data to use will be a list of IDs from the metrics
    const codeIDsToShow = metricFirst
      ? codeID
      : codeID.slice(offset, offset + limit)

    codeIDsToShow.forEach((linkID, index) => {
      const codeIndex = metricFirst ? index : index + offset
      const metricDataIndex = metricFirst ? index + offset : index

      let deepLinkServiceID: string | null = null

      if (shortLink) {
        deepLinkServiceID =
          availableAppLinkDomains.find(({ domainValue }) =>
            shortLink[codeIndex].includes(domainValue),
          )?.domainID || null
      }

      const linkData: TableLinkWithData = {
        linkID,
        deepLinkServiceID,
        createdTime: createdTime[codeIndex],
        author: author[codeIndex],
        versionNumber: versionNumber[codeIndex],
        fullLink: fullLink[codeIndex],
        hasNoLandingPage: fullLink[codeIndex].indexOf(masterPrefix) === 0,
        shortLink: shortLink ? shortLink[codeIndex] : null,
        params: minGenDef.map(({ paramID, paramName }, paramIndex) => {
          const hideParamFromTable = params.find((p) => p.fieldID === paramID)
            ?.hideInTrackView

          return {
            paramID,
            paramName,
            paramValue: codeDef[codeIndex][paramIndex],
            // Hide params not shown for the workspace
            isHidden: hideParamFromTable || !paramIDs.includes(paramID),
          }
        }),
        metricData: [],
      }

      if (uplifterIdPrefix) {
        const splitVals = fullLink[codeIndex].split(uplifterIdPrefix)

        // ID prefix exists in link
        if (splitVals.length >= 2) {
          // Only thing after ID will be the anchor
          // Remove anchor from string
          const useAnchor = getAnchorFromString(fullLink[codeIndex])

          linkData.uplifterIDValue = splitVals[splitVals.length - 1].replace(
            useAnchor,
            '',
          )
        }
      }

      // Cached validation data is always a list of IDs - use the lower index
      if (codeValidationData) {
        const fullValidation =
          codeValidationData[Math.min(codeIndex, metricDataIndex)]
        linkData.linkValidation = fullValidation
          ? {
              statusCode: fullValidation.statusCode,
              badUrl: fullValidation.badUrl,
              noAnalyticsTag: fullValidation.noAnalyticsTag,
              redirectedLandingPage: fullValidation.redirectedLandingPage,
              slowLandingPage: fullValidation.slowLandingPage,
            }
          : null
      }

      if (codeStats) {
        linkData.status = codeStats.status
          ? (codeStats.status[metricDataIndex] as CodeType)
          : null

        linkData.metricData = codeStats.metricValues.map(
          ({ metricID, displayName, totalMetricValues, units }) => ({
            metricID,
            metricName: displayName,
            metricValue: totalMetricValues[metricDataIndex],
            units,
          }),
        )
      }

      tableRows.push(linkData)
    })
  }

  return tableRows
}

export const cloneAndEditBuildForm = (
  links: TableLinkWithData[],
  selectedLinks: string[],
  masterPrefix = '?',
  paramDefs: MinimalParamDetails[],
) => {
  const result: {
    urls: string[]
    generatorParameterValues: GeneratorParameterValues
  } = {
    urls: [],
    generatorParameterValues: {},
  }

  const fullSelectedLinks = links.filter(({ linkID }) =>
    selectedLinks.includes(linkID),
  )

  fullSelectedLinks.forEach(({ fullLink, params }) => {
    const linkAnchor = getAnchorFromString(fullLink)

    const linkWithoutParams = fullLink.split(masterPrefix)

    const linkToUse = `${
      linkWithoutParams.length > 0 ? linkWithoutParams[0] : ''
    }${linkAnchor}`

    if (result.urls.indexOf(linkToUse) === -1) {
      result.urls.push(linkToUse)
    }

    params.forEach(({ paramID, paramValue }) => {
      if (!paramValue) return

      if (!result.generatorParameterValues[paramID]) {
        result.generatorParameterValues[paramID] = []
      }

      const paramDefValue = getItemByKeyValue(paramDefs, 'fieldID', paramID)

      let valueExists = false
      let useValue = paramValue

      if (paramDefValue !== -1) {
        valueExists = true
        const { selectFields } = paramDefValue

        if (selectFields) {
          const selectFieldValueFound = getItemByKeyValue(
            selectFields,
            'optionName',
            useValue,
          )

          valueExists = selectFieldValueFound !== -1

          if (!valueExists) return

          useValue = selectFieldValueFound.optionID
        }
      }

      if (result.generatorParameterValues[paramID].indexOf(useValue) === -1) {
        result.generatorParameterValues[paramID].push(useValue)
      }
    })
  })

  return result
}
