import React, { useState, useCallback, useMemo, useEffect } from 'react'
import ReactSwitch, { ReactSwitchProps } from 'react-switch'
import { ActionMeta, InputProps, MultiValue, components } from 'react-select'
import classNames from 'classnames'
import _ from 'lodash'

import { Label } from './input'
import SelectBox, { PillColors } from './select-box'
import Tooltip from './tooltip'
import { ErrorMessage, SuccessText } from './typography'
import styles from '../styles/input-v2.module.scss'
import { Preloader } from './loader'
import Button from './button'

export const Toggle = ({
  checkedIcon,
  uncheckedIcon,
  ...props
}: ReactSwitchProps) => {
  return (
    <ReactSwitch
      onColor="#e61969"
      offColor="#718096"
      width={65}
      height={30}
      handleDiameter={20}
      {...props}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
    />
  )
}

export interface RadioButtonProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: React.ReactNode
  labelClassName?: string
  subLabel?: string
}

export const RadioButton = ({
  label,
  labelClassName,
  subLabel,
  ...props
}: RadioButtonProps) => {
  const { id, name, className } = props

  return (
    <Label
      id={id || name}
      className={classNames(labelClassName, styles.radioLabel)}
      optional={subLabel}
    >
      <input
        type="radio"
        id={id}
        name={name}
        className={classNames(className, styles.radioInput)}
        {...props}
      />
      <span className={classNames({ [styles.disabled]: props.disabled })}>
        {label}
      </span>
    </Label>
  )
}

interface RadioGroupProps {
  className?: string
  /** Used to group radio buttons */
  groupName: string
  required?: boolean
  horizontal?: boolean
  options: Omit<RadioButtonProps, 'checked' | 'onChange' | 'labelClassName'>[]
  optionsClassName?: string
  checkedClassName?: string
  selectedValue?: string
  onChange: (value: string) => void
}

export const RadioGroup = ({
  className,
  groupName,
  required,
  horizontal,
  options,
  optionsClassName,
  checkedClassName,
  selectedValue,
  onChange,
}: RadioGroupProps) => {
  return (
    <div
      className={classNames(className, styles.radioPanel, {
        [styles.horizontal]: horizontal,
      })}
    >
      {options.map(({ id, value, ...optionProps }) => (
        <RadioButton
          key={value as string}
          id={id || (value as string)}
          name={groupName}
          required={required}
          labelClassName={classNames(
            optionsClassName,
            checkedClassName
              ? {
                  [checkedClassName]:
                    typeof selectedValue === 'string' &&
                    selectedValue === value,
                }
              : undefined,
          )}
          checked={typeof selectedValue === 'string' && selectedValue === value}
          onChange={(e) => onChange(e.target.id)}
          {...optionProps}
        />
      ))}
    </div>
  )
}

interface InputLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  tooltip?: React.ReactElement | string
  tooltipClickable?: boolean
}

/** Greyed out text that sits above the input field */
export const InputLabel = ({
  tooltip,
  tooltipClickable,
  htmlFor,
  className,
  children,
  ...labelProps
}: InputLabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={classNames(className, styles.inputLabel)}
      {...labelProps}
    >
      {tooltip ? (
        <Tooltip
          id={`${htmlFor}-tooltip`}
          useIcon
          tooltipPosition="right"
          tooltipMessage={tooltip}
          clickable={tooltipClickable}
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </label>
  )
}

interface ChangeInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  initialValue: string
  /** Allows 'onConfirm' button to be active if current value is '' */
  allowEmpty?: boolean
  beforeChange?: (value: string) => string
  validateChange?: (value: string) => boolean
  onConfirm: (value: string) => Promise<void>
  prefix?: string
  changeText?: string
  successText?: string
  errorText?: string
}

export const ChangeInput = ({
  initialValue,
  allowEmpty,
  beforeChange,
  validateChange,
  onConfirm,
  prefix,
  changeText = 'Change',
  successText = 'Updated',
  errorText = 'Error',
  ...inputProps
}: ChangeInputProps) => {
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState(changeText)

  // Update value whenever initialValue changes
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div className={styles.changeInputWrapper}>
      {prefix && <span className={styles.changeInputPrefix}>{prefix}</span>}
      <div
        className={classNames(styles.changeInputContainer, {
          [styles.error]: error,
        })}
      >
        <input
          value={value}
          onChange={(e) => {
            setError(false)

            const newValue = beforeChange
              ? beforeChange(e.target.value)
              : e.target.value

            setValue(newValue)

            if (initialValue !== newValue) {
              setMessage(changeText)
            }
          }}
          onKeyUp={async (event) => {
            if (event.key === 'Enter' && (allowEmpty || !!value)) {
              try {
                setLoading(true)

                if (validateChange) {
                  const isValid = validateChange(value)

                  if (!isValid) {
                    setMessage('Not valid')

                    return
                  }
                }

                await onConfirm(value)

                setMessage(successText)

                setTimeout(() => {
                  setMessage(changeText)
                }, 2000)
              } catch {
                setError(true)
              } finally {
                setLoading(false)
              }
            }
          }}
          {...inputProps}
        />
        {(loading ||
          error ||
          initialValue !== value ||
          message !== changeText) && (
          <>
            {loading ? (
              <Preloader className={styles.changeInputLoader} />
            ) : (
              <>
                {error ? (
                  <ErrorMessage className={styles.changeInputError}>
                    {errorText}
                  </ErrorMessage>
                ) : (
                  <>
                    {message === changeText && (
                      <Button
                        variant="text"
                        className={styles.changeInputButton}
                        isDisabled={!allowEmpty && !value}
                        onPress={async () => {
                          try {
                            setLoading(true)

                            if (validateChange) {
                              const isValid = validateChange(value)

                              if (!isValid) {
                                setMessage('Not valid')

                                return
                              }
                            }

                            await onConfirm(value)

                            setMessage(successText)
                          } catch {
                            setError(true)
                          } finally {
                            setLoading(false)
                          }
                        }}
                      >
                        {message}
                      </Button>
                    )}
                    {message === 'Not valid' && (
                      <ErrorMessage className={styles.changeInputError}>
                        {message}
                      </ErrorMessage>
                    )}
                    {message === successText && (
                      <SuccessText className={styles.changeInputSuccess}>
                        {successText}
                      </SuccessText>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export interface MultiInputFieldValue {
  label: string
  value: string
  pillColor?: PillColors
}

interface MultiInputFieldProps {
  id: string
  /** Used to apply styles to div containing field and children (excludes error messages) */
  containerClassName?: string
  className?: string
  placeholder?: string
  isClearable?: boolean
  /** Limit the number of values that can be added */
  maxValues?: number
  error?: boolean
  value: MultiInputFieldValue[]
  setValue: React.Dispatch<React.SetStateAction<MultiInputFieldValue[]>>
  /** If only one option is selected on blur of the field, make it look like text input */
  noPillForSingleValue?: boolean
  /** Apply rules to modify input value */
  formatInputValue?: (value: string) => string
  /** Regex matcher for breaking up input values into multiple options/pills */
  separatorChars?: string
  /** The string entered here will be replaced with '' when checking for duplicates. Useful for e.g. ignoring https:// in URL duplicate check */
  duplicateCheckerIgnoreString?: string
  /** Applies changes to newly added values before updating full values array */
  modifyInputValues?: (
    value: MultiInputFieldValue[],
  ) => {
    allowedValues: MultiInputFieldValue[]
    blockedValues: MultiInputFieldValue[]
  }
  // Action applied once full value list is built
  customOnChange?: (value: string[]) => void
  addInputOnBlur?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  children?: React.ReactNode
}

export const MultiInputField = ({
  id,
  className,
  containerClassName,
  placeholder = 'Enter a value',
  isClearable,
  maxValues,
  error,
  value,
  setValue,
  noPillForSingleValue,
  formatInputValue,
  separatorChars = ';,\\n\\t\\r',
  duplicateCheckerIgnoreString = '',
  modifyInputValues,
  customOnChange,
  addInputOnBlur,
  onBlur,
  children,
}: MultiInputFieldProps) => {
  const [inputValue, setInputValue] = useState('')
  const [maxValuesError, setMaxValuesError] = useState(false)
  const [duplicatesError, setDuplicatesError] = useState<
    false | 'warning' | 'removed'
  >(false)

  const onInputChange = useCallback(
    (newValue: string) => {
      setMaxValuesError(false)
      setDuplicatesError(false)

      // Prevent new values from being added
      if (!!newValue && !!maxValues && value.length >= maxValues) {
        setMaxValuesError(true)
        return
      }

      let formattedValue = newValue

      if (formatInputValue) {
        formattedValue = formatInputValue(newValue)
      }

      // Can be used to add values on paste - replicates old functionality
      // if (new RegExp(`[${separatorChars}]`).test(formattedValue)) {
      //   onChange(separateInputValues(formattedValue))
      //   setInputValue('')
      //   return
      // }

      setInputValue(formattedValue)

      if (
        value.find(
          (v) =>
            v.value.replaceAll(duplicateCheckerIgnoreString, '') ===
            formattedValue.replaceAll(duplicateCheckerIgnoreString, ''),
        )
      ) {
        setDuplicatesError('warning')
      }
    },
    [maxValues, value, formatInputValue, duplicateCheckerIgnoreString],
  )

  /** Splits input value based on separatorChars if any */
  const separateInputValues = useCallback(
    (input: string) => {
      const newValues: MultiInputFieldValue[] = []

      if (separatorChars) {
        const separatedInputValues = input
          .split(new RegExp(`[${separatorChars}]`, 'gi'))
          .filter((v) => v)

        separatedInputValues.forEach((v) =>
          newValues.push({ label: v, value: v }),
        )
      } else {
        newValues.push({ label: input, value: input })
      }

      return newValues
    },
    [separatorChars],
  )

  /** Updates value state and applies custom handlers */
  const onChange = useCallback(
    (
      newValue: MultiValue<MultiInputFieldValue>,
      actionMeta?: ActionMeta<MultiInputFieldValue>,
    ) => {
      setMaxValuesError(false)
      setDuplicatesError(false)

      // Remove duplicates from newValue based on the value property
      const uniqueValues = new Set<string>()

      const mappedValues = newValue.filter(({ value: v }) => {
        const normalizedValue = v.replaceAll(duplicateCheckerIgnoreString, '')
        if (uniqueValues.has(normalizedValue)) {
          return false
        }

        uniqueValues.add(normalizedValue)
        return true
      })

      // Values have been removed - no need to apply custom logic
      if (
        actionMeta &&
        ['remove-value', 'pop-value', 'clear', 'deselect-option'].includes(
          actionMeta.action,
        )
      ) {
        setValue(mappedValues)

        if (customOnChange) {
          customOnChange(mappedValues.map(({ value: v }) => v))
        }

        return
      }

      // Values should only be added if they are not already in the list
      const existingValues = mappedValues.filter((v) =>
        value.find(
          (existingValue) =>
            existingValue.value.replaceAll(duplicateCheckerIgnoreString, '') ===
            v.value.replaceAll(duplicateCheckerIgnoreString, ''),
        ),
      )

      const newlyAddedValues = mappedValues.filter(
        (v) =>
          !value.find(
            (existingValue) =>
              existingValue.value.replaceAll(
                duplicateCheckerIgnoreString,
                '',
              ) === v.value.replaceAll(duplicateCheckerIgnoreString, ''),
          ),
      )

      if (existingValues.length > 0 || newlyAddedValues.length === 0) {
        setDuplicatesError('removed')
      }

      let valueToSet = _.cloneDeep(value)

      if (!modifyInputValues) {
        setInputValue('')

        valueToSet = [...value, ...newlyAddedValues]

        setValue((curr) => [...curr, ...newlyAddedValues])
      } else {
        const { allowedValues, blockedValues } = modifyInputValues(
          newlyAddedValues,
        )

        // Stops blocked values from being added, but keeps them in the input field
        setInputValue(
          blockedValues.length > 0
            ? blockedValues.map((v) => v.value).join(';')
            : '',
        )

        valueToSet = [...value, ...allowedValues]
      }

      setValue(valueToSet)

      if (customOnChange) {
        customOnChange(valueToSet.map(({ value: v }) => v))
      }
    },
    [modifyInputValues, value, customOnChange],
  )

  const [valuePasted, setValuePasted] = useState(false)

  /** Only way to get Input component with custom onPaste event is to memoize it */
  const componentsMemo = useMemo(
    () => {
      return {
        Input: (inputProps: InputProps<MultiInputFieldValue, true>) => (
          <components.Input
            {...inputProps}
            onPaste={(e) => {
              setValuePasted(true)

              let pastedText = e.clipboardData.getData('Text')

              // Only way I've found to allow pasting multiple values separated by new lines
              pastedText = pastedText.replaceAll(
                /(\r\n|\r|\n)/g,
                separatorChars[0] || ';',
              )

              onInputChange(pastedText)
            }}
          />
        ),
      }
    },
    // * Concerned that the input component will not update with onInputChange
    // * But adding any dependencies breaks the component
    // TODO: Find out why and fix
    [],
  )

  return (
    <>
      <div className={containerClassName}>
        <SelectBox
          id={id}
          className={className}
          isMulti
          isClearable={isClearable}
          menuIsOpen={false}
          placeholder={placeholder}
          components={{ ...componentsMemo, DropdownIndicator: null }}
          inputValue={inputValue}
          value={value}
          error={error}
          noPillForSingleValue={noPillForSingleValue}
          onChange={onChange}
          onInputChange={(newValue) => {
            // Value is changed via onPaste event, do not perform actions here
            if (valuePasted) {
              setValuePasted(false)
              return
            }

            onInputChange(newValue)
          }}
          onKeyDown={(e) => {
            // Prevent form submission when adding new values
            if (e.key === 'Enter' && !!inputValue) {
              e.preventDefault()
            }

            if (!inputValue) return

            if (['Enter', 'Tab'].includes(e.key)) {
              onChange(separateInputValues(inputValue))
            }
          }}
          onBlur={(e) => {
            if (inputValue && addInputOnBlur) {
              onChange(separateInputValues(inputValue))

              if (onBlur) onBlur(e)
            }
          }}
        />
        {children}
      </div>
      {maxValuesError && <ErrorMessage>Cannot add more values.</ErrorMessage>}
      {duplicatesError && (
        <ErrorMessage>
          {duplicatesError === 'warning'
            ? 'Value already exists.'
            : 'Duplicate values not added.'}
        </ErrorMessage>
      )}
    </>
  )
}
