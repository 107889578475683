import React, { useState, useEffect, useMemo } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import AddMultiValuesTags from './add-multi-values-tags'
import Button, { DeleteButton } from './button'
import ButtonDropdown, { DropdownButtonItem } from './button-dropdown'
import { ButtonRow } from './button-row'
import { Label } from './input'
import Modal from './modal'
import SelectBox from './select-box'
import { InnerBox, OuterBox } from './two-columns'
import { SuccessText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { getIntegrationsStatus } from '../api/graphql/integrations-client'
import {
  getCampaignCodeGenerator,
  sendGeneratorUpdateRequest,
} from '../api/graphql/track-create-client'
import { getUpdateRequestList } from '../api/graphql/track-edit-client'
import { OptionsProps, ValidationChecks } from '../api/types'
import { getItemByKeyValue, isAdminUser, prepareInput } from '../helpers'
import { getValidationChecksObject } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/request-field-modal.module.scss'

interface FieldProps {
  id: string
  newOptions: string[]
  parameterName: string
  fieldName: string
  fieldID: string
  helpText?: string
}

const defaultField = (): FieldProps => {
  return {
    id: nanoid(),
    newOptions: [''],
    parameterName: '',
    fieldName: '',
    fieldID: '',
  }
}

interface RequestFieldModalProps {
  loading?: boolean
  active: boolean
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
  requestFieldName?: string | null
}

// TODO: Terrible. Rebuild
export const RequestFieldModal = ({
  loading = false,
  active,
  toggleActive,
  requestFieldName = null,
}: RequestFieldModalProps) => {
  const { workspaceID, userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  const [sendRequest, { loading: sendingRequest }] = useMutation(
    sendGeneratorUpdateRequest,
  )

  // Wait for workspace ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const { paramDefs, validation } = useMemo(() => {
    if (!generatorData) return { paramDefs: [], validation: null }

    return {
      paramDefs: generatorData.campaignCodeGenerator.paramDefs,
      validation: getValidationChecksObject(
        generatorData.campaignCodeGenerator,
      ),
    }
  }, [generatorData])

  // Check if requests should be sent to Monday.com instead of email
  const { data: thirdPartyIntegrationsData } = useQuery(getIntegrationsStatus)

  const mondayIntegrationActive = useMemo(() => {
    if (!thirdPartyIntegrationsData) return false

    return (
      thirdPartyIntegrationsData.currentCompany.mondayIntegrationStatus ===
      'active'
    )
  }, [thirdPartyIntegrationsData])

  const [reason, setReason] = useState('')
  const [fields, setFields] = useState([defaultField()])
  const [thanks, setThanks] = useState(false)
  const [similarValues, setSimilarValues] = useState<
    { name: string; value: string }[]
  >([])

  const list = useMemo(
    () => paramDefs.filter((item) => item.fieldType === 'select'),
    [paramDefs],
  )

  const onInputChange = (val: string, index: number) => {
    const f = fields.concat()

    f[index].parameterName = val

    const found = getItemByKeyValue(
      paramDefs,
      'fieldName',
      fields[index].parameterName,
    )

    if (found !== -1) {
      const { fieldName, fieldID, helpText } = found
      f[index].fieldName = fieldName
      f[index].fieldID = fieldID
      f[index].helpText = helpText
      f[index].newOptions = ['']
    }

    setFields(f)
    setSimilarValues([])
  }

  // Set initial value of request field ID
  useEffect(() => {
    if (paramDefs.length === 0) return

    if (requestFieldName !== '' && requestFieldName !== null) {
      onInputChange(requestFieldName, 0)
    }
  }, [paramDefs])

  const onSubmit = async () => {
    const data = {
      changeRequests: fields.map(
        (field: FieldProps, index): OptionsProps => {
          const { newOptions, fieldName, fieldID } = field

          const useNewOptions = newOptions.map((i) => {
            // Specific bug fix for forcing lower case
            const found = getItemByKeyValue(
              paramDefs,
              'fieldName',
              fields[index].parameterName,
            )

            const useValidation: ValidationChecks[] | null = Array.isArray(
              validation,
            )
              ? [...validation]
              : null

            // Lowercase should be forced if the field is not present - amend validation rules
            if (
              Array.isArray(useValidation) &&
              found.forceLowerCase !== false
            ) {
              const allLowerCaseIndex = useValidation.findIndex(
                (rule) => rule.name === 'ALL_LOWER_CASE',
              )

              useValidation.splice(allLowerCaseIndex, 1, {
                enabled: true,
                name: 'ALL_LOWER_CASE',
                value: null,
              })
            }

            const optionValue = prepareInput(i, useValidation)

            return {
              optionName: i,
              optionValue,
            }
          })
          return {
            newOptions:
              useNewOptions.length === 0
                ? [
                    {
                      optionName: '',
                      optionValue: '',
                    },
                  ]
                : useNewOptions,
            fieldName,
            fieldID,
          }
        },
      ),
      requestNote: reason,
    }

    // Function includes monday.com integration checks on the backend
    await sendRequest({
      variables: data,
      refetchQueries: isAdminUser(userPermission) ? [getUpdateRequestList] : [],
    })

    setThanks(true)

    logAction({
      variables: {
        action: mondayIntegrationActive
          ? 'send-update-request-monday'
          : 'send-update-request',
        extra: JSON.stringify(data),
        websiteSection: 'track',
        functionName: 'requestNewValue',
        pagePath: '/track/create',
      },
    })
  }

  const softDisable = useMemo(() => {
    const fieldsWithValues = fields.filter(
      (f) => f.parameterName !== '' && f.newOptions.length > 0,
    )

    return !(fieldsWithValues.length === fields.length)
  }, [fields])

  if (!active) return null

  return (
    <>
      <Modal
        className={classNames({ [styles.requestModal]: !thanks })}
        setIsOpen={toggleActive}
        yesButtonLoading={sendingRequest}
        modalHeader={thanks ? 'Request sent' : 'Request new dropdown values'}
        loading={loading}
        yesText={thanks ? 'OK' : 'Request values'}
        yesButtonDisabled={softDisable}
        onYes={thanks ? () => toggleActive(false) : onSubmit}
      >
        {thanks ? (
          <SuccessText color="black">
            Request emailed to your account admins to be reviewed.
          </SuccessText>
        ) : (
          <>
            <p style={{ padding: '16px 16px 0 16px', margin: 0 }}>
              Requests will be emailed to your admins to review.
            </p>
            <div className={styles.addNameBlockWrapper}>
              {fields.map((field: FieldProps, index: number) => {
                const fullParam = paramDefs.find(
                  (param) => param.fieldID === field.fieldID,
                )

                let existingValues: { name: string; value: string }[] = []

                if (fullParam && fullParam.selectFields) {
                  existingValues = fullParam.selectFields.map(
                    (selectField) => ({
                      name: selectField.optionName,
                      value: selectField.optionValue,
                    }),
                  )
                }

                return (
                  <div className={styles.addNameBlock} key={field.id}>
                    <Label modalHeading id="parameter-name">
                      Parameter name
                    </Label>
                    <SelectBox
                      id="parameter-name"
                      placeholder="Select parameter or start typing"
                      labelKey="fieldName"
                      valueKey="fieldID"
                      value={list.find(
                        (option) => option.fieldID === fields[index].fieldID,
                      )}
                      options={list}
                      onChange={(newValue) => {
                        onInputChange(newValue?.fieldName || '', index)
                      }}
                    />
                    {field.helpText && (
                      <p className={styles.helpText}>{field.helpText}</p>
                    )}
                    {!!field.fieldID && (
                      <>
                        <AddMultiValuesTags
                          transformTags={false}
                          label="Dropdown name(s)"
                          type="modal"
                          initialValue={field.newOptions}
                          onCheckIfExists={(value) => {
                            const data = getItemByKeyValue(
                              paramDefs,
                              'fieldName',
                              fields[index].parameterName,
                            )

                            if (data !== -1) {
                              const fieldValue = getItemByKeyValue(
                                data.selectFields,
                                'optionName',
                                value,
                              )
                              return fieldValue !== -1
                            }

                            return false
                          }}
                          beforeChange={(val) => {
                            // Check for similar existing values
                            if (val.length > 1) {
                              const searchPattern = val
                                .replace(/[^a-zA-Z0-9]/g, '')
                                .split('')
                                .join('.*')

                              const similarExistingVals = existingValues.filter(
                                (item) =>
                                  item.name
                                    .toLowerCase()
                                    .match(new RegExp(searchPattern, 'i')) !==
                                    null ||
                                  item.value
                                    .toLowerCase()
                                    .match(new RegExp(searchPattern, 'i')) !==
                                    null,
                              )

                              setSimilarValues(similarExistingVals)
                            } else {
                              setSimilarValues([])
                            }

                            return val
                          }}
                          onChange={(val: string[]) => {
                            const f = fields.concat()

                            // remove empty string values
                            // TODO: Rebuild component to deal with duplicates
                            f[index].newOptions = [
                              ...new Set(val.filter((item) => item !== '')),
                            ]

                            setFields(f)
                          }}
                        />
                        {field.newOptions.length > 0 &&
                          similarValues.length > 0 && (
                            <>
                              <p className={styles.footNote}>
                                Similar values found. Could you use:
                              </p>
                              <ul className={styles.footNote}>
                                {similarValues.map((item) => {
                                  return <li>{item.name}</li>
                                })}
                              </ul>
                            </>
                          )}
                      </>
                    )}
                    {index > 0 && (
                      <DeleteButton
                        className={styles.deleteButton}
                        onPress={() => {
                          const f = fields.concat()

                          f.splice(index, 1)
                          if (f.length === 0) {
                            setFields([defaultField()])
                          } else {
                            setFields(f)
                          }
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </div>
            <div className={styles.requestReason}>
              {fields[fields.length - 1].fieldID !== '' && (
                <ButtonRow className={styles.buttonRow}>
                  <Button
                    className={styles.addAnother}
                    variant="secondary"
                    type="submit"
                    onPress={() => setFields(fields.concat([defaultField()]))}
                  >
                    Add another
                  </Button>
                </ButtonRow>
              )}
              <Label modalHeading id="parameter-reason" optional="(optional)">
                Deadline and reason
              </Label>
              <textarea
                id="parameter-reason"
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const { value: val } = event.target
                  setReason(val)
                }}
                placeholder="I need this value before/because..."
                value={reason}
              />
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

interface RequestFieldProps {
  dropdownParamNames?: string[]
}

export default function RequestField({
  dropdownParamNames = [],
}: RequestFieldProps) {
  const [requestFieldActive, setRequestFieldActive] = useState(false)
  const [dropdownName, setDropdownName] = useState<string | null>(null)

  return (
    <>
      <OuterBox>
        <InnerBox>
          <ButtonDropdown
            variant="secondary"
            buttonText="Request new dropdown"
            mainAction={() => setRequestFieldActive(true)}
          >
            {dropdownParamNames.map((name) => (
              <DropdownButtonItem
                key={name}
                onPress={() => {
                  setDropdownName(name)
                  setRequestFieldActive(true)
                }}
              >
                {name}
              </DropdownButtonItem>
            ))}
          </ButtonDropdown>
        </InnerBox>
      </OuterBox>
      {requestFieldActive && (
        <RequestFieldModal
          active={requestFieldActive}
          toggleActive={() => setRequestFieldActive(!requestFieldActive)}
          requestFieldName={dropdownName}
        />
      )}
    </>
  )
}
