import React, { useRef } from 'react'
import classNames from 'classnames'

import styles from '../styles/top-scrollbar.module.scss'

interface TopScrollbarProps {
  className?: string
  disabled?: boolean
  scrollInnerRef: React.RefObject<HTMLElement>
  scrollHandle?: (e: any) => void
  children: React.ReactNode
}

const TopScrollbar = ({
  className,
  disabled,
  scrollInnerRef,
  scrollHandle,
  children,
}: TopScrollbarProps) => {
  const scrollBarRef = useRef<HTMLDivElement>(null)

  const scrollTop = (e) => {
    e.target.nextElementSibling.scrollLeft = e.target.scrollLeft
  }

  const scrollMain = (e) => {
    e.target.previousElementSibling.scrollLeft = e.target.scrollLeft
  }

  return (
    <>
      {(scrollInnerRef.current?.offsetWidth ?? 0) >
        (scrollBarRef.current?.offsetWidth ?? 1198) && (
        <div
          className={classNames(styles.doubleScroll, styles.topScroll, {
            [styles.scrollDisabled]: disabled,
          })}
          onScroll={scrollTop}
          data-html2canvas-ignore
        >
          <div
            className={styles.topScroll}
            style={{
              width: `${scrollInnerRef.current?.offsetWidth ?? 0}px`,
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
      <div
        className={classNames(className, styles.doubleScroll, {
          [styles.scrollDisabled]: disabled,
        })}
        ref={scrollBarRef}
        onScroll={(e) => {
          if (
            (scrollInnerRef.current?.offsetWidth ?? 0) >
            (scrollBarRef.current?.offsetWidth ?? 1198)
          ) {
            scrollMain(e)
          }

          if (scrollHandle) scrollHandle(e)
        }}
      >
        {children}
      </div>
    </>
  )
}

export default TopScrollbar
