import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'

import AddWorkspaceModal from './add-workspace-modal'
import Button from './button'
import { LoadingLogo } from './loader'
import SelectBox from './select-box'
import { currentUserDetails } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import { getUserAccounts, getUserInfo } from '../api/graphql/user-client'
import { isAdminUser } from '../helpers'
import useSwitchWorkspace from '../hooks/useSwitchWorkspace'
import styles from '../styles/workspace-selector.module.scss'

const getIconPath = (homepage: string | null): string => {
  return homepage
    ? `https://www.google.com/s2/favicons?domain=${homepage}`
    : `/favicon.png`
}

interface OptionType {
  accountID: string
  accountName: string
  companyName: string
  icon?: string
}

interface GroupType {
  label: string
  companyID: string
  options: OptionType[]
}

interface WorkspaceSelectorProps {
  className?: string
  redirectTo?: string
  hideAddWorkspacesButton?: boolean
}

const WorkspaceSelector = ({
  className,
  redirectTo,
  hideAddWorkspacesButton,
}: WorkspaceSelectorProps) => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: userAccountData } = useQuery(getUserInfo)
  const { data: companyData } = useQuery(getCompanyDetails)
  // This is a large query - only run it when dropdown is opened
  const [
    getOtherAccounts,
    { data: userAllAccountsData, loading: fetchingAllAccounts },
  ] = useLazyQuery(getUserAccounts)

  const { switchWorkspace, error: switchWorkspaceError } = useSwitchWorkspace()

  const [switchWorkspaceLoading, setSwitchWorkspaceLoading] = useState(false)
  const [accountsFetched, setAccountsFetched] = useState(false)
  const [currentAccountID, setCurrentAccountID] = useState('')
  const [selOption, setSelOption] = useState<OptionType | null>(null)

  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false)

  useEffect(() => {
    if (userAccountData && !currentAccountID) {
      const {
        currentAccount: { accountID, accountName, companyName, homepage },
      } = userAccountData

      setSelOption({
        accountID,
        accountName,
        companyName: companyName || '(No company name provided)',
        icon: getIconPath(homepage),
      })
      setCurrentAccountID(accountID)
    }
  }, [userAccountData])

  // List for new component
  const groupedList = useMemo(() => {
    if (!userAccountData) return []

    // Get all available accounts
    if (userAllAccountsData) {
      const fullGroupedList = userAllAccountsData.currentUser.userAccountProfiles.reduce<
        GroupType[]
      >((acc, curr) => {
        let companyIndex = acc.findIndex(
          (group) => group.companyID === curr.companyID,
        )

        if (companyIndex === -1) {
          acc.push({
            label: curr.companyName || '(No company name provided)',
            companyID: curr.companyID,
            options: [],
          })

          companyIndex = acc.length - 1
        }

        acc[companyIndex].options.push({
          accountName: curr.accountName,
          accountID: curr.accountID,
          companyName: curr.companyName || '(No company name provided)',
          icon: getIconPath(curr.homepage),
        })

        return acc
      }, [])

      return fullGroupedList.sort((a, b) =>
        b.label.toLowerCase() < a.label.toLowerCase() ? 1 : -1,
      )
    }

    // Initially, only show current account
    const {
      currentAccount: { accountID, accountName, homepage, companyName },
    } = userAccountData

    return [
      {
        label: companyName || '(No company name provided)',
        options: [
          {
            accountID,
            accountName,
            companyName: companyName || '(No company name provided)',
            icon: getIconPath(homepage),
          },
        ],
      },
    ]
  }, [userAccountData, userAllAccountsData])

  useEffect(() => {
    if (switchWorkspaceError) {
      setSwitchWorkspaceLoading(false)
    }
  }, [switchWorkspaceError])

  if (switchWorkspaceLoading) return <LoadingLogo fullScreen />

  return (
    <>
      <SelectBox
        labelKey="accountName"
        valueKey="accountID"
        containerClassName={className}
        isLoading={!userAccountData || fetchingAllAccounts}
        placeholder="Loading accounts..."
        value={selOption}
        options={groupedList}
        // Should be able to fuzzy-search on accountName AND companyName
        filterOption={(option, input) => {
          const searchPattern = input
            .replace(/[^a-zA-Z0-9]/g, '')
            .split('')
            .join('.*')

          const accountNameMatch = option.data.accountName.match(
            new RegExp(searchPattern, 'gi'),
          )
          const companyNameMatch = option.data.companyName.match(
            new RegExp(searchPattern, 'gi'),
          )

          return !!accountNameMatch || !!companyNameMatch
        }}
        onFocus={async () => {
          if (!accountsFetched && !userAllAccountsData) {
            await getOtherAccounts()
            setAccountsFetched(true)
          }
        }}
        onChange={(newValue) => {
          if (newValue) {
            setSelOption(newValue)

            if (currentAccountID && currentAccountID !== newValue.accountID) {
              setSwitchWorkspaceLoading(true)
              switchWorkspace(newValue.accountID, redirectTo)
            }
          }
        }}
      >
        {!hideAddWorkspacesButton && isAdminUser(userPermission) && (
          <Button
            variant="text"
            onPressStart={() => setWorkspaceModalOpen(true)}
            isDisabled={!companyData}
            className={styles.addButton}
            onPress={() => setWorkspaceModalOpen(true)}
          >
            Create new workspace +
          </Button>
        )}
      </SelectBox>
      {switchWorkspaceError && (
        <p className={styles.errorMsg}>
          There was an issue switching accounts. Please reload the page and try
          again.
        </p>
      )}
      {workspaceModalOpen && (
        <AddWorkspaceModal closeModal={setWorkspaceModalOpen} />
      )}
    </>
  )
}

export default WorkspaceSelector
