import React, { useEffect, useState } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'

import Input, { Label } from './input'
import Link from './link'
import Modal from './modal'
import SubscriptionComparison from './subscription-comparison'
import Tooltip from './tooltip'
import { BoxedText, ErrorMessage, Heading, TickListItem } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import {
  getEnterpriseCompanyDetails,
  sendFeatureRequest,
} from '../api/graphql/support-client'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import { dynamicTextValues, supportEmail } from '../core/constants'
import { isSupportUser } from '../helpers'
import { CustomDomainType } from '../helpers/custom-links'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import styles from '../styles/feature-blocker-modals.module.scss'

interface UpgradeModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const RequestBrandedDomainModal = ({
  onHideModal,
  linkType = 'shortLink',
}: UpgradeModalProps & { linkType?: CustomDomainType }) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { isStartup } = useSubscriptionLevel()

  const [requestFeature, { loading, error }] = useMutation(sendFeatureRequest)

  const [domain, setDomain] = useState('')
  const [showThankYou, setShowThankYou] = useState(false)

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-branded-domain-upgrade-modal',
        pagePath: window.location.pathname,
        websiteSection: 'track',
        functionName: 'requestCustomDomain',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  // Branded domains are not available to startup users
  if (isStartup) {
    return (
      <Modal
        setIsOpen={onHideModal}
        headerColor="pink"
        modalHeader={
          <Heading className={styles.modalTitle} type={3} align="left">
            Upgrade to create branded short links{' '}
            <img src={UpgradeRocket} alt="Upgrade" />
          </Heading>
        }
        hideFooter
      >
        <p>
          A <strong>branded short link</strong> gives you increased
          clickthroughs from customers who only trust recognisable, compact
          links.
        </p>
        <div style={{ width: 'fit-content', maxWidth: '100%', margin: 'auto' }}>
          <SubscriptionComparison
            highlightCurrent
            hideStartupTier
            hideIcons
            showLimits
            topFeature="shortlinks"
          />
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={`Request branded ${
        linkType === 'shortLink' ? 'short' : 'app'
      } link`}
      noText={showThankYou ? 'Back' : 'Cancel'}
      onYes={
        showThankYou
          ? undefined
          : async () => {
              if (!showThankYou) {
                setShowThankYou(true)
                const message = `Request custom domain: ${domain}`

                requestFeature({
                  variables: {
                    message,
                    page: 'track/create-campaign-codes',
                  },
                })

                logAction({
                  variables: {
                    action: 'request-custom-domain',
                    pagePath: window.location.pathname,
                    websiteSection: 'track',
                    functionName: 'requestCustomDomain',
                    extra: message,
                  },
                })
              } else {
                onHideModal(false)
              }
            }
      }
      footerContent={
        <>
          {error && (
            <ErrorMessage showSupport>Error sending request.</ErrorMessage>
          )}
        </>
      }
    >
      {(!showThankYou || loading) && (
        <>
          <p>
            Make your links trustworthy by upgrading to a branded{' '}
            {linkType === 'shortLink' ? 'short' : 'app'} link domain.
          </p>
          <p>
            This gives you control over the characters after the domain.
            Allowing you to create memorable links for offline media.
          </p>
          <p>
            Example: <BoxedText>your.company/offer1</BoxedText>
          </p>
          <p>
            You can give us access to your domain or we can buy one on your
            behalf. Put your preferred domain below and we will get in contact.
          </p>
          <Label
            modalHeading
            id="domain"
            optional="(optional)"
            className={styles.modalLabel}
          >
            <Tooltip
              id="preferred-domain-tooltip"
              useIcon
              tooltipMessage="Enter the name of a short domain you want to integrate with."
            >
              Preferred domain
            </Tooltip>
          </Label>
          <Input
            className={styles.domainInput}
            required
            name="domain"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value: val } = e.target as HTMLInputElement
              setDomain(val)
            }}
            value={domain}
          />
        </>
      )}
      {showThankYou && !loading && (
        <>
          <p>
            Thank you for contacting us and requesting custom domain
            integration. We will be in touch soon!
          </p>
        </>
      )}
    </Modal>
  )
}

export const RequestAppLinksModal = ({ onHideModal }: UpgradeModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-app-links-upgrade-modal',
        pagePath: window.location.pathname,
        websiteSection: 'track',
        functionName: 'requestAppLinks',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to create app links <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>We recommend creating app links to:</p>
      <ul className={styles.featureList} style={{ marginBottom: 16 }}>
        <li>Route mobile users directly to in-app screens and/or app stores</li>
        <li>Compare all marketing to your apps</li>
        <li>
          Track all journeys with the same consistent parameters you do for web
        </li>
      </ul>
      <div style={{ width: 'fit-content', maxWidth: '100%', margin: 'auto' }}>
        <SubscriptionComparison
          highlightCurrent
          hideStartupTier
          hideIcons
          showLimits
          topFeature="app-deeplinks"
        />
      </div>
    </Modal>
  )
}

export const RequestMoreUsersModal = ({ onHideModal }: UpgradeModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-user-limit-upgrade-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'requestUsers',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to add new users <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>
        Save time by letting your colleagues and agencies self-serve consistent
        links and reports.
      </p>
      <p>Regular users can:</p>
      <ul className={styles.featureList}>
        <li>
          <strong>Create links</strong> following your link parameters,
          dropdowns and rules
        </li>
        <li>
          <strong>Request new dropdowns</strong> for admin users to approve or
          reject
        </li>
        <li>
          <strong>View all links</strong> performance and create campaign
          reports
        </li>
      </ul>
      <p>Admin users can also:</p>
      <ul className={styles.featureList}>
        <li>Edit and delete links for all users</li>
        <li>Edit link parameters, dropdowns and rules</li>
        <li>Add, remove and edit user permissions</li>
      </ul>
      <p>We offer bespoke onboarding and training for Enterprise users.</p>
      <div style={{ width: 'fit-content', maxWidth: '100%', margin: 'auto' }}>
        <SubscriptionComparison
          highlightCurrent
          hideStartupTier
          hideIcons
          showLimits
          topFeature="users"
        />
      </div>
    </Modal>
  )
}

export const AddWorkspaceBlockerModal = ({
  onHideModal,
}: UpgradeModalProps) => {
  const linkCopy = useReactiveVar(linkOrCode)

  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-workspace-limit-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'requestWorkspaces',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to create new workspaces{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <p>We recommend creating new workspaces if:</p>
      <ul>
        <TickListItem pink>
          <strong>You're an agency with multiple clients.</strong> Each client's
          workspace can have different users, link taxonomies, reports and
          integrations.
        </TickListItem>
        <TickListItem pink>
          <strong>
            You're a large company with multiple brands or websites.
          </strong>{' '}
          Each workspace can be connected to a different web analytics profile.{' '}
          <Link href={`mailto:${supportEmail}`}>Email us</Link> if you want to
          share the same{' '}
          <BoxedText>
            <Link href="/track/create">
              {dynamicTextValues.trackCreatePage[linkCopy]}
            </Link>
          </BoxedText>{' '}
          taxonomy and dropdowns across workspaces.
        </TickListItem>
        <TickListItem pink>
          <strong>
            You need separate workspaces for each marketing channel.
          </strong>{' '}
          We don't recommend this because it makes comparing channels and
          managing users more challenging.
        </TickListItem>
      </ul>
      <div style={{ width: 'fit-content', maxWidth: '100%', margin: 'auto' }}>
        <SubscriptionComparison hideStartupTier hideIcons />
      </div>
    </Modal>
  )
}

export const ConnectorUpgradeModal = ({ onHideModal }: UpgradeModalProps) => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-connector-limit-modal',
        pagePath: window.location.pathname,
        websiteSection: 'connect',
        functionName: 'requestConnectors',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to access more connectors{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      hideFooter
    >
      <div style={{ width: 'fit-content', maxWidth: '100%', margin: 'auto' }}>
        <SubscriptionComparison
          highlightCurrent
          hideStartupTier
          hideIcons
          showLimits
          topFeature="connectors"
        />
      </div>
    </Modal>
  )
}

interface EnterpriseFeatureBlockerModalProps extends UpgradeModalProps {
  feature: 'user' | 'workspace' | 'connector' | 'link'
}

/** Feature blocking limits are applied on a case-by-case basis for enterprise clients. This modal is generic for all features that could be blocked. */
export const EnterpriseFeatureBlockerModal = ({
  onHideModal,
  feature,
}: EnterpriseFeatureBlockerModalProps) => {
  const { companyID, companyName, workspaceID, userID } = useReactiveVar(
    currentUserDetails,
  )

  const { isEnterprise, isMicrosoftMarketplace } = useSubscriptionLevel()

  const logAction = useLogAction()

  const [requestFeature, { loading: loadingEnterpriseRequest }] = useMutation(
    sendFeatureRequest,
  )

  useEffect(() => {
    logAction({
      variables: {
        action: `enterprise-view-feature-blocker-upgrade-modal-${feature}`,
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: `enterpriseRequest${feature}`,
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!isEnterprise || isMicrosoftMarketplace) return null

  return (
    <Modal
      setIsOpen={onHideModal}
      headerColor="pink"
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Increase your {feature} limit{' '}
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      yesText="Send request"
      yesButtonLoading={loadingEnterpriseRequest}
      onYes={async () => {
        const message = `Request contract upgrade to add more ${feature}s: ${companyName}.`

        await requestFeature({
          variables: {
            message,
            page: 'settings',
          },
        })

        logAction({
          variables: {
            action: `enterprise-company-request-contract-upgrade-more-${feature}`,
            pagePath: window.location.pathname,
            websiteSection: 'setting',
            functionName: 'requestContractUpgrade',
            extra: message,
          },
        })

        onHideModal(false)
      }}
    >
      <p>
        You have reached your {feature}s limit for your current contract.
        Contact <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> to
        upgrade your contract and increase your limit.
      </p>
    </Modal>
  )
}

export const EnterpriseExpiringLicenceModal = () => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data } = useQuery(getEnterpriseCompanyDetails)

  const hasSeenModal = JSON.parse(
    sessionStorage.getItem('expiringLicenceModalShown') || 'false',
  )

  const [showModal, setShowModal] = useState(!hasSeenModal)

  useEffect(() => {
    if (hasSeenModal) return

    logAction({
      variables: {
        action: 'enterprise-company-view-expiring-licence-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'licenceExiring',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!showModal) return null

  return (
    <Modal
      setIsOpen={setShowModal}
      headerColor="pink"
      modalHeader="Your licence is about to expire"
      hideFooter
      beforeClose={() => {
        sessionStorage.setItem('expiringLicenceModalShown', 'true')
      }}
    >
      <p>
        Your licence{' '}
        {data?.currentCompany.contractEndDate ? (
          <>
            will end on{' '}
            <strong>
              {moment(data?.currentCompany.contractEndDate).format(
                'Do MMM YYYY',
              )}
            </strong>
          </>
        ) : (
          'is ending soon'
        )}
        . Please email{' '}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> to renew
        your licence and continue using the platform without interruption.
      </p>
    </Modal>
  )
}

export const EnterpriseExpiredLicenceModal = () => {
  const { companyID, workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data } = useQuery(getEnterpriseCompanyDetails)

  const hasSeenModal = JSON.parse(
    sessionStorage.getItem('expiredLicenceModalShown') || 'false',
  )

  const [showModal, setShowModal] = useState(!hasSeenModal)

  useEffect(() => {
    if (hasSeenModal) return

    logAction({
      variables: {
        action: 'enterprise-company-view-expired-licence-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'licenceExpired',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!showModal) return null

  return (
    <Modal
      setIsOpen={setShowModal}
      headerColor="pink"
      modalHeader="Your licence has expired"
      hideFooter
      beforeClose={() => {
        sessionStorage.setItem('expiredLicenceModalShown', 'true')
      }}
    >
      <p>
        Your licence{' '}
        {data?.currentCompany.contractEndDate ? (
          <>
            ended on{' '}
            <strong>
              {moment(data?.currentCompany.contractEndDate).format(
                'Do MMM YYYY',
              )}
            </strong>
          </>
        ) : (
          'has ended'
        )}
        . Please email{' '}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> to renew
        your licence.
      </p>
      <p>
        Please note that all your data will be deleted after 90 days of your
        licence expiry. All short links will redirect to an error page.
      </p>
    </Modal>
  )
}

export const EnterpriseSuspendedLicenceModal = () => {
  const { companyID, workspaceID, userID, userPermission } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const { data } = useQuery(getEnterpriseCompanyDetails)

  const hasSeenModal = JSON.parse(
    sessionStorage.getItem('suspendedLicenceModalShown') || 'false',
  )

  const [showModal, setShowModal] = useState(!hasSeenModal)

  useEffect(() => {
    if (hasSeenModal) return

    logAction({
      variables: {
        action: 'enterprise-company-view-suspended-licence-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'licenceSuspended',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!showModal) return null

  return (
    <Modal
      setIsOpen={setShowModal}
      headerColor="pink"
      modalHeader="Your account is suspended"
      // Only support users should be able to close this modal
      hideCloseButton={!isSupportUser(userPermission)}
      closeonEsc={isSupportUser(userPermission)}
      hideFooter
      beforeClose={() => {
        sessionStorage.setItem('suspendedLicenceModalShown', 'true')
      }}
    >
      <p>
        Your licence{' '}
        {data?.currentCompany.contractEndDate ? (
          <>
            ended on{' '}
            <strong>
              {moment(data?.currentCompany.contractEndDate).format(
                'Do MMM YYYY',
              )}
            </strong>
          </>
        ) : (
          'has ended'
        )}
        . Please email{' '}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> to renew
        your licence.
      </p>
      <p>
        Please note that all your data will be deleted after 90 days of your
        licence expiry. All short links will redirect to an error page.
      </p>
    </Modal>
  )
}
