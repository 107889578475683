import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import _ from 'lodash'

import Button from './button'
import { BiLine } from './counter'
import Input from './input'
import { LoadingLabel } from './loader'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { RequestBrandedDomainModal } from './feature-blocker-modals'
import {
  currentUserDetails,
  selectedAppLinkDomainReactive,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import {
  getShortLinkCandidates,
  validateShortLinkCandidate,
} from '../api/graphql/track-create-client'
import { maxCustomLinkAliasLength } from '../api/constants'
import RefreshIcon from '../assets/icon-refresh.svg'
import { getItemByKeyValue, replaceCustomLinkCharacters } from '../helpers'
import { getUserData } from '../helpers/local-client'
import {
  addGeneratedCustomLinkToStorage,
  CustomDomainType,
  getValidAliasesByDomain,
  removeCustomLinkFromStorage,
  SavedCustomLink,
} from '../helpers/custom-links'
import {
  defaultShortLinkDomain,
  getCustomDomainID,
  maxBatchShortLinks,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import styles from '../styles/custom-link-fields.module.scss'

interface CustomLinkAliasMainProps {
  className?: string
  customLinkType?: CustomDomainType
  /** States how many links the batch alias represents. Only changes logic if > 1 */
  batchCount?: number
  /** Adds '-0x<suffix> to end of shown value, where suffix is a hexadecimal, but only if customAliasInitialValue is equal to the current alias */
  includeBatchSuffix?: string
  /** User can input their own custom value */
  canUseCustomAlias?: boolean
  /** Used to temporarily disable editing the alias */
  disabled?: boolean
  /** Allows custom alias to be carried from one instance of component to another. It should have already been validated */
  customAliasInitialValue?: string | null
  /** Only used when many alias fields are editable at once */
  reservedAliases?: string[]
  /** Reference index for reservedAliases. If the alias to validate is in the reservedAliases list, it should only be blocked if the index is different */
  index?: number
  showCustomiseMessage?: boolean
  onAliasChange?: (alias: string, status: UrlStatus, isCustom?: boolean) => void
}

interface CustomLinkState {
  alias: string
  isCustom: boolean
  status: UrlStatus
  message: string
}

export const CustomLinkAlias = ({
  className,
  customLinkType = 'shortLink',
  batchCount = 1,
  includeBatchSuffix,
  canUseCustomAlias = true,
  disabled,
  customAliasInitialValue,
  index = 0,
  reservedAliases = [],
  showCustomiseMessage,
  onAliasChange,
}: CustomLinkAliasMainProps) => {
  const { canUseAppLinks } = useCustomLinks()

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )
  const selectedAppLinkDomain = useReactiveVar(selectedAppLinkDomainReactive)

  const [fetchCustomLinkCandidates] = useLazyQuery(getShortLinkCandidates, {
    fetchPolicy: 'network-only',
  })
  const [validateCustomLinkAlias] = useLazyQuery(validateShortLinkCandidate, {
    fetchPolicy: 'network-only',
  })

  const [customLink, setCustomLink] = useState<CustomLinkState>({
    alias: customAliasInitialValue || '',
    isCustom: false,
    status: '',
    message: '',
  })
  // Ensures alias is reset correctly when custom alias is removed (field is emptied and onBlur is triggered)
  const [
    currentRandomlyGeneratedAlias,
    setCurrentRandomlyGeneratedAlias,
  ] = useState(customAliasInitialValue || '')

  const selectedDomain = useMemo(() => {
    return customLinkType === 'appLink'
      ? selectedAppLinkDomain
      : selectedShortLinkDomain
  }, [customLinkType, selectedShortLinkDomain, selectedAppLinkDomain])

  /**
   * Remove existing alias and fetch a new one
   * Without 'using' the existing alias (generating a link)
   */
  const replaceAlias = useCallback(
    async (domainToUse: string, aliasToReplace: string) => {
      const savedData: SavedCustomLink[] = getUserData(domainToUse) || []

      const found = getItemByKeyValue(savedData, 'shortLinkID', aliasToReplace)

      // Only remove existing alias if its batch type is the same
      if (
        found !== -1 &&
        ((found.isBatchAlias && batchCount > 1) ||
          (!found.isBatchAlias && batchCount === 1))
      ) {
        removeCustomLinkFromStorage(aliasToReplace, domainToUse)
      }

      let newAlias: string | null = null

      /**
       * Variables used in this mutation depend on custom link type
       * https://github.com/uplifter-limited/uplifter-graphql-backend/issues/421#issuecomment-2206500778
       */
      const { data } = await fetchCustomLinkCandidates({
        variables: {
          nLinks: batchCount > 1 ? maxBatchShortLinks : 1,
          customDomainID:
            customLinkType === 'shortLink'
              ? getCustomDomainID(domainToUse)
              : undefined,
          deepLinkServiceID:
            customLinkType === 'appLink' ? domainToUse : undefined,
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          // Should only ever be one availableLinkID, and never batched
          case 'ShortLinkCandidates':
            newAlias = shortLinkCandidates.availableLinkIDs[0] || null

            if (newAlias) {
              addGeneratedCustomLinkToStorage({
                shortLinkID: shortLinkCandidates.availableLinkIDs[0],
                domainID: domainToUse,
              })
            }

            break
          // Only called if batchCount > 1
          case 'ShortLinkBatchCandidates':
            newAlias = shortLinkCandidates.bulkStart

            addGeneratedCustomLinkToStorage({
              shortLinkID: newAlias,
              domainID: domainToUse,
              isBatchAlias: true,
            })
            break
          default:
            break
        }
      }

      if (newAlias) {
        setCurrentRandomlyGeneratedAlias(newAlias)
      }

      return newAlias
    },
    [customLinkType, batchCount],
  )

  /** Resets the custom link alias to the first one found */
  const resetAlias = useCallback(
    async (domainToUse: string) => {
      setCustomLink({
        alias: '',
        isCustom: false,
        status: 'refetching',
        message: '',
      })

      if (onAliasChange) onAliasChange('', 'refetching')

      // Valid aliases should not be custom on initial fetch
      const validAliases = getValidAliasesByDomain(domainToUse, {
        returnBatchAliases: batchCount > 1,
      })

      // Use the first available alias
      let aliasToUse = validAliases[0]?.shortLinkID || ''

      if (validAliases.length === 0) {
        /**
         * Variables used in this mutation depend on custom link type
         * https://github.com/uplifter-limited/uplifter-graphql-backend/issues/421#issuecomment-2206500778
         */
        const { data } = await fetchCustomLinkCandidates({
          variables: {
            nLinks: batchCount > 1 ? maxBatchShortLinks : 1,
            customDomainID:
              customLinkType === 'shortLink'
                ? getCustomDomainID(domainToUse)
                : undefined,
            deepLinkServiceID:
              customLinkType === 'appLink' ? domainToUse : undefined,
          },
        })

        if (data) {
          const { shortLinkCandidates } = data

          switch (shortLinkCandidates.__typename) {
            case 'ShortLinkBatchCandidates':
              addGeneratedCustomLinkToStorage({
                shortLinkID: shortLinkCandidates.bulkStart,
                domainID: domainToUse,
                isBatchAlias: true,
              })

              aliasToUse = shortLinkCandidates.bulkStart
              break
            default:
              shortLinkCandidates.availableLinkIDs.forEach((shortLinkID) => {
                addGeneratedCustomLinkToStorage({
                  shortLinkID,
                  domainID: domainToUse,
                })
              })

              // eslint-disable-next-line prefer-destructuring
              aliasToUse = shortLinkCandidates.availableLinkIDs[0]
              break
          }
        }
      }

      setCustomLink({
        alias: aliasToUse,
        isCustom: false,
        status: '',
        message: '',
      })

      setCurrentRandomlyGeneratedAlias(aliasToUse)

      if (onAliasChange) onAliasChange(aliasToUse, '')
    },
    [customLinkType, batchCount],
  )

  const validateCustomAlias = useCallback(
    async (domainToUse: string, aliasToValidate: string) => {
      if (!canUseCustomAlias) return

      // No need to validate empty string
      if (aliasToValidate === '') {
        setCustomLink({
          alias: aliasToValidate,
          isCustom: true,
          status: 'invalid',
          message: 'Alias cannot be empty.',
        })

        if (onAliasChange) onAliasChange(aliasToValidate, 'invalid', true)

        return
      }

      // Validate custom alias
      // If matches batch alias pattern, do not allow
      if (/-0x(.*)/.test(aliasToValidate)) {
        setCustomLink({
          alias: aliasToValidate,
          isCustom: true,
          status: 'invalid',
          message: 'This alias is not valid.',
        })

        if (onAliasChange) onAliasChange(aliasToValidate, 'invalid', true)

        return
      }

      const isMaxLength = aliasToValidate.length > maxCustomLinkAliasLength

      if (isMaxLength) {
        setCustomLink({
          alias: aliasToValidate,
          isCustom: true,
          status: 'invalid',
          message: 'Link alias cannot be more than **1024 characters**.',
        })

        if (onAliasChange) onAliasChange(aliasToValidate, 'invalid', true)

        return
      }

      let found: SavedCustomLink | -1 = -1
      let aliasIsAvailable = false

      // Check if alias is reserved
      if (reservedAliases.includes(aliasToValidate)) {
        // If it's reserved by a different index, do not allow it
        if (reservedAliases[index] !== aliasToValidate) {
          setCustomLink({
            alias: aliasToValidate,
            isCustom: true,
            status: 'invalid',
            message: 'Reserved by another link.',
          })

          if (onAliasChange) onAliasChange(aliasToValidate, 'invalid', true)

          return
        }
      }

      // Check if alias has already been validated for domain
      const savedData: SavedCustomLink[] = getUserData(domainToUse) || []

      found = getItemByKeyValue(savedData, 'shortLinkID', aliasToValidate)

      if (
        found !== -1 &&
        found.isAvailable
        // * The checks below are no longer necessary, since bulk- and single- alias checks are the same now
        // &&
        // ((found.isBatchAlias && batchCount > 1) ||
        //   (!found.isBatchAlias && batchCount === 1))
      ) {
        const { availableUntil } = found

        const now = new Date(Date.now())

        const diff = moment(availableUntil).utc().diff(moment(now).utc())
        const isExpired = diff < 0

        if (!isExpired) {
          // Can be used
          aliasIsAvailable = true
        } else {
          // Needs to be rechecked
          found = -1
          removeCustomLinkFromStorage(aliasToValidate, domainToUse)
        }
      }

      if (found === -1) {
        setCustomLink((curr) => ({
          ...curr,
          alias: aliasToValidate,
          isCustom: true,
          status: 'validating',
        }))

        if (onAliasChange) onAliasChange(aliasToValidate, 'validating', true)

        const { data: validateShortLinkData } = await validateCustomLinkAlias({
          variables: {
            testCandidate: aliasToValidate,
            customDomainID:
              customLinkType === 'shortLink'
                ? getCustomDomainID(domainToUse)
                : undefined,
            deepLinkServiceID:
              customLinkType === 'appLink' ? domainToUse : undefined,
          },
        })

        if (validateShortLinkData) {
          const {
            shortLinkID,
            availableUntil,
            isAvailable,
          } = validateShortLinkData.track.validateShortLinkCandidate

          addGeneratedCustomLinkToStorage({
            shortLinkID,
            domainID: domainToUse,
            isAvailable,
            availableUntil: availableUntil || undefined,
            isCustom: true,
            isBatchAlias: batchCount > 1,
          })

          if (isAvailable) aliasIsAvailable = true
        }
      }

      setCustomLink({
        alias: aliasToValidate,
        isCustom: true,
        status: aliasIsAvailable ? 'valid' : 'invalid',
        message: aliasIsAvailable
          ? `Alias **available**.`
          : `Alias **already in use**.${
              domainToUse === '' || domainToUse === defaultShortLinkDomain
                ? ' Upgrade to a branded domain or enter a different alias.'
                : ''
            }`,
      })

      if (onAliasChange)
        onAliasChange(
          aliasToValidate,
          aliasIsAvailable ? 'valid' : 'invalid',
          true,
        )
    },
    [customLinkType, batchCount, onAliasChange, index, reservedAliases],
  )

  // Check custom alias is valid before allowing it, or initialise if not set
  useEffect(() => {
    if (typeof customAliasInitialValue === 'string') {
      validateCustomAlias(selectedDomain, customAliasInitialValue || '')
    } else {
      resetAlias(selectedDomain)
    }
  }, [selectedDomain, customAliasInitialValue])

  // App links should not show for unpaid accounts
  if (customLinkType === 'appLink' && !canUseAppLinks) {
    return null
  }

  return (
    <div className={styles.aliasContainer}>
      <Input
        name="short-link"
        className={classNames(className, styles.shortLinkInput, {
          [styles.shortLinkInputAnimated]: [
            'refetching',
            'validating',
          ].includes(customLink.status),
          [styles.editable]: canUseCustomAlias,
          [styles.disabled]: disabled,
          [styles.error]: !['', 'valid'].includes(customLink.status),
        })}
        readOnly={!canUseCustomAlias || disabled}
        placeholder="Creating short link..."
        delay={600}
        value={`${customLink.alias}${
          customAliasInitialValue === customLink.alias &&
          typeof includeBatchSuffix === 'string'
            ? `-0x${includeBatchSuffix}`
            : ''
        }`}
        beforeChange={replaceCustomLinkCharacters}
        onValueChange={(newAlias) => {
          validateCustomAlias(
            selectedDomain,
            newAlias.replace(/-0x[a-f\d]+/, ''),
          )
        }}
        // Reset alias to randomly generated one if left empty
        onBlur={() => {
          if (customLink.alias === '' && customLink.isCustom) {
            setCustomLink({
              alias: currentRandomlyGeneratedAlias,
              isCustom: false,
              status: '',
              message: '',
            })

            if (onAliasChange) onAliasChange(currentRandomlyGeneratedAlias, '')
          }
        }}
        suffix={
          <>
            <Tooltip
              id="refresh-alias-tooltip"
              tooltipMessage={
                customLink.status === 'refetching'
                  ? ''
                  : 'Generate random alias'
              }
              tooltipPosition="top-end"
            >
              <Button
                variant="iconOnly"
                className={styles.shortLinkInputButton}
                isDisabled={customLink.status === 'refetching' || disabled}
                icon={{
                  src: RefreshIcon,
                  alt: 'Refresh alias',
                  imgHeight: 16,
                }}
                onPress={async () => {
                  setCustomLink((curr) => ({
                    ...curr,
                    alias: '',
                    status: 'refetching',
                  }))

                  if (onAliasChange) onAliasChange('', 'refetching')

                  const newAlias = await replaceAlias(
                    selectedDomain,
                    customLink.alias,
                  )

                  if (!newAlias) {
                    // TODO: Throw?

                    return
                  }

                  setCustomLink({
                    alias: newAlias,
                    isCustom: false,
                    status: '',
                    message: '',
                  })

                  if (onAliasChange) onAliasChange(newAlias, '')
                }}
              />
            </Tooltip>
          </>
        }
      />
      {!['', 'refetching'].includes(customLink.status) &&
      customLink.alias !== customAliasInitialValue ? (
        <>
          <div
            className={classNames(
              styles.validationCheckShortLink,
              styles.validationCheck,
              {
                [styles.urlIsBad]: customLink.status === 'invalid',
                [styles.urlIsGood]: customLink.status === 'valid',
              },
            )}
          >
            {customLink.status === 'validating' ? (
              <LoadingLabel label="Checking availability" />
            ) : (
              <>
                {!!customLink.message && (
                  <div
                    className={classNames(styles.validationCheckItem, {
                      [styles.urlIsBad]: customLink.status === 'invalid',
                      [styles.urlIsGood]: customLink.status === 'valid',
                    })}
                  >
                    <ReactMarkdown linkTarget="_blank">
                      {customLink.message}
                    </ReactMarkdown>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {showCustomiseMessage && canUseCustomAlias && (
            <BiLine arrowTop className={styles.editableLinkBiLine}>
              Make me <strong>memorable</strong>!
            </BiLine>
          )}
        </>
      )}
    </div>
  )
}

interface CustomLinkFullProps {
  className?: string
  domainSelectorClassName?: string
  aliasClassName?: string
  customLinkType?: CustomDomainType
  domainSelectorOnly?: boolean
  /** Provide a value to show a fixed-test value for the custom link domain instead of a dropdown */
  domainIsEditable?: boolean
  /** Sets if batch alias or individual aliases should be fetched */
  batchCount?: number
  onAliasChange?: (alias: string, status: UrlStatus, isCustom?: boolean) => void
  /** User can input their own custom value */
  canUseCustomAlias?: boolean
  showCustomiseMessage?: boolean
}

export const CustomLinkFull = ({
  className,
  domainSelectorClassName,
  aliasClassName,
  customLinkType = 'shortLink',
  domainSelectorOnly = false,
  domainIsEditable = true,
  batchCount = 1,
  canUseCustomAlias = true,
  showCustomiseMessage,
  onAliasChange,
}: CustomLinkFullProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )
  const selectedAppLinkDomain = useReactiveVar(selectedAppLinkDomainReactive)

  const {
    canUseAppLinks,
    availableShortLinkDomains,
    availableAppLinkDomains,
    updateSelectedDomain,
  } = useCustomLinks()

  const [showBrandedDomainModal, setShowBrandedDomainModal] = useState(false)

  const fullSelectedDomain =
    customLinkType === 'appLink'
      ? availableAppLinkDomains.find(
          ({ domainID }) => domainID === selectedAppLinkDomain,
        )
      : availableShortLinkDomains.find(
          ({ domainID }) => domainID === selectedShortLinkDomain,
        )

  const fullDomainOptions =
    customLinkType === 'appLink'
      ? availableAppLinkDomains
      : availableShortLinkDomains

  // App links should not show for unpaid accounts
  if (customLinkType === 'appLink' && !canUseAppLinks) {
    return null
  }

  return (
    <>
      <div className={classNames(className, styles.fullShortLinkWrapper)}>
        {!domainIsEditable ? (
          <p className={styles.staticDomainValue} style={{ margin: 0 }}>
            {fullSelectedDomain?.domainValue}
          </p>
        ) : (
          <SelectBox
            id="domain-selector"
            className={classNames(
              domainSelectorClassName,
              styles.domainSelectorContainer,
            )}
            labelKey="domainName"
            valueKey="domainID"
            value={fullSelectedDomain}
            options={fullDomainOptions}
            onChange={(newValue) => {
              if (newValue?.domainID) {
                updateSelectedDomain(newValue.domainID, customLinkType)
              }
            }}
          >
            {workspaceID && (
              <Button
                variant="text"
                className={styles.addButton}
                onPressStart={() => setShowBrandedDomainModal(true)}
              >
                Branded {customLinkType === 'shortLink' ? 'short' : 'deep'} link
                +
              </Button>
            )}
          </SelectBox>
        )}
        {!domainSelectorOnly && fullSelectedDomain && (
          <>
            <p className={styles.shortLinkInputPrefixDiv}>/</p>
            <CustomLinkAlias
              className={classNames(aliasClassName)}
              customLinkType={customLinkType}
              batchCount={batchCount}
              canUseCustomAlias={canUseCustomAlias}
              showCustomiseMessage={showCustomiseMessage}
              onAliasChange={onAliasChange}
            />
          </>
        )}
      </div>
      {showBrandedDomainModal && (
        <RequestBrandedDomainModal
          onHideModal={setShowBrandedDomainModal}
          linkType={customLinkType}
        />
      )}
    </>
  )
}
