import React, { useCallback, useMemo, useState } from 'react'
import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client'
import numeral from 'numeraljs'

import Modal from './modal'
import { SuccessText } from './typography'
import { SelectedCode } from '../api/types'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { deleteBatchCodes } from '../api/graphql/track-actions-client'
import { AVAILABLE_CODE_IDS } from '../api/graphql/track-view-client'
import { getItemByKeyValue, isAdminUser } from '../helpers'
import { removeCustomLinkFromStorage } from '../helpers/custom-links'
import useCustomLinks from '../hooks/useCustomLinks'
import useLogAction from '../hooks/useLogAction'
import {
  defaultAppDeepLinkDomain,
  defaultShortLinkDomain,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'

const removeCachedItem = (cacheIndexes: number[], existingItems: any[]) => {
  return existingItems.filter((_, index) => !cacheIndexes.includes(index))
}

export interface BulkDeleteModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedCodes: SelectedCode[]
  cacheID?: string
  cacheIndexes?: number[]
  cacheIndexOffset?: number
  rowsPerPage?: number
  refetchData: () => Promise<void>
}

export default function BulkDeleteModal({
  setShowModal,
  selectedCodes,
  cacheID,
  cacheIndexes,
  cacheIndexOffset = 0,
  rowsPerPage = 0,
  refetchData,
}: BulkDeleteModalProps) {
  const client = useApolloClient()

  const linkText = useReactiveVar(linkOrCode)

  const logAction = useLogAction()

  const {
    availableShortLinkDomains,
    availableAppLinkDomains,
  } = useCustomLinks()

  const { userPermission, userEmail } = useReactiveVar(currentUserDetails)

  const [deleteCodes] = useMutation(deleteBatchCodes)

  const isAdmin = isAdminUser(userPermission)

  const [deletingLinks, setDeletingLinks] = useState(false)
  const [allLinksDeleted, setAllLinksDeleted] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [refetchRequired, setRefetchRequired] = useState(false)

  const loading = useMemo(() => {
    return (
      [...availableShortLinkDomains, ...availableAppLinkDomains].length === 0
    )
  }, [availableShortLinkDomains, availableAppLinkDomains])

  const selectedCodesCanDelete = useMemo(() => {
    if (!userEmail) return []

    if (isAdminUser(userPermission)) return selectedCodes

    return selectedCodes.filter((item) => {
      const found = getItemByKeyValue(selectedCodes, 'cID', item.cID)

      return found !== -1 && found.e === userEmail
    })
  }, [userPermission, selectedCodes, userEmail])

  const message = useMemo(() => {
    if (!isAdmin && selectedCodes.length !== selectedCodesCanDelete.length) {
      if (selectedCodesCanDelete.length === 0)
        return `You can only delete ${linkText}s that you have created. Contact an admin user to delete ${linkText}s created by other users.`

      return `You can only delete ${
        linkText ? 'links' : 'codes'
      } that you have created. Would you like to delete your ${numeral(
        selectedCodesCanDelete.length,
      ).format('0,0')} ${linkText}${
        selectedCodesCanDelete.length > 1 ? 's' : ''
      }?`
    }

    return `Are you sure you want to delete the ${numeral(
      selectedCodes.length,
    ).format('0,0')} selected ${linkText}${
      selectedCodes.length > 1 ? 's' : ''
    }?`
  }, [isAdmin, linkText, selectedCodes, selectedCodesCanDelete])

  const isDisabled = !isAdmin && selectedCodesCanDelete.length === 0

  const checkCacheAndRefetch = useCallback(() => {
    const cachedData = client.cache.readFragment({
      id: cacheID,
      fragment: AVAILABLE_CODE_IDS,
    })

    const numberOfItems =
      cachedData?.codeID?.slice(cacheIndexOffset, rowsPerPage)?.length || 0

    // Row data needs updating
    if (numberOfItems < rowsPerPage) {
      setRefetchRequired(true)
    }
  }, [cacheID, cacheIndexOffset, rowsPerPage, client])

  return (
    <Modal
      beforeClose={refetchRequired ? refetchData : undefined}
      setIsOpen={setShowModal}
      modalHeader={
        allLinksDeleted
          ? `Link${selectedCodes.length === 1 ? '' : 's'} deleted`
          : 'Confirm deletion'
      }
      yesText="Delete"
      yesButtonDisabled={
        isDisabled || loading || allLinksDeleted || deleteError
      }
      yesButtonLoading={deletingLinks}
      onYes={
        allLinksDeleted
          ? undefined
          : async () => {
              setDeletingLinks(true)

              const codesToDeleteByID = selectedCodesCanDelete.map(
                ({ sL, cID }) => {
                  const dLDomain = availableAppLinkDomains?.find(
                    ({ domainName }) => sL.includes(domainName),
                  )?.domainID

                  return {
                    cID,
                    sL,
                    sLDomainName:
                      dLDomain || !sL
                        ? undefined
                        : getCustomDomainID(
                            getDomain(sL).replace('https://', ''),
                          ),
                    dLDomain,
                  }
                },
              )

              const domainsToDelete = codesToDeleteByID.reduce(
                (acc, curr) => {
                  if (curr.dLDomain) {
                    if (!acc.find(({ domain }) => domain === curr.dLDomain)) {
                      acc.push({
                        domain: curr.dLDomain,
                        isDeepLink: true,
                      })
                    }
                  } else if (curr.sLDomainName) {
                    if (
                      !acc.find(({ domain }) => domain === curr.sLDomainName)
                    ) {
                      acc.push({
                        domain: curr.sLDomainName,
                        isDeepLink: false,
                      })
                    }
                  } else if (!acc.find(({ domain }) => domain === null)) {
                    acc.push({ domain: null })
                  }

                  return acc
                },
                [] as {
                  domain: string | null
                  isDeepLink?: boolean
                }[],
              )

              const deleteObjects: {
                codeIDList: string[]
                aliasList: string[]
                customDomainID?: string
                deepLinkServiceID?: string
              }[] = []

              domainsToDelete.forEach(({ domain, isDeepLink }) => {
                // App link domains
                if (isDeepLink) {
                  deleteObjects.push({
                    codeIDList: codesToDeleteByID
                      .filter(({ dLDomain }) => dLDomain === domain)
                      .map(({ cID }) => cID),
                    aliasList: codesToDeleteByID
                      .filter(({ dLDomain }) => dLDomain === domain)
                      .map(({ sL }) => sL),
                    deepLinkServiceID: domain || defaultAppDeepLinkDomain,
                  })

                  return
                }

                // Short link domains
                if (domain) {
                  const found = availableShortLinkDomains.find(
                    ({ domainName }) =>
                      domainName ===
                      (getCustomDomainID(domain.replace('https://', '')) ||
                        defaultShortLinkDomain),
                  )

                  deleteObjects.push({
                    codeIDList: codesToDeleteByID
                      .filter(({ sLDomainName }) => sLDomainName === domain)
                      .map(({ cID }) => cID),
                    aliasList: codesToDeleteByID
                      .filter(({ sLDomainName }) => sLDomainName === domain)
                      .map(({ sL }) => sL),
                    customDomainID:
                      getCustomDomainID(found?.domainID) || undefined,
                  })

                  return
                }

                // Basic links
                deleteObjects.push({
                  codeIDList: codesToDeleteByID
                    .filter(
                      ({ sLDomainName, dLDomain }) =>
                        !sLDomainName && !dLDomain,
                    )
                    .map(({ cID }) => cID),
                  aliasList: codesToDeleteByID
                    .filter(
                      ({ sLDomainName, dLDomain }) =>
                        !sLDomainName && !dLDomain,
                    )
                    .map(({ sL }) => sL),
                })
              })

              try {
                // eslint-disable-next-line no-restricted-syntax
                for (const deleteObject of deleteObjects) {
                  // eslint-disable-next-line no-await-in-loop
                  const { errors } = await deleteCodes({
                    variables: deleteObject,
                    update: (cache, { data }) => {
                      if (
                        cacheID &&
                        cacheIndexes &&
                        cacheIndexes.length > 0 &&
                        data
                      ) {
                        cache.modify({
                          id: cacheID,
                          fields: {
                            author: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            codeDef: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            codeID: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            createdTime: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            fullLink: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            shortLink: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            versionNumber: (existing) =>
                              removeCachedItem(cacheIndexes, existing),
                            totalCodes: (existing) =>
                              Math.max(0, existing - cacheIndexes.length),
                          },
                        })
                      }
                    },
                  })

                  deleteObject.aliasList.forEach((alias) => {
                    if (!alias) return

                    if (deleteObject.customDomainID) {
                      removeCustomLinkFromStorage(
                        alias,
                        deleteObject.customDomainID,
                      )
                    } else if (deleteObject.deepLinkServiceID) {
                      removeCustomLinkFromStorage(
                        alias.split('/').pop() || '',
                        deleteObject.deepLinkServiceID,
                      )
                    }
                  })

                  // TODO: Fetch more codes if necessary

                  if (errors && errors.length > 0) throw new Error()
                }

                checkCacheAndRefetch()
              } catch {
                setDeleteError(true)
                setDeletingLinks(false)
              }

              logAction({
                variables: {
                  action: 'bulk-delete-codes',
                  functionName: 'bulkDelete',
                  pagePath: '/track/view-links',
                  websiteSection: 'track',
                  extra: JSON.stringify(selectedCodesCanDelete),
                },
              })

              setDeletingLinks(false)
              setAllLinksDeleted(true)
            }
      }
      noText={allLinksDeleted ? 'Close' : 'Back'}
      footerContent={
        <>{deleteError && <span>There was an issue deleting the codes</span>}</>
      }
    >
      {allLinksDeleted ? (
        <SuccessText color="black">
          Your {linkText}
          {selectedCodes.length === 1 ? ' has' : 's have'} been successfully
          deleted.
        </SuccessText>
      ) : (
        <p style={{ marginBottom: 0 }}>{message}</p>
      )}
    </Modal>
  )
}
