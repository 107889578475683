import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { Paddle as PaddleType } from '@paddle/paddle-js'
import classNames from 'classnames'
import moment from 'moment'
import numeral from 'numeraljs'
import { nanoid } from 'nanoid'

import Button, { NavigateButton } from './button'
import { BetaLabel } from './counter'
import { Toggle } from './input-v2'
import { LoadingLabel, Preloader } from './loader'
import ProgressBar from './progress-bar'
import {
  DowngradePlanModal,
  PaddleChangeBillingPeriodModal,
  RequestContractUpgradeModal,
  UpdateSubscriptionUsersModal,
  UpgradePlanModal,
} from './subscription-management-modals'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { BoldText, ErrorMessage, Heading } from './typography'
import { activeConnectors } from '../api/apollo/variables'
import {
  getCompanyDetails,
  listAzureMarketplaceQueries,
  undoCancelPaddlePlan,
} from '../api/graphql/company-client'
import { getEnterpriseCompanyDetails } from '../api/graphql/support-client'
import { PeopleIcon } from '../assets/svgs/people-icons'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import integrationsData from '../connections/connectors-data'
import { calendarBookingLink, currencyLookup } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import usePaddle from '../hooks/usePaddle'
import useSubscriptionLevel, {
  FeatureCategory,
  paddleSubscriptionIDs,
  subscriptionLevelDetails,
  SubscriptionLevels,
} from '../hooks/useSubscriptionLevel'
import styles from '../styles/subscription-comparison.module.scss'

interface LimitsProgressBarsProps {
  showLinks?: boolean
  showWorkspaces?: boolean
  showConnectors?: boolean
  showUsers?: boolean
}

export const LimitsProgressBars = ({
  showLinks,
  showWorkspaces,
  showConnectors,
  showUsers,
}: LimitsProgressBarsProps) => {
  const activeConnectorsCount = useReactiveVar(activeConnectors).length

  const history = useHistory()

  const {
    isEnterprise,
    isMicrosoftMarketplace,
    featureLimits: {
      workspaces: maxWorkspaces,
      connectors: maxConnectors,
      links: linksLimit,
    },
    currentLicenceLimit,
    getCompanyLinksCount,
  } = useSubscriptionLevel()

  const [loadingStates, setLoadingStates] = useState({
    links: showLinks && !isEnterprise,
    workspaces: showWorkspaces,
    users: showUsers,
  })
  const [companyLinksCount, setCompanyLinksCount] = useState(0)

  // Count total links in company
  useEffect(() => {
    // Don't show links limit if it's infinite
    if (!Number.isFinite(linksLimit) || linksLimit === -1) return

    const setLinksCount = async () => {
      const linksCount = await getCompanyLinksCount()

      setCompanyLinksCount(linksCount)
      setLoadingStates((curr) => ({ ...curr, links: false }))
    }

    setLinksCount()
  }, [isEnterprise])

  const { data: companyData } = useQuery(getCompanyDetails)

  const [
    showRequestContractUpgradeModal,
    setShowRequestContractUpgradeModal,
  ] = useState(false)

  const { currentWorkspaceCount, currentUserCount } = useMemo(() => {
    return {
      currentWorkspaceCount: companyData?.currentCompany.accountCount || 0,
      currentUserCount: companyData?.currentCompany.userCount || 0,
    }
  }, [companyData])

  const workspaceProgress = useMemo(() => {
    if (!currentWorkspaceCount || maxWorkspaces < 1) {
      return null
    }

    return (currentWorkspaceCount / maxWorkspaces) * 100
  }, [currentWorkspaceCount, maxWorkspaces])

  const userProgress = useMemo(() => {
    if (!currentLicenceLimit) return null

    if (currentUserCount || currentLicenceLimit < 1) {
      setLoadingStates((curr) => ({ ...curr, users: false }))
    }

    return (currentUserCount / currentLicenceLimit) * 100
  }, [currentUserCount, currentLicenceLimit])

  // Remove loading states
  useEffect(() => {
    if (workspaceProgress === null && userProgress === null) return

    setLoadingStates((curr) => ({
      ...curr,
      workspaces: workspaceProgress === null,
      users: userProgress === null,
    }))
  }, [workspaceProgress, userProgress])

  if (
    !showLinks &&
    Number.isFinite(linksLimit) &&
    linksLimit === -1 &&
    !showWorkspaces &&
    !showConnectors &&
    !showUsers
  ) {
    return null
  }

  return (
    <>
      <div className={styles.limitsProgress}>
        {showLinks && linksLimit > 0 && Number.isFinite(linksLimit) && (
          <div
            className={styles.progressIndicator}
            onClick={
              isEnterprise && isMicrosoftMarketplace
                ? undefined
                : () => {
                    if (isEnterprise) {
                      setShowRequestContractUpgradeModal(true)

                      return
                    }

                    history.push('/upgrade')
                  }
            }
          >
            <Tooltip
              id="links-limit-tooltip"
              className={styles.limitTooltip}
              tooltipMessage="The number of links you have vs the limit on your current plan."
            >
              {loadingStates.links ? (
                <LoadingLabel label="Checking links" />
              ) : (
                <p>
                  <strong>{numeral(companyLinksCount).format('0,0')}</strong> of{' '}
                  <strong>
                    {numeral(linksLimit).format('0,0')} links monitored
                  </strong>
                </p>
              )}
              <ProgressBar
                className={styles.progressBar}
                percentage={(companyLinksCount / linksLimit) * 100}
                showPercent={false}
                thin
                superThin
                useUrgency
              />
            </Tooltip>
          </div>
        )}
        {showWorkspaces && maxWorkspaces > 0 && (
          <div
            className={styles.progressIndicator}
            onClick={
              isEnterprise && isMicrosoftMarketplace
                ? undefined
                : () => {
                    if (isEnterprise) {
                      setShowRequestContractUpgradeModal(true)

                      return
                    }

                    history.push('/upgrade')
                  }
            }
          >
            <Tooltip
              id="workspaces-limit-tooltip"
              className={styles.limitTooltip}
              tooltipMessage="The number of workspaces you have vs the limit on your current plan."
            >
              {loadingStates.workspaces ? (
                <LoadingLabel label="Checking workspaces" />
              ) : (
                <p>
                  <strong>{currentWorkspaceCount}</strong> of{' '}
                  <strong>
                    {maxWorkspaces} workspace{maxWorkspaces === 1 ? '' : 's'}
                  </strong>
                </p>
              )}
              <ProgressBar
                className={styles.progressBar}
                percentage={workspaceProgress || 0}
                showPercent={false}
                thin
                superThin
                useUrgency
              />
            </Tooltip>
          </div>
        )}
        {showConnectors && maxConnectors > 0 && (
          <div
            className={styles.progressIndicator}
            onClick={
              isEnterprise && isMicrosoftMarketplace
                ? undefined
                : () => {
                    if (isEnterprise) {
                      setShowRequestContractUpgradeModal(true)

                      return
                    }

                    history.push('/upgrade')
                  }
            }
          >
            <Tooltip
              id="connectors-limit-tooltip"
              className={styles.limitTooltip}
              tooltipMessage="The number of connectors you have vs the limit on your current plan."
            >
              <p>
                <strong>{activeConnectorsCount}</strong> of{' '}
                <strong>
                  {maxConnectors} connector{maxConnectors === 1 ? '' : 's'}
                </strong>
              </p>
              <ProgressBar
                className={styles.progressBar}
                percentage={(activeConnectorsCount / maxConnectors) * 100}
                showPercent={false}
                thin
                superThin
                useUrgency
              />
            </Tooltip>
          </div>
        )}
        {showUsers &&
          !!currentLicenceLimit &&
          currentLicenceLimit > 0 &&
          Number.isFinite(currentLicenceLimit) && (
            <div
              className={styles.progressIndicator}
              onClick={
                isEnterprise && isMicrosoftMarketplace
                  ? undefined
                  : () => {
                      if (isEnterprise) {
                        setShowRequestContractUpgradeModal(true)

                        return
                      }

                      history.push('/upgrade')
                    }
              }
            >
              <Tooltip
                id="users-limit-tooltip"
                className={styles.limitTooltip}
                tooltipMessage="The number of users you have vs the limit on your current plan."
              >
                {loadingStates.users ? (
                  <LoadingLabel label="Checking users" />
                ) : (
                  <p>
                    <strong>{currentUserCount}</strong> of{' '}
                    <strong>
                      {currentLicenceLimit} user
                      {currentLicenceLimit === 1 ? '' : 's'}
                    </strong>
                  </p>
                )}
                <ProgressBar
                  className={styles.progressBar}
                  percentage={userProgress || 0}
                  showPercent={false}
                  thin
                  superThin
                  useUrgency
                />
              </Tooltip>
            </div>
          )}
      </div>
      {showRequestContractUpgradeModal && (
        <RequestContractUpgradeModal
          onHideModal={setShowRequestContractUpgradeModal}
        />
      )}
    </>
  )
}

const CurrentSubscriptionDetails = () => {
  const {
    loadingSubscriptionInfo,
    isEnterprise,
    isPaddle,
    isMicrosoftMarketplace,
    subscriptionBillingPeriod,
    paddleData,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const [
    fetchEnterpriseDetails,
    { data: enterpriseBillingData, loading: loadingEnterpriseData },
  ] = useLazyQuery(getEnterpriseCompanyDetails)

  useEffect(() => {
    if (isEnterprise) fetchEnterpriseDetails()
  }, [isEnterprise])

  const currentBillDetails = useMemo(() => {
    if (loadingSubscriptionInfo) return null

    if (isEnterprise && !isMicrosoftMarketplace && enterpriseBillingData) {
      const {
        contractStartDate,
        contractEndDate,
        totalContractValue,
      } = enterpriseBillingData.currentCompany

      return {
        contractValue:
          typeof totalContractValue === 'number'
            ? numeral(enterpriseBillingData.currentCompany.totalContractValue)
                .format('0,0.00')
                .replace('.00', '')
            : null,
        contractStartDate: contractStartDate
          ? moment(
              enterpriseBillingData.currentCompany.contractStartDate,
            ).format('Do MMM YYYY')
          : null,
        contractEndDate: contractEndDate
          ? moment(enterpriseBillingData.currentCompany.contractEndDate).format(
              'Do MMM YYYY',
            )
          : null,
      }
    }

    if (isPaddle && paddleData) {
      const {
        localPaddleData,
        paddleSubscriptionCurrency,
        paddleSubscriptionUnitPrice,
        paddleSubscriptionQuantity,
        paddleSubscriptionNextBillDate,
        paddleSubscriptionId,
      } = paddleData.currentCompany

      if (!paddleSubscriptionQuantity || !paddleSubscriptionNextBillDate) {
        return null
      }

      const legacyPaddleSubscriptionIDs = Object.keys(
        paddleSubscriptionIDs,
      ).filter((id) => !Number.isNaN(parseInt(id, 10)))

      // Legacy subscriptions are shown in pounds, not pence. THey are numbers, not prefixed with 'pri_'
      if (
        paddleSubscriptionId &&
        (legacyPaddleSubscriptionIDs.includes(paddleSubscriptionId) ||
          !Number.isNaN(parseInt(paddleSubscriptionId, 10)))
      ) {
        if (!paddleSubscriptionCurrency || !paddleSubscriptionUnitPrice) {
          return null
        }

        return {
          billAmount: `${currencyLookup[paddleSubscriptionCurrency]}${numeral(
            parseInt(paddleSubscriptionUnitPrice, 10) *
              parseInt(paddleSubscriptionQuantity, 10),
          )
            .format('0,0.00')
            .replace('.00', '')}`,
          nextBillDate: moment(paddleSubscriptionNextBillDate).format(
            'Do MMM YYYY',
          ),
        }
      }

      if (!localPaddleData) {
        return null
      }

      /** Details of the current users plan */
      const { localPrice, localCurrency } = localPaddleData

      if (!localPrice || !localCurrency) {
        return null
      }

      return {
        billAmount: `${currencyLookup[localCurrency]}${numeral(
          parseInt(localPrice, 10) * parseInt(paddleSubscriptionQuantity, 10),
        )
          .format('0,0.00')
          .replace('.00', '')}`,
        nextBillDate: moment(paddleSubscriptionNextBillDate).format(
          'Do MMM YYYY',
        ),
      }
    }

    if (isMicrosoftMarketplace && microsoftSubscriptionData) {
      const { currency, price, quantity, periodEndDate } =
        microsoftSubscriptionData.currentCompany.microsoftSubscriptionData || {}

      if (!currency || !price || !quantity || !periodEndDate) {
        return null
      }

      return {
        billAmount: `${currencyLookup[currency]}${
          parseInt(price, 10) * quantity
        }`,
        nextBillDate: moment(periodEndDate).format('Do MMM YYYY'),
      }
    }

    return null
  }, [
    isEnterprise,
    isPaddle,
    paddleData,
    isMicrosoftMarketplace,
    microsoftSubscriptionData,
    enterpriseBillingData,
  ])

  const scheduledCancellationDate = useMemo(() => {
    if (!isPaddle || !paddleData) {
      return null
    }

    return paddleData.currentCompany.paddleSubscriptionScheduledCancel
      ? moment(
          paddleData.currentCompany.paddleSubscriptionScheduledCancel,
        ).format('Do MMM YYYY')
      : null
  }, [isPaddle, paddleData])

  return (
    <div className={styles.currentBill}>
      {isPaddle && scheduledCancellationDate && (
        <ErrorMessage>
          Cancelled, you have access until {scheduledCancellationDate}.
        </ErrorMessage>
      )}
      {isEnterprise && !isMicrosoftMarketplace ? (
        <>
          {currentBillDetails?.contractValue && (
            <p>
              Contract value:{' '}
              {loadingEnterpriseData ? (
                <></>
              ) : (
                <BoldText>£{currentBillDetails.contractValue}</BoldText>
              )}
            </p>
          )}
          {currentBillDetails?.contractStartDate && (
            <p>
              Start date:{' '}
              <>
                {loadingEnterpriseData ? (
                  <></>
                ) : (
                  <BoldText>{currentBillDetails.contractStartDate}</BoldText>
                )}
              </>
            </p>
          )}
          {currentBillDetails?.contractEndDate && (
            <p>
              End date:{' '}
              {loadingEnterpriseData ? (
                <></>
              ) : (
                <BoldText>{currentBillDetails.contractEndDate}</BoldText>
              )}
            </p>
          )}
        </>
      ) : (
        <>
          <p>
            Current bill:{' '}
            <BoldText>{currentBillDetails?.billAmount} + VAT</BoldText>
          </p>
          <p>
            Next payment:{' '}
            <BoldText>{currentBillDetails?.nextBillDate}</BoldText>
          </p>
          {subscriptionBillingPeriod && (
            <p>
              Billing period:{' '}
              <BoldText>
                {subscriptionBillingPeriod[0].toUpperCase()}
                {subscriptionBillingPeriod?.slice(1)}
              </BoldText>
            </p>
          )}
        </>
      )}
    </div>
  )
}

const PaddleActions = () => {
  const logAction = useLogAction()

  const {
    isPaddle,
    subscriptionBillingPeriod,
    paddleData,
  } = useSubscriptionLevel()

  const [undoCancellation, { loading: undoingCancellation }] = useMutation(
    undoCancelPaddlePlan,
  )

  const [
    showSwitchBillingPeriodModal,
    setShowSwitchBillingPeriodModal,
  ] = useState(false)

  const scheduledCancellationDate = useMemo(() => {
    if (!isPaddle || !paddleData) {
      return null
    }

    return paddleData.currentCompany.paddleSubscriptionScheduledCancel
      ? moment(
          paddleData.currentCompany.paddleSubscriptionScheduledCancel,
        ).format('Do MMM YYYY')
      : null
  }, [isPaddle, paddleData])

  if (!isPaddle || !paddleData) return null

  return (
    <>
      {!scheduledCancellationDate && subscriptionBillingPeriod && (
        <Button
          className={styles.upgradeButton}
          onPress={() => setShowSwitchBillingPeriodModal(true)}
        >
          Switch to{' '}
          {subscriptionBillingPeriod === 'annual' ? 'monthly' : 'annual'}{' '}
          {subscriptionBillingPeriod === 'monthly' ? (
            <BetaLabel className={styles.savePill} title="Save 17%" />
          ) : (
            ''
          )}
        </Button>
      )}
      {scheduledCancellationDate && (
        <Button
          variant="secondary"
          className={styles.upgradeButton}
          loading={undoingCancellation}
          onPress={async () => {
            logAction({
              variables: {
                action: 'click-undo-cancel-subscription',
                websiteSection: 'upgrade',
                pagePath: window.location.pathname,
                functionName: 'clickDowngrade',
              },
            })

            await undoCancellation()

            window.location.replace('/settings?show=billing')
          }}
        >
          Undo cancellation
        </Button>
      )}
      {showSwitchBillingPeriodModal && (
        <PaddleChangeBillingPeriodModal
          setIsOpen={setShowSwitchBillingPeriodModal}
        />
      )}
    </>
  )
}

interface SubscriptionTierDetailsProps {
  hideCurrent?: boolean
  isCurrent?: boolean
  tierLevel: SubscriptionLevels
  Paddle?: PaddleType
  showIcon?: boolean
  /** Whether the tier price should show at all */
  showTierPrice?: boolean
  /** Whether monthly or annual price should show */
  showAnnualPrice?: boolean
  /** E.g. Enterprise does not have a fixed price */
  showFromPrice?: boolean
  showSubscriptionDetails?: boolean
  showLimits?: boolean
  showFeatures?: boolean
  topFeature?: FeatureCategory
  showCompareFeaturesButton?: boolean
  children?: React.ReactNode
}

const SubscriptionTierDetails = React.forwardRef<
  HTMLDivElement,
  SubscriptionTierDetailsProps
>(
  (
    {
      hideCurrent,
      isCurrent,
      tierLevel,
      Paddle,
      showIcon,
      showTierPrice,
      showAnnualPrice,
      showFromPrice,
      showSubscriptionDetails,
      showLimits,
      showFeatures,
      topFeature,
      showCompareFeaturesButton,
      children,
    },
    ref,
  ) => {
    const {
      title,
      icon,
      tagline,
      annualID,
      monthlyID,
      defaultMonthlyPrice,
      whatYouCanDo,
      whatYouGet,
    } = subscriptionLevelDetails[tierLevel]

    const history = useHistory()

    const [tierPrice, setTierPrice] = useState(defaultMonthlyPrice)

    const { topWhatYouCanDo, topWhatYouGet } = useMemo(() => {
      if (!topFeature) return {}

      return {
        topWhatYouCanDo:
          whatYouCanDo.find(({ id }) => id === `${tierLevel}-${topFeature}`) ||
          null,
        topWhatYouGet:
          whatYouGet.find(({ id }) => id === `${tierLevel}-${topFeature}`) ||
          null,
      }
    }, [whatYouCanDo, topFeature, tierLevel])

    // Get localised prices
    useEffect(() => {
      if (
        !Paddle ||
        (!showAnnualPrice && !monthlyID) ||
        (showAnnualPrice && !annualID)
      ) {
        return
      }

      Paddle.PricePreview({
        items: [
          {
            priceId: showAnnualPrice
              ? (annualID as string)
              : (monthlyID as string),
            quantity: 1,
          },
        ],
      }).then(({ data }) => {
        const { details } = data

        let priceToShow = details.lineItems[0].formattedTotals.subtotal

        if (showAnnualPrice) {
          const annualPriceString =
            details.lineItems[0].formattedTotals.subtotal
          const currencySymbol =
            (annualPriceString.match(/^[^\d]+/) as string[])[0] || ''
          const annualPrice = parseFloat(
            annualPriceString.replace(/^[^\d]+/, '').replace(',', ''),
          )
          const monthlyPrice = (annualPrice / 12).toFixed(2)
          priceToShow = `${currencySymbol}${monthlyPrice}`
        }

        setTierPrice(priceToShow)
      })
    }, [annualID, monthlyID, showAnnualPrice, Paddle])

    return (
      <InnerBox
        ref={ref}
        className={classNames(styles.tierContainer, {
          [styles.highlight]: isCurrent,
        })}
      >
        {!hideCurrent && (
          <div id={`${title}-current-plan`} className={styles.currentPlan}>
            {isCurrent && <BetaLabel title="Current plan" />}
          </div>
        )}
        {showIcon && (
          <div
            id={`${title}-tier-image`}
            className={classNames(styles.tierImage, styles.teamTierImg)}
          >
            <img src={icon} alt={`${title} tier`} />
          </div>
        )}
        <Heading type={1} className={styles.tierTitle}>
          {title}
        </Heading>
        <div id={`${title}-tagline`} className={styles.tagline}>
          <p>{tagline}</p>
        </div>
        {showTierPrice && (
          <div id={`${title}-price`} className={styles.price}>
            <p>
              <strong>
                {showFromPrice ? 'From ' : ''}
                {tierPrice?.replace('.00', '')}
              </strong>{' '}
              (ex. VAT)
              <br />
              Per user, per month.
            </p>
          </div>
        )}
        {(showFeatures || showCompareFeaturesButton) && (
          <div id={`${title}-features`}>
            {showFeatures ? (
              <div className={styles.whatYouCanDo}>
                <Heading type={4} align="left">
                  What you can do:
                </Heading>
                <ul className={styles.featureList}>
                  {topWhatYouCanDo && (
                    <li>
                      <Tooltip
                        id={topWhatYouCanDo.id || nanoid()}
                        tooltipMessage={topWhatYouCanDo.tooltip}
                        maxWidth={200}
                      >
                        <strong>{topWhatYouCanDo.text}</strong>
                      </Tooltip>
                    </li>
                  )}
                  {whatYouCanDo.map(({ id, text, tooltip }) => {
                    if (topFeature && id === `${tierLevel}-${topFeature}`) {
                      return null
                    }

                    return (
                      <li key={id}>
                        <Tooltip
                          id={`${id}-tooltip`}
                          tooltipMessage={tooltip}
                          maxWidth={200}
                        >
                          {text}
                        </Tooltip>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ) : (
              <>
                {showCompareFeaturesButton &&
                  window.location.pathname !== '/upgrade' && (
                    <NavigateButton
                      className={styles.textButton}
                      onPress={() => history.push('/upgrade')}
                    >
                      Compare features
                    </NavigateButton>
                  )}
              </>
            )}
          </div>
        )}
        {showFeatures && (
          <div id={`${title}-what-you-get`} className={styles.whatYouGet}>
            <Heading type={4} align="left">
              What you get:
            </Heading>
            <ul className={styles.featureList} style={{ marginBottom: 0 }}>
              {topWhatYouGet && (
                <li>
                  <Tooltip
                    id={topWhatYouGet.id || nanoid()}
                    tooltipMessage={topWhatYouGet.tooltip}
                    maxWidth={200}
                  >
                    <strong>{topWhatYouGet.text}</strong>
                  </Tooltip>
                </li>
              )}
              {whatYouGet.map(({ id, text, tooltip }) => {
                if (topFeature && id === `${tierLevel}-${topFeature}`) {
                  return null
                }

                return (
                  <li key={id}>
                    <Tooltip
                      id={`whatYouGet-${id}-tooltip`}
                      tooltipMessage={tooltip}
                      maxWidth={200}
                    >
                      {text}
                    </Tooltip>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        <div id={`${title}-tier-details`} className={styles.tierDetails}>
          {showSubscriptionDetails && isCurrent && (
            <CurrentSubscriptionDetails />
          )}
          {showLimits && isCurrent && (
            <LimitsProgressBars
              showLinks
              showWorkspaces
              showConnectors
              showUsers
            />
          )}
        </div>
        <div id={`${title}-tier-actions`} className={styles.tierActions}>
          {children}
          {isCurrent && <PaddleActions />}
        </div>
      </InnerBox>
    )
  },
)

const AddRemoveUserLicencesButton = () => {
  const logAction = useLogAction()

  const [showUsersModal, setShowUsersModal] = useState(false)

  return (
    <>
      <Button
        className={styles.upgradeButton}
        onPress={() => {
          // @ts-ignore
          if (window.dataLayer && window.dataLayer.push) {
            // @ts-ignore
            window.dataLayer.push({
              event: 'click-buy-more-licences',
            })
          }

          logAction({
            variables: {
              action: 'click-buy-more-licences',
              websiteSection: 'upgrade',
              pagePath: window.location.pathname,
              functionName: 'clickUpgrade',
            },
          })

          setShowUsersModal(true)
        }}
      >
        Add or remove users <PeopleIcon style={{ marginLeft: 8 }} />
      </Button>
      {showUsersModal && (
        <UpdateSubscriptionUsersModal onHideModal={setShowUsersModal} />
      )}
    </>
  )
}

/** Set the heights of the 3 tier elements to be the same */
const getMinHeights = (
  startupContainer: HTMLDivElement | null,
  businessContainer: HTMLDivElement | null,
  enterpriseContainer: HTMLDivElement | null,
  ids: string[],
) => {
  ids.forEach((id) => {
    const startupIdContainer = startupContainer?.querySelector(`#Startup-${id}`)
    const businessIdContainer = businessContainer?.querySelector(
      `#Business-${id}`,
    )
    const enterpriseIdContainer = enterpriseContainer?.querySelector(
      `#Enterprise-${id}`,
    )

    const minHeight = Math.max(
      startupIdContainer?.clientHeight || 0,
      businessIdContainer?.clientHeight || 0,
      enterpriseIdContainer?.clientHeight || 0,
    )

    startupIdContainer?.setAttribute('style', `height: ${minHeight}px`)
    businessIdContainer?.setAttribute('style', `height: ${minHeight}px`)
    enterpriseIdContainer?.setAttribute('style', `height: ${minHeight}px`)
  })
}

interface FromPage {
  from: string
}

interface SubscriptionComparisonProps {
  hideBillingPeriodToggle?: boolean
  highlightCurrent?: boolean
  hideStartupTier?: boolean
  hideBusinessTier?: boolean
  hideIcons?: boolean
  hideTierPrices?: boolean
  showSubscriptionDetails?: boolean
  showLimits?: boolean
  hideUpgradeActions?: boolean
  showDowngradeActions?: boolean
  hideFeatures?: boolean
  topFeature?: FeatureCategory
}

const SubscriptionComparison = ({
  hideBillingPeriodToggle,
  highlightCurrent,
  hideStartupTier,
  hideBusinessTier,
  hideIcons,
  hideTierPrices,
  showSubscriptionDetails,
  showLimits,
  hideUpgradeActions,
  showDowngradeActions,
  hideFeatures,
  topFeature,
}: SubscriptionComparisonProps) => {
  // Set which page the user came from when logging actions
  const { state } = useLocation<FromPage>()

  const mainContainerRef = useRef<HTMLDivElement>(null)
  const startupContainerRef = useRef<HTMLDivElement>(null)
  const businessContainerRef = useRef<HTMLDivElement>(null)
  const enterpriseContainerRef = useRef<HTMLDivElement>(null)
  const annualToggleRef = useRef<HTMLParagraphElement>(null)

  const logAction = useLogAction()

  const {
    loadingSubscriptionInfo,
    isStartup,
    isBusiness,
    isEnterprise,
    paddleData,
    isMicrosoftMarketplace,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const { Paddle } = usePaddle()

  const { data: companyData } = useQuery(getCompanyDetails)

  const [
    fetchMicrosoftEnterprisePrice,
    { data: microsoftPriceData },
  ] = useLazyQuery(listAzureMarketplaceQueries)

  const [showAnnualPrices, setShowAnnualPrices] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState<
    false | 'business' | 'enterprise'
  >(false)
  const [showDowngradeModal, setShowDowngradeModal] = useState<
    false | 'startup' | 'business'
  >(false)
  const [showUpgradeContractModal, setShowUpgradeContractModal] = useState(
    false,
  )

  const currentWorkspaceCount = useMemo(() => {
    return companyData?.currentCompany.accountCount || 0
  }, [companyData])

  const currentUserCount = useMemo(() => {
    return companyData?.currentCompany.userCount || 0
  }, [companyData])

  /**
   * MS Marketplace subscribers do not need to get in touch to upgrade to enterprise
   * The price is fixed already (currently £59 per user per month, Aug 2023)
   */
  const microsoftEnterprisePrice = useMemo(() => {
    if (!microsoftPriceData) return null

    const enterpriseTierData = microsoftPriceData.accountSettings.azureMarketplaceQueries.listPlans.find(
      (plan) => plan.displayName === 'Enterprise',
    )?.billingTerms[0]

    if (!enterpriseTierData) return null

    return `${currencyLookup[enterpriseTierData.currency]}${numeral(
      enterpriseTierData.price,
    )
      .format('0,0.00')
      .replace('.00', '')}`
  }, [microsoftPriceData])

  const paddleSubscriptionCancelled = useMemo(() => {
    if (!paddleData) return false

    return !!paddleData.currentCompany.paddleSubscriptionScheduledCancel
  }, [paddleData])

  // Fetch details for MS Marketplace subscriptions
  useEffect(() => {
    if (!isMicrosoftMarketplace) return

    fetchMicrosoftEnterprisePrice()
  }, [isMicrosoftMarketplace])

  // Default to annual prices if user is paying annually
  useEffect(() => {
    if (!paddleData) return

    const { subscriptionLevel } = paddleData.currentCompany

    if (
      paddleSubscriptionIDs[subscriptionLevel] &&
      paddleSubscriptionIDs[subscriptionLevel].billingPeriod === 'annual'
    ) {
      setShowAnnualPrices(true)
    }
  }, [paddleData])

  // Get connection information for all available connectors
  useEffect(() => {
    const getConnectors = async () => {
      const activeConnections = await Promise.all(
        integrationsData.map(async (connector) => {
          const details = await connector.getConnectionDetails()

          return details?.connected ? connector.connectorID : null
        }),
      ).then((results) => results.filter(Boolean))

      activeConnectors(activeConnections as string[])
    }

    if (showLimits) {
      getConnectors()
    }
  }, [showLimits])

  // Set min heights of sections to be equal
  useLayoutEffect(() => {
    if (loadingSubscriptionInfo || !(paddleData || microsoftSubscriptionData))
      return

    const startupContainer = hideStartupTier
      ? null
      : startupContainerRef.current
    const businessContainer = businessContainerRef.current
    const enterpriseContainer = enterpriseContainerRef.current

    getMinHeights(startupContainer, businessContainer, enterpriseContainer, [
      'current-plan',
      'tier-image',
      'tagline',
      'price',
      'tier-details',
      'tier-actions',
      'features',
      'what-you-get',
    ])
  }, [
    loadingSubscriptionInfo,
    startupContainerRef.current,
    businessContainerRef.current,
    enterpriseContainerRef.current,
    hideStartupTier,
    hideTierPrices,
    hideUpgradeActions,
    showSubscriptionDetails,
    showLimits,
    hideFeatures,
    paddleData,
    microsoftSubscriptionData,
  ])

  return (
    <>
      {!hideBillingPeriodToggle && (
        <div
          className={styles.annualToggle}
          style={{ width: mainContainerRef.current?.clientWidth }}
        >
          <p style={{ width: annualToggleRef.current?.offsetWidth }}>
            <BoldText>Monthly billing</BoldText>
          </p>
          <div className={styles.toggle}>
            <Toggle
              checked={showAnnualPrices}
              onChange={(checked) => setShowAnnualPrices(checked)}
              onColor="#007d9b"
              offColor="#cbd5e0"
              checkedIcon={<></>}
              uncheckedIcon={<></>}
            />
          </div>
          <p ref={annualToggleRef}>
            <BoldText>Annual billing</BoldText>
            <BetaLabel title="Save 17%" />
          </p>
        </div>
      )}
      <OuterBox ref={mainContainerRef} className={styles.container}>
        {loadingSubscriptionInfo ? (
          <Preloader />
        ) : (
          <>
            {!hideStartupTier && (
              <SubscriptionTierDetails
                ref={startupContainerRef}
                hideCurrent={!highlightCurrent}
                isCurrent={isStartup}
                tierLevel="startup"
                Paddle={Paddle}
                showIcon={!hideIcons}
                showTierPrice={!hideTierPrices}
                showAnnualPrice={showAnnualPrices}
                showSubscriptionDetails={showSubscriptionDetails}
                showLimits={showLimits}
                showFeatures={!hideFeatures}
                topFeature={topFeature}
              >
                {!hideUpgradeActions &&
                  !paddleSubscriptionCancelled &&
                  isStartup && <AddRemoveUserLicencesButton />}
                {showDowngradeActions &&
                  !paddleSubscriptionCancelled &&
                  isBusiness && (
                    <Button
                      variant="secondary"
                      className={styles.upgradeButton}
                      onPress={() => {
                        logAction({
                          variables: {
                            action: 'click-downgrade-to-startup',
                            websiteSection: 'upgrade',
                            pagePath: window.location.pathname,
                            functionName: 'clickDowngrade',
                          },
                        })

                        setShowDowngradeModal('startup')
                      }}
                    >
                      Downgrade
                    </Button>
                  )}
              </SubscriptionTierDetails>
            )}
            {!hideBusinessTier && (
              <SubscriptionTierDetails
                ref={businessContainerRef}
                hideCurrent={!highlightCurrent}
                isCurrent={isBusiness}
                tierLevel="business"
                Paddle={Paddle}
                showIcon={!hideIcons}
                showTierPrice={!hideTierPrices}
                showAnnualPrice={showAnnualPrices}
                showSubscriptionDetails={showSubscriptionDetails}
                showLimits={showLimits}
                showFeatures={!hideFeatures}
                topFeature={topFeature}
              >
                {!hideUpgradeActions &&
                  !paddleSubscriptionCancelled &&
                  isStartup && (
                    <Button
                      icon={{
                        src: UpgradeRocket,
                        alt: 'Upgrade',
                        iconAfter: true,
                      }}
                      className={styles.upgradeButton}
                      onPress={() => {
                        // @ts-ignore
                        if (window.dataLayer && window.dataLayer.push) {
                          // @ts-ignore
                          window.dataLayer.push({
                            event: `start-${
                              isMicrosoftMarketplace
                                ? 'ms-marketplace'
                                : 'paddle'
                            }-upgrade`,
                          })
                        }

                        logAction({
                          variables: {
                            action: `start-${
                              isMicrosoftMarketplace
                                ? 'ms-marketplace'
                                : 'paddle'
                            }-upgrade`,
                            extra: JSON.stringify({
                              from: state?.from || '',
                            }),
                            websiteSection: 'upgrade',
                            pagePath: '/upgrade',
                            functionName: `upgrade${
                              isMicrosoftMarketplace
                                ? 'MicrosoftMarketplace'
                                : 'Paddle'
                            }`,
                          },
                        })

                        setShowUpgradeModal('business')
                      }}
                    >
                      Upgrade now
                    </Button>
                  )}
                {!hideUpgradeActions &&
                  !paddleSubscriptionCancelled &&
                  isBusiness && <AddRemoveUserLicencesButton />}
                {showDowngradeActions &&
                  isEnterprise &&
                  isMicrosoftMarketplace && (
                    <Button
                      variant="secondary"
                      className={styles.upgradeButton}
                      onPress={() => {
                        // @ts-ignore
                        if (window.dataLayer && window.dataLayer.push) {
                          // @ts-ignore
                          window.dataLayer.push({
                            event: 'click-ms-marketplace-downgrade-to-business',
                          })
                        }

                        logAction({
                          variables: {
                            action:
                              'click-ms-marketplace-downgrade-to-business',
                            websiteSection: 'upgrade',
                            pagePath: window.location.pathname,
                            functionName: 'clickDowngrade',
                          },
                        })

                        setShowDowngradeModal('business')
                      }}
                    >
                      Downgrade to Business
                    </Button>
                  )}
              </SubscriptionTierDetails>
            )}
            <SubscriptionTierDetails
              ref={enterpriseContainerRef}
              hideCurrent={!highlightCurrent}
              isCurrent={isEnterprise}
              tierLevel="enterprise"
              Paddle={Paddle}
              showIcon={!hideIcons}
              showTierPrice={!hideTierPrices}
              showAnnualPrice={showAnnualPrices}
              showSubscriptionDetails={showSubscriptionDetails}
              showLimits={showLimits}
              showFromPrice
              showFeatures={!hideFeatures}
              topFeature={topFeature}
              showCompareFeaturesButton={!hideFeatures}
            >
              {!hideUpgradeActions && (
                <>
                  {!paddleSubscriptionCancelled && !isEnterprise && (
                    <Button
                      className={styles.upgradeButton}
                      icon={
                        isMicrosoftMarketplace && microsoftEnterprisePrice
                          ? {
                              src: UpgradeRocket,
                              alt: 'Upgrade',
                              iconAfter: true,
                            }
                          : undefined
                      }
                      onPress={() => {
                        if (
                          isMicrosoftMarketplace &&
                          microsoftEnterprisePrice
                        ) {
                          setShowUpgradeModal('enterprise')
                        } else {
                          if (window.dataLayer && window.dataLayer.push) {
                            window.dataLayer.push({
                              event: 'request-enterprise-upgrade',
                            })
                          }

                          logAction({
                            variables: {
                              action: 'request-enterprise-upgrade',
                              extra: JSON.stringify({
                                from: state?.from || '',
                              }),
                              websiteSection: 'upgrade',
                              pagePath: '/upgrade',
                              functionName: 'upgradeToEnterprise',
                            },
                          })

                          window.open(calendarBookingLink, '_blank')
                        }
                      }}
                    >
                      {isMicrosoftMarketplace && microsoftEnterprisePrice ? (
                        'Upgrade now'
                      ) : (
                        <span>
                          Get a quote{' '}
                          <span role="img" aria-label="Chat emoji">
                            💬
                          </span>
                        </span>
                      )}
                    </Button>
                  )}
                  {isEnterprise && !isMicrosoftMarketplace && (
                    <Button
                      className={styles.upgradeButton}
                      style={{ marginBottom: 0 }}
                      onPress={() => setShowUpgradeContractModal(true)}
                    >
                      Increase limits
                    </Button>
                  )}
                </>
              )}
            </SubscriptionTierDetails>
          </>
        )}
      </OuterBox>
      {showDowngradeModal && (
        <DowngradePlanModal
          setIsOpen={() => setShowDowngradeModal(false)}
          downgradeTo={showDowngradeModal}
          currentUserCount={currentUserCount}
          currentWorkspaceCount={currentWorkspaceCount}
        />
      )}
      {showUpgradeModal && (
        <UpgradePlanModal
          setIsOpen={() => setShowUpgradeModal(false)}
          upgradeTo={showUpgradeModal}
        />
      )}
      {showUpgradeContractModal && (
        <RequestContractUpgradeModal
          onHideModal={setShowUpgradeContractModal}
        />
      )}
    </>
  )
}

export default SubscriptionComparison
