import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import ButtonTabs from '../components/button-tabs'
import CompanySettings from '../components/company-settings'
import Intro from '../components/intro'
import Layout from '../components/layout'
import RegularUserSettings from '../components/regular-user-settings'
import SiteWrapper from '../components/site-wrapper'
import SupportSettings from '../components/support-settings'
import WorkspaceSettings from '../components/workspace-settings'
import { getUrlQuery, isAdminUser, isSupportUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'

export const settingsTabs = {
  company: 'Company',
  workspace: 'Workspace',
  you: 'You',
}

const showOptions = [
  'billing',
  'users',
  'workspaces',
  'publicAPI',
  'workspaceDetails',
  'workspacePreferences',
  'workspaceConnections',
  'profile',
  'preferences',
] as const
type ShowOptions = typeof showOptions[number]

const checkShowValue = (show: any): show is ShowOptions => {
  return showOptions.includes(show)
}

const setTab = (show: ShowOptions): keyof typeof settingsTabs => {
  switch (show) {
    case 'profile':
    case 'preferences':
      return 'you'
    case 'workspaceDetails':
    case 'workspacePreferences':
    case 'workspaceConnections':
      return 'workspace'
    case 'billing':
    case 'users':
    case 'workspaces':
    case 'publicAPI':
    default:
      return 'company'
  }
}

export default function SettingsPage() {
  const { userPermission, workspaceName } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [currentTab, setCurrentTab] = useState<
    keyof typeof settingsTabs | 'support'
  >('company')

  const query = getUrlQuery()

  const adminSettingsTabs = useMemo(() => {
    if (!isAdminUser(userPermission)) return []

    const _adminSettingsTabs: { key: string; label: string }[] = []

    if (isSupportUser(userPermission)) {
      _adminSettingsTabs.push({ key: 'support', label: 'Super admin' })
    }

    Object.entries(settingsTabs).forEach(([key, label]) => {
      _adminSettingsTabs.push({ key, label })
    })

    return _adminSettingsTabs
  }, [userPermission])

  useEffect(() => {
    const section = query?.get('show')

    if (section && checkShowValue(section)) {
      setCurrentTab(setTab(section))
    }
  }, [])

  const buttonTabs = useMemo(() => {
    const tabs = [
      <CompanySettings />,
      <WorkspaceSettings />,
      <RegularUserSettings />,
    ]

    if (isSupportUser(userPermission)) {
      tabs.unshift(<SupportSettings />)
    }

    return tabs
  }, [userPermission])

  return (
    <>
      <SiteWrapper>
        <Layout>
          <Intro title="Settings">
            <p>
              {isAdminUser(userPermission)
                ? `Manage settings for your entire company, just this workspace (${workspaceName}) or just you.`
                : 'Manage your profile settings and preferences.'}
            </p>
          </Intro>
          {isAdminUser(userPermission) ? (
            <ButtonTabs
              selected={adminSettingsTabs
                .map(({ key }) => key)
                .indexOf(currentTab)}
              isTopOfBox
              tabsLabels={adminSettingsTabs.map(({ label }) => label)}
              type="tabs"
              onChange={(index) => {
                setCurrentTab(
                  adminSettingsTabs.map(({ key }) => key)[index] as
                    | keyof typeof settingsTabs
                    | 'support',
                )

                logAction({
                  variables: {
                    action: 'track-create-change-tab',
                    pagePath: '/track/create-links',
                    functionName: 'changeTab',
                    websiteSection: 'track',
                    extra: adminSettingsTabs.map(({ label }) => label)[
                      index
                    ] as keyof typeof settingsTabs | 'support',
                  },
                })
              }}
            >
              {buttonTabs}
            </ButtonTabs>
          ) : (
            <RegularUserSettings topBorder />
          )}
        </Layout>
      </SiteWrapper>
    </>
  )
}
