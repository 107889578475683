import React, { useCallback, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import { Preloader } from './loader'
import Modal from './modal'
import Row, { Slot } from './row'
import Tag from './tag'
import { ErrorMessage, Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  fetchAdobeAuthLink,
  fetchGaAuthLink,
} from '../api/graphql/oauth-client'
import AAImage from '../assets/logos/adobe-analytics.svg'
import AnalyticsImage from '../assets/logos/google-analytics-icon.svg'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import styles from '../styles/connect-analytics-blocker.module.scss'

interface ConnectButtonProps {
  adobe?: boolean
  reconnect?: boolean
  className?: string
}

export const ConnectButton = ({
  adobe,
  reconnect,
  className,
}: ConnectButtonProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [getGaAuthLink] = useLazyQuery(fetchGaAuthLink)
  const [getAaAuthLink] = useLazyQuery(fetchAdobeAuthLink)

  const [connectionFailed, setConnectionFailed] = useState(false)

  const onClick = useCallback(async () => {
    try {
      const { data } = await getGaAuthLink({
        variables: {
          data: JSON.stringify({
            connectId: workspaceID,
            fromId: workspaceID,
            reconnect,
          }),
        },
      })

      if (!data) throw new Error('Link not found')

      window.location.assign(data.accountSettings.dataSourceQueries.gaAuthLink)
    } catch {
      setConnectionFailed(true)
    }
  }, [workspaceID])

  const onClickAdobe = useCallback(async () => {
    try {
      const { data } = await getAaAuthLink({
        variables: {
          data: JSON.stringify({
            connectId: workspaceID,
            fromId: workspaceID,
            reconnect,
          }),
        },
      })

      if (!data) throw new Error('Link not found')

      window.location.assign(
        data.accountSettings.dataSourceQueries.adobeAuthLink,
      )
    } catch {
      setConnectionFailed(true)
    }
  }, [workspaceID])

  if (!workspaceID) return <Preloader />

  return (
    <>
      <div
        className={classNames(styles.connectCard, className)}
        role="button"
        tabIndex={-1}
        onClick={adobe ? onClickAdobe : onClick}
        onKeyDown={adobe ? onClickAdobe : onClick}
      >
        <Row>
          <Slot className={styles.logo}>
            <img
              src={adobe ? AAImage : AnalyticsImage}
              alt={`Connect to ${adobe ? 'Adobe' : 'Google'} Analytics`}
              style={{ display: 'block' }}
            />
          </Slot>
          <Slot className={styles.textBoxOuter}>
            <Row>
              <Slot className={styles.textBox}>
                <div>
                  <Tag className={styles.tag} />
                </div>
                <h3 className={styles.title}>
                  Connect {adobe ? 'Adobe' : 'Google'} Analytics
                </h3>
              </Slot>
            </Row>
          </Slot>
        </Row>
      </div>
      {connectionFailed && (
        <ErrorMessage>Connection failed. Please try again later.</ErrorMessage>
      )}
    </>
  )
}

interface ConnectAnalyticsBlockerModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  reconnect?: boolean
  includeAdobe?: boolean
}

const ConnectAnalyticsBlockerModal = ({
  setIsOpen,
  reconnect,
  includeAdobe = true,
}: ConnectAnalyticsBlockerModalProps) => {
  return (
    <Modal
      setIsOpen={setIsOpen}
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Discover insights <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
    >
      <p>
        Connect to your analytics to import metrics, create reports and find
        insights.
      </p>
      <ConnectButton reconnect={reconnect} />
      {includeAdobe && (
        <ConnectButton
          adobe
          reconnect={reconnect}
          className={styles.marginTop}
        />
      )}
    </Modal>
  )
}

export default ConnectAnalyticsBlockerModal
