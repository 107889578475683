import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'

import { ImgUploader } from './img-uploader'
import { ChangeInput, InputLabel } from './input-v2'
import { Preloader } from './loader'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  deleteQrLogo,
  getCurrentAccountQRDetails,
  getQrLogoImage,
  updateAccountDescription,
  updateQrSettings,
} from '../api/graphql/company-client'
import { uploadAccountLogo, uploadQrLogo } from '../api/REST/account-client'
import { uplifterWebsite } from '../core/constants'
import { makeHexString, getDefaultOption } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-details.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

const WorkspaceDetails = ({ className }: WorkspaceDetailsProps) => {
  const { workspaceID, workspaceName, workspaceHomepage } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const { data: qrData, refetch: refetchQrData } = useQuery(
    getCurrentAccountQRDetails,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  const [getQrCodeLogoImage] = useLazyQuery(getQrLogoImage)

  const [updateWorkspaceDescription] = useMutation(updateAccountDescription)
  const [deleteQrCodeLogo] = useMutation(deleteQrLogo)
  const [updateQrCodeSettings] = useMutation(updateQrSettings)

  const [loadingQrData, setLoadingQrData] = useState(true)

  const [qrCodeImgSources, setQrCodeImgSources] = useState<string[]>([])

  const logoLink = `${qrData?.currentAccount.logoLink || ''}`.replace(
    '/static/logos/PNG/uplogo.png',
    '',
  )

  useEffect(() => {
    if (!qrData) return

    setLoadingQrData(false)
  }, [qrData])

  const { fgColour, bgColour } = useMemo(() => {
    if (qrData) {
      return {
        fgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'fgColour',
          '000000',
        ).replace('#', ''),
        bgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'bgColour',
          'FFFFFF',
        ).replace('#', ''),
      }
    }

    return {
      fgColour: '#000000',
      bgColour: '#FFFFFF',
      qrTransparentLogo: false,
    }
  }, [qrData])

  const qrCodeLogoList = useMemo(() => {
    return qrData ? qrData.currentAccount.qrCodeLogoList : []
  }, [qrData])

  useEffect(() => {
    const loadImages = async () => {
      const images: string[] = []

      const fetchPromises = qrCodeLogoList.map(async (qrCodeLogo) => {
        const { data } = await getQrCodeLogoImage({
          variables: { blobName: qrCodeLogo },
        })

        if (data) {
          images.push(data.currentAccount.qrCodeLogoSrc)
        }
      })

      await Promise.all(fetchPromises)
      setQrCodeImgSources(images)
    }

    if (qrCodeLogoList.length > 0) {
      loadImages()
    }
  }, [qrCodeLogoList])

  return (
    <div className={className}>
      <div className={styles.subsection}>
        <Heading type={3} align="left">
          Details
        </Heading>
        <div className={styles.fieldRow}>
          <div className={styles.field}>
            <InputLabel
              htmlFor="workspace-name"
              tooltip="This is the name of your workspace."
            >
              Workspace name
            </InputLabel>
            <ChangeInput
              id="workspace-name"
              name="workspace-name"
              initialValue={workspaceName || ''}
              onConfirm={async (nextWorkspacename) => {
                await updateWorkspaceDescription({
                  variables: {
                    accountID: workspaceID,
                    updatedAccountName: nextWorkspacename,
                  },
                })

                currentUserDetails({
                  ...currentUserDetails(),
                  workspaceName: nextWorkspacename,
                })
              }}
            />
          </div>
          <div className={styles.field}>
            <InputLabel
              htmlFor="workspace-homepage"
              tooltip={`This is your homepage (use full domain name, e.g. ${uplifterWebsite}).`}
            >
              Homepage
            </InputLabel>
            <ChangeInput
              id="workspace-homepage"
              name="workspace-homepage"
              allowEmpty
              initialValue={workspaceHomepage ?? ''}
              onConfirm={async (nextHomepage) => {
                await updateWorkspaceDescription({
                  variables: {
                    accountID: workspaceID,
                    updatedAccountDomain: nextHomepage,
                  },
                })

                currentUserDetails({
                  ...currentUserDetails(),
                  workspaceHomepage: nextHomepage,
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.subsection}>
        <InputLabel
          htmlFor="report-logo"
          tooltip="This logo will be displayed on the top right of reports. We support png, gif or jpeg files up to 1MB."
        >
          Report logo
        </InputLabel>
        <ImgUploader
          id="report-logo"
          imgSrc={logoLink}
          uploadFn={async (file: File) => {
            const res = await uploadAccountLogo({ file })

            if (res === true) {
              logAction({
                variables: {
                  action: 'company-logo-changed',
                  extra: '',
                  websiteSection: 'settings',
                  pagePath: '/settings',
                  functionName: 'uploadAccountLogo',
                },
              })

              refetchQrData()

              return true
            }
            return false
          }}
        />
      </div>

      <div className={styles.subsection}>
        <InputLabel
          htmlFor="qr-logo"
          tooltip="Default logo used in the centre of QR codes. Simple versions of your logo work best, ideal size 300px x 300px &amp; less than 1MB."
        >
          QR code
        </InputLabel>
        {loadingQrData ? (
          <Preloader style={{ margin: '16px 0' }} />
        ) : (
          <>
            {qrCodeLogoList.length > 0 ? (
              qrCodeLogoList.map((logoLinkQR, logoLinkIndex) => {
                return (
                  <ImgUploader
                    key={logoLinkQR}
                    imgSrc={qrCodeImgSources[logoLinkIndex]}
                    uploadFn={async (file: File) => {
                      setLoadingQrData(true)
                      try {
                        // Delete existing: Remove when allowing multiple logos
                        await deleteQrCodeLogo({
                          variables: { blobName: logoLinkQR },
                        })

                        const res = await uploadQrLogo({ file })

                        if (res === true) {
                          logAction({
                            variables: {
                              action: 'company-qr-logo-changed',
                              extra: '',
                              websiteSection: 'settings',
                              pagePath: '/settings',
                              functionName: 'uploadQrLogo',
                            },
                          })

                          await refetchQrData()

                          return true
                        }
                      } catch {
                        console.error('Error uploading QR logo')
                      } finally {
                        setLoadingQrData(false)
                      }

                      return false
                    }}
                    deleteFn={async () => {
                      await deleteQrCodeLogo({
                        variables: { blobName: logoLinkQR },
                      })

                      logAction({
                        variables: {
                          action: 'company-qr-logo-deleted',
                          extra: '',
                          websiteSection: 'settings',
                          pagePath: '/settings',
                          functionName: 'deleteQrLogo',
                        },
                      })
                    }}
                  />
                )
              })
            ) : (
              <ImgUploader
                id="qr-logo"
                uploadFn={async (file) => {
                  const res = await uploadQrLogo({
                    file,
                  })

                  if (res === true) {
                    logAction({
                      variables: {
                        action: 'company-qr-logo-changed',
                        extra: '',
                        websiteSection: 'settings',
                        pagePath: '/settings',
                        functionName: 'uploadQrLogo',
                      },
                    })

                    await refetchQrData()

                    return true
                  }
                  return false
                }}
              />
            )}
          </>
        )}
      </div>

      <div className={styles.subsection}>
        <div className={styles.fieldRow}>
          <div className={styles.field}>
            <InputLabel
              htmlFor="qr-code-foreground-color"
              tooltip="Use RGB hex value, e.g. #000000 for black."
            >
              QR code foreground colour
            </InputLabel>
            <ChangeInput
              id="qr-code-foreground-color"
              name="qr-code-foreground-color"
              prefix="#"
              initialValue={fgColour}
              beforeChange={(val) => makeHexString(val)}
              onConfirm={async (newValue) => {
                await updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'fgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }}
            />
          </div>
          <div className={styles.field}>
            <InputLabel
              htmlFor="qr-code-background-color"
              tooltip="Use RGB hex value, e.g. #000000 for black."
            >
              QR code background colour
            </InputLabel>
            <ChangeInput
              id="qr-code-background-color"
              name="qr-code-background-color"
              prefix="#"
              initialValue={bgColour}
              beforeChange={(val) => makeHexString(val)}
              onConfirm={async (newValue) => {
                await updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'bgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkspaceDetails
