import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Button, { ClearButton, DeleteButton } from './button'
import FileDragAndDrop from './file-drag-and-drop'
import { messages } from '../core/constants'
import styles from '../styles/img-uploader.module.scss'

interface ImgUploaderProps {
  id?: string
  className?: string
  imgSrc?: string
  uploadFn: (file: File) => Promise<boolean>
  deleteFn?: (link: string) => Promise<void>
}

export function ImgUploader({
  id,
  className,
  imgSrc,
  uploadFn,
  deleteFn,
}: ImgUploaderProps) {
  const [showUploader, setShowUploader] = useState(!imgSrc)
  const [error, setError] = useState<string | null>(null)

  // Revert back to image when upload finishes
  useEffect(() => {
    setShowUploader(!imgSrc)
  }, [imgSrc])

  // Remove error message when uploader is closed
  useEffect(() => {
    if (!showUploader) {
      setError(null)
    }
  }, [showUploader])

  return (
    <div className={classNames(styles.companyLogoComponent, className)}>
      {showUploader || !imgSrc ? (
        <>
          <FileDragAndDrop
            id={id}
            onDrop={async (acceptedFiles) => {
              if (acceptedFiles.length > 0) {
                const file = acceptedFiles.pop() as File

                const { type, size } = file

                if (
                  type &&
                  size &&
                  type.indexOf('image/') !== -1 &&
                  size < 1038383
                ) {
                  const res = await uploadFn(file)

                  if (res === false) {
                    setError(messages.fileUploadError)
                  }
                } else {
                  setError(messages.fileUploadErrorImageOnly)
                }
              } else {
                setError(messages.fileUploadError)
              }
            }}
          />
          {imgSrc && (
            <ClearButton onPress={() => setShowUploader(false)}>
              Cancel
            </ClearButton>
          )}
          {error && <p className={styles.error}>{error}</p>}
        </>
      ) : (
        <div className={styles.logoWrapper}>
          <img src={imgSrc} alt="logo" />
          <div className={styles.hoverButtons}>
            <Button onPress={() => setShowUploader(true)} variant="secondary">
              Replace
            </Button>
            {!!deleteFn && (
              <DeleteButton
                onPress={async () => {
                  await deleteFn(imgSrc)

                  setShowUploader(true)
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
