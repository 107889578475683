import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'
import classNames from 'classnames'

import { CopyButton } from './button'
import {
  getCurrentAccountQRDetails,
  getQrLogoImage,
} from '../api/graphql/company-client'
import defaultLogoImage from '../assets/logos/uplifter-arrow.png'
import { getItemByKeyValue } from '../helpers'
import { defaultBgColour, defaultFgColour, qrToBlob } from '../helpers/qr-code'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/qr-code.module.scss'

interface ClipboardItemData {
  [mimeType: string]: Blob | string | Promise<Blob | string>
}

interface ClipboardItem {
  readonly types: string[]
  readonly presentationStyle: 'unspecified' | 'inline' | 'attachment'
  getType(): Promise<Blob>
}

declare const ClipboardItem: {
  prototype: ClipboardItem
  new (itemData: ClipboardItemData): ClipboardItem
}

interface useCopyQrCodeProps {
  copyRef: React.RefObject<HTMLDivElement>
  fgColourToUse?: string
  bgColourToUse?: string
  siteSection?: string
  showLogo?: boolean
  qrType?: string
  size?: number
}

export const useCopyQrCode = ({
  copyRef,
  fgColourToUse = '#000',
  bgColourToUse = '#fff',
  siteSection = 'track-create',
  showLogo = false,
  qrType = 'canvas',
  size = 200,
}: useCopyQrCodeProps) => {
  const logAction = useLogAction()

  const copyQrCode = async () => {
    if (copyRef) {
      const { current } = copyRef

      if (current) {
        const blob = await qrToBlob(current, 'png', bgColourToUse)

        if (!blob) return

        const data = new ClipboardItem({
          'image/png': blob,
        })

        // @ts-ignore
        await navigator.clipboard.write([data])

        logAction({
          variables: {
            action: `qr-code-copy-${siteSection}`,
            extra: JSON.stringify({
              fgColour: fgColourToUse,
              bgColour: bgColourToUse,
              showLogo,
              fileType: qrType,
              dimensions: `Copy (${size} x ${size} px)`,
            }),
            websiteSection: 'track',
            pagePath: window.location.pathname,
            functionName: 'copy',
          },
        })
      }
    }
  }

  return copyQrCode
}

interface ImageSettings {
  src: string
  height: number
  width: number
  excavate: boolean
  x?: number
  y?: number
}

interface QRCodePreviewProps {
  url: string
  qrType?: 'svg' | 'canvas'
  siteSection?: string
  size?: number
  fgColour?: string
  bgColour?: string
  accuracyLevel?: 'L' | 'M' | 'Q' | 'H'
  showLogo?: boolean
  /** Overrides default */
  imageSrc?: string
  disableCopy?: boolean
  className?: string
  children?: React.ReactNode
}

const QRCodePreview = forwardRef<HTMLDivElement, QRCodePreviewProps>(
  (
    {
      url,
      qrType = 'canvas',
      siteSection = 'track-create',
      size = 200,
      fgColour,
      bgColour,
      accuracyLevel = 'H',
      showLogo,
      imageSrc,
      disableCopy = false,
      className,
      children,
    },
    ref,
  ) => {
    const { data: qrData, loading: loadingQrLogo } = useQuery(
      getCurrentAccountQRDetails,
    )
    const [getLogoImage] = useLazyQuery(getQrLogoImage)

    const copyRef = useRef<HTMLDivElement>(null)

    const [fgColourToUse, setFgColourToUse] = useState(
      fgColour || defaultFgColour,
    )
    const [bgColourToUse, setBgColourToUse] = useState(
      bgColour || defaultBgColour,
    )
    const [imageToUse, setImageToUse] = useState(imageSrc)

    const copyQrCode = useCopyQrCode({
      copyRef: (ref as React.RefObject<HTMLDivElement>) || copyRef,
      fgColourToUse,
      bgColourToUse,
      siteSection,
      showLogo,
      qrType,
      size,
    })

    // Initial fetch of account QR colours
    useEffect(() => {
      if (
        !qrData ||
        !qrData.currentAccount.qrSettings ||
        qrData.currentAccount.qrSettings.length === 0
      ) {
        return
      }

      // Get saved QR settings
      const { qrSettings } = qrData.currentAccount

      const useFgColour = getItemByKeyValue(qrSettings, 'name', 'fgColour')
      const useBgColour = getItemByKeyValue(qrSettings, 'name', 'bgColour')

      // If fgColour prop not set, use saved value
      if (
        !fgColour &&
        useFgColour !== -1 &&
        /^#([0-9A-F]{3}){1,2}$/i.test(useFgColour.value)
      ) {
        setFgColourToUse(useFgColour.value)
      }

      // If bgColour prop not set, use saved value
      if (
        !bgColour &&
        useBgColour !== -1 &&
        /^#([0-9A-F]{3}){1,2}$/i.test(useBgColour.value)
      ) {
        setBgColourToUse(useBgColour.value)
      }
    }, [qrData])

    // Update foreground colour when changed
    useEffect(() => {
      if (fgColour) setFgColourToUse(fgColour)
    }, [fgColour])

    // Update background colour when changed
    useEffect(() => {
      if (bgColour) setBgColourToUse(bgColour)
    }, [bgColour])

    // Initial fetch of account QR logo
    useEffect(() => {
      if (loadingQrLogo) return

      if (
        !imageSrc &&
        (!qrData || qrData.currentAccount.qrCodeLogoList.length === 0)
      ) {
        setImageToUse(defaultLogoImage)
        return
      }

      const loadImage = async () => {
        const useLogo = qrData?.currentAccount.qrCodeLogoList || []

        const { data } = await getLogoImage({
          variables: { blobName: useLogo[0] },
        })

        if (data) setImageToUse(data.currentAccount.qrCodeLogoSrc)
      }

      if (!imageSrc) loadImage()
    }, [loadingQrLogo, qrData])

    // Update logo when changed
    useEffect(() => {
      if (imageSrc) setImageToUse(imageSrc)
    }, [imageSrc])

    const imageSettings: ImageSettings | undefined = useMemo(() => {
      if (!imageToUse || !showLogo) return undefined

      return {
        src: imageToUse,
        height: qrType === 'svg' ? 180 : Math.floor(size / 3.5),
        width: qrType === 'svg' ? 180 : Math.floor(size / 3.5),
        excavate: true,
      }
    }, [imageToUse, size, showLogo])

    return (
      <div
        ref={ref || copyRef}
        className={classNames(className, styles.qrContainer)}
        style={{ backgroundColor: bgColourToUse }}
      >
        {/* Canvas version should always be rendered so copying to clipboard is possible */}
        <QRCodeCanvas
          className={qrType === 'svg' ? styles.hideCanvas : undefined}
          value={url}
          size={size}
          bgColor={bgColourToUse}
          fgColor={fgColourToUse}
          level={accuracyLevel}
          imageSettings={imageSettings}
          // Android phones require a white border to scan properly
          // 'includeMargin' scales the margin based on code size/URL length
          // For short links, this is too large. Custom solution built in modal download function
          // includeMargin
        />
        {qrType === 'svg' && (
          <QRCodeSVG
            value={url}
            size={size}
            bgColor={bgColourToUse}
            fgColor={fgColourToUse}
            level={accuracyLevel}
            imageSettings={imageSettings}
          />
        )}
        {!disableCopy && (
          <CopyButton className={styles.copyButton} onPress={copyQrCode}>
            Copy
          </CopyButton>
        )}
        {children}
      </div>
    )
  },
)

export default QRCodePreview
