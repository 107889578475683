import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import ButtonTabs from './button-tabs'
import Button from './button'
import { BetaLabel } from './counter'
import Input from './input'
import Link from './link'
import Modal from './modal'
import RequestNewConnectorModal from './request-new-connector-modal'
import { FieldSlot } from './row'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { Heading } from './typography'
import {
  ConnectorUpgradeModal,
  EnterpriseFeatureBlockerModal,
} from './feature-blocker-modals'
import { activeConnectors, currentUserDetails } from '../api/apollo/variables'
import { sendFeedback } from '../api/graphql/onboarding-client'
import { supportEmail } from '../core/constants'
import { isAdminUser, isSupportUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import useSwitchWorkspace from '../hooks/useSwitchWorkspace'
import {
  ConnectionDetails,
  IntegrationData,
  TabsContent,
  isGenericConnector,
} from '../connections/connectors-data'
import styles from '../styles/connector-template.module.scss'

export interface ConnectorLocationState {
  connectorID: string
  code: string
  state: string
  credentials?: string
  reconnect?: boolean
  switchToWorkspace?: string
  error?: string
}

interface ConnectorStatusProps {
  status?: 'Connected' | 'Requires attention' | null
}

export const ConnectorStatus = ({ status }: ConnectorStatusProps) => {
  if (!status) return null

  return (
    <p
      className={classNames(styles.connectorStatus, {
        [styles.connected]: status === 'Connected',
        [styles.attention]: status === 'Requires attention',
      })}
    >
      {status}
    </p>
  )
}

type ModalTabs = keyof TabsContent | 'Feedback'

interface ConnectorModalProps {
  connectionData: IntegrationData
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  connectionDetails: ConnectionDetails | null
  activeTab: ModalTabs
  hideConnectButton?: boolean
}

export const ConnectorModal = ({
  connectionData,
  setIsOpen,
  connectionDetails,
  activeTab,
  hideConnectButton,
}: ConnectorModalProps) => {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { state: locationState } = useLocation<ConnectorLocationState>()

  const {
    connectorID,
    connectorExists,
    logo,
    title,
    tags,
    shortDescription,
    tabsContent,
    connectButton,
  } = connectionData

  const connectorStatus = useMemo(() => {
    if (connectionDetails?.connected) {
      return connectionDetails?.requiresReconnect
        ? 'Requires attention'
        : 'Connected'
    }

    return null
  }, [connectionDetails])

  const logAction = useLogAction()

  const [sendFeedbackMutation, { loading: sendFeedbackLoading }] = useMutation(
    sendFeedback,
  )

  const [currentTab, setCurrentTab] = useState<ModalTabs>(activeTab)
  const [feedback, setFeedback] = useState({
    message: '',
    error: false,
    success: false,
  })
  const [loadingConnect, setLoadingConnect] = useState(false)
  const [connectError, setConnectError] = useState(false)

  const connectTooltipMsg = useMemo(() => {
    if (userPermission && !isAdminUser(userPermission)) {
      return 'Only admin users can set up new connectors'
    }

    if (connectionDetails?.disabled && connectionDetails.disabledMsg) {
      return connectionDetails.disabledMsg
    }

    return connectButton.tooltipCopy || null
  }, [connectionDetails, connectButton])

  const connectAuthError = useMemo(() => {
    return locationState && !!locationState.error
  }, [locationState])

  const modalTabs = useMemo(() => {
    const fullTabs: (string | React.ReactElement)[] = []

    Object.keys(tabsContent).forEach((key) => {
      if (!tabsContent[key]) return

      // Non-admins can't edit connectors
      if (
        key === 'Settings' &&
        userPermission &&
        !isAdminUser(userPermission)
      ) {
        return
      }

      // Only Uplfiter admins should be able to view the 'Support' tabe
      if (key === 'Support' && !isSupportUser(userPermission)) {
        return
      }

      if (key === 'Support') {
        fullTabs.push(
          <span className={styles.supportTab}>Support (Super admin only)</span>,
        )
        return
      }

      fullTabs.push(key)
    })

    fullTabs.push('Feedback')

    return fullTabs
  }, [tabsContent])

  return (
    <Modal
      width="wide"
      setIsOpen={setIsOpen}
      className={styles.connectModal}
      modalHeader={title}
      yesText={
        <Tooltip
          id={`${connectorID}-connect-modal-tooltip`}
          tooltipMessage={connectTooltipMsg}
          maxWidth={200}
        >
          {!connectionDetails?.connected || connectButton.alwaysShowButton ? (
            <>{connectButton.buttonCopy || 'Connect'}</>
          ) : (
            <>
              {connectionDetails.requiresReconnect ? 'Reconnect' : 'Connected'}
            </>
          )}
        </Tooltip>
      }
      yesButtonLoading={!connectionDetails || loadingConnect}
      yesButtonDisabled={connectError || connectionDetails?.disabled}
      onYes={
        ((connectionDetails?.connected &&
          !connectionDetails?.requiresReconnect) ||
          hideConnectButton) &&
        !connectButton.alwaysShowButton
          ? undefined
          : async () => {
              if (isGenericConnector(connectButton)) {
                try {
                  setLoadingConnect(true)

                  if (
                    !connectionDetails?.connected ||
                    connectButton.alwaysShowButton
                  ) {
                    await connectButton.connectFn()
                  } else if (connectButton.reconnectFn) {
                    await connectButton.reconnectFn()

                    logAction({
                      variables: {
                        action: `reconnect-${connectorID}`,
                        extra: title,
                        pagePath: '/connect',
                        websiteSection: 'connect',
                        functionName: 'reconnect',
                      },
                    })
                  }
                } catch (err) {
                  setConnectError(true)
                } finally {
                  setLoadingConnect(false)
                }
              } else if (currentTab !== 'Settings') {
                // Connect button has formId property
                // User must be on the Settings tab to connect
                // They must complete fields required for connection
                setCurrentTab('Settings')
              }
            }
      }
      form={
        !isGenericConnector(connectButton) ? connectButton.formId : undefined
      }
      footerContent={
        connectError && (
          <p className={styles.footerError}>
            There was an issue connecting. Please try again or contact support (
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>).
          </p>
        )
      }
    >
      <div className={styles.connectorSummary}>
        <img className={styles.connectorLogo} src={logo} alt={title} />
        <div>
          <div className={styles.tagsContainer}>
            {Array.isArray(tags) &&
              tags.map((tag, index) => (
                <BetaLabel
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${tag}-${index}`}
                  className={styles.connectorTag}
                  title={tag}
                />
              ))}
          </div>
          <p>{shortDescription}</p>
          {connectorExists && <ConnectorStatus status={connectorStatus} />}
          {connectAuthError && (
            <p className={styles.error} style={{ textAlign: 'left' }}>
              There was an issue connecting to your account:{' '}
              {locationState.error}
            </p>
          )}
        </div>
      </div>
      <ButtonTabs
        className={styles.tabsContent}
        selected={modalTabs.indexOf(currentTab)}
        isTopOfBox
        tabsLabels={modalTabs}
        type="tabs"
        onChange={(index) => {
          setCurrentTab(modalTabs[index] as ModalTabs)

          logAction({
            variables: {
              action: 'connect-modal-change-tab',
              pagePath: '/connect',
              functionName: 'changeTab',
              websiteSection: 'connect',
              // @ts-ignore
              extra:
                typeof modalTabs[index] === 'string'
                  ? modalTabs[index]
                  : 'Support',
            },
          })
        }}
      >
        {modalTabs.map((key) => {
          let useKey = key

          // Special case for support tab
          if (typeof key !== 'string') {
            useKey = 'Support'
          }

          return (
            // @ts-ignore
            <OuterBox key={useKey} className={styles.outerBox}>
              <InnerBox
                style={
                  useKey === 'Feedback'
                    ? {
                        paddingBottom: 7,
                      }
                    : undefined
                }
              >
                {useKey === 'Feedback' ? (
                  <>
                    <p>
                      Got an idea? Want to improve this integration? Noticed a
                      bug?
                    </p>
                    {feedback.success ? (
                      <p className={styles.success}>
                        Thank you for your feedback. It has been logged and we
                        will investigate as soon as possible.
                      </p>
                    ) : (
                      <>
                        <FieldSlot column>
                          <Input
                            name="supportingInformation"
                            type="textArea"
                            label="Supporting information"
                            value={feedback.message}
                            onValueChange={(val) =>
                              setFeedback({
                                message: val,
                                error: false,
                                success: false,
                              })
                            }
                            placeholder="Send us your feedback and we will respond within
                    24 hours."
                            multilineInput
                            textAreaHeight={100}
                            lineBreakAll={false}
                          />
                        </FieldSlot>
                        <Button
                          className={styles.sendFeedbackBtn}
                          loading={sendFeedbackLoading}
                          isDisabled={!feedback.message}
                          onPress={async () => {
                            try {
                              await sendFeedbackMutation({
                                variables: {
                                  message: feedback.message,
                                  page: '/connect',
                                  feedbackContext: {
                                    feedbackPrompt: 'Provide feedback',
                                    feedbackResponse: title,
                                  },
                                },
                              })

                              setFeedback({
                                ...feedback,
                                success: true,
                              })

                              await logAction({
                                variables: {
                                  action: 'request-new-connector',
                                  extra: `{"connectorName": "${title}", "feedback": "${feedback.message}"}`,
                                  websiteSection: 'connect',
                                  functionName: 'connectorFeedback',
                                  pagePath: '/connect',
                                },
                              })
                            } catch (err) {
                              setFeedback({
                                ...feedback,
                                error: true,
                              })
                            }
                          }}
                        >
                          Send feedback
                        </Button>
                        {feedback.error && (
                          <p className={styles.error}>
                            Unable to send feedback. Please try again or contact
                            support (
                            <Link href={`mailto:${supportEmail}`}>
                              {supportEmail}
                            </Link>
                            ).
                          </p>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  // @ts-ignore
                  tabsContent[useKey]
                )}
              </InnerBox>
            </OuterBox>
          )
        })}
      </ButtonTabs>
    </Modal>
  )
}

interface ConnectorBoxProps {
  connectionData: IntegrationData
  connectorLimitReached?: boolean
}

const ConnectorBox = ({
  connectionData,
  connectorLimitReached,
}: ConnectorBoxProps) => {
  const { switchWorkspace } = useSwitchWorkspace()

  const {
    connectorID,
    connectorExists,
    logo,
    title,
    tags,
    shortDescription,
    getConnectionDetails,
  } = connectionData

  const { state: locationState } = useLocation<ConnectorLocationState>()

  const { workspaceID } = useReactiveVar(currentUserDetails)
  const {
    isStartup,
    isEnterprise,
    isMicrosoftMarketplace,
  } = useSubscriptionLevel()

  const [
    connectionDetails,
    setConnectionDetails,
  ] = useState<ConnectionDetails | null>(null)
  const [connectorModalIsOpen, setConnectorModalIsOpen] = useState(false)
  const [connectorModalActiveTab, setConnectorModalActiveTab] = useState<
    ModalTabs
  >('Why connect?')
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
  const [requestConnectorModal, setRequestConnectorModal] = useState(false)
  const [hideModalYesButton, setHideModalYesButton] = useState(false)

  const ctaCopy = useMemo(() => {
    switch (true) {
      case !connectorExists:
        return 'Request'
      case connectionDetails?.connected:
        return 'View details'
      default:
        return 'Try now'
    }
  }, [connectorExists, connectionDetails, isStartup])

  // Check connection status
  useEffect(() => {
    const fetchDetails = async () => {
      const details = await getConnectionDetails()

      // Update reactive variable containing active connectors
      if (details?.connected) {
        const existingConnectors = activeConnectors()

        if (!existingConnectors.includes(connectorID)) {
          activeConnectors([...existingConnectors, connectorID])
        }
      }

      setConnectionDetails(details || null)
    }

    fetchDetails()
  }, [])

  // Opens the modal if the locationState is present and applicable
  useEffect(() => {
    if (!workspaceID || !locationState) return

    const idToConnect = locationState.connectorID

    if (!idToConnect || idToConnect !== connectorID) return

    // Connecting to analytics for workspaces other than the current one is possible via Settings page
    // We must force workspace to switch while preserving locationState
    if (
      !locationState.switchToWorkspace ||
      locationState.switchToWorkspace === workspaceID
    ) {
      setConnectorModalActiveTab('Settings')
      setConnectorModalIsOpen(true)

      if (
        ['googleAnalytics', 'adobeAnalytics'].indexOf(idToConnect) > -1 &&
        !locationState.error
      ) {
        setHideModalYesButton(true)
      }

      return
    }

    switchWorkspace(locationState.switchToWorkspace)
  }, [workspaceID, connectorID, locationState])

  const connectorStatus = useMemo(() => {
    if (connectionDetails?.connected) {
      return connectionDetails?.requiresReconnect
        ? 'Requires attention'
        : 'Connected'
    }

    return null
  }, [connectionDetails])

  return (
    <>
      <OuterBox className={styles.container}>
        <InnerBox>
          <div className={styles.connectorHeader}>
            <img className={styles.connectorLogo} src={logo} alt={title} />
            <div>
              <Heading type={3} align="left">
                {title}
              </Heading>
              <div className={styles.tagsContainer}>
                {Array.isArray(tags) &&
                  tags.map((tag, index) => (
                    <BetaLabel
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${tag}${index}`}
                      className={styles.connectorTag}
                      title={tag}
                    />
                  ))}
              </div>
              {connectorExists && <ConnectorStatus status={connectorStatus} />}
            </div>
          </div>
          <div>
            <p>{shortDescription}</p>
          </div>
          <Button
            variant={
              connectionDetails?.connected || !connectorExists
                ? 'secondary'
                : 'primary'
            }
            className={styles.connectButton}
            onPress={() => {
              if (!connectorExists) {
                setRequestConnectorModal(true)
                return
              }

              // Startup tier can only use the GA connector
              if (
                (connectorID !== 'googleAnalytics' && isStartup) ||
                connectorLimitReached
              ) {
                setUpgradeModalIsOpen(true)

                return
              }

              setConnectorModalIsOpen(true)
            }}
          >
            <Tooltip
              id={`${connectorID}-cta-tooltip`}
              tooltipMessage={
                connectionDetails?.disabled && connectionDetails?.disabledMsg
              }
              maxWidth={200}
            >
              {ctaCopy}
            </Tooltip>
          </Button>
        </InnerBox>
      </OuterBox>
      {connectorModalIsOpen && (
        <ConnectorModal
          connectionData={connectionData}
          connectionDetails={connectionDetails}
          setIsOpen={setConnectorModalIsOpen}
          activeTab={connectorModalActiveTab}
          hideConnectButton={hideModalYesButton}
        />
      )}
      {upgradeModalIsOpen && (
        <>
          {isEnterprise && !isMicrosoftMarketplace ? (
            <EnterpriseFeatureBlockerModal
              onHideModal={setUpgradeModalIsOpen}
              feature="connector"
            />
          ) : (
            <ConnectorUpgradeModal onHideModal={setUpgradeModalIsOpen} />
          )}
        </>
      )}
      {requestConnectorModal && (
        <RequestNewConnectorModal
          toggleActive={setRequestConnectorModal}
          connectorName={title}
          connectorLogo={logo}
        />
      )}
    </>
  )
}

export default ConnectorBox
