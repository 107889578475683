import { gql } from '../../__gql-types__/gql'

export const getCompanyDetails = gql(`
  query GetCompanyDetails {
    currentCompany {
			accountCount
			created
			activeState
			companyID
			subscriptionLevel
			paddleSubscriptionStatus
			internalContactName
			internalContactEmail
			microsoftSubscriptionId
			paymentProcessor
			userCount
			namedUserLimit
			userLimit
			linkMonitorLimit
			connectionLimit
			workspaceLimit
		}
  }
`)

export const getEnterpriseLicenceStatus = gql(`
	query GetEnterpriseLicenceStatus {
		currentCompany {
			companyID
			aboutToExpireCheckbox
			hasExpiredCheckbox
    	isSuspendedCheckbox
		}
	}
`)

export const getPublicAPIToken = gql(`
	query GetPublicAPIToken {
		currentCompany {
			companyID
			publicApiKey
		}
	}
`)

export const createPublicAPIToken = gql(`
	mutation CreatePublicAPIToken {
		userAccountSettings {
			createPublicAPIKey {
				companyID
				publicApiKey
			}
		}
	}
`)

export const updateCompanyInternalContact = gql(`
	mutation UpdateCompanyInternalContact (
		$newEmail: String,
		$newName: String
	) {
		userAccountSettings {
			updateCompanyInternalContact(newEmail: $newEmail, newName: $newName) {
				companyID
				internalContactName
				internalContactEmail
			}
		}
	}
`)

export const addNewWorkspace = gql(`
	mutation AddNewWorkspace (
			$workspaceName: String!
			$copyGeneratorAccountID: String
			$copyQRSettingsAccountID: String
			$copyQRLogoAccountID: String
			$copyAccountLogoID: String
		) {
		userAccountSettings {
			addAccount(
				accountName: $workspaceName
				copyGeneratorAccountID: $copyGeneratorAccountID
				copyQRSettingsAccountID: $copyQRSettingsAccountID,
				copyQRLogoAccountID: $copyQRLogoAccountID
				copyAccountLogoID: $copyAccountLogoID
			) {
				accountID
				accountName
				companyID
				companyName
				created
				explainAvailable
				trackAvailable
				auditAvailable
				reportAvailable
				planAvailable
				connectAvailable
				homepage
				isDemo
			}
		}
	}
`)

export const updateCompanyName = gql(`
	mutation UpdateCompanyName (
		$newName: String!
	) {
		userAccountSettings {
			updateCompanyName(newName: $newName) {
				companyID
				companyName
			}
		}
	}
`)

export const updateAccountDescription = gql(`
	mutation UpdateAccountDescription (
		$accountID: String!,
		$updatedAccountDomain: String,
		$updatedAccountName: String
		$trackLearnCopy: String
	) {
		userAccountSettings {
			updateAccountDescription (
				accountID: $accountID
				updatedAccountDomain: $updatedAccountDomain
				updatedAccountName: $updatedAccountName
				trackLearnCopy: $trackLearnCopy
			) {
				accountID
				homepage
				accountName
				trackLearnCopy
			}
		}
	}
`)

export const getCurrentAccountQRDetails = gql(`
	query GetCurrentAccountQRDetails {
		currentAccount {
			accountID
			qrSettings {
				enabled
				name
				value
			}
			qrCodeLogoList
			logoLink
		}
	}
`)

export const getQrLogoImage = gql(`
	query GetQrLogoImage ($blobName: String!) {
		currentAccount {
			accountID
			qrCodeLogoSrc (blobName: $blobName)
		}
	}
`)

export const deleteQrLogo = gql(`
	mutation DeleteQrCodeImage($blobName: String!) {
		deleteQrCodeImage(blobName: $blobName) {
			accountID
			qrCodeLogoList
		}
	}
`)

export const updateQrSettings = gql(`
	mutation UpdateQrSettings(
		$qrSettings: [GenericSettingInput!]!
	) {
		updateQrSettings(
			qrSettings: $qrSettings
		) {
			accountID
			qrSettings {
				enabled
				value
				name
			}
		}
	}
`)

export const getPaddleSubscriptionDetails = gql(`
	query GetPaddleSubscriptionDetails {
		currentCompany {
			companyID
			localPaddleData{
				localPrice
				localCurrency
			}
			activeState
			paddleSubscriptionCheckoutId
			paddleSubscriptionCurrency
			paddleSubscriptionEmail
			paddleSubscriptionId
			paddleSubscriptionLastAlertName
			paddleSubscriptionLastAlertId
			paddleSubscriptionLastEventTime
			paddleSubscriptionLastCustomData
			paddleSubscriptionLinkedSubscriptions
			paddleSubscriptionMarketingConsent
			paddleSubscriptionNextBillDate
			paddleSubscriptionPlanId
			paddleSubscriptionQuantity
			paddleSubscriptionSource
			paddleSubscriptionStatus
			paddleSubscriptionUnitPrice
			paddleSubscriptionUserId
			paddleUpdateUrl
			paddlePaymentMethodURL
			paddleCancelUrl
			subscriptionLevel
			paddleSubscriptionTrialEndDate
			paddleSubscriptionTrialStartDate
			paddleSubscriptionScheduledCancel
			paddleSubscriptionScheduledPause
			paddleSubscriptionScheduledResume
			paddleSubscriptionCancelDate
		}
	}
`)

// Returns the number of links created in all workspaces for an org
export const getSubscriptionLinkCount = gql(`
	query GetSubscriptionLinkCount {
		currentCompany {
			companyID
			codeCount
			userCount
		}
	}
`)

export const updatePaddleSeatsQuantity = gql(`
	mutation UpdatePaddleSeatsQuantity (
		$companyID: String!
		$updatedQuantity: Int!
		$paddleEnv: String
	) {
		userAccountSettings {
			billingMutations {
				updatePaddleQuantity(
					companyID: $companyID,
					updatedQuantity: $updatedQuantity,
					paddleEnv: $paddleEnv
				) {
					companyID
					paddleSubscriptionCheckoutId
					paddleSubscriptionCurrency
					paddleSubscriptionEmail
					paddleSubscriptionId
					paddleSubscriptionLastAlertId
					paddleSubscriptionLastAlertName
					paddleSubscriptionLastCustomData
					paddleSubscriptionLastEventTime
					paddleSubscriptionNextBillDate
					paddleSubscriptionLinkedSubscriptions
					paddleSubscriptionMarketingConsent
					paddleSubscriptionPlanId
					paddleSubscriptionQuantity
					paddleSubscriptionSource
					paddleSubscriptionStatus
					paddleSubscriptionUnitPrice
					paddleSubscriptionUserId
					paddleUpdateUrl
					paddleSubscriptionTrialEndDate
					paddleSubscriptionTrialStartDate
					paddleSubscriptionScheduledCancel
					paddleSubscriptionScheduledPause
					paddleSubscriptionScheduledResume
					paddleSubscriptionCancelDate
				}
			}
		}
	}
`)

/** Used to change billing period (monthly/annual) or upgrade/downgrade licence */
export const updatePaddlePlan = gql(`
	mutation UpdatePaddlePlan (
		$updatedQuantity: Int!
		$updatedPriceID: String!
	) {
		userAccountSettings {
			billingMutations {
				updatePaddlePlan(
					updatedQuantity: $updatedQuantity,
					updatedPriceID: $updatedPriceID
			) {
					companyID
					paddleSubscriptionId
					paddleSubscriptionScheduledCancel
					paddleSubscriptionScheduledPause
					paddleSubscriptionScheduledResume
					paddleSubscriptionCancelDate
				}
			}
		}
	}
`)

/** effectiveFrom defaults to next billing period  */
export const cancelPaddlePlan = gql(`
	mutation CancelPaddleSubscription (
		$effectiveFrom: String
	) {
		userAccountSettings {
			billingMutations {
				cancelPaddleSubscription(
						effectiveFrom: $effectiveFrom
				) {
					companyID
					paddleSubscriptionScheduledCancel
					paddleSubscriptionScheduledPause
					paddleSubscriptionScheduledResume
					paddleSubscriptionCancelDate
				}
			}
		}
	}
`)

export const undoCancelPaddlePlan = gql(`
	mutation UndoCancelPaddleSubscription  {
		userAccountSettings {
			billingMutations {
				cancelPaddleSubscriptionCancellation {
					companyID
				}
			}
		}
	}
`)

export const deleteCompany = gql(`
	mutation DeleteCompany(
		$companyID: String!
	) {
		userAccountSettings {
			closeCompany(companyID: $companyID)
		}
	}
`)

export const getMicrosoftSubscriptionData = gql(`
  query GetMicrosoftSubscriptionData {
    currentCompany {
			companyID
			microsoftSubscriptionData {
				subscriptionID
				currency
				planId
				planName
				periodStartDate
				periodEndDate
				price
				quantity
				status
				termDescription
				termUnit
			}
		}
  }
`)

export const listAzureMarketplaceQueries = gql(`
	query ListAzureMarketplaceQueries {
		accountSettings {
			azureMarketplaceQueries {
				listPlans {
					billingTerms {
						currency
						price
						termDescription
						termUnit
					}
					description
					hasFreeTrials
					displayName
					isPricePerSeat
					maxQuantity
					isStopSell
					minQuantity
					planId
				}
			}
		}
	}
`)

export const changeMicrosoftMarketplacePlan = gql(`
	mutation ChangeMicrosoftMarketplacePlan (
		$companyID: String!
		$newPlanID: String!
	) {
		userAccountSettings {
			billingMutations {
				changeMicrosoftMarketplacePlan(
						companyID: $companyID,
						newPlanID: $newPlanID
				) {
					companyID
					paymentProcessor
				}
			}
		}
	}
`)

export const updateMicrosoftMarketplaceSeats = gql(`
	mutation UpdateMicrosoftMarketplaceSeats (
		$companyID: String!
		$totalSeats: Int!
	) {
		userAccountSettings {
			billingMutations {
				updateMicrosoftMarketplaceSeats(
					companyID: $companyID, 
					totalSeats: $totalSeats
				) {
					companyID
					paymentProcessor
				}
			}
		}
	}
`)

export const cancelMicrosoftMarketplaceSubscription = gql(`
	mutation CancelMicrosoftMarketplaceSubscription (
		$companyID: String!
	) {
		userAccountSettings {
			billingMutations {
				cancelMicrosoftMarketplaceSubscription(
					companyID: $companyID
				) {
					companyID
				}
			}
		}
	}
`)

export const getCompanyAccountsAndUsers = gql(`
	query GetCompanyAccountsAndUsers {
		currentCompany {
			companyID
			accountList {
				accountName
				accountID
				userAccountProfiles {
					userEmail
					userID
				}
			}
		}
	}
`)
