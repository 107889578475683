import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import FileSaver from 'file-saver'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Button, { NavigateButton } from './button'
import FileDragAndDrop from './file-drag-and-drop'
import { FormField, FormLabel, FormRow } from './form'
import { RadioGroup } from './input-v2'
import Link from './link'
import { Preloader } from './loader'
import { CustomLinkFull } from './custom-link-fields'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText, NoteText } from './typography'
import {
  currentUserDetails,
  hasCreatedLinksReactive,
  linkOrCode,
  selectedShortLinkDomainReactive,
} from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  getDownloadTemplateLinkGQL,
  uploadBulkTemplate,
} from '../api/REST/track-client'
import {
  brandName,
  dynamicTextValues,
  messages,
  supportEmail,
} from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import { CustomLinkAliasDetails, useAliases } from '../helpers/custom-links'
import { getUserData, saveUserData } from '../helpers/local-client'
import {
  FullValidationCheck,
  defaultShortLinkDomain,
  getValidationChecksObject,
  maxBatchShortLinks,
} from '../helpers/track-module'
import { LinkType } from '../helpers/track-create'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/bulk-import.module.scss'

const BulkImport = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  const hasCreatedLinks = useReactiveVar(hasCreatedLinksReactive)

  const selectedShortLinkDomain = useReactiveVar(
    selectedShortLinkDomainReactive,
  )

  const logAction = useLogAction()
  const history = useHistory()

  const { updateOnboardingSection } = useOnboarding()

  const [
    getGenerator,
    { data: generatorData, loading: loadingGenerator },
  ] = useLazyQuery(getCampaignCodeGenerator)

  const savedState = getUserData()

  const [linkType, setLinkType] = useState<LinkType>(
    !savedState?.linkType ||
      !['shortLink', 'basic'].includes(savedState.linkType)
      ? 'basic'
      : savedState.linkType,
  )
  const [shortLinkAliasDetails, setShortLinkAliasDetails] = useState<
    CustomLinkAliasDetails
  >({
    key: nanoid(),
    alias: '',
    isCustom: false,
    isBatch: true,
    status: '',
    error: false,
  })

  const [error, setError] = useState<string | React.ReactElement>('')

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const validationChecks: FullValidationCheck[] | null = useMemo(() => {
    return getValidationChecksObject(generatedStructure)
  }, [generatedStructure])

  // Initial value setter for link type based on validationChecks
  useEffect(() => {
    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (
      foundShortLink !== -1 &&
      foundShortLink.enabled &&
      foundShortLink.value
    ) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      if (selectedRule === 'force-short-links' && linkType === 'basic') {
        setLinkType('shortLink')
        return
      }

      if (selectedRule === 'force-long-links' && linkType === 'shortLink') {
        setLinkType('basic')
        return
      }

      if (!savedState?.linkType) {
        setLinkType(
          selectedRule === 'recommend-short-links' ? 'shortLink' : 'basic',
        )
        return
      }
    }

    if (!savedState?.linkType) {
      setLinkType('basic')
    }
  }, [savedState, generatedStructure])

  // Options and order for URL link presentation: 'short' or 'full'
  const shortLinkOptions = useMemo(() => {
    const options = [
      {
        label: (
          <>
            Short link <span className={styles.recommended}>(recommended)</span>
          </>
        ),
        value: 'shortLink',
      },
      {
        label: 'Basic link',
        value: 'basic',
      },
    ]

    const forceShortLinkRule = validationChecks?.find(
      (check) => check.name === 'FORCE_SHORT_LINK',
    )

    if (
      forceShortLinkRule &&
      forceShortLinkRule.enabled &&
      forceShortLinkRule.value
    ) {
      const shortLinkValue = JSON.parse(forceShortLinkRule.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
        case 'force-long-links':
          return []
        case 'recommend-long-links':
          return [
            {
              label: (
                <>
                  Basic link{' '}
                  <span className={styles.recommended}>(recommended)</span>
                </>
              ),
              value: 'basic',
            },
            {
              label: 'Short link',
              value: 'shortLink',
            },
          ]
        default:
          break
      }
    }

    return options
  }, [validationChecks])

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const res = await uploadBulkTemplate({
          file: acceptedFiles.pop() as File,
          createShortLinks: linkType === 'shortLink',
          customDomainID:
            linkType === 'shortLink' &&
            selectedShortLinkDomain !== defaultShortLinkDomain
              ? selectedShortLinkDomain
              : null,
          bulkStart:
            linkType === 'shortLink' ? shortLinkAliasDetails.alias : null,
        })

        if (res === true) {
          setError('')

          if (linkType === 'shortLink') {
            useAliases([shortLinkAliasDetails.alias], selectedShortLinkDomain)
          }

          // @ts-ignore
          if (window.dataLayer && window.dataLayer.push) {
            // @ts-ignore
            window.dataLayer.push({
              event: 'create_campaign_link',
              link_creation_type: 'add-code-bulk',
              link_count: 1,
            })
          }

          logAction({
            variables: {
              action: 'add-code-bulk',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: '1',
            },
          })

          if (hasCreatedLinks === false) {
            // Updates the backend cache for onboarding state
            updateOnboardingSection('createCampaignLink', 'user')
          }

          window.setTimeout(() => {
            history.push({
              pathname: '/track/view-links',
              state: { fromBulk: true },
            })
          }, 2000)

          return
        }

        if (
          res?.status === 400 &&
          res?.error?.response?.data &&
          res?.error?.response?.headers['content-type'] ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // Save the file as a blob and add a button to download it
          const fileBlob = new Blob([new Uint8Array(res.error.response.data)], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          setError(
            <>
              <span>
                There were validation issues with your form.{' '}
                <NavigateButton
                  variant="text"
                  onPress={async () => {
                    const now = new Date(Date.now())

                    await FileSaver.saveAs(
                      fileBlob,
                      `${moment(now).format(
                        'YYYY-MM-DD',
                      )} - ${brandName} Bulk Upload Errors.xlsx`,
                    )
                  }}
                >
                  Download error sheet
                </NavigateButton>
              </span>
            </>,
          )

          return
        }

        const resJSON =
          res?.status === 500
            ? res?.error?.response?.data
            : JSON.parse(
                String.fromCharCode.apply(
                  null,
                  // @ts-ignore
                  new Uint8Array(res?.error?.response?.data),
                ),
              )

        const type = resJSON?.detail || ''

        if (
          type === 'BAD_TEMPLATE' ||
          type === 'Template file does not match expected format' ||
          type ===
            'The header of the uploaded file does not match the current template.'
        ) {
          setError(
            'Incorrect import template. Please download and use the sample template.',
          )
        } else if (type === 'BAD_FILE_FORMAT') {
          setError('Incorrect file type.')
        } else if (type === 'FAILED_UPLOAD') {
          setError('File upload has failed, please try again.')
        } else {
          setError(
            <>
              File upload has failed. Please email{' '}
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
              help.
            </>,
          )
        }
      }
    },
    [linkType, selectedShortLinkDomain, shortLinkAliasDetails],
  )

  if (loadingGenerator) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox className={classNames(styles.innerBox, styles.loading)}>
          <Preloader />
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <OuterBox className={styles.outerBox}>
      <InnerBox>
        <p className={styles.intro}>
          <span>
            Bulk create links by uploading a CSV with landing pages and
            parameters.
          </span>
        </p>
        <div className={styles.bulkUploadContent}>
          <ol className={styles.bulkList}>
            <li>
              <Button
                className={styles.downloadButton}
                onPress={async () => {
                  await getDownloadTemplateLinkGQL()
                }}
              >
                Download template
              </Button>
            </li>
            <li>
              <Tooltip
                id="bulk-upload-download-template-tooltip"
                useIcon
                tooltipMessage={
                  <ul>
                    <li>
                      Dropdown parameters match dropdown names (not codes)
                    </li>
                    <li>No prohibited special characters</li>
                    <li>No prohibited parent-child combinations</li>
                    <li>Under max character limit</li>
                    <li>Correct date formats</li>
                  </ul>
                }
              >
                Add landing pages and parameters that match your validation
                rules
              </Tooltip>
            </li>
            <li>
              Save the template as a CSV file (.xlsx if containing non-Latin
              characters)
            </li>
            <li>Select link type and upload your CSV below</li>
          </ol>
          {/* First field should only be visible if not using appLinks and user can choose short/basic link type */}
          {shortLinkOptions.length > 0 && (
            <FormRow
              heading={
                <Tooltip
                  id="presentation-heading-tooltip"
                  useIcon
                  tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
                >
                  Presentation
                </Tooltip>
              }
            >
              <FormLabel id="link-type" tooltip={messages.linkTypes}>
                Link type
              </FormLabel>
              <FormField>
                <RadioGroup
                  horizontal
                  groupName="link-type"
                  options={shortLinkOptions}
                  optionsClassName={styles.radioButton}
                  selectedValue={linkType}
                  onChange={(option) => {
                    setLinkType(option as 'shortLink' | 'basic')
                    saveUserData({ linkType: option })
                  }}
                />
              </FormField>
            </FormRow>
          )}
          {linkType === 'shortLink' && (
            <FormRow
              heading={
                linkType === 'shortLink' && shortLinkOptions.length === 0 ? (
                  <Tooltip
                    id="presentation-heading-tooltip"
                    useIcon
                    tooltipMessage="How you want the link to look for your users before they click or scan. Branded short links and QR codes get increased clickthroughs from more users trusting the link."
                  >
                    Presentation
                  </Tooltip>
                ) : undefined
              }
            >
              <FormLabel id="custom-link" tooltip={messages.customiseShortLink}>
                Bulk short link template
              </FormLabel>
              <FormField>
                <CustomLinkFull
                  key={shortLinkAliasDetails.key}
                  // This should only ever fetch a batch alias
                  batchCount={maxBatchShortLinks}
                  onAliasChange={(alias, status, isCustom) =>
                    setShortLinkAliasDetails((curr) => ({
                      ...curr,
                      alias,
                      status,
                      isCustom: isCustom || false,
                    }))
                  }
                />
              </FormField>
            </FormRow>
          )}
          <FormRow includePaddingBottom>
            <FormField className={styles.dragAndDrop}>
              <FileDragAndDrop
                disabled={shortLinkAliasDetails.status === 'refetching'}
                uploadError={error}
                onDrop={onDrop}
                uploadButtonText="Upload CSV"
              />
            </FormField>
          </FormRow>
          <NoteText sameLine>
            Uploads which don't match your validation rules will return an excel
            file with invalid cells highlighted. Please correct any mistakes and
            reupload.
          </NoteText>
          <ol start={5} style={{ margin: 0 }}>
            <li>
              Share or download links from 'Your recently created links' or
              <BoxedText>
                <Link href="/track/view-links">
                  {dynamicTextValues.trackViewPage[linkCopy]}
                </Link>
              </BoxedText>
            </li>
          </ol>
        </div>
      </InnerBox>
    </OuterBox>
  )
}

export default BulkImport
