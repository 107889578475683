import moment from 'moment'
import numeral from 'numeraljs'
import FileSaver from 'file-saver'

import { ValidationChecks } from '../api/types'
import { maxCustomLinkAliasLength, TOKEN } from '../api/constants'
import { getLocalItem, setLocalItem } from './local-client'

export const isAdminUser = (userPermission: string) => {
  return ['admin', 'support', 'whitelabelSupport'].indexOf(userPermission) > -1
}

/**
 * These users can access all information for any org in their whitelabel domain
 * However they are not full support users, as they shouldn't see orgs outside of their whitelabel domain
 */
export const isSupportUser = (userPermission: string) => {
  return userPermission === 'support' || userPermission === 'whitelabelSupport'
}

/** These users can access all information for any org in any domain, including whitelabel */
export const isNonWhitelabelSupportUser = (userPermission: string) => {
  return userPermission === 'support'
}

export function getToken(): string {
  const token = getLocalItem(TOKEN)
  return typeof token === 'string' ? token : ''
}

export function setToken(token: string): void {
  setLocalItem(TOKEN, token)
}

export function getAnomalyBreakdowUrl({
  date,
  metricID,
}: {
  date: string
  metricID: string
}): string {
  return `/explain/anomaly/${metricID}/${moment(date, 'DD/MM/YYYY').format(
    'DD-MM-YYYY',
  )}`
}

export function getAnomalyType(
  anomaly: Anomaly,
): null | 'explained' | 'not-interesting' {
  const { explainedAnomaly, anomalyNotInteresting } = anomaly
  const type = anomalyNotInteresting ? 'not-interesting' : null
  return type === null && explainedAnomaly ? 'explained' : type
}

export function getUrlQuery<T extends string>(s?: T) {
  const useString = window.location.search

  return new URLSearchParams(s || useString)
}

export const copyString = (toCopy: string | string[]): void => {
  const input = document.createElement('textarea')
  input.style.opacity = '0'
  input.style.pointerEvents = 'none'
  input.style.position = 'absolute'
  input.value = Array.isArray(toCopy) ? toCopy.join('\r\n') : toCopy
  document.body.append(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}

const replaceChecks = {
  NO_SPECIAL_CHARS: [/[?=&]/, ''],
  REPLACE_SPACES_WITH: [/\s/, '_'],
  ALL_LOWER_CASE: [/[A-Z]/, (a: string): string => a.toLowerCase()],
}

// TODO: Replace this with a more robust function that takes all global and parameter-level rules
/** Use it everywhere it's needed
 * campaign-codes-add-new-select-field.tsx
 * track-create-parameter-fields.tsx (ParameterInputField)
 * track-dropdown-requests.tsx (ParamRequestRow)
 */
export const prepareInput = (
  value: string,
  validation?: ValidationChecks[] | null,
): string => {
  if (value && value !== '' && validation) {
    let result = value

    validation.forEach((item) => {
      if (
        item.enabled &&
        Object.prototype.hasOwnProperty.call(replaceChecks, item.name)
      ) {
        const pattern = replaceChecks[item.name][0]

        const useValue =
          Object.prototype.hasOwnProperty.call(item, 'value') &&
          (!!item.value || item.value === '')
            ? item.value
            : replaceChecks[item.name][1]

        result = result.replace(new RegExp(pattern, 'ig'), useValue)
      }

      return value
    })

    return result
  }

  return value
}

// Return title for dimensions based on mDN value
export const getDimensionTitle = (mDN, dimensions): string => {
  if (mDN.indexOf('_') === -1) {
    return mDN
  }
  const data = mDN.split('_')
  const dimensionTitle =
    dimensions && Object.prototype.hasOwnProperty.call(dimensions, data[1])
      ? dimensions[data[1]]
      : data[1]
  const title = data.slice(2).join('_')
  return `${dimensionTitle
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()} = ${title}`
}

// High level validation for URL
export const isValidUrl = (url?: string) => {
  return (
    url &&
    url !== '' &&
    url.length > 1 &&
    url.search(/(https:\/\/)|(http:\/\/)/gi) !== -1
  )
}

// return all values that match search value in array with items of assoc arrays
export const getItemByKeyValue = (
  items: any[] | undefined | null,
  key: string,
  value: string | number | undefined,
  returnIndex = false,
  ignoreCase = true,
): any => {
  if (
    typeof value === 'undefined' ||
    items === null ||
    typeof items === 'undefined'
  ) {
    return -1
  }

  const searchVal = ignoreCase
    ? JSON.stringify(value).toLowerCase()
    : JSON.stringify(value)

  let indexCounter = -1

  const found = items.find((item, index) => {
    let res

    if (ignoreCase) {
      res =
        typeof item[key] !== 'undefined' &&
        JSON.stringify(item[key]).toLowerCase() === searchVal
    } else {
      res =
        typeof item[key] !== 'undefined' &&
        JSON.stringify(item[key]) === searchVal
    }

    if (res) {
      indexCounter = index
    }

    return res
  })

  if (returnIndex) {
    return indexCounter
  }

  return found || -1
}

export const removeByKeyValue = (
  items: any[],
  key: string,
  value: string,
): any[] => {
  return items.filter((item) => {
    return item[key] && item[key].toLowerCase() !== value.toLowerCase()
  })
}

export const replaceByKeyValue = (
  items: any[],
  key: string,
  value: string,
  newValue: any,
  appendWhenNotFound: any = false,
): any[] => {
  let found = false
  const result = items.map((item) => {
    if (item[key] && item[key].toLowerCase() === value.toLowerCase()) {
      found = true
      return newValue
    }
    return item
  })
  if (appendWhenNotFound && !found) {
    return [...result, newValue]
  }
  return result
}

export const getMetricName = (
  metrics: (GaMetric | KeyMetrics)[],
  metric: string,
): string => {
  const found = metrics.find((item) => {
    return item.apiName === metric
  })
  return found ? found.displayName : ''
}

export const getAnomalyDateRange = (
  dateRange: string,
): { from: string; to: string } => {
  let from = ''
  const now = new Date(Date.now())
  let to: string = moment(now).subtract(1, 'days').format('YYYYMMDD')

  if (dateRange === 'Last 30days') {
    from = moment(now).subtract(31, 'days').format('YYYYMMDD')
  } else if (dateRange === 'Last 90days') {
    from = moment(now).subtract(91, 'days').format('YYYYMMDD')
  } else if (dateRange === 'Year') {
    from = moment(now).subtract(366, 'days').format('YYYYMMDD')
  } else if (dateRange.indexOf(' - ') !== -1) {
    // eslint-disable-next-line
    ;[from, to] = dateRange.split(' - ')
    // from = moment(from, 'YYYYMMDD').format('YYYYMMDD')
    // to = moment(to, 'YYYYMMDD').format('YYYYMMDD')
  }

  return {
    to,
    from,
  }
}

/** Sorts array by key and order value */
export const sortData = <T extends {}>(
  data: T[],
  /** If key is not a keyof T, a customSortFn should be provided */
  key: string,
  orderAsc: boolean,
  customSortFn?:
    | null
    | ((row: T, _orderAsc: boolean, _key: string) => number | string | Date),
  ignoreCase?: boolean,
): T[] => {
  if (!data || (data && data.length === 0)) {
    return data
  }
  const newData = data.concat()

  /** If customSortFn not set, use generic case-insensitive string sort */
  const fn =
    customSortFn ||
    ((row) =>
      ignoreCase && typeof row[key] === 'string'
        ? row[key].toLowerCase()
        : row[key])

  newData.sort((previous, next) => {
    const p = fn(previous, orderAsc, key)
    const n = fn(next, orderAsc, key)
    return p < n ? 1 : -1
  })

  if (orderAsc) return newData.reverse()

  return newData
}

export const formatValueForDisplay = (
  value: any,
  units?: string,
  hideEmpty = false,
  hideNegativeValues = false,
): string => {
  let displayValue = ''
  const returnEmpty =
    (hideNegativeValues && value < 0) || (hideEmpty && value === 0)

  if (returnEmpty) {
    return displayValue
  }

  if (units === 'percentage') {
    displayValue = `${numeral(value).format('0,0')}%`
  } else if (units === 'duration') {
    const time = Math.floor(value)
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60
    displayValue = `${minutes}m ${seconds}s`
  } else {
    displayValue = numeral(value).format('0,0.[0]a')
  }
  return displayValue
}

export const makeHexString = (val: string): string => {
  return val
    .replace('#', '')
    .toUpperCase()
    .replace(/(?!([0-9A-F]))./gi, '')
    .slice(0, 6)
}

// removed duplicate items in array of objects or strings
export const returnUnique = <T>(arr: T[], key: null | string = null) => {
  if (key !== null) {
    return arr.reduce((prev, curr) => {
      if (getItemByKeyValue(prev, key, curr[key], true) === -1) {
        prev.push(curr)
      }
      return prev
    }, [] as T[])
  }

  const seen = {}

  return arr.filter((item) => {
    const hasSeen = Object.prototype.hasOwnProperty.call(seen, item as string)
    if (!hasSeen) {
      seen[item as string] = true
    }
    return !hasSeen
  })
}

export const getDefaultOption = (
  data,
  dataKey,
  keyValue,
  defaultValue: any = null,
) => {
  if (data && data[dataKey] && data[dataKey].length > 0) {
    const _val = getItemByKeyValue(data[dataKey], 'name', keyValue)
    if (_val !== -1) {
      return _val.value
    }
  }
  return defaultValue
}

export const getCsvString = (data: any[]) => {
  if (data.length === 0) return ''

  const allKeys = data.flatMap((row) => Object.keys(row))
  const headers = Array.from(new Set(allKeys))
  const outArr = [headers.join(',')]

  data.forEach((row) => {
    const vals: any[] = []

    headers.forEach((header) => {
      vals.push(row[header] !== undefined ? row[header] : '')
    })

    const stringVals = vals.map(
      (r) => `"${r.toString().replace(/\"/gi, '""')}"`,
    )
    outArr.push(stringVals.join(','))
  })

  return outArr.join('\n')
}

export const downloadUsersCsv = async (
  data: string,
  companyName: string,
): Promise<boolean> => {
  if (typeof data === 'string') {
    const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8' })
    const now = new Date(Date.now())
    await FileSaver.saveAs(
      blob,
      `User list - ${companyName} - ${moment(now).format('YYYY-MM-DD')}.csv`,
    )
  }

  return true
}

export const removeSpecialChars = (input: string, replaceChar = '_') => {
  const pattern = new RegExp(/[^A-Za-z0-9 ]/, 'g')
  const out = input.replaceAll(pattern, replaceChar)

  return out
}

export const replaceCustomLinkCharacters = (input: string) => {
  const rep = new RegExp(/[\\\\\s#%+?/\u0000-\u001F\u007F-\u009F]/, 'gi')

  return input
    .toLowerCase()
    .replace(rep, '-')
    .slice(0, maxCustomLinkAliasLength)
}

/** Runs the given callback a certain number of times */
export const loopApiCall = async <In, Res>(
  /** Async function to be looped */
  callback: (callbackVars?: In) => Promise<Res>,
  options: {
    /** Check if returend value from callback is as expected. If not, loop back */
    successCheck: (valToCheck: Res) => boolean
    /** Recursive step: Set initial callback variables (if any) when running loopApiCall */
    currCallbackVars?: In
    /** Recursive step: Transforms callback response into a new set of input variables. Only use if input variables must change with the responses */
    varsTransformer?: (resToTransform: Res) => In
  },
  /** Recursive step only: Do not set manually */
  /** How many times the function should loop */
  attemptsLimit = 5,
  currAttempts = 0,
): Promise<Res> => {
  const { successCheck, currCallbackVars, varsTransformer } = options

  // Stopping criteria
  if (currAttempts >= attemptsLimit) {
    throw new Error('Max number of attempts reached')
  }

  try {
    const callbackResponse = await callback(currCallbackVars)

    if (successCheck(callbackResponse)) {
      // Success criteria was met - end loop
      return callbackResponse
    }

    // Success criteria not met
    // Get new callback input and rerun
    const newCallbackVars = varsTransformer
      ? varsTransformer(callbackResponse)
      : currCallbackVars

    return loopApiCall(
      callback,
      {
        successCheck,
        currCallbackVars: newCallbackVars,
        varsTransformer,
      },
      attemptsLimit,
      currAttempts + 1,
    )
  } catch (error) {
    throw new Error('Failed')
  }
}

export const hexToRgb = (hex: string) => {
  // Remove the '#' if present
  let cleanedHex = hex.replace(/^#/, '')

  // Handle short hex codes (e.g., #RGB)
  if (cleanedHex.length === 3) {
    cleanedHex = cleanedHex.replace(/(.)/g, '$1$1')
  }

  // Parse the hexadecimal value
  const bigint = parseInt(cleanedHex, 16)

  // Extract the red, green, and blue components
  // eslint-disable-next-line no-bitwise
  const r = (bigint >> 16) & 255
  // eslint-disable-next-line no-bitwise
  const g = (bigint >> 8) & 255
  // eslint-disable-next-line no-bitwise
  const b = bigint & 255

  // Return the RGB representation
  return { r, g, b }
}

/** Fuzzy-filters a list of data based on the chosen key */
export const fuzzySearch = <T>(
  data: T[],
  itemKey: keyof T | (keyof T)[],
  search: string,
) => {
  return data.filter((item) => {
    const searchPattern = search
      .replace(/[^a-zA-Z0-9]/g, '')
      .split('')
      .join('.*')

    if (Array.isArray(itemKey)) {
      return itemKey.some((key) => {
        return (
          (item[key] as string)
            .toLowerCase()
            .match(new RegExp(searchPattern, 'i')) !== null
        )
      })
    }

    return (
      (item[itemKey] as string)
        .toLowerCase()
        .match(new RegExp(searchPattern, 'i')) !== null
    )
  })
}
