import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import _ from 'lodash'

import Accordion from './accordion'
import Button from './button'
import StyledDatePicker from './date-picker'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import { InputLabel } from './input-v2'
import { Preloader } from './loader'
import Modal from './modal'
import MultiList from './multi-list'
import { SelectBoxSimple } from './select-box'
import { ErrorMessage, Heading, NoteText, SuccessText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { updateAccountDescription } from '../api/graphql/company-client'
import {
  addSupportUser,
  createEnterpriseCompany,
  getEnterpriseCompanyDetails,
  updateEnterpriseCompany,
} from '../api/graphql/support-client'
import { messages } from '../core/constants'
import { isNonWhitelabelSupportUser } from '../helpers'
import { validateEmail } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel, {
  subscriptionLevelDetails,
} from '../hooks/useSubscriptionLevel'
import styles from '../styles/support-settings.module.scss'
import {
  CreateEnterpriseCompanyMutationVariables,
  GetEnterpriseCompanyDetailsQuery,
} from '../__gql-types__/graphql'

const defaultEnterpriseFormFieldValues: GetEnterpriseCompanyDetailsQuery['currentCompany'] = {
  companyID: '',
  whitelabel: 'support',
  companyName: '',
  businessDivision: '',
  sponsorList: [],
  legalEntityName: '',
  contractStartDate: '',
  breakClauseExists: false,
  breakClause: '',
  breakClauseDate: '',
  contractEndDate: '',
  totalContractValue: 0,
  revenuePerMonth: 0,
  customTandC: false,
  autoRenew: false,
  domains: '',
  domainLimit: 999,
  namedUserLimit: 20,
  userLimit: 20,
  activeUserLimit: 10,
  userCount: 0,
  linkMonitorLimit: -1,
  connectionLimit: 2,
  workspaceLimit: 3,
  origDataSource: 'GA4_PROP',
  trackAvailable: true,
  reportAvailable: true,
  planAvailable: true,
  connectAvailable: true,
  companyNotes: '',
  aboutToExpireCheckbox: false,
  hasExpiredCheckbox: false,
  isSuspendedCheckbox: false,
}

const requiredFields = [
  'companyName',
  'sponsorList',
  'legalEntityName',
  'contractStartDate',
  'contractEndDate',
  'totalContractValue',
  'revenuePerMonth',
  'domainLimit',
  'namedUserLimit',
  'origDataSource',
]

interface EnterpriseContractDetailsProps {
  showUpdateButton?: boolean
  onCreateNewClient?: (
    variables: CreateEnterpriseCompanyMutationVariables,
  ) => Promise<void>
}

const EnterpriseContractDetails = ({
  showUpdateButton,
  onCreateNewClient,
}: EnterpriseContractDetailsProps) => {
  const { companyName, workspaceID, userPermission } = useReactiveVar(
    currentUserDetails,
  )

  const [
    fetchCompanyDetails,
    {
      data: enterpriseCompanyData,
      loading: loadingEnterpriseCompanyData,
      error: fetchEnterpriseCompanyDataError,
    },
  ] = useLazyQuery(getEnterpriseCompanyDetails, { fetchPolicy: 'network-only' })
  const [
    updateCompanyDetails,
    { loading: updatingCompanyDetails },
  ] = useMutation(updateEnterpriseCompany)
  /** Used for Alex to change the Track>Learn copy without needing to connect */
  const [updateTrackLearnCopy] = useMutation(updateAccountDescription)

  useEffect(() => {
    if (!showUpdateButton) return
    fetchCompanyDetails()
  }, [showUpdateButton])

  const [formFields, setFormFields] = useState<
    GetEnterpriseCompanyDetailsQuery['currentCompany']
  >(defaultEnterpriseFormFieldValues)
  const [formIsUpdated, setFormIsUpdated] = useState(false)
  const [formError, setFormError] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)

  useEffect(() => {
    if (!enterpriseCompanyData) return

    setFormFields((curr) => {
      const newFormFields = _.cloneDeep(curr)

      Object.keys(newFormFields).forEach((field) => {
        if (
          field in enterpriseCompanyData.currentCompany &&
          enterpriseCompanyData.currentCompany[field] !== null &&
          enterpriseCompanyData.currentCompany[field] !== undefined
        ) {
          newFormFields[field] = enterpriseCompanyData.currentCompany[field]
        }
      })

      return newFormFields
    })
  }, [enterpriseCompanyData])

  const updateFormField = useCallback((fieldID: string, value: any) => {
    setFormFields((prev) => ({
      ...prev,
      [fieldID]: value,
    }))
    setFormIsUpdated(true)
  }, [])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setFormError('')
    setUpdateSuccess(false)

    if (!formIsUpdated) {
      setFormError('Please update at least one field before submitting.')

      return
    }

    if (
      !requiredFields.every((field) => {
        if (Array.isArray(formFields[field])) return !!formFields[field].length

        return !!formFields[field] || formFields[field] === 0
      })
    ) {
      setFormError('Please fill in all required fields.')

      return
    }

    if (showUpdateButton) {
      await updateCompanyDetails({
        variables: {
          ...formFields,
          sponsorList:
            formFields.sponsorList?.map(({ name, email }) => ({
              name,
              email,
            })) || [],
          dataSourceType: formFields.origDataSource || 'GA4_PROP',
        },
      })

      await updateTrackLearnCopy({
        variables: {
          accountID: workspaceID,
          trackLearnCopy: formFields.origDataSource,
        },
      })

      setUpdateSuccess(true)

      return
    }

    if (onCreateNewClient) {
      await onCreateNewClient({
        ...formFields,
        businessDivision: formFields.businessDivision || '',
        sponsorList:
          formFields.sponsorList?.map(({ name, email }) => ({
            name,
            email,
          })) || [],
        legalEntityName: formFields.legalEntityName as string,
        dataSourceType: formFields.origDataSource || 'GA4_PROP',
        contractStartDate: formFields.contractStartDate as string,
        contractEndDate: formFields.contractEndDate as string,
        totalContractValue: formFields.totalContractValue as number,
        revenuePerMonth: formFields.revenuePerMonth as number,
        customTandC: formFields.customTandC as boolean,
        autoRenew: formFields.autoRenew as boolean,
        domains: formFields.domains || '',
        domainLimit: formFields.domainLimit as number,
        userLimit: formFields.namedUserLimit as number,
        namedUserLimit: formFields.namedUserLimit as number,
        companyNotes: formFields.companyNotes || '',
      })
    }
  }

  return (
    <>
      {showUpdateButton && (
        <>
          <Heading type={3} align="left">
            Contract details for {companyName}
          </Heading>
          {!fetchEnterpriseCompanyDataError && loadingEnterpriseCompanyData && (
            <Preloader
              style={{
                width: 60,
                height: 40,
                marginTop: 50,
                marginBottom: 50,
              }}
            />
          )}
          {!!fetchEnterpriseCompanyDataError && (
            <ErrorMessage>
              Error loading enterprise data for this account.
            </ErrorMessage>
          )}
        </>
      )}
      {!fetchEnterpriseCompanyDataError && !loadingEnterpriseCompanyData && (
        <form
          id="enterpriseOrgForm"
          className={styles.enterpriseCompanyDetailsForm}
          onSubmit={onSubmit}
        >
          {isNonWhitelabelSupportUser(userPermission) && (
            <FormRow bottomBorder includePaddingBottom>
              <FormLabel id="whitelabel">Whitelabel domain</FormLabel>
              <FormField>
                <SelectBoxSimple
                  name="whitelabel"
                  // This field should not be editable after creation
                  disabled={showUpdateButton}
                  value={formFields.whitelabel || 'support'}
                  onChange={(val) => {
                    updateFormField('whitelabel', val)
                  }}
                >
                  {[
                    {
                      name: 'None (Regular Uplifter client)',
                      value: 'support',
                    },
                    {
                      name: 'Uptimal',
                      value: 'uptimal',
                    },
                  ].map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectBoxSimple>
              </FormField>
            </FormRow>
          )}
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="companyName">Client</FormLabel>
            <FormField>
              <Input
                name="companyName"
                id="companyName"
                type="text"
                required
                error={
                  !formFields.companyName &&
                  formError === 'Please fill in all required fields.'
                }
                autoComplete="off"
                value={formFields.companyName}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('companyName', val)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel optional="(if applicable)" id="businessDivision">
              Business division
            </FormLabel>
            <FormField>
              <Input
                name="businessDivision"
                id="businessDivision"
                type="text"
                autoComplete="off"
                value={formFields.businessDivision || ''}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('businessDivision', val)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="sponsorList">Sponsors</FormLabel>
            <FormField>
              <MultiList
                id="sponsorList"
                ItemOneLabel="Full Name"
                ItemTwoLabel="Email"
                list={
                  formFields.sponsorList?.map(({ name, email }) => ({
                    col1Value: name,
                    col2Value: email,
                  })) || []
                }
                onChange={(val) =>
                  updateFormField(
                    'sponsorList',
                    val.map(({ col1Value, col2Value }) => ({
                      name: col1Value,
                      email: col2Value,
                    })),
                  )
                }
              />
              {formError === 'Please fill in all required fields.' &&
                (!formFields.sponsorList ||
                  formFields.sponsorList.length === 0) && (
                  <ErrorMessage>Please add at least one sponsor.</ErrorMessage>
                )}
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="legalEntityName">Client's legal name</FormLabel>
            <FormField>
              <Input
                name="legalEntityName"
                id="legalEntityName"
                type="text"
                autoComplete="off"
                required
                error={
                  !formFields.legalEntityName &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.legalEntityName || ''}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('legalEntityName', val)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="contractStartDate">Contract start date</FormLabel>
            <FormField>
              <StyledDatePicker
                id="contractStartDate"
                dateFormat="dd/MM/y"
                placeholderText="dd/MM/y"
                required
                isError={
                  !formFields.contractStartDate &&
                  formError === 'Please fill in all required fields.'
                }
                selected={
                  moment(formFields.contractStartDate, 'YYYYMMDD').isValid()
                    ? moment(formFields.contractStartDate, 'YYYYMMDD').toDate()
                    : null
                }
                onChange={(date) => {
                  updateFormField(
                    'contractStartDate',
                    date ? moment(date.toString()).format('YYYYMMDD') : null,
                  )
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="breakClauseDate" optional="(if applicable)">
              Break clause date
            </FormLabel>
            <FormField>
              <StyledDatePicker
                id="breakClauseDate"
                dateFormat="dd/MM/y"
                placeholderText="dd/MM/y"
                selected={
                  moment(formFields.breakClauseDate, 'YYYYMMDD').isValid()
                    ? moment(formFields.breakClauseDate, 'YYYYMMDD').toDate()
                    : null
                }
                onChange={(date) => {
                  updateFormField(
                    'breakClauseDate',
                    date ? moment(date.toString()).format('YYYYMMDD') : null,
                  )
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="contractEndDate">Contract end date</FormLabel>
            <FormField>
              <StyledDatePicker
                id="contractEndDate"
                dateFormat="dd/MM/y"
                placeholderText="dd/MM/y"
                required
                isError={
                  !formFields.contractEndDate &&
                  formError === 'Please fill in all required fields.'
                }
                selected={
                  moment(formFields.contractEndDate, 'YYYYMMDD').isValid()
                    ? moment(formFields.contractEndDate, 'YYYYMMDD').toDate()
                    : null
                }
                onChange={(date) => {
                  updateFormField(
                    'contractEndDate',
                    date ? moment(date.toString()).format('YYYYMMDD') : null,
                  )
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="totalContractValue">
              Total contract value (£)
            </FormLabel>
            <FormField>
              <Input
                name="totalContractValue"
                id="totalContractValue"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.totalContractValue !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.totalContractValue || 0}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('totalContractValue', parseFloat(val))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="revenuePerMonth">Monthly revenue (£)</FormLabel>
            <FormField>
              <Input
                name="revenuePerMonth"
                id="revenuePerMonth"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.revenuePerMonth !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.revenuePerMonth || 0}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('revenuePerMonth', parseFloat(val))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="customTandC">
              Custom Terms &amp; Conditions?
            </FormLabel>
            <FormField>
              <Input
                id="customTandC"
                name="customTandC"
                type="checkbox"
                checked={formFields.customTandC ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('customTandC', checked)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="autoRenew">Automatic contract renewal?</FormLabel>
            <FormField>
              <Input
                id="autoRenew"
                name="autoRenew"
                type="checkbox"
                checked={formFields.autoRenew ?? false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('autoRenew', checked)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="domains" optional="(if applicable)">
              Website domains
            </FormLabel>
            <FormField>
              <Input
                id="domains"
                name="domains"
                type="textArea"
                placeholder="Add domains separated by ;"
                value={formFields.domains || ''}
                onChange={(val: string) => updateFormField('domains', val)}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="domainLimit">Paid domain limit</FormLabel>
            <FormField>
              <Input
                name="domainLimit"
                id="domainLimit"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.domainLimit !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.domainLimit || 0}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('domainLimit', parseInt(val, 10))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="namedUserLimit">Named user limit</FormLabel>
            <FormField>
              <Input
                name="namedUserLimit"
                id="namedUserLimit"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.namedUserLimit !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.namedUserLimit || formFields.userLimit || 0}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('namedUserLimit', parseInt(val, 10))
                  updateFormField('userLimit', parseInt(val, 10))
                }}
              />
              {formFields.userCount > 0 && (
                <span className={styles.subtitle}>
                  Current user count: {formFields.userCount.toString()}
                </span>
              )}
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="activeUserLimit">Active user limit</FormLabel>
            <FormField>
              <Input
                name="activeUserLimit"
                id="activeUserLimit"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.activeUserLimit !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={formFields.activeUserLimit || 0}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('activeUserLimit', parseInt(val, 10))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="linkMonitorLimit">Monitored links limit</FormLabel>
            <FormField>
              <Input
                name="linkMonitorLimit"
                id="linkMonitorLimit"
                type="number"
                autoComplete="off"
                value={formFields.linkMonitorLimit ?? -1}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('linkMonitorLimit', parseInt(val, 10))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="workspaceLimit">Workspaces limit</FormLabel>
            <FormField>
              <Input
                name="workspaceLimit"
                id="workspaceLimit"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.workspaceLimit !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={
                  formFields.workspaceLimit ||
                  subscriptionLevelDetails.enterprise.maxWorkspaces
                }
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('workspaceLimit', parseInt(val, 10))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="connectionLimit">Connectors limit</FormLabel>
            <FormField>
              <Input
                name="connectionLimit"
                id="connectionLimit"
                type="number"
                autoComplete="off"
                required
                error={
                  typeof formFields.connectionLimit !== 'number' &&
                  formError === 'Please fill in all required fields.'
                }
                value={
                  formFields.connectionLimit ||
                  subscriptionLevelDetails.enterprise.maxConnectors
                }
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  const { value: val } = e.target as HTMLInputElement

                  updateFormField('connectionLimit', parseInt(val, 10))
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="origDataSource">Data source</FormLabel>
            <FormField>
              <SelectBoxSimple
                name="origDataSource"
                required
                value={formFields.origDataSource || 'GA4_PROP'}
                onChange={(val) => {
                  updateFormField('origDataSource', val)
                }}
              >
                {[
                  {
                    name: 'Google Analytics',
                    value: 'GA4_PROP',
                  },
                  {
                    name: 'Adobe Analytics',
                    value: 'AA_REPORT',
                  },
                ].map(({ name, value }) => (
                  <option key={value} value={value}>
                    {name}
                  </option>
                ))}
              </SelectBoxSimple>
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel>Paid modules</FormLabel>
            <FormField className={styles.checkboxGroup}>
              <Input
                id="trackAvailable"
                name="trackAvailable"
                type="checkbox"
                label="Track"
                checked={
                  typeof formFields.trackAvailable === 'boolean'
                    ? formFields.trackAvailable
                    : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('trackAvailable', checked)
                }}
              />
              <Input
                id="reportAvailable"
                name="reportAvailable"
                type="checkbox"
                label="Report"
                checked={
                  typeof formFields.reportAvailable === 'boolean'
                    ? formFields.reportAvailable
                    : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('reportAvailable', checked)
                }}
              />
              <Input
                id="planAvailable"
                name="planAvailable"
                type="checkbox"
                label="Plan"
                checked={
                  typeof formFields.planAvailable === 'boolean'
                    ? formFields.planAvailable
                    : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('planAvailable', checked)
                }}
              />
              <Input
                id="connectAvailable"
                name="connectAvailable"
                type="checkbox"
                label="Connect"
                checked={
                  typeof formFields.connectAvailable === 'boolean'
                    ? formFields.connectAvailable
                    : true
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = e.target as HTMLInputElement
                  updateFormField('connectAvailable', checked)
                }}
              />
            </FormField>
          </FormRow>
          <FormRow bottomBorder includePaddingBottom>
            <FormLabel id="companyNotes" optional="(if applicable)">
              Notes
            </FormLabel>
            <FormField>
              <Input
                id="companyNotes"
                name="companyNotes"
                type="textArea"
                placeholder="Add details here"
                value={formFields.companyNotes || ''}
                onChange={(val: string) => updateFormField('companyNotes', val)}
              />
            </FormField>
          </FormRow>
          <FormRow
            bottomBorder={showUpdateButton}
            includePaddingBottom={showUpdateButton}
          >
            <FormLabel>Company licence status</FormLabel>
            <FormField className={styles.checkboxGroup}>
              <div className={styles.checkboxGroup}>
                <Input
                  id="aboutToExpireCheckbox"
                  name="aboutToExpireCheckbox"
                  type="checkbox"
                  label="About to expire"
                  checked={formFields.aboutToExpireCheckbox ?? false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target as HTMLInputElement
                    updateFormField('aboutToExpireCheckbox', checked)

                    if (checked) {
                      updateFormField('hasExpiredCheckbox', false)
                      updateFormField('isSuspendedCheckbox', false)
                    }
                  }}
                />
                <Input
                  id="hasExpiredCheckbox"
                  name="hasExpiredCheckbox"
                  type="checkbox"
                  label="Expired"
                  checked={formFields.hasExpiredCheckbox ?? false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target as HTMLInputElement
                    updateFormField('hasExpiredCheckbox', checked)

                    if (checked) {
                      updateFormField('aboutToExpireCheckbox', false)
                      updateFormField('isSuspendedCheckbox', false)
                    }
                  }}
                />
                <Input
                  id="isSuspendedCheckbox"
                  name="isSuspendedCheckbox"
                  type="checkbox"
                  label="Suspended"
                  checked={formFields.isSuspendedCheckbox ?? false}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target as HTMLInputElement

                    updateFormField('isSuspendedCheckbox', checked)

                    if (checked) {
                      updateFormField('aboutToExpireCheckbox', false)
                      updateFormField('hasExpiredCheckbox', false)
                    }
                  }}
                />
              </div>
              {!showUpdateButton && formError && (
                <ErrorMessage>{formError}</ErrorMessage>
              )}
            </FormField>
          </FormRow>
          {showUpdateButton && (
            <FormRow>
              <FormLabel />
              <FormField>
                <Button
                  form="enterpriseOrgForm"
                  style={{ width: 'fit-content' }}
                  loading={updatingCompanyDetails}
                  isDisabled={!formIsUpdated}
                >
                  Update
                </Button>
                {updateSuccess && (
                  <SuccessText>Contract details updated.</SuccessText>
                )}
                {formError && <ErrorMessage>{formError}</ErrorMessage>}
              </FormField>
            </FormRow>
          )}
        </form>
      )}
    </>
  )
}

interface AddSupportUserFormState {
  email: string
  permissionLevel: 'support' | 'whitelabelSupport'
  whitelabelDomain: string | null
  loading: boolean
  success: string
  error: string
}

const AddSuperAdminUser = () => {
  const { userPermission, whiteLabelAdminDomain } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const [addAdminUser] = useMutation(addSupportUser)

  const [addSupportUserFormState, setAddSupportUserFormState] = useState<
    AddSupportUserFormState
  >({
    email: '',
    permissionLevel: isNonWhitelabelSupportUser(userPermission)
      ? 'support'
      : 'whitelabelSupport',
    whitelabelDomain: whiteLabelAdminDomain || null,
    loading: false,
    success: '',
    error: '',
  })
  return (
    <>
      <form
        className={styles.addSupportUserForm}
        onSubmit={async (e) => {
          e.preventDefault()

          try {
            const {
              email,
              permissionLevel,
              whitelabelDomain,
            } = addSupportUserFormState

            setAddSupportUserFormState((curr) => ({
              ...curr,
              loading: true,
              success: '',
              error: '',
            }))

            if (!email || !validateEmail(email)) {
              setAddSupportUserFormState((curr) => ({
                ...curr,
                loading: false,
                error: messages.notValidEmail,
              }))
              return
            }

            await addAdminUser({
              variables: {
                email,
                whitelabel: whitelabelDomain || undefined,
                permissionLevel,
              },
            })

            logAction({
              variables: {
                action: 'add-support-user',
                websiteSection: 'settings',
                pagePath: '/settings',
                functionName: 'addSupportUser',
                extra: JSON.stringify({
                  email,
                  permissionLevel,
                  whiteLabelAdminDomain,
                }),
              },
            })

            setAddSupportUserFormState((curr) => ({
              ...curr,
              email: '',
              success: `${email} has been added as a support user${
                whitelabelDomain ? ` to the ${whitelabelDomain} domain.` : '.'
              }`,
              loading: false,
            }))
          } catch {
            setAddSupportUserFormState((curr) => ({
              ...curr,
              error: 'Unable to add - please try again or contact support.',
            }))
          }
        }}
      >
        <div>
          <InputLabel htmlFor="addEmail">Email</InputLabel>
          <Input
            name="addEmail"
            className={styles.inputField}
            type="text"
            required
            placeholder="user@example.com"
            value={addSupportUserFormState.email}
            onValueChange={(nextEmail) =>
              setAddSupportUserFormState((curr) => ({
                ...curr,
                email: nextEmail,
              }))
            }
          />
        </div>
        {isNonWhitelabelSupportUser(userPermission) && (
          <div>
            <InputLabel htmlFor="permission">Whitelabel domain</InputLabel>
            <SelectBoxSimple
              name="permission"
              value={addSupportUserFormState.whitelabelDomain || 'support'}
              onChange={(domain) => {
                setAddSupportUserFormState((curr) => ({
                  ...curr,
                  permissionLevel:
                    domain === 'support' ? 'support' : 'whitelabelSupport',
                  whitelabelDomain: domain === 'support' ? null : domain,
                }))
              }}
            >
              {/* Hardcoded options */}
              <option value="support">Uplifter</option>
              <option value="uptimal">Uptimal</option>
            </SelectBoxSimple>
          </div>
        )}
        <Button
          loading={addSupportUserFormState.loading}
          isDisabled={
            addSupportUserFormState.email === '' ||
            addSupportUserFormState.loading
          }
          type="submit"
        >
          Add super admin user
        </Button>
      </form>
      {!addSupportUserFormState.whitelabelDomain && (
        <NoteText label="Warning">
          The user will have full access to all organisations on Uplifter.
        </NoteText>
      )}
      {addSupportUserFormState.success && (
        <SuccessText>{addSupportUserFormState.success}</SuccessText>
      )}
      {addSupportUserFormState.error && (
        <ErrorMessage>{addSupportUserFormState.error}</ErrorMessage>
      )}
    </>
  )
}

const SupportSettings = () => {
  const { isEnterprise, isMicrosoftMarketplace } = useSubscriptionLevel()

  const [
    addNewEnterpriseCompany,
    { loading: addingEnterpriseCompany },
  ] = useMutation(createEnterpriseCompany)

  const [createCompanyModalActive, setCreateCompanyModalActive] = useState(
    false,
  )
  const [createCompanySuccess, setCreateCompanySuccess] = useState(false)

  const accordionItems = useMemo(() => {
    const items = [
      {
        key: 'billing',
        header: {
          icon: '🏢',
          title: 'Create an enterprise client',
          subtitle:
            'Create a new paying enterprise client with their own workspaces and users.',
        },
        expandedContent: (
          <Button
            variant="secondary"
            onPress={() => setCreateCompanyModalActive(true)}
          >
            Create a new enterprise client
          </Button>
        ),
      },
      {
        key: 'users',
        header: {
          icon: '👥',
          title: 'Add super admin user',
          subtitle:
            'Create a user with access to every organisation and workspace on this white label domain.',
        },
        expandedContent: <AddSuperAdminUser />,
      },
    ]

    if (isEnterprise && !isMicrosoftMarketplace) {
      items.unshift({
        key: 'accountSettings',
        header: {
          icon: '⚙️',
          title: 'Enterprise contract details',
          subtitle: 'Review contract details.',
        },
        expandedContent: <EnterpriseContractDetails showUpdateButton />,
      })
    }

    return items
  }, [isEnterprise, isMicrosoftMarketplace])

  return (
    <>
      <Accordion
        id="supportSettings"
        className={styles.accordionContainer}
        accordionItems={accordionItems}
      />
      {createCompanyModalActive && (
        <Modal
          setIsOpen={setCreateCompanyModalActive}
          width="wide"
          modalHeader="Create a new enterprise client"
          headerColor="pink"
          className={styles.createEnterpriseClientModal}
          footerContent={
            <>
              {createCompanySuccess && (
                <SuccessText>New client created successfully.</SuccessText>
              )}
              <Button
                form="enterpriseOrgForm"
                loading={addingEnterpriseCompany}
              >
                Create a new client
              </Button>
            </>
          }
        >
          <EnterpriseContractDetails
            onCreateNewClient={async (variables) => {
              await addNewEnterpriseCompany({
                variables,
              })

              setCreateCompanySuccess(true)

              setTimeout(() => {
                setCreateCompanySuccess(false)
                setCreateCompanyModalActive(false)
              }, 5000)
            }}
          />
        </Modal>
      )}
    </>
  )
}

export default SupportSettings
