import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'

import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { getUserInfo } from '../api/graphql/user-client'
import Stars from '../assets/star-cluster-yellow.svg'
import { NavigateButton } from '../components/button'
import Intro from '../components/intro'
import Layout from '../components/layout'
import { Preloader } from '../components/loader'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import TrackDropdownRequests from '../components/track-dropdown-requests'
import EditExistingDropdowns from '../components/track-edit-existing-dropdowns'
import UploadExistingUtmSpreadsheetModal from '../components/upload-existing-utm-spreadsheet-modal'
import useOnboarding from '../hooks/useOnboarding'

const TrackEditDropdowns = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { data: userDetailsData } = useQuery(getUserInfo)

  const [showUtmSheetUploadModal, setShowUtmSheetUploadModal] = useState(false)

  const accountCreationDate = userDetailsData?.currentAccount.created

  const {
    fullOnboardingSections: { account: accountOnboardingSections },
  } = useOnboarding()

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  // Wait for workspace ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const showSupportMessage = useMemo(() => {
    const editTaxonomyComplete =
      accountOnboardingSections !== null &&
      !!accountOnboardingSections.find(
        ({ onboardingSectionID, sectionCompleted }) =>
          onboardingSectionID === 'editTaxonomy' && sectionCompleted,
      )

    if (editTaxonomyComplete || !accountCreationDate) return false

    // Do not show for accounts created > 30 days ago
    return moment().diff(moment(accountCreationDate), 'days') < 30
  }, [accountOnboardingSections, accountCreationDate])

  const dropdownParams = useMemo(() => {
    if (generatedStructure === null) return []

    return generatedStructure.paramDefs.filter(
      (param) => param.fieldType === 'select' && param.selectFields,
    )
  }, [generatedStructure])

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <Intro title="Edit dropdowns">
            <p>Add, remove, and approve values for dropdown parameters.</p>
            {showSupportMessage && (
              <Tag inline icon={Stars}>
                <p>
                  <strong>Have an existing UTM spreadsheet?</strong>{' '}
                  <NavigateButton
                    onPress={() => setShowUtmSheetUploadModal(true)}
                  >
                    Upload it and we will set up your parameters
                  </NavigateButton>
                </p>
              </Tag>
            )}
          </Intro>
          {generatedStructure && (
            <TrackDropdownRequests currentWorkspaceData={generatedStructure} />
          )}
          {generatedStructure === null ? (
            <Preloader />
          ) : (
            <EditExistingDropdowns
              dropdownParams={dropdownParams}
              validationChecks={generatedStructure.validationChecks}
            />
          )}
        </Layout>
      </SiteWrapper>
      {showUtmSheetUploadModal && (
        <UploadExistingUtmSpreadsheetModal
          onHideModal={setShowUtmSheetUploadModal}
        />
      )}
    </>
  )
}

export default TrackEditDropdowns
