import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'

import styles from '../styles/tooltip.module.scss'

type PlacesType =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'

interface TooltipProps {
  id: string
  className?: string
  useIcon?: boolean
  clickable?: boolean
  maxWidth?: number
  centerText?: boolean
  tooltipIconClassName?: string
  tooltipClassName?: string
  tooltipPosition?: PlacesType
  tooltipPositionStrategy?: 'absolute' | 'fixed'
  tooltipColor?: 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'
  tooltipMessage?: React.ReactNode | string
  forceOpen?: boolean
  children?: React.ReactNode
}

// Full props: https://react-tooltip.com/docs/options
export default function Tooltip({
  id,
  className,
  useIcon = false,
  clickable,
  maxWidth,
  centerText = false,
  tooltipIconClassName,
  tooltipClassName,
  tooltipPosition = 'top',
  tooltipPositionStrategy = 'absolute',
  tooltipColor = 'light',
  tooltipMessage,
  forceOpen,
  children,
}: TooltipProps) {
  return (
    <>
      <div className={styles.tooltipTextContainer}>
        {useIcon && <span className={className}>{children}</span>}
        {(!useIcon || (useIcon && !!tooltipMessage)) && (
          <span
            data-tooltip-id={id}
            data-tooltip-place={tooltipPosition}
            data-tooltip-variant={tooltipColor}
            data-tooltip-position-strategy={tooltipPositionStrategy}
            className={classNames({
              [tooltipIconClassName || '']: useIcon,
              [className || '']: !useIcon,
              [styles.tooltipText]: !useIcon && !!tooltipMessage,
              [styles.tooltipIcon]: useIcon,
            })}
          >
            {useIcon ? '?' : children}
          </span>
        )}
      </div>
      {!!tooltipMessage &&
        ReactDOM.createPortal(
          <ReactTooltip
            id={id}
            className={classNames(tooltipClassName, styles.tooltipMessage, {
              [styles.centerText]: centerText,
            })}
            classNameArrow={styles.tooltipArrow}
            style={{
              maxWidth: maxWidth || 250,
              zIndex: 9999,
            }}
            clickable={clickable}
            globalCloseEvents={{ resize: true }}
            isOpen={forceOpen}
          >
            {typeof tooltipMessage === 'string' ? (
              <ReactMarkdown plugins={[gfm]} linkTarget="_blank">
                {tooltipMessage}
              </ReactMarkdown>
            ) : (
              tooltipMessage
            )}
          </ReactTooltip>,
          document.body,
        )}
    </>
  )
}
