import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'

import Button from './button'
import { FormField, FormRow } from './form'
import Link from './link'
import { Preloader } from './loader'
import Modal from './modal'
import SelectBox from './select-box'
import SiteLogo, { LogoTagline } from './site-logo'
import Tooltip from './tooltip'
import { BoldText, ErrorMessage } from './typography'
import WorkspaceSelector from './workspace-selector'
import { currentUserDetails, loggedInState } from '../api/apollo/variables'
import { getPaddleSubscriptionDetails } from '../api/graphql/company-client'
import { getUserAccounts, getUserInfo } from '../api/graphql/user-client'
import { logout } from '../api/REST/auth-client'
import { supportEmail } from '../core/constants'
import { isSupportUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import usePaddle from '../hooks/usePaddle'
import useSubscriptionLevel, {
  subscriptionLevelDetails,
} from '../hooks/useSubscriptionLevel'
import styles from '../styles/paddle-checkout.module.scss'

/** Builds available plans based on whether a trial should be included */
const buildPlanOptions = (includeTrial = true, includeStartup = true) => {
  const planOptions = [
    {
      label: `Business -${includeTrial ? ' 14-day trial -' : ''} monthly`,
      value: includeTrial
        ? (subscriptionLevelDetails.business.monthlyID as string)
        : (subscriptionLevelDetails.business.monthlyNoTrialID as string),
    },
    {
      label: `Business -${
        includeTrial ? ' 14-day trial -' : ''
      } annual (save 17%)`,
      value: includeTrial
        ? (subscriptionLevelDetails.business.annualID as string)
        : (subscriptionLevelDetails.business.annualNoTrialID as string),
    },
    {
      label: 'Enterprise - 1 month trial - book a demo',
      value: 'enterprise',
    },
  ]

  if (includeStartup) {
    planOptions.unshift(
      {
        label: `Startup -${includeTrial ? ' 14-day trial -' : ''} monthly`,
        value: includeTrial
          ? (subscriptionLevelDetails.startup.monthlyID as string)
          : (subscriptionLevelDetails.startup.monthlyNoTrialID as string),
      },
      {
        label: `Startup -${
          includeTrial ? ' 14-day trial -' : ''
        } annual (save 17%)`,
        value: includeTrial
          ? (subscriptionLevelDetails.startup.annualID as string)
          : (subscriptionLevelDetails.startup.annualNoTrialID as string),
      },
    )
  }

  return planOptions
}

interface PaddleInitialPaymentProps {
  showPlanSelector?: boolean
  includeTrial?: boolean
  onSuccess?: () => void
}

export const PaddleInitialPayment = ({
  showPlanSelector = false,
  includeTrial = true,
  onSuccess,
}: PaddleInitialPaymentProps) => {
  const currLoggedInState = useReactiveVar(loggedInState)

  const { userEmail, companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: accountData, loading: accountDataLoading } = useQuery(
    getUserInfo,
  )

  const fullPlanOptions = useMemo(
    () => buildPlanOptions(includeTrial, showPlanSelector),
    [includeTrial],
  )

  const [selectedPlan, setSelectedPlan] = useState(fullPlanOptions[0])

  // Fetch user details
  useEffect(() => {
    if (!accountData) return

    const { currentUser, currentAccount } = accountData

    currentUserDetails({
      ...currentUserDetails(),
      userEmail: currentUser.email,
      userID: currentUser.userID,
      userFirstName: currentUser.firstName,
      userLastName: currentUser.lastName,
      userPermission: currentUser.currentAccountPermission as PermissionLevel,
      workspaceID: currentAccount.accountID,
      workspaceName: currentAccount.accountName,
      workspaceHomepage: currentAccount.homepage,
      trackAvailable: currentAccount.trackAvailable,
      reportAvailable: currentAccount.reportAvailable,
      explainAvailable: currentAccount.explainAvailable,
      planAvailable: currentAccount.planAvailable,
      connectAvailable: currentAccount.connectAvailable,
      companyID: currentAccount.companyID,
      companyName: currentAccount.companyName,
    })
  }, [accountData])

  // Create Paddle instance and define success callback
  const { Paddle, paddleCheckoutLoading } = usePaddle({
    eventCallback: ({ name, data }) => {
      if (name === 'checkout.completed') {
        logAction({
          variables: {
            action: 'paddle-checkout-complete',
            functionName: 'createNewClient',
            pagePath: window.location.pathname,
            websiteSection: 'setup',
            extra: JSON.stringify({ selectedPlan: selectedPlan.value }),
          },
        })

        // Trials are only available once per company. This is a good signup metric
        if (includeTrial) {
          // @ts-ignore
          if (window.dataLayer && window.dataLayer.push) {
            // @ts-ignore
            window.dataLayer.push({
              event: 'paddle-trial-started',
              subscription_level: selectedPlan.value,
            })
          }

          logAction({
            variables: {
              action: 'paddle-trial-started',
              functionName: 'paddleStartTrial',
              pagePath: window.location.pathname,
              websiteSection: 'setup',
              extra: JSON.stringify({
                selectedPlan: selectedPlan.value,
              }),
            },
          })
        }

        // Set a sessionStorage variable to allow logins for this session while waiting for webhook updates
        if (data?.transaction_id) {
          window.sessionStorage.setItem('paddle_payment_id', companyID)
        }

        // Update login state to newlyCreated
        loggedInState({
          ...currLoggedInState,
          isNewlyCreated: includeTrial
            ? true
            : currLoggedInState.isNewlyCreated,
          authenticated: true,
          checked: true,
          blockLoginCheck: false,
          paddleSubscriptionID: selectedPlan.value,
        })

        if (onSuccess) {
          onSuccess()
        }
      }
    },
  })

  // Create Paddle checkout
  // https://developer.paddle.com/build/checkout/build-branded-inline-checkout
  useEffect(() => {
    if (!Paddle || !userEmail || !companyID) {
      return
    }

    // Params reference: https://developer.paddle.com/paddlejs/overview
    Paddle.Checkout.open({
      items: [
        {
          priceId: selectedPlan.value,
        },
      ],
      customer: {
        email: userEmail,
      },
      customData: {
        companyID,
      },
      settings: {
        displayMode: 'inline',
        variant: 'one-page',
        frameTarget: 'paddle-checkout-container',
        frameInitialHeight: 450,
        frameStyle:
          'width:100%; min-width:312px; background-color: transparent; border: none;',
        allowLogout: false,
        showAddTaxId: false,
      },
    })
  }, [Paddle, userEmail, companyID])

  return (
    <>
      <div
        className={classNames(
          'paddle-checkout-container',
          styles.paddleCheckoutContainer,
          {
            [styles.loading]: paddleCheckoutLoading,
          },
        )}
      >
        {Paddle && (
          <>
            {showPlanSelector && (
              <>
                <p style={{ marginBottom: 8 }}>
                  <BoldText>
                    {includeTrial ? 'P' : 'Your free trial has ended, p'}lease
                    select a plan:
                  </BoldText>
                </p>
                <FormRow className={styles.topFormRow} includePaddingBottom>
                  <FormField className={styles.formField}>
                    <SelectBox
                      value={selectedPlan}
                      options={fullPlanOptions}
                      onChange={(newValue) => {
                        if (!newValue) return

                        if (newValue.value === 'enterprise') {
                          window.open(
                            'https://calendly.com/uplifterai/demo',
                            '_blank',
                          )

                          return
                        }

                        setSelectedPlan(newValue)

                        Paddle.Checkout.updateCheckout({
                          items: [{ priceId: newValue.value, quantity: 1 }],
                        })
                      }}
                    >
                      <Button
                        variant="text"
                        className={styles.addButton}
                        onPressStart={() =>
                          window.open('https://uplifter.ai/pricing', '_blank')
                        }
                      >
                        Compare plans &gt;
                      </Button>
                    </SelectBox>
                  </FormField>
                </FormRow>
              </>
            )}
            {includeTrial && (
              <>
                <p className={styles.paymentInfo}>
                  <BoldText>
                    Enter your billing details to start 14-day free Business
                    plan trial.
                  </BoldText>{' '}
                  <Tooltip
                    id="why-tooltip"
                    className={styles.why}
                    clickable
                    tooltipMessage={
                      <>
                        <p>
                          We collect credit card details to verify customers and
                          prevent our platform being used for malicious purposes
                          like fraud and spam.
                        </p>
                        <p>
                          You can cancel anytime and have a 30-day money back
                          guarantee. Email{' '}
                          <Link href={`mailto:${supportEmail}`}>
                            {supportEmail}
                          </Link>{' '}
                          to activate.
                        </p>
                      </>
                    }
                  >
                    Why?
                  </Tooltip>
                </p>
                <p>
                  You will <BoldText>not</BoldText> be charged today, first 14
                  days are free, cancel or downgrade any time.
                </p>
              </>
            )}
          </>
        )}
        {paddleCheckoutLoading && <Preloader />}
        {((!Paddle && !paddleCheckoutLoading) ||
          (!accountData && !accountDataLoading)) && (
          <ErrorMessage showSupport>Error loading payment form.</ErrorMessage>
        )}
      </div>
    </>
  )
}

/** Used for existing accounts that have never paid (old 'Free' tier), or who have cancelled/deleted their subscription */
export const PaddlePaymentBlocker = () => {
  const { companyID, workspaceID, userID, userPermission } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const { data: userAllAccountsData, loading: fetchingAllAccounts } = useQuery(
    getUserAccounts,
  )
  const {
    data: paddleSubscriptionData,
    loading: loadingPaddleSubscriptionData,
  } = useQuery(getPaddleSubscriptionDetails)

  const [showModal, setShowModal] = useState(true)

  /** Checks if subscription has a previous trial period */
  const includeTrial = useMemo(() => {
    if (!paddleSubscriptionData) return true

    // ! TBC: Trial plans should not be visible for accounts created more than 30 days ago
    // const createdDate = companyData?.currentCompany.created
    // const includeTrial = createdDate
    //   ? moment().diff(moment(createdDate), 'days') <= 2
    //   : true

    const {
      paddleSubscriptionTrialEndDate,
    } = paddleSubscriptionData.currentCompany

    return !(
      !!paddleSubscriptionTrialEndDate &&
      moment(paddleSubscriptionTrialEndDate).isValid()
    )
  }, [paddleSubscriptionData])

  const totalWorkspaces =
    userAllAccountsData?.currentUser.userAccountProfiles.length || 1

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-paddle-payment-blocker-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'paddlePaymentRequired',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!showModal) return null

  return (
    <Modal
      setIsOpen={setShowModal}
      width="superNarrow"
      // Only support users should be able to close this modal
      hideCloseButton={!isSupportUser(userPermission)}
      closeonEsc={isSupportUser(userPermission)}
      beforeClose={() => {
        if (isSupportUser(userPermission)) return

        logout('/login')
      }}
      noText="Log out"
      loading={loadingPaddleSubscriptionData}
      // Do not show the dropdown if fetching accounts or only one workspace
      footerContent={
        fetchingAllAccounts || totalWorkspaces < 2 ? null : (
          <WorkspaceSelector
            className={styles.workspaceSelector}
            redirectTo="/welcome"
            hideAddWorkspacesButton
          />
        )
      }
    >
      <SiteLogo className={styles.siteLogo} />
      <LogoTagline className={styles.tagLine} />
      <PaddleInitialPayment
        // Accounts that have not had a trial before should be forced onto the Business plan, no selector shown
        showPlanSelector={!includeTrial}
        includeTrial={includeTrial}
        onSuccess={() => {
          window.setTimeout(() => {
            setShowModal(false)
          }, 3000)
        }}
      />
    </Modal>
  )
}

export const PaddlePastDuePaymentBlocker = () => {
  const { companyID, workspaceID, userID, userPermission } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const {
    loadingSubscriptionInfo,
    subscriptionID,
    paddleData,
  } = useSubscriptionLevel()

  const { data: userAllAccountsData, loading: fetchingAllAccounts } = useQuery(
    getUserAccounts,
  )

  const [showModal, setShowModal] = useState(true)

  const { paddleNextBillDate, paddlePaymentMethodURL } = useMemo(() => {
    if (!paddleData) {
      return {}
    }

    return {
      paddleNextBillDate:
        paddleData.currentCompany.paddleSubscriptionNextBillDate,
      paddlePaymentMethodURL: paddleData.currentCompany.paddlePaymentMethodURL,
    }
  }, [paddleData])

  const totalWorkspaces =
    userAllAccountsData?.currentUser.userAccountProfiles.length || 1

  useEffect(() => {
    logAction({
      variables: {
        action: 'view-paddle-past-due-payment-blocker-modal',
        pagePath: window.location.pathname,
        websiteSection: 'settings',
        functionName: 'paddlePaymentRequired',
        extra: JSON.stringify({ companyID, workspaceID, userID }),
      },
    })
  }, [])

  if (!showModal) return null

  return (
    <Modal
      setIsOpen={setShowModal}
      // Only support users should be able to close this modal
      hideCloseButton={!isSupportUser(userPermission)}
      closeonEsc={isSupportUser(userPermission)}
      beforeClose={() => {
        if (isSupportUser(userPermission)) return

        logout('/login')
      }}
      isWarning
      headerColor="pink"
      modalHeader="Payment due"
      noText="Log out"
      yesText="Update payment method"
      yesButtonLoading={loadingSubscriptionInfo}
      yesButtonDisabled={!loadingSubscriptionInfo && !paddleData}
      onYes={() => {
        if (!paddlePaymentMethodURL) return

        window.open(paddlePaymentMethodURL, '_blank')

        logAction({
          variables: {
            action: 'update-paddle-subscription-payment-method',
            extra: JSON.stringify({
              paddleSubscriptionId: subscriptionID,
            }),
            websiteSection: 'upgrade',
            pagePath: '/upgrade',
            functionName: 'updatePaddleSubscription',
          },
        })
      }}
      // Do not show the dropdown if fetching accounts or only one workspace
      footerContent={
        fetchingAllAccounts || totalWorkspaces < 2 ? null : (
          <WorkspaceSelector
            className={styles.workspaceSelector}
            redirectTo="/welcome"
            hideAddWorkspacesButton
          />
        )
      }
    >
      <p>
        Your payment on {moment(paddleNextBillDate).format('Do MMM YYYY')}{' '}
        declined. Please update payment method to retake payment.
      </p>
      <p>
        Please note that all your data will be deleted after 90 days of
        unlicensed inactivity. All short links will redirect to an error page.
        Email <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
        additional support.
      </p>
    </Modal>
  )
}
