import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'

import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import {
  getCampaignCodeGenerator,
  getUniqueParamCurrentTotal,
} from '../api/graphql/track-create-client'
import { lockUnlockGenerator } from '../api/graphql/track-edit-client'
import { getUserInfo } from '../api/graphql/user-client'
import { ParamColors } from '../api/types'
import LockedIcon from '../assets/svgs/padlock-locked'
import UnlockedIcon from '../assets/svgs/padlock-unlocked'
import Stars from '../assets/star-cluster-yellow.svg'
import Button, { CopyButton, NavigateButton } from '../components/button'
import Intro from '../components/intro'
import Layout from '../components/layout'
import { Preloader } from '../components/loader'
import { Toggle } from '../components/input-v2'
import Modal from '../components/modal'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import Tooltip from '../components/tooltip'
import ParametersMasterRules from '../components/track-edit-advanced-rules'
import { LinkPreview } from '../components/track-edit-link-preview'
import { ParametersDragAndDropContainer } from '../components/track-edit-params-drag-and-drop'
import { Heading } from '../components/typography'
import UploadExistingUtmSpreadsheetModal from '../components/upload-existing-utm-spreadsheet-modal'
import { linkPreviewColours } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/track-edit-parameters-and-rules.module.scss'

const TrackEditParametersAndRules = () => {
  const logAction = useLogAction()

  const { workspaceID } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const { data: companyData } = useQuery(getCompanyDetails)
  const { data: currentUserData } = useQuery(getUserInfo)
  const [
    fetchUplifterIDParamState,
    { data: uplifterIDParamData },
  ] = useLazyQuery(getUniqueParamCurrentTotal)
  const [
    getGenerator,
    { data: campaignCodeGeneratorData },
  ] = useLazyQuery(getCampaignCodeGenerator, { fetchPolicy: 'cache-first' })
  const [toggleGeneratorLock, { error: toggleError }] = useMutation(
    lockUnlockGenerator,
  )

  // Wait for account ID so that generator can be cached
  useEffect(() => {
    if (workspaceID !== '') getGenerator()
  }, [workspaceID])

  // Adobe accounts should not use Uplifter ID
  useEffect(() => {
    if (
      workspaceID !== '' &&
      (!dataSource || dataSource.connectionSource !== 'adobe')
    ) {
      fetchUplifterIDParamState({ variables: { fieldType: 'up_id' } })
    }
  }, [workspaceID, dataSource])

  const internalContactEmail = useMemo(() => {
    if (!companyData) return null

    return companyData.currentCompany.internalContactEmail
  }, [companyData])

  const accountCreationDate = currentUserData?.currentAccount.created

  const [hoverItem, setHoverItem] = useState<string | null>(null)
  const [paramColors, setParamColors] = useState<ParamColors | null>(null)
  const [prefixSeparatorOpen, setPrefixSeparatorOpen] = useState(false)
  const [showUtmSheetUploadModal, setShowUtmSheetUploadModal] = useState(false)
  const [showUnlockModal, setShowUnlockModal] = useState(false)

  // Set locked status from DB
  const { isLocked } = useMemo(() => {
    if (!campaignCodeGeneratorData) return { isLocked: false }

    return campaignCodeGeneratorData.campaignCodeGenerator
  }, [campaignCodeGeneratorData])

  const showUnlockModalAndTrack = useCallback(() => {
    setShowUnlockModal(true)

    logAction({
      variables: {
        action: 'open-track-edit-unlock-modal',
        websiteSection: 'track',
        pagePath: '/track/edit-parameters-and-rules',
        functionName: 'openUnlockModal',
      },
    })
  }, [])

  const {
    fullOnboardingSections: { account: accountOnboardingSections },
  } = useOnboarding()

  const generatedStructure = useMemo(() => {
    if (!campaignCodeGeneratorData) return null

    return campaignCodeGeneratorData.campaignCodeGenerator
  }, [campaignCodeGeneratorData])

  const showUplifterIDParam = useMemo(() => {
    if (!uplifterIDParamData) return false

    return uplifterIDParamData.track.currentSequentialCodeID.isEnabled || false
  }, [uplifterIDParamData])

  // Show support message if account has not had taxonomy edited yet
  const showSupportMessage = useMemo(() => {
    const editTaxonomyComplete =
      accountOnboardingSections !== null &&
      !!accountOnboardingSections.find(
        ({ onboardingSectionID, sectionCompleted }) =>
          onboardingSectionID === 'editTaxonomy' && sectionCompleted,
      )

    if (editTaxonomyComplete || !accountCreationDate) return false

    // Do not show for accounts created > 30 days ago
    return moment().diff(moment(accountCreationDate), 'days') < 30
  }, [accountOnboardingSections, accountCreationDate])

  const paramsToShow = useMemo(() => {
    if (!generatedStructure || !generatedStructure.paramDefs) return []

    return generatedStructure.paramDefs.filter((param) => {
      return param.fieldAvailable && !param.metaParameter
    })
  }, [generatedStructure])

  const toggleLock = useCallback(() => {
    toggleGeneratorLock({
      variables: { isLocked: !isLocked },
      optimisticResponse: {
        track: {
          updateGeneratorMasterSettings: {
            accountID: workspaceID,
            isLocked: !isLocked,
            __typename: 'CampaignCodeGenerator',
          },
          __typename: 'TrackMutations',
        },
      },
    })
  }, [workspaceID, isLocked])

  // Param colours in editor
  useEffect(() => {
    if (paramsToShow.length > 0) {
      setParamColors((currColors) => {
        const newColors = currColors ? { ...currColors } : {}

        paramsToShow.forEach((param, index) => {
          if (!newColors[param.fieldID]) {
            newColors[param.fieldID] =
              linkPreviewColours[index % linkPreviewColours.length]
          }
        })

        if (showUplifterIDParam) {
          // Color for Uplifter ID
          newColors.uplifterID =
            linkPreviewColours[paramsToShow.length % linkPreviewColours.length]
        }

        return newColors
      })
    }
  }, [paramsToShow, showUplifterIDParam])

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <Intro title="Edit parameters and rules">
            <div className={styles.introContainer}>
              <div>
                <p style={{ marginBottom: showSupportMessage ? undefined : 0 }}>
                  Add, remove, and edit parameters and the rules about what data
                  they capture.
                </p>
                {showSupportMessage && (
                  <>
                    <Tag inline icon={Stars}>
                      <p>
                        <strong>Have an existing UTM spreadsheet?</strong>{' '}
                        <NavigateButton
                          onPress={() => setShowUtmSheetUploadModal(true)}
                        >
                          Upload it and we will set up your parameters
                        </NavigateButton>
                      </p>
                    </Tag>
                  </>
                )}
              </div>
              <div className={styles.lockContainer}>
                {toggleError ? (
                  <p className={styles.errorMessage}>
                    Unable to {isLocked ? 'unlock' : 'lock'}, please try again
                    later or contact support.
                  </p>
                ) : (
                  <p
                    className={classNames(styles.toggleLabel, {
                      [styles.isLocked]: isLocked,
                    })}
                  >
                    <Tooltip
                      id="editing-locked-tooltip"
                      useIcon
                      tooltipMessage={
                        <>
                          <p>
                            <strong>Editing locked:</strong> Your admins have
                            designed the parameters and rules for your
                            organisation. Any changes should be discussed with
                            them.
                          </p>
                          <p>
                            <strong>Editing unlocked:</strong> You are free to
                            change the parameters and rules as required. Lock
                            your changes once you are happy with your parameters
                            and rules, so other admins can't accidentally change
                            them.
                          </p>
                        </>
                      }
                    >
                      Editing {isLocked ? 'locked' : 'unlocked'}
                    </Tooltip>
                  </p>
                )}
                <Toggle
                  checked={isLocked}
                  onChange={toggleLock}
                  checkedIcon={<LockedIcon className={styles.lockIcon} />}
                  uncheckedIcon={<UnlockedIcon className={styles.lockIcon} />}
                />
              </div>
            </div>
          </Intro>
          {generatedStructure === null ? (
            <Preloader />
          ) : (
            <>
              <ParametersDragAndDropContainer
                isLocked={isLocked}
                setShowUnlockModal={showUnlockModalAndTrack}
                validation={generatedStructure.validationChecks}
                parameters={generatedStructure.paramDefs}
                paramColors={paramColors}
                setHoverItem={setHoverItem}
                showUplifterIDParam={showUplifterIDParam}
              />
              <LinkPreview
                masterPrefix={generatedStructure.masterPrefix}
                paramSeparator={generatedStructure.paramSeparator}
                paramsToShow={paramsToShow}
                paramColors={paramColors}
                hoverItem={hoverItem}
                setPrefixSeparatorOpen={setPrefixSeparatorOpen}
                showUplifterIDParam={showUplifterIDParam}
              />
              <ParametersMasterRules
                isLocked={isLocked}
                setShowUnlockModal={showUnlockModalAndTrack}
                prefixSeparatorOpen={prefixSeparatorOpen}
                setPrefixSeparatorOpen={setPrefixSeparatorOpen}
                rules={generatedStructure.validationChecks}
                setHoverItem={setHoverItem}
                showUplifterIDParam={showUplifterIDParam}
              />
            </>
          )}
        </Layout>
      </SiteWrapper>
      {showUtmSheetUploadModal && (
        <UploadExistingUtmSpreadsheetModal
          onHideModal={setShowUtmSheetUploadModal}
        />
      )}
      {showUnlockModal && (
        <Modal
          className={styles.unlockModal}
          setIsOpen={setShowUnlockModal}
          modalHeader={
            <>
              <Heading type={3} style={{ display: 'inline-block' }}>
                Your link taxonomy is locked
              </Heading>
              <LockedIcon className={styles.lockIcon} color="#333" />
            </>
          }
          headerColor="pink"
          isWarning
          noText="Cancel"
          footerContent={
            <Button
              variant="secondary"
              onPress={() => {
                toggleLock()

                setShowUnlockModal(false)
              }}
            >
              Unlock to make changes
            </Button>
          }
        >
          <p
            style={{
              margin: internalContactEmail || toggleError ? undefined : 0,
            }}
          >
            Changes to parameters or rules should be discussed with your
            admin(s) before being made.
          </p>
          {internalContactEmail && (
            <p style={{ margin: toggleError ? undefined : 0 }}>
              Please contact internal support:
              <br />
              <CopyButton value={internalContactEmail} iconAfter>
                {internalContactEmail}
              </CopyButton>
            </p>
          )}
          {toggleError && (
            <p className={styles.errorMessage}>
              Unable to {isLocked ? 'unlock' : 'lock'}, please try again later
              or contact support.
            </p>
          )}
        </Modal>
      )}
    </>
  )
}

export default TrackEditParametersAndRules
