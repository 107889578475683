import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import ReactMarkdown from 'react-markdown'

import { Tag } from './add-multi-values-tags'
import { ImgUploader } from './img-uploader'
import { ClickEditInput } from './input'
import { LoadingLabel } from './loader'
import { WorkspaceLogo } from './workspace-logo'
import { currentUserDetails } from '../api/apollo/variables'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import { uploadAccountLogo } from '../api/REST/account-client'
import uplifter from '../assets/logos/uplogo.png'
import { isAdminUser } from '../helpers'
import { getDataSourceDescription } from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import styles from '../styles/report-summary.module.scss'

export interface FilterListItem {
  /** Filter option */
  dimensionName: string
  /** Filter option ID */
  dimensionID: string
  /** Value of filter */
  optionName: string
  /** Value of filter */
  optionID: string
  /** Specific remove function to be used for e.g. URL filters */
  onRemove?: (dimensionID: string, optionID: string) => void
}

interface ReportSummaryProps {
  id?: string
  loading?: boolean
  error?: boolean
  reportTitle?: string
  onUpdateTitle?: (newTitle: string) => Promise<void>
  dateRange?: string
  showDataSource?: boolean
  successMetric?: {
    name: string
    tooltip: string
  }
  filterList?: FilterListItem[]
  onRemoveFilterOption?: (dimensionID: string, optionID: string) => void
  includeLogo?: boolean
  logoIsEditable?: boolean
  children?: React.ReactElement
}

export default function ReportSummary({
  id = 'reportSummary',
  loading,
  error,
  reportTitle,
  onUpdateTitle,
  dateRange,
  showDataSource,
  successMetric,
  filterList,
  onRemoveFilterOption,
  includeLogo = true,
  logoIsEditable = true,
  children,
}: ReportSummaryProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const isMobile = useMobile(768)

  const [fetchDataSource, { data: dataSourceData }] = useLazyQuery(
    getAccountDataSource,
  )
  const [
    fetchLogoData,
    { data: logoData, refetch: refetchLogoDetails },
  ] = useLazyQuery(getCurrentAccountQRDetails)

  const [titleValue, setTitleValue] = useState(reportTitle)

  // Update title value when saved report changes
  useEffect(() => {
    setTitleValue(reportTitle)
  }, [reportTitle])

  // Only fetch logo data if logo is included
  useEffect(() => {
    if (!includeLogo) return

    fetchLogoData()
  }, [includeLogo])

  useEffect(() => {
    if (!showDataSource) return

    fetchDataSource()
  }, [showDataSource])

  // UI display of data source details
  const dataSourceDescription = useMemo(() => {
    return getDataSourceDescription(dataSourceData)
  }, [dataSourceData])

  const logoLink = useMemo(() => {
    if (!logoData || !logoData.currentAccount) return uplifter

    const useLogo = logoData.currentAccount.logoLink || uplifter

    return useLogo.indexOf('logos/PNG/uplogo.png') !== -1 ? uplifter : useLogo
  }, [logoData])

  return (
    <div id={id} className={styles.summaryContainer}>
      <div className={styles.summary}>
        <ClickEditInput
          id="title"
          name="title"
          disabled={!onUpdateTitle}
          value={titleValue}
          className={styles.editTitle}
          onChange={async (newValue) => {
            if (!onUpdateTitle || newValue === titleValue) return

            setTitleValue(newValue)

            await onUpdateTitle(newValue)

            logAction({
              variables: {
                action: 'report-title-updated',
                extra: newValue,
                websiteSection: 'report',
                functionName: 'updateReportTitle',
                pagePath: window.location.pathname,
              },
            })
          }}
        />
        {error ? (
          <p className={styles.error}>Error fetching report data.</p>
        ) : (
          <>
            {dateRange !== undefined && (
              <p>
                <strong>Date range:</strong>{' '}
                {loading ? <LoadingLabel label="Loading" /> : dateRange}
              </p>
            )}
            {showDataSource &&
              (!dataSourceDescription ? (
                <p>
                  <strong>Metrics from:</strong>{' '}
                  <LoadingLabel label="Loading" />
                </p>
              ) : (
                <ReactMarkdown source={dataSourceDescription} />
              ))}
            {successMetric?.name && (
              <p className={styles.successMetric}>
                {loading ? (
                  <>
                    <strong>Key metric:</strong>{' '}
                    {loading ? <LoadingLabel label="Loading" /> : dateRange}
                  </>
                ) : (
                  <>
                    <strong>{successMetric.name}:</strong>{' '}
                    <ReactMarkdown source={successMetric.tooltip} />
                  </>
                )}
              </p>
            )}
            {filterList && filterList.length > 0 && onRemoveFilterOption && (
              <div className={styles.filterContainer}>
                <p className={styles.filterTitle}>
                  <strong>Filtered on:</strong>
                </p>
                <div className={styles.filterPillsContainer}>
                  {filterList.map(
                    ({
                      dimensionName,
                      dimensionID,
                      optionName,
                      optionID,
                      onRemove,
                    }) => (
                      <Tag
                        key={optionID}
                        className={styles.filterPill}
                        compact
                        onClick={async () => {
                          if (onRemove) {
                            // Specific remove function to be used for e.g. URL filters
                            onRemove(dimensionID, optionID)
                          } else {
                            onRemoveFilterOption(dimensionID, optionID)
                          }

                          logAction({
                            variables: {
                              action: `update-filters-remove-option`,
                              extra: JSON.stringify({
                                dimensionID,
                                dimensionName,
                                optionID,
                                optionName,
                              }),
                              websiteSection: 'report',
                              functionName: 'edit',
                              pagePath: window.location.pathname,
                            },
                          })
                        }}
                      >
                        <span className={styles.filterDimensionName}>
                          {dimensionName}:
                        </span>{' '}
                        {optionName === '' ? '(empty)' : optionName}
                      </Tag>
                    ),
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {children}
      </div>
      {includeLogo && (
        <div className={styles.logoWrapper}>
          {isAdminUser(userPermission) && logoIsEditable && !isMobile ? (
            <ImgUploader
              imgSrc={logoLink}
              uploadFn={async (file: File) => {
                const res = await uploadAccountLogo({
                  file,
                })

                if (res === true) {
                  logAction({
                    variables: {
                      action: 'company-logo-changed',
                      extra: '',
                      websiteSection: 'settings',
                      functionName: 'updateCompanyLogo',
                      pagePath: '/report/performance',
                    },
                  })

                  refetchLogoDetails()

                  return true
                }
                return false
              }}
            />
          ) : (
            <WorkspaceLogo
              className={styles.workspaceLogo}
              logoLink={logoLink}
              loading={!logoData}
            />
          )}
        </div>
      )}
    </div>
  )
}
