import React from 'react'
import { animated, useTransition } from '@react-spring/web'
import classNames from 'classnames'

interface CarouselProps {
  items: { key: string | number; content: React.ReactElement }[]
  currentProgress: number
  containerClassName?: string
}

const Carousel = ({
  items,
  currentProgress,
  containerClassName,
}: CarouselProps) => {
  const transitions = useTransition(currentProgress, {
    initial: null,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    // leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
    // config: { duration: 10000 },
  })

  return (
    <div className={classNames(containerClassName)}>
      {transitions((style, i) => (
        <animated.div key={items[i].key} style={style}>
          {items[i].content}
        </animated.div>
      ))}
    </div>
  )
}

export default Carousel
