import React from 'react'

import { PlanIcon } from '../assets/svgs/menu/module-icons'
import { BetaLabel } from '../components/counter'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import { joinBetaLink } from '../core/constants'
import styles from '../styles/plan.module.scss'

const Plan = () => {
  return (
    <SiteWrapper>
      <Layout width={1200}>
        <Intro
          title={
            <>
              <span>Plan your next campaign</span>{' '}
              <BetaLabel className={styles.betaLabel} title="Coming soon" />
            </>
          }
        >
          <p>
            Plan what advertising mix you want to use in your next campaigns and
            estimate costs, clickthrough's and conversions.
          </p>
          <Tag>
            Get early access to this new module in exchange for feedback.{' '}
            <Link type="arrowForward" href={joinBetaLink}>
              Join beta
            </Link>
          </Tag>
        </Intro>
        <PlanIcon fillColor="#e2e8f0" />
      </Layout>
    </SiteWrapper>
  )
}

export default Plan
