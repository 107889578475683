import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Modal from './modal'

interface LocationState {
  o365permissionGrant: string
}

/**
 * This modal is shown when an O365 admin has successfully granted permission for the O365 SSO integration
 * https://support.uplifter.ai/hc/en-us/articles/4402382907025-Logging-in-with-Single-Sign-On-SSO#h_01F7RTPP22DXN9Q5ZYKTCYNFQX:~:text=If%20you%20are%20an%20administrator
 */
const SSOModal = () => {
  const { state } = useLocation<LocationState>()

  const [showSSOModal, setShowSSOModal] = useState(false)

  useEffect(() => {
    if (!state || !state.o365permissionGrant) return

    setShowSSOModal(true)
  }, [])

  if (!showSSOModal) return null

  return (
    <Modal
      setIsOpen={setShowSSOModal}
      headerColor="green"
      modalHeader="Microsoft SSO has been successfully set up"
    >
      <p>Users can now log in to Uplifter via Microsoft SSO</p>
    </Modal>
  )
}

export default SSOModal
