import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import Link from './link'
import Tag from './tag'
import { getUserInfo } from '../api/graphql/user-client'
import Stars from '../assets/star-cluster-yellow.svg'
import { calendarBookingLink } from '../core/constants'

interface TrackIntroContentProps {
  className?: string
  tagLine?: string | React.ReactElement
  showSetupMessage?: boolean
  children?: React.ReactNode
}

const TrackIntroContent = ({
  className,
  tagLine,
  showSetupMessage = true,
  children,
}: TrackIntroContentProps) => {
  const { data: userDetailsData } = useQuery(getUserInfo)

  const accountCreationDate = userDetailsData?.currentAccount.created

  const showSupportMessage = useMemo(() => {
    if (!showSetupMessage) return false

    if (!accountCreationDate) return false

    // Do not show for accounts created > 30 days ago
    return moment().diff(moment(accountCreationDate), 'days') < 30
  }, [showSetupMessage, accountCreationDate])

  return (
    <div className={className}>
      <div>
        {tagLine && (
          <>
            {typeof tagLine === 'string' ? (
              <p style={showSupportMessage ? undefined : { marginBottom: 0 }}>
                {tagLine}
              </p>
            ) : (
              tagLine
            )}
          </>
        )}
        {showSupportMessage && (
          <Tag inline icon={Stars}>
            <p>
              Need help?{' '}
              <Link href={calendarBookingLink}>
                Book a free setup consultation
              </Link>
              .
            </p>
          </Tag>
        )}
      </div>
      {children}
    </div>
  )
}

export default TrackIntroContent
