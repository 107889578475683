import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { Switch, Redirect, RouteProps } from 'react-router'
import { BrowserRouter, Route, useLocation } from 'react-router-dom'
import moment from 'moment'

import {
  pageRedirect,
  loggedInState,
  currentUserDetails,
  dataSourceReactive,
  DataSourceDetails,
  showAppDeepLinkContent,
  linkOrCode,
  hasCreatedLinksReactive,
  loginForm,
  authError,
} from './api/apollo/variables'
import { isLoggedIn } from './api/REST/auth-client'
import {
  getCompanyDetails,
  getPaddleSubscriptionDetails,
} from './api/graphql/company-client'
import { getOnboardingSectionsProgress } from './api/graphql/onboarding-client'
import { getCampaignCodeGenerator } from './api/graphql/track-create-client'
import { getUpdateRequestList } from './api/graphql/track-edit-client'
import { getPreferredHomepage, getUserInfo } from './api/graphql/user-client'
import { getAccountDataSource } from './api/graphql/workspace-client'
import { sendGaCampaignData, SignupMethod } from './api/REST/account-client'
import { LoadingLogo } from './components/loader'
import OAuthRedirect from './connections/redirect-handler'
import {
  adobeDataSource,
  defaultHomepageSlugs,
  gaMeasurementID,
  googleDataSources,
} from './core/constants'
import { getToken, getUrlQuery, isAdminUser } from './helpers'
import {
  removeCustomLinkFromStorage,
  SavedCustomLink,
} from './helpers/custom-links'
import { PlanInterestLevel } from './helpers/forms'
import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
} from './helpers/local-client'
import { defaultEnabled } from './helpers/track-module'
import useAuthenticate from './hooks/useAuthenticate'
import useLogAction from './hooks/useLogAction'
import useSubscriptionLevel from './hooks/useSubscriptionLevel'
import useSwitchWorkspace from './hooks/useSwitchWorkspace'

// Pages
import Login from './pages/login'
import CreateAccount from './pages/create-account'
import RegisterUser from './pages/register-user'
import ForgotPassword from './pages/forgot-password'
import ResetPassword from './pages/reset-password'
import MarketingOptOut from './pages/marketing-optout'
import WelcomePage from './pages/welcome-page'
import Share from './pages/share'
import DirectDownload from './pages/direct-download'
import ConnectPage from './pages/connect'
import SettingsPage from './pages/settings'
import UpgradePage from './pages/upgrade'
import TrackLearn from './pages/track-learn'
import TrackCreate from './pages/track-create'
import TrackView from './pages/track-view'
import TrackEditDropdowns from './pages/track-edit-dropdowns'
import TrackEditParametersAndRules from './pages/track-edit-parameters-and-rules'
import TrackEditAppDestinations from './pages/track-edit-app-destinations'
import PerformanceReportPage from './pages/performance-report-page'
import ReportMarketingJourneysPage from './pages/report-marketing-journeys'
import LostLinksReportPage from './pages/lost-links-report'
import UsageReportPage from './pages/usage-report-page'
import ReportCustomDashboards from './pages/report-custom-dashboards'
import Plan from './pages/plan'
import Explain from './pages/explain'
import AnomalyBreakdown from './pages/anomaly-breakdown'
import NotFound from './pages/not-found'

interface PrivateRouteProps extends RouteProps {
  minSubscriptionLevel?: 'business' | 'enterprise'
  /** Only admin users will be able to see this page */
  adminOnly?: boolean
  /** Used to prevent the page from being shown based on custom logic */
  customBlockerFunction?: () => boolean
  /**
   * If page is not available to users (e.g. not an admin), it will redirect here.
   * Defaults to welcome page
   */
  redirectTo?: string
}

/** Pages that are only available to logged in users */
const PrivateRoute = ({
  minSubscriptionLevel,
  adminOnly,
  customBlockerFunction,
  redirectTo,
  ...props
}: PrivateRouteProps) => {
  const { state } = useLocation()

  const fullLoggedInState = useReactiveVar(loggedInState)

  const { authenticated, paddleSubscriptionID } = fullLoggedInState

  // Used to check if the userID is set in the cache yet
  const userDetails = useReactiveVar(currentUserDetails)

  const hasCreatedLinks = useReactiveVar(hasCreatedLinksReactive)

  const {
    checkedSubscriptionLevel,
    subscriptionCategory,
    isStartup,
    isEnterprise,
  } = useSubscriptionLevel()

  const { switchWorkspace } = useSwitchWorkspace()

  // Check if generator has select fields
  // Used to display notifications for admin users
  const [fetchGeneratorFieldTypes] = useLazyQuery(getCampaignCodeGenerator, {
    pollInterval: 240000,
    nextFetchPolicy: 'network-only',
  })
  const [refetchCompanyDetails] = useLazyQuery(getCompanyDetails, {
    fetchPolicy: 'network-only',
  })
  const [getPaddleDetails, { stopPolling }] = useLazyQuery(
    getPaddleSubscriptionDetails,
  )
  const [authenticatedUserDetails, { data: authUserData }] = useLazyQuery(
    getUserInfo,
  )
  const [fetchDataSource, { data: dataSourceData }] = useLazyQuery(
    getAccountDataSource,
  )
  const [
    fetchOnboardingSectionsProgress,
    { data: onboardingSectionsData },
  ] = useLazyQuery(getOnboardingSectionsProgress)

  // Fetch update requests every 2 minutes
  const [refetchUpdateRequests] = useLazyQuery(getUpdateRequestList, {
    pollInterval: 240000,
    nextFetchPolicy: 'network-only',
  })

  const logAction = useLogAction()

  const pathname = props?.location?.pathname || ''
  const search = props?.location?.search || ''

  // Assign local variable to redirect to if not logged in
  useEffect(() => {
    pageRedirect({ pathname, search })
  }, [])

  // Redirect to a different workspace if accountID is in the URL
  useEffect(() => {
    const { workspaceID } = userDetails

    if (!workspaceID) return

    const query = getUrlQuery()

    const accountID = query?.get('accountID')

    if (accountID && workspaceID !== accountID) {
      switchWorkspace(accountID)
    }
  }, [userDetails.workspaceID])

  // Fetch data on first private page load to set cached values
  // Also allow Clarity to set cookies on the logged-in site
  useEffect(() => {
    if (authenticated) {
      fetchOnboardingSectionsProgress()

      if (window.clarity) {
        window.clarity('consent')
      }

      const section = pathname.match(/(^\/.+\/)|(\/.+$)/gi)

      const logActionCall = async () => {
        await logAction({
          variables: {
            action: `page-load${pathname}`,
            functionName: 'pageLoad',
            pagePath: pathname,
            websiteSection: section ? section[0].replace(/\//gi, '') : '',
          },
        })
      }

      if (section && section.length > 0) {
        logActionCall()
      }

      if (userDetails.userID === '') {
        authenticatedUserDetails()
      }
    }
  }, [authenticated, pathname])

  // Set MS Clarity tag for session recording
  useEffect(() => {
    if (!onboardingSectionsData || hasCreatedLinks) return

    const createdLinkInfo = onboardingSectionsData.userOnboardingProgress?.userOnboardingProgressList.find(
      (item) => item.onboardingSectionID === 'createCampaignLink',
    )

    if (createdLinkInfo) {
      hasCreatedLinksReactive(createdLinkInfo.sectionCompleted)

      if (window.clarity) {
        window.clarity(
          'set',
          'hasCreatedLinks',
          JSON.stringify(createdLinkInfo.sectionCompleted),
        )
      }
    }
  }, [onboardingSectionsData, hasCreatedLinks])

  // Check URL state for upgrade success
  useEffect(() => {
    if (!state && !paddleSubscriptionID) return

    if (paddleSubscriptionID || typeof state.newSeatCount === 'string') {
      getPaddleDetails({
        pollInterval: 2000,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        // Keep polling until fetched details match expected details
        onCompleted: (data) => {
          if (
            data.currentCompany.subscriptionLevel === paddleSubscriptionID ||
            (state.newSeatCount &&
              data.currentCompany.paddleSubscriptionQuantity &&
              state.newSeatCount ===
                data.currentCompany.paddleSubscriptionQuantity)
          ) {
            stopPolling()

            refetchCompanyDetails()
          }
        },
      })
    }
  }, [state, paddleSubscriptionID])

  // When successfully authenticated, cache core user info
  // Then fetch track requests and generator if user is admin
  useEffect(() => {
    if (!authUserData) return

    const {
      currentUser: {
        userID,
        email: userEmail,
        firstName: userFirstName,
        lastName: userLastName,
        currentAccountPermission: userPermission,
        whitelabelAdmin,
      },
      currentAccount: {
        accountID,
        accountName,
        companyID,
        companyName,
        isDemo,
        homepage,
        trackAvailable,
        reportAvailable,
        explainAvailable,
        planAvailable,
        connectAvailable,
      },
      currentCompany: {
        subscriptionLevel,
        trackAvailable: companyTrackAvailable,
        reportAvailable: companyReportAvailable,
        explainAvailable: companyExplainAvailable,
        planAvailable: companyPlanAvailable,
        connectAvailable: companyConnectAvailable,
      },
    } = authUserData

    // Check if the account connects to Google or Adobe
    fetchDataSource()

    currentUserDetails({
      userID,
      userEmail,
      userFirstName,
      userLastName,
      userPermission: userPermission as PermissionLevel,
      workspaceID: accountID,
      workspaceName: accountName,
      workspaceHomepage: homepage,
      // Module availability for enterprise companies can be controlled at company level by support users
      // Otherwise, it is controlled at workspace level by account admins
      trackAvailable:
        subscriptionLevel === 'enterprise'
          ? companyTrackAvailable && trackAvailable
          : trackAvailable,
      reportAvailable:
        subscriptionLevel === 'enterprise'
          ? companyReportAvailable && reportAvailable
          : reportAvailable,
      explainAvailable:
        subscriptionLevel === 'enterprise'
          ? companyExplainAvailable && explainAvailable
          : explainAvailable,
      planAvailable:
        subscriptionLevel === 'enterprise'
          ? companyPlanAvailable && planAvailable
          : planAvailable,
      connectAvailable:
        subscriptionLevel === 'enterprise'
          ? companyConnectAvailable && connectAvailable
          : connectAvailable,
      companyID,
      companyName,
      isDemoAccount: isDemo,
      whiteLabelAdminDomain: whitelabelAdmin,
    })

    // For debugging in Sentry
    Sentry.setUser({
      id: userID,
      email: userEmail,
    })

    if (isAdminUser(authUserData.currentUser.currentAccountPermission)) {
      // Only fetch update requests for users that have permission to approve them
      // Also fetches validation checks so we know if user can access app links (ALLOW_APP_LINKS)
      fetchGeneratorFieldTypes()
      refetchUpdateRequests()
    }

    // Tom wants a debug object on staging
    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(
        `User ID: ${userEmail}\n${userID}\nWorkspace ID: ${accountName}\n${accountID}\nCompany ID: ${companyName}\n${companyID}\nToken\n${getToken()}`,
      )
    }
  }, [authUserData])

  // Check and set company billing level
  useEffect(() => {
    if (!userDetails.companyID || !checkedSubscriptionLevel) return

    // Set MS Clarity segment for session recording filtering
    if (window.clarity) {
      window.clarity('set', 'companyID', userDetails.companyID)
      window.clarity('set', 'companyName', userDetails.companyName)
      window.clarity('set', 'companySubscriptionLevel', subscriptionCategory)
    }
  }, [userDetails, checkedSubscriptionLevel])

  // Set Clarity variables
  useEffect(() => {
    if (!userDetails) return

    if (window.clarity) {
      window.clarity('set', 'workspaceID', userDetails.workspaceID)
      window.clarity('set', 'workspaceName', userDetails.workspaceName)
      window.clarity('set', 'userID', userDetails.userID)
      window.clarity('set', 'userEmail', userDetails.userEmail)
      window.clarity('set', 'userPermission', userDetails.userPermission)
    }
  }, [userDetails])

  // Check connected data source
  useEffect(() => {
    if (!dataSourceData) return

    if (dataSourceData.currentAccount.dataSource) {
      // @ts-ignore
      const dataSourceToSet: DataSourceDetails = {}

      if (
        googleDataSources.includes(
          dataSourceData.currentAccount.dataSource.kind,
        )
      ) {
        dataSourceToSet.connectionSource = 'google'
        dataSourceToSet.requiresReconnect = !dataSourceData.currentAccount
          .dataSource.connected
      } else if (
        dataSourceData.currentAccount.dataSource.kind === adobeDataSource
      ) {
        dataSourceToSet.connectionSource = 'adobe'
        dataSourceToSet.requiresReconnect = !dataSourceData.currentAccount
          .dataSource.connected
      } else {
        dataSourceToSet.connectionSource = 'not-connected'
      }

      dataSourceReactive(dataSourceToSet)
    }
  }, [dataSourceData])

  if (authenticated) {
    const { userPermission, isDemoAccount } = userDetails

    // Need to wait for subscription level and status
    if (!userPermission || !checkedSubscriptionLevel) {
      return <LoadingLogo fullScreen />
    }

    // Redirect to Upgrade page if subscriptionLevel is insufficient
    if (
      !isDemoAccount &&
      ((minSubscriptionLevel === 'enterprise' && !isEnterprise) ||
        (minSubscriptionLevel === 'business' && isStartup))
    ) {
      return (
        <Redirect
          to={{
            pathname: '/upgrade',
          }}
        />
      )
    }

    if (
      (customBlockerFunction && customBlockerFunction()) ||
      (adminOnly && !isAdminUser(userDetails.userPermission))
    ) {
      return (
        <Redirect
          to={{
            pathname: redirectTo || '/login',
          }}
        />
      )
    }

    return <Route {...props} />
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  )
}

/** Only accessible to non-authenticated users */
const NonPrivateRoute = ({ ...props }: RouteProps) => {
  const { authenticated } = useReactiveVar(loggedInState)
  const { pathname, search } = useReactiveVar(pageRedirect)

  const { loginMicrosoft, loginGoogle, loginOkta } = useAuthenticate()

  const [loadingSSOReauth, setLoadingSSOReauth] = useState(true)

  // If user has a SSO key in localStorage, attempt to login with this
  useEffect(() => {
    const attemptReauth = async () => {
      const microsoftKey = getLocalItem('ms-microsoftKey')
      const googleKey = getLocalItem('ga-googleKey')
      const oktaKey = getLocalItem('okta-oktaKey')

      if (microsoftKey) {
        await loginMicrosoft({ microsoftKey })
      } else if (googleKey) {
        await loginGoogle({ googleKey })
      } else if (oktaKey) {
        await loginOkta({ oktaKey })
      }

      setLoadingSSOReauth(false)
    }

    attemptReauth()
  }, [])

  const [
    getPreferredHomepageQuery,
    { data: preferredHomepageData, error: homepageError },
  ] = useLazyQuery(getPreferredHomepage)

  useEffect(() => {
    if (authenticated) getPreferredHomepageQuery()
  }, [authenticated])

  // Wait for reauth
  if (loadingSSOReauth) {
    return <LoadingLogo fullScreen />
  }

  if (!authenticated) {
    return <Route {...props} />
  }

  if (pathname || search) {
    return (
      <Redirect
        to={{
          pathname,
          search,
        }}
      />
    )
  }

  if (!preferredHomepageData && !homepageError) {
    return <LoadingLogo fullScreen />
  }

  const preferredHomepage =
    preferredHomepageData?.currentUser.preferredHomepage || ''

  /** Sets homepage path based on availability of module */
  const homepagePath =
    preferredHomepageData?.currentAccount[`${preferredHomepage}Available`] ||
    preferredHomepage === 'welcome'
      ? defaultHomepageSlugs[preferredHomepage]
      : '/welcome'

  return (
    <Redirect
      to={{
        pathname: homepagePath || '/welcome',
      }}
    />
  )
}

interface OAuthRedirectSSOProps {
  source: 'google' | 'microsoft' | 'okta'
}

const OAuthRedirectSSO = ({ source }: OAuthRedirectSSOProps) => {
  const { authenticated } = useReactiveVar(loggedInState)

  // TODO: Find a better way of doing this
  /** Used for manage failed MS login attempts */
  const {
    onFail: { message },
  } = useReactiveVar(loginForm)

  const {
    createNewClient,
    registerUser,
    loginGoogle,
    loginOkta,
    loginMicrosoft,
  } = useAuthenticate()

  const [
    getPreferredHomepageQuery,
    { data: preferredHomepageData, error: homepageError },
  ] = useLazyQuery(getPreferredHomepage)

  const [loadingSSO, setLoadingSSO] = useState(true)
  const [resumeCreateAccountJourney, setResumeCreateAccountJourney] = useState(
    false,
  )
  const [newAccountInterestLevel, setNewAccountInterestLevel] = useState<
    PlanInterestLevel
  >('Individual')

  const { hash, search } = window.location
  const searchQuery = new URLSearchParams(search)
  const queryCode = searchQuery?.get('code')
  const hashQuery = new URLSearchParams(hash.split('#').pop())
  const isO365AdminApproval = searchQuery.has('admin_consent')

  // TODO: Use Oauth response state object instead of local storage
  const forceReauth = getLocalItem('force-reauth')

  // Used for updating user email
  // See profile-settings component
  useEffect(() => {
    if (forceReauth) {
      if (forceReauth.type === 'ga') {
        setLocalItem('ga-code', queryCode)
      } else if (forceReauth.type === 'ms') {
        const idToken = hashQuery?.get('id_token')
        setLocalItem('ms-id-token', idToken)
      } else if (forceReauth.type === 'okta') {
        setLocalItem('okta-code', queryCode)

        const savedClientId = getLocalItem('okta-client-id')
        if (!savedClientId) {
          setLocalItem('okta-client-id', searchQuery?.get('state') || '')
        }
      }

      setLoadingSSO(false)
    }
  }, [])

  // Fetch preferred homepage if logged in with no redirect set
  useEffect(() => {
    if (authenticated && (!forceReauth || !forceReauth.returnUrl)) {
      getPreferredHomepageQuery()
      setLoadingSSO(false)
    }
  }, [authenticated])

  // Handle SSO auth
  useEffect(() => {
    if (authenticated) return

    const runAuth = async () => {
      if (source === 'google') {
        const gaNonce = getLocalItem('google-sso-nonce')

        // Get then update SSO state
        const gaAction = getLocalItem('google-sso-action')
        setLocalItem('google-sso-action', 'progress')

        // TODO: Get this from the return URL instead of local storage
        const gaState = getLocalItem('google-sso-state')
        removeLocalItem('google-sso-state')

        if (!gaAction || gaAction === 'login') {
          await loginGoogle({
            code: queryCode || '',
            nonce: gaNonce,
          })
        } else if (gaState) {
          const isParsed = typeof gaState === 'object' && gaState !== null

          if (isParsed && gaAction === 'register') {
            const state = {
              signUpMethod: 'google' as SignupMethod,
              token: gaState.token,
              nonce: gaNonce,
              code: queryCode,
              fName: gaState.fName,
              lName: gaState.lName,
              acceptedMarketing: gaState.acceptedMarketing,
            }

            await registerUser(state)
          } else if (isParsed && gaAction === 'create') {
            const state = {
              signUpMethod: 'google' as SignupMethod,
              nonce: gaNonce,
              code: queryCode,
              fName: gaState.fName,
              lName: gaState.lName,
              email: gaState.email,
              organisation: gaState.organisation,
              acceptedMarketing: gaState.acceptedMarketing,
              planInterest: gaState.planInterest,
              useCases: gaState.useCases || [],
              discoveryMethod: gaState.discoveryMethod,
              discoveryMethodOther: gaState.discoveryMethodOther,
            }

            const createAccountSuccess = await createNewClient(state, (error) =>
              authError(error),
            )

            if (createAccountSuccess) {
              setNewAccountInterestLevel(gaState.planInterest)
              setResumeCreateAccountJourney(true)
            }
          }
        }
      }

      if (source === 'microsoft') {
        const msNonce = getLocalItem('ms-nonce')
        const msAction = getLocalItem('ms-action')

        const erroredButHasAccount =
          source === 'microsoft' &&
          message.indexOf('already got an account') !== -1

        if (hash && hash.indexOf('#') !== -1) {
          setLocalItem('ms-action', 'progress')

          const idToken = hashQuery?.get('id_token')

          const msState = getLocalItem('ms-state')
          removeLocalItem('ms-state')

          if (erroredButHasAccount || !msAction || msAction === 'login') {
            // User has attempted to register/create new company but already exists
            await loginMicrosoft({
              id_token: idToken || '',
              nonce: msNonce,
            })
          } else if (msState) {
            const isParsed = typeof msState === 'object' && msState !== null

            if (isParsed && msAction === 'register') {
              const state = {
                signUpMethod: 'microsoft' as SignupMethod,
                token: msState.token,
                nonce: msNonce,
                id_token: idToken || '',
                fName: msState.fName,
                lName: msState.lName,
                acceptedMarketing: msState.acceptedMarketing,
              }

              await registerUser(state)
            } else if (isParsed && msAction === 'create') {
              const state = {
                signUpMethod: 'microsoft' as SignupMethod,
                nonce: msNonce,
                id_token: idToken,
                fName: msState.fName,
                lName: msState.lName,
                email: msState.email,
                organisation: msState.organisation,
                acceptedMarketing: msState.acceptedMarketing,
                planInterest: msState.planInterest,
                useCases: msState.useCases || [],
                discoveryMethod: msState.discoveryMethod,
                discoveryMethodOther: msState.discoveryMethodOther,
              }

              const createAccountSuccess = await createNewClient(
                state,
                (error) => authError(error),
              )

              if (createAccountSuccess) {
                setNewAccountInterestLevel(msState.planInterest)
                setResumeCreateAccountJourney(true)
              }
            }
          }
        }
      }

      // Okta does not have a 'create' action to create a new account
      if (source === 'okta') {
        const oktaState = getLocalItem('okta-state')
        const oktaAction = getLocalItem('okta-action')
        const oktaClientID =
          searchQuery?.get('state') || getLocalItem('okta-client-id') || ''

        removeLocalItem('okta-state')

        setLocalItem('okta-action', 'progress')

        if (!oktaAction || oktaAction === 'login') {
          await loginOkta({
            code: queryCode || '',
            clientID: oktaClientID,
          })
        } else if (oktaState) {
          if (oktaAction === 'register') {
            const {
              fName,
              lName,
              token,
              acceptedMarketing,
              planInterest,
            } = oktaState

            const state = {
              fName,
              lName,
              token,
              signUpMethod: 'okta' as SignupMethod,
              code: queryCode,
              acceptedMarketing,
              planInterest,
              oktaClientID,
            }
            await registerUser(state)
          }
        }
      }

      setLoadingSSO(false)
    }

    runAuth()
  }, [authenticated, source])

  if (loadingSSO) {
    return <LoadingLogo fullScreen />
  }

  // New account has been created - switch back to CreateAccount page
  if (resumeCreateAccountJourney) {
    return (
      <Redirect
        to={{
          pathname: '/create-account',
          state: { skipToPayment: true, newAccountInterestLevel },
        }}
      />
    )
  }

  // If all info is loaded and user is not logged in, redirect to login page
  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: isO365AdminApproval
            ? { o365permissionGrant: true }
            : undefined,
        }}
      />
    )
  }

  // Redirect to the return URL if set
  if (forceReauth?.returnUrl) {
    removeLocalItem('force-reauth')

    return (
      <Redirect
        to={{
          pathname: forceReauth.returnUrl.split('?')[0],
          search: forceReauth.returnUrl.split('?')[1],
        }}
      />
    )
  }

  // Check if a preferred homepage is set
  if (!preferredHomepageData && !homepageError) {
    return <LoadingLogo fullScreen />
  }

  const preferredHomepage =
    preferredHomepageData?.currentUser.preferredHomepage || ''

  /** Sets homepage path based on availability of module */
  const homepagePath =
    preferredHomepageData?.currentAccount[`${preferredHomepage}Available`] ||
    preferredHomepage === 'welcome'
      ? defaultHomepageSlugs[preferredHomepage]
      : '/welcome'

  return (
    <Redirect
      to={{
        pathname: homepagePath || '/welcome',
        state: isO365AdminApproval ? { o365permissionGrant: true } : undefined,
      }}
    />
  )
}

export default function Router() {
  const userLoggedInState = useReactiveVar(loggedInState)
  const {
    workspaceID,
    trackAvailable,
    reportAvailable,
    explainAvailable,
    planAvailable,
    connectAvailable,
  } = useReactiveVar(currentUserDetails)
  const showAppContent = useReactiveVar(showAppDeepLinkContent)

  const [campaignDataSent, setCampaignDataSent] = useState(false)

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
    {
      fetchPolicy: 'cache-first',
    },
  )

  // Wait for account ID so that generator can be cached
  useEffect(() => {
    if (workspaceID !== '') getGenerator()
  }, [workspaceID])

  // Set whether app deep links are avaialable
  // And whether copy should be 'link' or 'code' (based on SHOW_LANDING_PAGE)
  useEffect(() => {
    if (!generatorData) return

    const {
      campaignCodeGenerator: { validationChecks },
    } = generatorData

    const appRule = validationChecks.find(
      (rule) => rule.name === 'ALLOW_APP_LINKS',
    )?.enabled

    showAppDeepLinkContent(
      typeof appRule === 'boolean' ? appRule : defaultEnabled.ALLOW_APP_LINKS,
    )

    if (
      validationChecks.find((rule) => rule.name === 'SHOW_LANDING_PAGE')
        ?.enabled === false
    ) {
      // Landing page field is hidden - we should refer to 'codes' instead of 'links'
      linkOrCode('code')
    }
  }, [generatorData])

  // Send GA campaign data for measurement protocol
  useEffect(() => {
    if (campaignDataSent) return

    // Check UTM params in URL
    // They can be carried over from uplifter.ai
    // See custom scripts on Squarespace: https://uplifter.squarespace.com/config/pages/website-tools/code-injection
    // Saved to sessionStorage so they can be passed to Pipedrive on new account signup
    // sessionStorage item is used in createClient function in `useAuthenticate` hook
    const urlParams = new URLSearchParams(window.location.search)

    // Get utm_source, utm_medium and utm_campaign if present
    const utm_source = urlParams.get('utm_source')
    const utm_medium = urlParams.get('utm_medium')
    const utm_campaign = urlParams.get('utm_campaign')
    const utm_term = urlParams.get('utm_term')
    const utm_content = urlParams.get('utm_content')

    if (
      !utm_source &&
      !utm_medium &&
      !utm_campaign &&
      !utm_term &&
      !utm_content
    ) {
      return
    }

    window.sessionStorage.setItem(
      'utmParams',
      JSON.stringify({
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      }),
    )

    const getGaClientID = () => {
      const cookie = {}
      document.cookie.split(';').forEach(function parseCookie(el) {
        const splitCookie = el.split('=')
        const key = splitCookie[0].trim()
        const value = splitCookie[1]
        cookie[key] = value
      })

      // @ts-ignore
      return cookie._ga?.substring(6) || null
    }

    const gaClientID = getGaClientID()

    const getGaSessionID = () => {
      const pattern = new RegExp(
        `_ga_${gaMeasurementID.replace('G-', '')}=GS\\d\\.\\d\\.(.+?)(?:;|$)`,
      )
      const match = document.cookie.match(pattern)
      const parts = match?.[1].split('.')

      if (!parts) {
        // Cookie not yet available; wait a bit and try again.
        return null
      }

      return parts[0]
    }
    const gaSessionID = getGaSessionID()

    if (gaClientID && gaSessionID) {
      setCampaignDataSent(true)

      sendGaCampaignData({
        gaClientID,
        gaSessionID,
        // campaign_id: utm_id || undefined,
        campaign: utm_campaign || undefined,
        source: utm_source || undefined,
        medium: utm_medium || undefined,
        term: utm_term || undefined,
        content: utm_content || undefined,
      })
    }
  }, [campaignDataSent, window.location])

  // Check if current browser is logged in
  const hasToken = useMemo(() => {
    return !!userLoggedInState.token
  }, [userLoggedInState.token])

  // Check auth state every few minutes
  useEffect(() => {
    const checkAuth = async () => {
      if (userLoggedInState.blockLoginCheck) return

      const auth = await isLoggedIn()

      loggedInState({
        ...userLoggedInState,
        authenticated: !!auth,
        checked: true,
      })
    }

    if (!userLoggedInState.checked && hasToken) {
      checkAuth()
    }

    const interval = setInterval(() => {
      checkAuth()
    }, 250000)

    return function cleanup() {
      clearInterval(interval)
    }
  }, [
    userLoggedInState.checked,
    userLoggedInState.authenticated,
    userLoggedInState.blockLoginCheck,
    hasToken,
  ])

  // Reset shortLink aliases that are expired or unavailable
  useEffect(() => {
    const userSessionData = getLocalItem('user-session-data')

    if (!userSessionData || typeof userSessionData !== 'object') return

    Object.keys(userSessionData).forEach((item) => {
      // Check if item is a shortLink holder
      if (
        !Array.isArray(userSessionData[item]) ||
        userSessionData[item].length === 0 ||
        typeof userSessionData[item][0] !== 'object' ||
        !Object.prototype.hasOwnProperty.call(
          userSessionData[item][0],
          'shortLinkID',
        )
      ) {
        return
      }

      // Check if shortLink is unavailable or expired
      userSessionData[item].forEach(
        ({ shortLinkID, availableUntil, isAvailable }: SavedCustomLink) => {
          if (!isAvailable) {
            // Delete it
            removeCustomLinkFromStorage(shortLinkID, item)

            return
          }

          const now = new Date(Date.now())

          const diff = moment(availableUntil).utc().diff(moment(now).utc())
          const isExpired = diff < 0

          if (isExpired) {
            // Delete it
            removeCustomLinkFromStorage(shortLinkID, item)
          }
        },
      )
    })
  }, [])

  if (hasToken && !userLoggedInState.checked) {
    return <LoadingLogo fullScreen />
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )}
        />
        {/* Pages you must be logged out to see */}
        <Route exact path="/share/:shareLinkID" component={Share} />
        <Route
          exact
          path="/code/direct-download/:downloadId"
          component={DirectDownload}
        />
        <Route
          exact
          path="/user-marketing-optout/:userId"
          component={MarketingOptOut}
        />

        {/* Auth/Onboarding */}
        <NonPrivateRoute exact path="/login" component={Login} />
        <NonPrivateRoute
          exact
          path="/create-account"
          component={CreateAccount}
        />
        <NonPrivateRoute
          exact
          path="/create-account-microsoft"
          render={() => <CreateAccount microsoftMarketplace />}
        />
        <NonPrivateRoute exact path="/register" component={RegisterUser} />
        <NonPrivateRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <NonPrivateRoute
          exact
          path="/reset-password"
          component={ResetPassword}
        />

        {/* Oauth callback URLs */}
        <Route
          exact
          path="/auth/o365-redirect"
          render={() => <OAuthRedirectSSO source="microsoft" />}
        />
        <Route
          exact
          path="/auth/gsuite-redirect"
          render={() => <OAuthRedirectSSO source="google" />}
        />
        <Route
          exact
          path="/auth/okta-redirect"
          render={() => <OAuthRedirectSSO source="okta" />}
        />

        {/* Track */}
        <PrivateRoute exact path="/track/learn" component={TrackLearn} />
        <PrivateRoute
          exact
          path="/track/generator-help"
          // Legacy Track>Learn URL changes
          render={() => (
            <Redirect
              to={{
                pathname: '/track/learn',
              }}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/track/create-links"
          customBlockerFunction={() => !trackAvailable}
          redirectTo="/welcome"
          component={TrackCreate}
        />
        {['/track/create', '/track/create-campaign-codes'].map((path) => (
          // Legacy Track>Create URL changes
          <PrivateRoute
            key={path}
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: '/track/create-links',
                }}
              />
            )}
          />
        ))}
        <PrivateRoute
          exact
          path="/track/view-links"
          customBlockerFunction={() => !trackAvailable}
          redirectTo="/welcome"
          component={TrackView}
        />
        {['/track/view', '/track/view-campaign-codes'].map((path) => (
          <PrivateRoute
            // Legacy Track>View URL changes
            key={path}
            exact
            path={path}
            render={() => (
              <Redirect
                to={{
                  pathname: '/track/view-links',
                }}
              />
            )}
          />
        ))}
        <PrivateRoute
          exact
          path="/track/edit-dropdowns"
          adminOnly
          customBlockerFunction={() => !trackAvailable}
          redirectTo="/track/create-links"
          component={TrackEditDropdowns}
        />
        <PrivateRoute
          exact
          path="/track/edit-campaign-codes"
          // Legacy Track>Edit Dropdowns URL changes
          render={() => (
            <Redirect
              to={{
                pathname: '/track/edit-dropdowns',
              }}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/track/edit-parameters-and-rules"
          adminOnly
          customBlockerFunction={() => !trackAvailable}
          redirectTo="/track/create-links"
          component={TrackEditParametersAndRules}
        />
        <PrivateRoute
          exact
          path="/track/edit-app-destinations"
          minSubscriptionLevel="business"
          adminOnly
          customBlockerFunction={() => {
            if (!trackAvailable) return true

            return !showAppContent
          }}
          redirectTo="/track/create-links"
          component={TrackEditAppDestinations}
        />

        {/* Report */}
        <PrivateRoute
          exact
          path="/report/performance"
          customBlockerFunction={() => !reportAvailable}
          redirectTo="/welcome"
          component={PerformanceReportPage}
        />
        <PrivateRoute
          exact
          path="/report/other-links-audit"
          customBlockerFunction={() => !reportAvailable}
          redirectTo="/welcome"
          component={LostLinksReportPage}
        />
        <PrivateRoute
          exact
          path="/report/marketing-journeys"
          minSubscriptionLevel="business"
          customBlockerFunction={() => !reportAvailable}
          redirectTo="/welcome"
          component={ReportMarketingJourneysPage}
        />
        <PrivateRoute exact path="/report/usage" component={UsageReportPage} />
        <PrivateRoute
          exact
          path="/report/dashboards"
          customBlockerFunction={() => !reportAvailable}
          redirectTo="/welcome"
          component={ReportCustomDashboards}
        />

        {/* Plan */}
        <PrivateRoute
          exact
          path="/plan"
          customBlockerFunction={() => !planAvailable}
          redirectTo="/welcome"
          component={Plan}
        />

        {/* Misc */}
        <PrivateRoute exact path="/welcome" component={WelcomePage} />
        <PrivateRoute
          exact
          path="/connect"
          adminOnly
          customBlockerFunction={() => !connectAvailable}
          redirectTo="/welcome"
          component={ConnectPage}
        />
        <PrivateRoute exact path="/settings" component={SettingsPage} />
        <PrivateRoute exact path="/upgrade" component={UpgradePage} />

        {/* Explain (Legacy - not used) */}
        <PrivateRoute
          exact
          path="/explain"
          customBlockerFunction={() => !explainAvailable}
          redirectTo="/welcome"
          component={Explain}
        />
        <PrivateRoute
          exact
          path="/explain/anomaly/:metricID/:anomDate"
          customBlockerFunction={() => !explainAvailable}
          redirectTo="/welcome"
          component={AnomalyBreakdown}
        />

        {/* Analytics/Connectors callback URLs */}
        <Route
          exact
          path="/oauth2callback"
          render={() => <OAuthRedirect connectorID="googleAnalytics" />}
        />
        <Route
          exact
          path="/aa-oauth2callback"
          render={() => {
            return <OAuthRedirect connectorID="adobeAnalytics" />
          }}
        />
        <Route
          exact
          path="/oauth2callback-monday"
          render={() => <OAuthRedirect connectorID="monday" />}
        />
        <Route
          exact
          path="/salesforce-oauth-callback"
          render={() => <OAuthRedirect connectorID="salesforcePardot" />}
        />
        <Route
          exact
          path="/salesforce-mc-oauth-callback"
          render={() => (
            <OAuthRedirect connectorID="salesforceMarketingCloud" />
          )}
        />

        {/* 404 */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}
