import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import Button from './button'
import ProgressBar from './progress-bar'
import arrow from '../assets/right-arrow-pink.svg'
import cyclePrev from '../assets/drop-down-arrow-left.svg'
import cycleNext from '../assets/drop-down-arrow.svg'
import { onboardingSectionData } from '../core/constants'
import { saveUserData } from '../helpers/local-client'
import useMobile from '../hooks/useMobile'
import useOnboarding, {
  OnboardingProgressListItem,
} from '../hooks/useOnboarding'
import styles from '../styles/onboarding-widget.module.scss'
import Link from './link'

interface OnboardingProgressWidgetProps {
  currentAccount: string
  setShowOnboardingWidget: Dispatch<SetStateAction<boolean>>
}

const OnboardingProgressWidget = React.memo(
  ({
    setShowOnboardingWidget,
    currentAccount,
  }: OnboardingProgressWidgetProps) => {
    const mobile = useMobile()

    const { mergedOnboardingSections } = useOnboarding()

    const completedSections = useMemo(() => {
      if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
        return mergedOnboardingSections.filter((section) => {
          return (
            (section.sectionCompleted &&
              (section.sectionSkipped || !section.sectionSkippedBefore)) ||
            section.sectionSkipped
          )
        }).length
      }
      return null
    }, [mergedOnboardingSections])

    const c = classNames(styles.widget, {
      [styles.mobile]: mobile,
    })

    const history = useHistory()

    const [
      currentStep,
      setCurrentStep,
    ] = useState<OnboardingProgressListItem | null>(null)

    useEffect(() => {
      if (
        mergedOnboardingSections.length > 0 &&
        completedSections !== null &&
        completedSections < mergedOnboardingSections.length
      ) {
        const initialStepToShow = mergedOnboardingSections.find((item) => {
          return !(
            (item.sectionCompleted &&
              (item.sectionSkipped || !item.sectionSkippedBefore)) ||
            item.sectionSkipped
          )
        })

        setCurrentStep(initialStepToShow as OnboardingProgressListItem)
      }
    }, [mergedOnboardingSections, completedSections])

    const currSectionIndex = useMemo(() => {
      return mergedOnboardingSections.findIndex(
        (section) =>
          section.onboardingSectionID === currentStep?.onboardingSectionID,
      )
    }, [currentStep])

    const showPreviousSection = useCallback(() => {
      if (currSectionIndex === 0) {
        setCurrentStep(
          mergedOnboardingSections[mergedOnboardingSections.length - 1],
        )
        return
      }

      setCurrentStep(mergedOnboardingSections[currSectionIndex - 1])
    }, [currentStep])

    const showNextSection = useCallback(() => {
      if (currSectionIndex === mergedOnboardingSections.length - 1) {
        setCurrentStep(mergedOnboardingSections[0])
        return
      }

      setCurrentStep(mergedOnboardingSections[currSectionIndex + 1])
    }, [currentStep])

    const goToSection = (sectionID) => {
      if (onboardingSectionData[sectionID].link) {
        history.push(onboardingSectionData[sectionID].link)
      } else {
        // First step: go to onboarding page
        history.push('/welcome')
      }
    }

    if (currentStep === null || completedSections === null) return null

    return (
      <div className={c}>
        <div className={styles.row}>
          <div className={styles.widgetTop}>
            <p>
              <span className={styles.progressIndicator}>
                <Button
                  variant="iconOnly"
                  icon={{
                    src: cyclePrev,
                    alt: 'Go to previous',
                  }}
                  onPress={showPreviousSection}
                  className={styles.cycleArrow}
                />
                <span>
                  {currSectionIndex + 1} of {mergedOnboardingSections.length}
                </span>
                <Button
                  variant="iconOnly"
                  icon={{
                    src: cycleNext,
                    alt: 'Go to next',
                  }}
                  onPress={showNextSection}
                  className={styles.cycleArrow}
                />
              </span>
              <span
                className={
                  (currentStep.sectionCompleted &&
                    (currentStep.sectionSkipped ||
                      !currentStep.sectionSkippedBefore)) ||
                  currentStep.sectionSkipped
                    ? styles.stepComplete
                    : undefined
                }
              >
                <Button
                  variant="text"
                  className={styles.viewStep}
                  onPress={() => goToSection(currentStep.onboardingSectionID)}
                >
                  <span className={styles.pink}>
                    {onboardingSectionData[currentStep.onboardingSectionID]
                      .title || 'View next task'}
                  </span>
                </Button>
              </span>
            </p>
            <ProgressBar
              percentage={Math.ceil(
                (completedSections / mergedOnboardingSections.length) * 100,
              )}
              showPercent={false}
              thin
              superThin
              fixedColor="green"
            />
          </div>
          <Button
            variant="iconOnly"
            icon={{
              src: arrow,
              alt: 'Go to next section',
            }}
            onPress={() => goToSection(currentStep.onboardingSectionID)}
            className={styles.progressButton}
          />
        </div>
        <div className={styles.row}>
          <Button
            variant="text"
            color="grey"
            className={styles.hide}
            onPress={() => {
              setShowOnboardingWidget(false)
              saveUserData({ hideOnboardingWidget: true }, currentAccount)
            }}
          >
            Hide
          </Button>
          <Link href="/welcome" className={styles.viewAll} newTab={false}>
            View all
          </Link>
        </div>
      </div>
    )
  },
)

export default OnboardingProgressWidget
