import { gql } from '../../__gql-types__/gql'

export const userShortLinks = gql(`
	fragment UserShortLinks on MinimalCodeList {
		shortLink
	}
`)

export const AVAILABLE_CODE_IDS = gql(`
	fragment MinCodeIDs on MinimalCodeList {
		codeID
	}
`)

/**
 * Accepted values for dimensionParameterID:
 * ['any',  'createdBy', 'createdTime', 'shortLink', 'fullLink', '{parameterIDs from generator...}']
 */
export const getMinCodesQuick = gql(`
	query GetMinCodesQuick (
		$codeIDList: [String!]
		$dimensionFilter: DimensionFilter
		$endDate: String
		$filterByCurrentUser: Boolean
		$filterByEdited: Boolean
		$limit: Int
		$offset: Int
		$orderBy: SortFilter
		$startDate: String
	) {
		track {
			minCodesQuick(
				codeIDList: $codeIDList
				dimensionFilter: $dimensionFilter
				endDate: $endDate
				filterByCurrentUser: $filterByCurrentUser
				filterByEdited: $filterByEdited
				limit: $limit
				offset: $offset
				orderBy: $orderBy
				startDate: $startDate
			) {
				filteredByCurrentUser @client
				dimensionFilter @client
				sortDirection @client
				sortField @client
				isCodeIDList @client
				author
				codeDef
				codeID
				createdTime
				fullLink
				minGenDef {
					paramID
					paramName
				}
				shortLink
				totalCodes
				versionNumber
			}
		}
	}
`)

export const getUrlValidationStatus = gql(`
  query GetUrlValidationStatus (
		$codeIDList: [String!]!,
	) {
		track {
			accountID @client
			trackValidationResults(
				codeIDList: $codeIDList,
			) {
				statusCode
				badUrl
				campaignCodeID
				noAnalyticsTag
				redirectedLandingPage
				slowLandingPage
			}
		}
	}
`)

export const getTrackValidationIgnoreList = gql(`
	query GetTrackValidationIgnoreList {
		track {
			getTrackValidationIgnoreList {
				ignoreAll
				ignoreID
				landingPage
				insertTime
				redirectedLandingPage
				noAnalyticsTag
				slowLandingPage
				userID
				validURL
				version
				domain
				applyToUser
				applyToAccount
				accountID
			}
		}
	}
`)

export const addTrackValidationIgnore = gql(`
	mutation AddTrackValidationIgnore (
		$applyToAccount: Boolean
		$applyToUser: Boolean
		$ignoreAll: Boolean
		$domain: String
		$landingPage: String!
		$redirectedLandingPage: Boolean
		$slowLandingPage: Boolean
		$noAnalyticsTag: Boolean
		$validURL: Boolean
	) {
		track {
			addTrackValidationIgnore(
				applyToAccount: $applyToAccount
				applyToUser: $applyToUser
				ignoreAll: $ignoreAll
				domain: $domain
				landingPage: $landingPage
				redirectedLandingPage: $redirectedLandingPage
				slowLandingPage: $slowLandingPage
				noAnalyticsTag: $noAnalyticsTag
				validURL: $validURL
			) {
				version
				validURL
				userID
				slowLandingPage
				redirectedLandingPage
				landingPage
				noAnalyticsTag
				insertTime
				ignoreID
				ignoreAll
				domain
				applyToUser
				applyToAccount
				accountID
			}
		}
	}
`)

export const deleteTrackValidationIgnore = gql(`
	mutation DeleteTrackValidationIgnore (
		$ignoreID: String!
	) {
		track {
			deleteTrackValidationIgnore(
				ignoreID: $ignoreID
			) {
				version
				validURL
				userID
				slowLandingPage
				redirectedLandingPage
				landingPage
				noAnalyticsTag
				insertTime
				ignoreID
				ignoreAll
				domain
				applyToUser
				applyToAccount
				accountID
			}
		}
	}
`)

export const getStoredCodeStatsQuick = gql(`
	query GetStoredCodesStatsQuick (
		$codeIDList: [String!]
		$dimensionFilter: DimensionFilter
		$filterByCurrentUser: Boolean
		$limit: Int
		$offset: Int
		$orderBy: SortFilter
	) {
		track {
			storedCodeStatsQuick(
				codeIDList: $codeIDList
				dimensionFilter: $dimensionFilter
				filterByCurrentUser: $filterByCurrentUser
				limit: $limit
				offset: $offset
				orderBy: $orderBy
			) {
				filteredByCurrentUser @client
				dimensionFilter @client
				sortDirection @client
				sortField @client
				isCodeIDList @client
				codeIDs
				earliestClickDate
				earliestMetricDate
				earliestDeepLinkDate
				metricValues {
					displayName
					helpText
					metricID
					totalMetricValues
					units
				}
				status
				totalCodes
			}
		}
	}
`)

export const quickUrlValidation = gql(`
	query QuickUrlValidation($urlList: [String!]!) {
		track {
			quickUrlValidation(urlList: $urlList) {
				validationResults {
					statusCode
					testUrl
				}
			}
		}
	}
`)

export const intensiveUrlValidation = gql(`
	query IntensiveUrlValidation($urlList: [String!]!) {
		track {
			intensiveUrlValidation(urlList: $urlList) {
				validationResults {
					badUrl
					clickedCookieConsent
					noAnalyticsTag
					redirectedLandingPage
					slowLandingPage
					statusCode
					testUrl
					validatorReturned
				}
			}
		}
	}
`)

export const addShortLinkExistingCode = gql(`
	mutation AddShortLinkExistingCode (
		$codeID: String!
		$customDomainID: String
		$newShortLinkID: String!
	) {
		track {
			addShortLinkExistingCode(
				codeID: $codeID,
				customDomainID: $customDomainID
				newShortLinkID: $newShortLinkID,
			) {
				c
				cID
				cDef {
					fID
					n
					v
				}
				e
				edB
				edT
				sL
				t
				vN
			}
		}
	}
`)

export const updateCode = gql(`
	mutation UpdateCode(
		$codeID: String!
		$fullCode: String
		$paramDefs: [ParamDefInput!]
		$customDomainID: String
		$deepLinkConfig: DeepLinkInput
	) {
		updateCode(
			codeID: $codeID
			fullCode: $fullCode
			paramDefs: $paramDefs
			customDomainID: $customDomainID
			deepLinkConfig: $deepLinkConfig
		) {
			cDef {
				fID
				n
				v
			}
			c
			cID
			e
			edB
			edT
			sL
			t
			vN
		}
	}
`)

export const fetchLinkEditHistory = gql(`
	query FetchLinkEditHistory (
		$codeID: String!
	) {
		track {
			codeVersionHistory(
				codeID: $codeID
			) {
				changeMap
				editedBy
				editedTime
				versionHistory {
					versionHistoryLinkID @client
					codeDef
					fullLink
					minGenDef {
						paramID
						paramName
					}
					totalCodes
					shortLink
					versionNumber
				}
				codeID
				createdTime
				author
			}
		}
	}
`)

export const getDeepLinkDetails = gql(`
	query GetDeeplinkDetails (
		$codeID: String!
		$deepLinkServiceID: String!
	) {
		track {
			deepLinkQueries {
				getDeeplinkDetails(
					codeID: $codeID
					deepLinkServiceID: $deepLinkServiceID
				) {
					accountID
					analyticsContext
					appGroupID
					campaignCodeID
					companyID
					deepLinkServiceID
					deepLinkShortLink
					fallBackURL
					ogDescription
					ogImage
					ogImageAlt
					ogImageHeight
					ogImageWidth
					ogTitle
					redirectContext
				}
			}
		}
	}
`)
