export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)

export function validateEmail(email: string): boolean

export function validateEmail(email: string[], returnResult: false): boolean

export function validateEmail(
  email: string[],
  returnResult: true,
): {
  valid: string[]
  invalid: string[]
}

export function validateEmail(email: string | string[], returnResult = false) {
  if (typeof email === 'string') {
    return emailRegex.test(email)
  }

  const invalidEmails = email.filter((eml) => {
    const isValid = emailRegex.test(eml)
    return !isValid
  })

  if (returnResult) {
    return {
      valid: email.filter((eml) => invalidEmails.indexOf(eml) === -1),
      invalid: invalidEmails,
    }
  }

  return invalidEmails.length === 0
}

export function validatePassword(password?: string) {
  if (!password || password.split('').length < 8) {
    return 'Password must be at least 8 characters.'
  }

  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least 1 uppercase letter.'
  }

  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least 1 lowercase letter.'
  }

  // if (!/[ !@#$%~^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
  //   return 'Password must contain at least 1 special character (!@#$%~^&*()_+-=[]{};\':"\\|,.<>/?)'
  // }

  if (!/\d/.test(password)) {
    return 'Password must contain at least 1 number.'
  }

  return ''
}

interface LoginForm {
  email: string
  password: string
}

export function loginFormValidation(
  form: LoginForm,
  dispatch: (message: string) => void,
): boolean {
  if (!form.email) {
    dispatch('Email field cannot be empty')
    return false
  }

  if (!emailRegex.test(form.email)) {
    dispatch('Email must be valid')
    return false
  }

  if (!form.password) {
    dispatch('Password field cannot be empty')
    return false
  }

  return true
}

export type PlanInterestLevel = 'Individual' | 'Team' | 'Enterprise'

export interface NewAccountDetails {
  fName: string
  lName: string
  email: string
  organisation: string
  botCheckbox: boolean
  acceptedMarketing: boolean
  terms: boolean
  planInterest?: PlanInterestLevel
  useCases?: string[]
  discoveryMethod?: string
  discoveryMethodOther?: string
  password?: string
  passwordConfirmation?: string
  microsoftActivationToken?: string
}

export const createAccountFormValidation = (
  formStep: number,
  form: NewAccountDetails,
  onError: (message: string) => void,
) => {
  let formHasError =
    !emailRegex.test(form.email) ||
    !form.fName ||
    !form.lName ||
    form.botCheckbox ||
    !form.organisation ||
    !form.terms

  if (formStep > 0) {
    formHasError =
      formHasError ||
      !form.planInterest ||
      !form.useCases ||
      form.useCases.length === 0 ||
      !form.discoveryMethod ||
      (form.discoveryMethod === 'other' && !form.discoveryMethodOther)
  }

  if (formStep > 1) {
    formHasError =
      formHasError ||
      !form.password ||
      !form.passwordConfirmation ||
      form.password !== form.passwordConfirmation ||
      validatePassword(form.password) !== ''
  }

  if (formHasError) {
    let errorMessage = 'Please fill in all required fields.'

    switch (true) {
      case !emailRegex.test(form.email):
        errorMessage = 'Email must be valid.'
        break
      case !form.terms:
        errorMessage = 'Please accept the terms of service and privacy policy.'
        break
      case form.botCheckbox:
        errorMessage = 'Suspicious activity detected. Please try again later.'
        break
      case form.password !== form.passwordConfirmation:
        errorMessage = 'Passwords do not match.'
        break
      case validatePassword(form.password) !== '':
        errorMessage = validatePassword(form.password)
        break
      default:
        break
    }

    onError(errorMessage)
  }

  return !formHasError
}
