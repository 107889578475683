import { useCallback, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import {
  SavedFormType,
  UpdateFormValuesVars,
  WorkspaceForm,
  defaultFormData,
  saveTrackCreateFormData,
  updateTrackCreateFormData,
  vCardFieldDefs,
  vEventFieldDefs,
} from '../helpers/track-create'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

export interface UpdateFormOptions {
  switchFormType?: boolean
  resetSubmissionState?: boolean
  errorsToAdd?: string[]
  errorsToRemove?: string[]
}

/** Submission logic and error state for Track>Create form */
const useTrackCreateSavedValues = (
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null,
) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { paramDefs, validationChecks } = generatedStructure || {}

  const [formValues, setFormValues] = useState<WorkspaceForm>(defaultFormData)
  const [formSubmissionState, setFormSubmissionState] = useState({
    softDisable: false,
    fieldsWithErrors: [] as string[],
    showErrorMessages: false,
  })

  const requireLandingPageField = useMemo(() => {
    // Landing page field is not used on email form
    if (formValues.options.active === 'email') {
      return false
    }

    // Landing page field should never be optional if isApp
    if (
      formValues.options.active === 'web' &&
      formValues.web.linkTo === 'app'
    ) {
      return true
    }

    const showLandingCheck = validationChecks?.find(
      (check) => check.name === 'SHOW_LANDING_PAGE',
    )?.enabled

    const requireLandingCheck = validationChecks?.find(
      (check) => check.name === 'REQUIRE_LANDING_PAGE',
    )?.enabled

    const _showLandingPageField =
      typeof showLandingCheck === 'boolean' ? showLandingCheck : true

    return !(!_showLandingPageField || requireLandingCheck === false)
  }, [validationChecks, formValues.options.active, formValues.web.linkTo])

  // Reset errors based on formValues and generator configuration
  const resetFormSubmissionState = useCallback(
    (formType: SavedFormType, formData: UpdateFormValuesVars) => {
      const fieldsWithErrors: string[] = []

      if (formType === 'email') {
        const { emailSource, emailHtml } = formData.emailFields || {}

        if (!emailHtml) {
          fieldsWithErrors.push('emailHtml')
        }

        if (emailSource && emailSource !== 'emailHtml') {
          fieldsWithErrors.push(emailSource)
        }
      } else {
        if (
          !['vCard', 'vEvent'].includes(formData?.linkTo ?? '') &&
          requireLandingPageField &&
          formData.url &&
          (formData.url.length === 0 || formData.url[0] === '')
        ) {
          fieldsWithErrors.push('landingPage')
        }

        if (formData.linkTo === 'app') {
          if (!formData.appValues?.appGroupID) {
            fieldsWithErrors.push('appGroupID')
          }
        }
      }

      if (
        !['vCard', 'vEvent'].includes(formData?.linkTo ?? '') &&
        formData.generatorParameterValues
      ) {
        const { generatorParameterValues } = formData

        paramDefs?.forEach(
          ({
            fieldID,
            fieldType,
            required,
            parameterDependsOn,
            selectFields,
          }) => {
            if (
              generatorParameterValues[fieldID] &&
              generatorParameterValues[fieldID][0]
            ) {
              if (fieldType === 'select' && selectFields) {
                const fieldsThatExist = generatorParameterValues[
                  fieldID
                ].filter((val: string) => {
                  return selectFields.find(
                    ({ optionID }) =>
                      // Select fields no longer use optionValues for their values, they use IDs
                      // optionValue === val ||
                      optionID === val,
                  )
                })

                if (
                  fieldsThatExist.length !==
                  generatorParameterValues[fieldID].length
                ) {
                  fieldsWithErrors.push(fieldID)
                }
              }

              // No need to check required state if param has a value
              return
            }

            // If the field's dependencies aren't met, we ignore its `required` value for now
            let paramIsRequired = parameterDependsOn ? false : required

            if (parameterDependsOn) {
              if (parameterDependsOn.parentFieldID !== 'account') {
                // Find the parent field in the generator
                const parentParam = paramDefs.find(
                  (parent) =>
                    parent.fieldID === parameterDependsOn.parentFieldID,
                )

                // If parent has values
                if (
                  parentParam &&
                  generatorParameterValues[parentParam.fieldID] &&
                  generatorParameterValues[parentParam.fieldID][0]
                ) {
                  let canShowParam = false
                  let valueIndex = 0

                  // Check if parent param's current value contains a value the dependent param needs
                  while (
                    valueIndex <
                    generatorParameterValues[parentParam.fieldID].length
                  ) {
                    if (
                      parameterDependsOn.parentOptionIDs.indexOf(
                        generatorParameterValues[parentParam.fieldID][
                          valueIndex
                        ],
                      ) > -1
                    ) {
                      canShowParam = true
                      // Update param's required status
                      paramIsRequired = required
                      break
                    }

                    valueIndex += 1
                  }

                  if (canShowParam) {
                    paramIsRequired = required
                  }
                }
              } else if (
                parameterDependsOn.parentOptionIDs.includes(workspaceID)
              ) {
                // If the parameter's visibility is workspace-dependent, use its `required` rule
                paramIsRequired = required
              }
            }

            if (paramIsRequired) {
              fieldsWithErrors.push(fieldID)
            }
          },
        )
      }

      // Set error state to match required fields for vCard format
      if (formData.linkTo === 'vCard') {
        fieldsWithErrors.push(
          ...Object.keys(vCardFieldDefs).filter(
            (fieldName) =>
              vCardFieldDefs[fieldName].required &&
              (!formData.vCardFieldValues ||
                !formData.vCardFieldValues[fieldName] ||
                formData.vCardFieldValues[fieldName]?.length === 0),
          ),
        )
      }

      // Set error state to match required fields for vEvent format
      if (formData.linkTo === 'vEvent') {
        fieldsWithErrors.push(
          ...Object.keys(vEventFieldDefs).filter(
            (fieldName) =>
              vEventFieldDefs[fieldName].required &&
              (!formData.vEventFieldValues ||
                !formData.vEventFieldValues[fieldName] ||
                formData.vEventFieldValues[fieldName]?.length === 0),
          ),
        )
      }

      setFormSubmissionState({
        showErrorMessages: false,
        softDisable: fieldsWithErrors.length > 0,
        fieldsWithErrors,
      })
    },
    [paramDefs, requireLandingPageField],
  )

  const updateFormValues = useCallback(
    (
      formType: SavedFormType,
      newValues: UpdateFormValuesVars,
      options?: UpdateFormOptions,
    ) => {
      setFormValues((curr) => {
        return {
          ...curr,
          [formType]: updateTrackCreateFormData(curr[formType], newValues),
          options: {
            ...curr.options,
            active: options?.switchFormType ? formType : curr.options.active,
          },
        }
      })

      saveTrackCreateFormData(workspaceID, formType, newValues)

      // Set full form error state
      if (options) {
        const { resetSubmissionState, errorsToAdd, errorsToRemove } = options

        if (resetSubmissionState) {
          resetFormSubmissionState(formType, newValues)
        } else {
          const { fieldsWithErrors } = formSubmissionState

          let newFieldsWithErrors = [...fieldsWithErrors]

          // Remove errors
          if (errorsToRemove) {
            newFieldsWithErrors = newFieldsWithErrors.filter(
              (field) => !errorsToRemove.includes(field),
            )
          }

          // Add new errors
          if (errorsToAdd) {
            errorsToAdd.forEach((errorField) => {
              if (!newFieldsWithErrors.includes(errorField)) {
                newFieldsWithErrors.push(errorField)
              }
            })
          }

          setFormSubmissionState((curr) => ({
            ...curr,
            softDisable: newFieldsWithErrors.length > 0,
            fieldsWithErrors: newFieldsWithErrors,
          }))
        }
      }
    },
    [workspaceID, formValues, formSubmissionState, resetFormSubmissionState],
  )

  return {
    formValues,
    updateFormValues,
    formSubmissionState,
    setFormSubmissionState,
  }
}

export default useTrackCreateSavedValues
