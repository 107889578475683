import React, { useMemo } from 'react'
import classNames from 'classnames'

import useAnimatedNumber from '../hooks/useAnimateNumber'
import styles from '../styles/progress-bar.module.scss'

interface Props {
  percentage: number
  showPercent?: boolean
  thin?: boolean
  superThin?: boolean
  fixedColor?: 'red' | 'yellow' | 'green' | 'grey'
  useUrgency?: boolean
  urgencyReversed?: boolean
  className?: string
  style?: React.CSSProperties
}

const ProgressBar = ({
  percentage,
  showPercent = true,
  thin = false,
  superThin = false,
  fixedColor,
  useUrgency = false,
  urgencyReversed,
  className,
  style,
}: Props) => {
  const animatedPercentage = useAnimatedNumber(percentage, 0)

  const urgencyColor = useMemo(() => {
    if (fixedColor || !useUrgency) return null

    if (
      (!urgencyReversed && percentage >= 66) ||
      (urgencyReversed && percentage < 34)
    ) {
      return 'red'
    }

    if (
      (!urgencyReversed && percentage < 66 && percentage >= 34) ||
      (urgencyReversed && percentage >= 34 && percentage < 66)
    ) {
      return 'yellow'
    }

    return 'green'
  }, [percentage, useUrgency, urgencyReversed])

  return (
    <div
      className={classNames(className, styles.container, {
        [styles.thin]: thin,
        [styles.superThin]: superThin,
      })}
      style={style}
    >
      <span className={styles.progressContainer}>
        <span
          className={classNames(
            styles.progress,
            fixedColor && styles[fixedColor],
            urgencyColor && styles[urgencyColor],
          )}
          style={{ width: `${animatedPercentage}%` }}
        />
        {showPercent && <b className={styles.number}>{animatedPercentage}%</b>}
      </span>
    </div>
  )
}

export default ProgressBar
