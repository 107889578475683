import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import FileSaver from 'file-saver'
import moment from 'moment'
import numeral from 'numeraljs'

import { ButtonRow } from './button-row'
import Button, { NavigateButton } from './button'
import AddNewSelectField from './track-edit-add-new-dropdown-row'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import FileDragAndDrop from './file-drag-and-drop'
import { ClickEditInput, SearchInput } from './input'
import Link from './link'
import Modal from './modal'
import SelectBox, { SelectBoxSimple } from './select-box'
import Table, { TableHeaderColumn } from './table-v2'
import { BoxedText } from './typography'
import { currentUserDetails, linkOrCode } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import {
  deleteGeneratorParameterSelectOption,
  updateGeneratorParameterSelectOption,
  updateGeneratorParameterSelectResetParent,
} from '../api/graphql/track-edit-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { bulkUploadDropdowns } from '../api/REST/track-client'
import {
  GeneratorFields,
  GeneratorSelectFields,
  ValidationChecks,
} from '../api/types'
import hideIcon from '../assets/icon-eye-open.svg'
import hiddenIcon from '../assets/icon-eye-crossed-inactive.svg'
import deleteImg from '../assets/bin.svg'
import { brandName, dynamicTextValues, supportEmail } from '../core/constants'
import {
  fuzzySearch,
  getCsvString,
  getItemByKeyValue,
  getUrlQuery,
  isAdminUser,
  prepareInput,
} from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useMobile from '../hooks/useMobile'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/track-dropdowns-table.module.scss'
import {
  GetCampaignCodeGeneratorQuery,
  SelectField,
} from '../__gql-types__/graphql'

interface DropdownOptionRowProps {
  tableRowRef: React.RefObject<HTMLTableRowElement>
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  option: SelectField
  validation: ValidationChecks[]
  parentFieldID: string
  restrictDropdowns?: boolean
  restrictDropdownsOptionsList:
    | GeneratorSelectFields[]
    | { optionName: string; optionID: string }[]
  updateSelectOption: (options: {
    optionName?: string
    optionValue?: string
    optionFilter?: {
      parentFieldID: string
      parentOptionIDs: string[]
    }[]
    hide?: boolean
  }) => Promise<void>
  onDelete: () => Promise<void>
}

const DropdownOptionRow = ({
  tableRowRef,
  param,
  option,
  validation,
  parentFieldID,
  restrictDropdowns,
  restrictDropdownsOptionsList = [],
  updateSelectOption,
  onDelete,
}: DropdownOptionRowProps) => {
  const { optionID, optionName, optionValue, optionFilter, hide } = option

  const restrictDropdownsValue =
    optionFilter && optionFilter.length > 0
      ? optionFilter[0].parentOptionIDs
      : []

  return (
    <>
      <td>
        <ClickEditInput
          hide={hide}
          id={`${param.fieldID}-${optionID}-name`}
          name={`${param.fieldID}-${optionID}-name`}
          value={optionName}
          onChange={async (value) => {
            await updateSelectOption({ optionName: value })
          }}
        />
      </td>
      <td>
        <ClickEditInput
          hide={hide}
          id={`${param.fieldID}-${optionID}-value`}
          name={`${param.fieldID}-${optionID}-value`}
          value={optionValue}
          beforeChange={(value: string) => prepareInput(value, validation)}
          onChange={async (value) => {
            await updateSelectOption({
              optionValue: value,
            })
          }}
        />
      </td>
      {restrictDropdowns && (
        <td>
          {parentFieldID !== '' && (
            <SelectBox
              id={`${param.fieldName}-${optionName}-${optionValue}`}
              className={styles.multiSelect}
              placeholder="Always show"
              isMulti
              menuPosition="fixed"
              labelKey="optionName"
              valueKey="optionID"
              value={restrictDropdownsOptionsList.filter(
                (o) => restrictDropdownsValue.indexOf(o.optionID) > -1,
              )}
              options={restrictDropdownsOptionsList}
              onChange={async (newValue) => {
                const newOptionIDs = newValue.map((o) => o.optionID)

                // Preserve workspaces that the current user isn't admin on
                if (parentFieldID === 'account') {
                  restrictDropdownsValue.forEach((id) => {
                    if (
                      !restrictDropdownsOptionsList.find(
                        (o) => o.optionID === id,
                      )
                    )
                      newOptionIDs.push(id)
                  })
                }

                await updateSelectOption({
                  optionFilter: [
                    {
                      parentFieldID,
                      parentOptionIDs: newOptionIDs,
                    },
                  ],
                })
              }}
            />
          )}
        </td>
      )}
      <td className={styles.deleteColumn}>
        <div className={styles.actionsContainer}>
          <Button
            variant="iconOnly"
            icon={{
              src: hide ? hiddenIcon : hideIcon,
              alt: hide ? 'Unhide' : 'Hide',
            }}
            onPress={async () => {
              await updateSelectOption({
                hide: !hide,
              })
            }}
          />
          <DeleteButtonWithConfirmation
            containerRef={tableRowRef}
            confirmMessage="Are you sure you want to delete this option?"
            onConfirm={onDelete}
          />
        </div>
      </td>
    </>
  )
}

interface DropdownsTableProps {
  param: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  parentsList: GeneratorFields[]
  validation: ValidationChecks[]
}

export const DropdownsTable = ({
  param,
  parentsList,
  validation,
}: DropdownsTableProps) => {
  const { workspaceID, companyID } = useReactiveVar(currentUserDetails)

  const linkCopy = useReactiveVar(linkOrCode)

  const query = getUrlQuery()

  const fieldID = query?.get('fieldID')

  // TODO: Get all workspaces for a company
  const { data: userAccountData } = useQuery(getUserAccounts)

  const [refetchGenerator] = useLazyQuery(getCampaignCodeGenerator, {
    fetchPolicy: 'network-only',
  })

  const [updateSelectOption] = useMutation(updateGeneratorParameterSelectOption)
  const [deleteSelectOption] = useMutation(deleteGeneratorParameterSelectOption)
  const [resetParentOptions, { loading: resetOptionsLoading }] = useMutation(
    updateGeneratorParameterSelectResetParent,
  )

  /** Get the workspaces for which the current user is an admin
   * The workspace-specific restriction rule only applies to companies using shared generators
   * This code doesn't check if a generator is shared
   * So workspace-restricted dropdown values just won't matter if the current workspace's generator isn't shared
   * Shared generators are currently only configurable manually via the backend
   * TODO: Accommodate shared generators via UI
   */
  const userAdminWorkspaces = useMemo(() => {
    if (!companyID || !userAccountData) return []

    return userAccountData.currentUser.userAccountProfiles
      .filter(
        (account) =>
          account.companyID === companyID &&
          isAdminUser(account.userPermission),
      )
      .map((account) => ({
        optionID: account.accountID,
        optionName: account.accountName,
      }))
  }, [userAccountData, companyID])

  const logAction = useLogAction()

  const isMobile = useMobile(769)

  const [searchTerm, setSearchTerm] = useState('')
  const [totalOptions, setTotalOptions] = useState(
    param.selectFields?.length || 0,
  )
  const [restrictDropdowns, setRestrictDropdowns] = useState(false)
  const [hasChildren, setHasChildren] = useState(false)
  const [parentFieldID, setParentFieldID] = useState('')
  const [confirmRemoveParentActive, setConfirmRemoveParentActive] = useState(
    false,
  )
  const [showAdd, setShowAdd] = useState(false)
  const [bulkUploadModal, setBulkUploadModal] = useState(false)
  const [bulkUploadStatus, setBulkUploadStatus] = useState<{
    error: string | React.ReactElement
    success: string
  }>({
    success: '',
    error: '',
  })

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const onBulkUpload = useCallback(
    async (acceptedFiles: File[]) => {
      setBulkUploadStatus({ success: '', error: '' })

      if (acceptedFiles.length > 0) {
        const res = await bulkUploadDropdowns({
          file: acceptedFiles.pop() as File,
          parameterID: param.fieldID,
        })

        if (res === true) {
          setBulkUploadStatus({
            error: '',
            success: 'Your dropdowns have been added.',
          })

          // Refetch to update dropdowns
          refetchGenerator()

          logAction({
            variables: {
              action: 'bulk-upload-parameter-options-success',
              functionName: 'bulkImportParameterOptions',
              pagePath: '/track/edit-dropdowns',
              websiteSection: 'track',
            },
          })

          setTimeout(() => {
            setBulkUploadModal(false)
          }, 3000)

          return
        }

        if (
          res?.status === 400 &&
          res?.error?.response?.data &&
          res?.error?.response?.headers['content-type'] ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // Save the file as a blob and add a button to download it
          const fileBlob = new Blob([new Uint8Array(res.error.response.data)], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          setBulkUploadStatus({
            error: (
              <>
                <span>
                  There were validation issues with your form.{' '}
                  <NavigateButton
                    variant="text"
                    onPress={async () => {
                      const now = new Date(Date.now())

                      await FileSaver.saveAs(
                        fileBlob,
                        `${moment(now).format(
                          'YYYY-MM-DD',
                        )} - ${brandName} Bulk Upload Errors.xlsx`,
                      )
                    }}
                  >
                    Download error sheet
                  </NavigateButton>
                </span>
              </>
            ),
            success: '',
          })

          return
        }

        const resJSON =
          res?.status === 500
            ? res?.error?.response?.data
            : JSON.parse(
                String.fromCharCode.apply(
                  null,
                  // @ts-ignore
                  new Uint8Array(res?.error?.response?.data),
                ),
              )

        const type = resJSON?.detail || ''

        if (
          type === 'BAD_TEMPLATE' ||
          type === 'Template file does not match expected format'
        ) {
          setBulkUploadStatus({
            error:
              'Incorrect import template. Please download and use the sample template.',
            success: '',
          })
        } else if (type === 'BAD_FILE_FORMAT') {
          setBulkUploadStatus({
            error: 'Incorrect file type.',
            success: '',
          })
        } else if (type === 'FAILED_UPLOAD') {
          setBulkUploadStatus({
            error: 'File upload has failed, please try again.',
            success: '',
          })
        } else {
          setBulkUploadStatus({
            error: (
              <span>
                File upload has failed. Please email{' '}
                <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
                help.
              </span>
            ),
            success: '',
          })
        }

        logAction({
          variables: {
            action: 'bulk-upload-parameter-options-failed',
            functionName: 'bulkImportParameterOptions',
            pagePath: '/track/edit-dropdowns',
            websiteSection: 'track',
          },
        })
      }
    },
    [param.fieldID],
  )

  // Show 'add' row if param comes from URL
  useEffect(() => {
    if (!fieldID || param.fieldID !== fieldID) return

    setShowAdd(true)
  }, [fieldID])

  const completeOnboardingSection = useCallback(() => {
    const editTaxonomy = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'editTaxonomy',
    )

    if (editTaxonomy && !editTaxonomy.sectionCompleted) {
      updateOnboardingSection('editTaxonomy', 'account')
    }
  }, [fullOnboardingSections])

  // Check if the parameter has restrictions against it
  useEffect(() => {
    if (param.selectFields) {
      const selectFieldFilter = param.selectFields.find(
        (field) => field.optionFilter && field.optionFilter.length > 0,
      )

      if (selectFieldFilter && selectFieldFilter.optionFilter) {
        setRestrictDropdowns(true)
        setParentFieldID(selectFieldFilter.optionFilter[0].parentFieldID)

        let _hasChildren = false

        param.selectFields.forEach((selectField) => {
          if (_hasChildren || !selectField.optionFilter) return

          if (
            selectField.optionFilter.find(
              (optionFilter) => optionFilter.parentOptionIDs.length > 0,
            )
          ) {
            _hasChildren = true
          }
        })

        setHasChildren(_hasChildren)
      }
    }
  }, [param])

  const selectedParent: null | GeneratorFields = useMemo(() => {
    // Restrict by workspace
    if (parentFieldID === '' || parentFieldID === 'account') return null

    return getItemByKeyValue(parentsList, 'fieldID', parentFieldID)
  }, [parentFieldID])

  const headerColumns = useMemo(() => {
    const headers: TableHeaderColumn<GeneratorSelectFields>[] = [
      {
        id: 'optionName',
        content: 'Dropdown option name',
        tooltip: (
          <p>
            The name users can select on{' '}
            <BoxedText>{dynamicTextValues.trackCreatePage[linkCopy]}</BoxedText>{' '}
            page dropdown.
          </p>
        ),
        columnSortKey: 'optionName',
      },
      {
        id: 'optionValue',
        content: 'Code',
        tooltip: 'The value used in the URL when the option is selected.',
        columnSortKey: 'optionValue',
      },
    ]

    if (restrictDropdowns) {
      headers.push({
        id: 'restrict',
        content: (
          <div className={styles.restrictDropdownsColumnHeader}>
            <span>Only show if</span>
            <SelectBoxSimple
              className={styles.selectWrapper}
              name={`select-${param.fieldName}`}
              value={parentFieldID}
              onChange={(val) => {
                if (hasChildren) {
                  setConfirmRemoveParentActive(true)
                } else {
                  setParentFieldID(val)
                }
              }}
            >
              <option value="">None</option>
              {/* Dropdown values can be restricted by workspace */}
              <option value="account">Workspace</option>
              {parentsList.map((item: GeneratorFields) => {
                return (
                  <option key={item.fieldID} value={item.fieldID}>
                    {item.fieldName}
                  </option>
                )
              })}
            </SelectBoxSimple>
            <span>=</span>
          </div>
        ),
      })
    }

    headers.push({
      id: 'actions',
      className: styles.deleteColumn,
      content: 'Actions',
      tooltip: (
        <>
          <div>
            <img src={hideIcon} alt="hide" />
            <p>Temporarily hide this option.</p>
          </div>
          <div>
            <img src={hiddenIcon} alt="hidden" />
            <p>Unhide option.</p>
          </div>
          <div>
            <img src={deleteImg} alt="delete" />
            <p>Remove this option permanently.</p>
          </div>
        </>
      ),
      tooltipClassName: styles.actionsTooltip,
    })

    return headers
  }, [restrictDropdowns])

  // Search optionValue and optionName together
  // Custom search fn ensures search is not fuzzy (default)
  const filteredList = useMemo(() => {
    if (!param.selectFields) return []

    return fuzzySearch(
      param.selectFields,
      ['optionName', 'optionValue'],
      searchTerm,
    )
  }, [searchTerm, param.selectFields])

  // Reset options count when filtered list changes
  useEffect(() => {
    if (filteredList.length > totalOptions) {
      setTotalOptions(filteredList.length)
    }
  }, [filteredList])

  return (
    <>
      <ButtonRow className={styles.tableActions}>
        <div className={styles.tableSearch}>
          <SearchInput onChange={(value) => setSearchTerm(value || '')}>
            <p>
              {filteredList.length !== totalOptions
                ? `${numeral(filteredList.length).format('0,0')}/`
                : ''}
              {numeral(totalOptions).format('0,0')} option
              {totalOptions > 1 ? 's' : ''}
            </p>
          </SearchInput>
        </div>
        <div className={styles.flexBtns}>
          <Button onPress={() => setShowAdd((curr) => !curr)}>
            Add dropdown
          </Button>
          {!isMobile && (
            <Button
              variant="secondary"
              onPress={() => setBulkUploadModal(true)}
            >
              Bulk import
            </Button>
          )}
          <Button
            variant="secondary"
            isDisabled={!!parentFieldID && hasChildren}
            onPress={() => setRestrictDropdowns(!restrictDropdowns)}
          >
            {restrictDropdowns
              ? "Don't restrict dropdowns"
              : 'Restrict dropdowns'}
          </Button>
        </div>
      </ButtonRow>
      <Table
        headerColumns={headerColumns}
        rowIDKey="optionID"
        // Originally sorted by reversing array order returned from API - prefer this
        initialSort={{ sortKey: 'optionName', sortAsc: true }}
        tableData={filteredList}
        noDataMsg="No options found."
        topRows={
          showAdd && (
            <AddNewSelectField
              field={param}
              fields={param.selectFields as GeneratorSelectFields[]}
              validation={validation}
              restrictDropdowns={restrictDropdowns}
              setSearchTerm={setSearchTerm}
              shownValues={filteredList}
            />
          )
        }
      >
        {(dropdownOption, dropdownOptionIndex, dropdownOptionRowRef) => {
          const { optionID } = dropdownOption

          let restrictDropdownsOptionsList:
            | GeneratorSelectFields[]
            | { optionName: string; optionID: string }[] = []

          if (parentFieldID === 'account') {
            restrictDropdownsOptionsList = userAdminWorkspaces
          } else if (selectedParent && selectedParent.selectFields) {
            restrictDropdownsOptionsList = selectedParent.selectFields
          }

          return (
            <DropdownOptionRow
              tableRowRef={dropdownOptionRowRef}
              param={param}
              option={dropdownOption}
              validation={validation}
              parentFieldID={parentFieldID}
              restrictDropdowns={restrictDropdowns}
              restrictDropdownsOptionsList={restrictDropdownsOptionsList}
              updateSelectOption={async (options) => {
                await updateSelectOption({
                  variables: {
                    fieldID: param.fieldID,
                    optionID,
                    ...options,
                  },
                })
              }}
              onDelete={async () => {
                deleteSelectOption({
                  variables: {
                    fieldID: param.fieldID,
                    optionID,
                  },
                })

                logAction({
                  variables: {
                    action: 'update-generator-parameter-delete-select-option',
                    extra: JSON.stringify({
                      fieldID: param.fieldID,
                      optionID,
                      accountID: workspaceID,
                    }),
                    websiteSection: 'track',
                    functionName: 'updateGeneratorParameterSelectDeleteOption',
                    pagePath: '/track/edit-dropdowns',
                  },
                })

                completeOnboardingSection()
              }}
            />
          )
        }}
      </Table>
      {confirmRemoveParentActive && (
        <Modal
          setIsOpen={setConfirmRemoveParentActive}
          headerColor="pink"
          isWarning
          modalHeader="Remove dropdown restrictions"
          noText="Cancel"
          yesText="Yes"
          yesButtonLoading={resetOptionsLoading}
          onYes={async () => {
            await resetParentOptions({
              variables: {
                fieldID: param.fieldID,
              },
            })

            setConfirmRemoveParentActive(false)
            setParentFieldID('')
          }}
        >
          <p>Are you sure you want to remove all the dropdown restrictions?</p>
        </Modal>
      )}
      {bulkUploadModal && (
        <Modal
          setIsOpen={setBulkUploadModal}
          width="wide"
          modalHeader="Bulk import from CSV"
        >
          <p>Save time when adding long lists of dropdown names and values.</p>
          <ol>
            <li>
              <Button
                className={styles.downloadTemplateButton}
                onPress={async () => {
                  const data = getCsvString([
                    { 'Option name': '', 'Option value': '' },
                  ])

                  const blob = new Blob([data], {
                    type: 'data:text/csv;charset=utf-8',
                  })
                  await FileSaver.saveAs(
                    blob,
                    `Bulk import parameters - ${param.fieldName}.csv`,
                  )
                }}
              >
                Download template
              </Button>
            </li>
            <li>
              For every dropdown add in the two columns:
              <ul style={{ marginTop: 8 }}>
                <li>Parameter name (how the dropdown appears in Uplifter)</li>
                <li>Parameter value (how it appears in the link)</li>
              </ul>
            </li>
            <li>Save the CSV template as CSV UTF-8 format</li>
            <li>Upload your CSV below</li>
          </ol>
          <FileDragAndDrop
            onDrop={onBulkUpload}
            success={bulkUploadStatus.success}
            uploadError={bulkUploadStatus.error}
          />
        </Modal>
      )}
    </>
  )
}
