import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import { TrackCreateIntro } from './track-create'
import { loggedInState } from '../api/apollo/variables'
import { resolveMicrosoftToken } from '../api/REST/account-client'
import defaultLogoImage from '../assets/logos/uplifter-arrow.png'
import Button, { NavigateButton } from '../components/button'
import ButtonTabs from '../components/button-tabs'
import Carousel from '../components/carousel'
import DisplayError from '../components/display-error'
import { FormField, FormRow } from '../components/form'
import Input from '../components/input'
import { InputLabel } from '../components/input-v2'
import Layout from '../components/layout'
import Link from '../components/link'
import Modal from '../components/modal'
import { PaddleInitialPayment } from '../components/paddle-checkout'
import SelectBox, { SelectBoxChecklist } from '../components/select-box'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import SiteWrapper from '../components/site-wrapper'
import { GoogleSSO, MicrosoftSSO } from '../components/sso-buttons'
import TrackCreateFormWeb from '../components/track-create-form-web'
import TrackCreateRecentUserLinks from '../components/track-create-recent-user-links'
import TwoColumns, { Column } from '../components/two-columns'
import { brandName, uplifterWebsite } from '../core/constants'
import {
  createAccountFormValidation,
  NewAccountDetails,
  PlanInterestLevel,
} from '../helpers/forms'
import { defaultBgColour, defaultFgColour } from '../helpers/qr-code'
import { defaultFormData, trackCreateTabs } from '../helpers/track-create'
import { defaultUAGenerator } from '../helpers/track-module'
import useAuthenticate from '../hooks/useAuthenticate'
import styles from '../styles/create-account.module.scss'

interface FormErrorState {
  message: string
  attempts: number
}

interface CreateAccountStepProps {
  setFormStep: React.Dispatch<React.SetStateAction<number>>
  newAccountDetails: NewAccountDetails
  setNewAccountDetails: React.Dispatch<React.SetStateAction<NewAccountDetails>>
  handleError: (message: string) => void
  setFormErrorState: React.Dispatch<React.SetStateAction<FormErrorState>>
}

interface YourDetailsStepProps extends CreateAccountStepProps {
  emailIsDisabled?: boolean
}

const YourDetailsStep = ({
  emailIsDisabled,
  setFormStep,
  newAccountDetails,
  setNewAccountDetails,
  handleError,
  setFormErrorState,
}: YourDetailsStepProps) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const formIsComplete = createAccountFormValidation(
      0,
      newAccountDetails,
      handleError,
    )

    if (formIsComplete) {
      setFormStep(1)
      setFormErrorState((curr) => ({ ...curr, message: '' }))
    }
  }

  return (
    <form className={styles.createAccountForm} onSubmit={handleSubmit}>
      <Input
        name="fName"
        type="fName"
        label="First name"
        required
        value={newAccountDetails.fName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement

          setNewAccountDetails((curr) => ({ ...curr, fName: val }))
        }}
      />
      <Input
        name="lName"
        type="lName"
        label="Last name"
        required
        value={newAccountDetails.lName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement

          setNewAccountDetails((curr) => ({ ...curr, lName: val }))
        }}
      />
      <Input
        name="email"
        type="email"
        label="Email"
        required
        disabled={emailIsDisabled}
        value={newAccountDetails.email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement

          setNewAccountDetails((curr) => ({ ...curr, email: val }))
        }}
      />
      <Input
        name="organisation"
        label="Organisation"
        required
        value={newAccountDetails.organisation}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement

          setNewAccountDetails((curr) => ({ ...curr, organisation: val }))
        }}
      />
      <Input
        className={styles.checkbox}
        name={nanoid()}
        type="checkbox"
        hidden
        checked={newAccountDetails.botCheckbox}
        onChange={() => {
          setNewAccountDetails((curr) => ({
            ...curr,
            botCheckbox: !curr.botCheckbox,
          }))
        }}
        label={<>Don't tick this box, its hidden to detect bots.</>}
      />
      <Input
        className={styles.checkbox}
        name="acceptedMarketing"
        type="checkbox"
        checked={newAccountDetails.acceptedMarketing}
        onChange={() => {
          setNewAccountDetails((curr) => ({
            ...curr,
            acceptedMarketing: !curr.acceptedMarketing,
          }))
        }}
        label="Receive updates and offers."
      />
      <Input
        className={styles.checkbox}
        name="terms"
        type="checkbox"
        checked={newAccountDetails.terms}
        onChange={() => {
          setNewAccountDetails((curr) => ({ ...curr, terms: !curr.terms }))
        }}
        label={
          <>
            I accept the{' '}
            <Link href="/terms-of-service.pdf">Terms of Service</Link> and{' '}
            <Link href={`${uplifterWebsite}privacy`}>Privacy Policy</Link>.
          </>
        }
      />
      <Button type="submit" className={styles.submitButton}>
        Create new account
      </Button>
    </form>
  )
}

const useCases = [
  { label: 'Links with UTMs', value: 'links-with-utms' },
  { label: 'Short links', value: 'short-links' },
  { label: 'App links', value: 'app-links' },
  { label: 'QR codes', value: 'qr-codes' },
  { label: 'Landing page monitoring', value: 'landing-page-monitoring' },
  { label: 'Reports', value: 'reports' },
]

const discoveryOptions = [
  { label: 'Advert', value: 'advert' },
  { label: 'Event', value: 'event' },
  { label: 'Search', value: 'search' },
  { label: 'Social media', value: 'social-media' },
  { label: 'Word of mouth', value: 'word-of-mouth' },
  { label: 'Other', value: 'other' },
]

const whoWillUseOptions = [
  { label: 'Just me', value: 'Individual' },
  { label: 'My team', value: 'Team' },
  { label: 'Multiple teams', value: 'Enterprise' },
]

interface OptimiseAccountStepProps extends CreateAccountStepProps {
  showWhoWillUse?: boolean
}

const OptimiseAccountStep = ({
  showWhoWillUse = true,
  setFormStep,
  newAccountDetails,
  setNewAccountDetails,
  handleError,
  setFormErrorState,
}: OptimiseAccountStepProps) => {
  const portalTarget = useMemo(() => {
    const portalElement = document.createElement('div')

    portalElement.className = styles.selectBoxPortal

    document.getElementById('modals')?.appendChild(portalElement)

    return portalElement
  }, [])

  // Cleanup when unmounted
  useEffect(() => {
    return () => {
      if (portalTarget) {
        document.getElementById('modals')?.removeChild(portalTarget)
      }
    }
  }, [portalTarget])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const formIsComplete = createAccountFormValidation(
      1,
      newAccountDetails,
      handleError,
    )

    if (formIsComplete) {
      setFormStep(2)
      setFormErrorState((curr) => ({ ...curr, message: '' }))
    }
  }

  return (
    <form className={styles.createAccountForm} onSubmit={handleSubmit}>
      {showWhoWillUse && (
        <FormRow className={styles.topFormRow}>
          <FormField className={styles.formField}>
            <SelectBox
              name="who-will-use"
              required
              className={styles.selectBox}
              menuPortalTarget={portalTarget}
              menuPosition="fixed"
              placeholder={`Who will be using ${brandName}?`}
              isClearable={false}
              value={whoWillUseOptions.find(
                ({ value }) => value === newAccountDetails.planInterest,
              )}
              options={whoWillUseOptions}
              onChange={(newValue) => {
                if (!newValue) return

                setNewAccountDetails((curr) => ({
                  ...curr,
                  planInterest: newValue.value as PlanInterestLevel,
                }))
              }}
            />
          </FormField>
        </FormRow>
      )}
      <FormRow className={showWhoWillUse ? undefined : styles.topFormRow}>
        <FormField className={styles.formField}>
          <SelectBoxChecklist
            required
            menuPortalTarget={portalTarget}
            menuPosition="fixed"
            placeholder="What do you want to create?"
            isClearable={false}
            excludeAny
            excludeNone
            allLabel="All selected"
            name="use-cases"
            value={useCases.filter(({ value }) =>
              newAccountDetails.useCases?.includes(value),
            )}
            options={useCases}
            onChange={(newValues) => {
              setNewAccountDetails((curr) => ({
                ...curr,
                useCases: newValues.map(({ value }) => value),
              }))
            }}
          />
        </FormField>
      </FormRow>
      <FormRow
        includePaddingBottom={newAccountDetails.discoveryMethod !== 'other'}
      >
        <FormField className={styles.formField}>
          <SelectBox
            name="discovery-method"
            required
            className={styles.selectBox}
            menuPortalTarget={portalTarget}
            menuPosition="fixed"
            placeholder={`How did you discover ${brandName}?`}
            isClearable={false}
            value={discoveryOptions.find(
              ({ value }) => value === newAccountDetails.discoveryMethod,
            )}
            options={discoveryOptions}
            onChange={(newValue) => {
              if (!newValue) return

              setNewAccountDetails((curr) => ({
                ...curr,
                discoveryMethod: newValue.value,
                discoveryMethodOther:
                  newValue.value === 'other'
                    ? curr.discoveryMethodOther
                    : undefined,
              }))
            }}
          />
        </FormField>
      </FormRow>
      {newAccountDetails.discoveryMethod === 'other' && (
        <FormRow includePaddingBottom>
          <FormField className={styles.formField}>
            <Input
              name="discovery-method-other"
              required
              className={styles.textAreaInput}
              placeholder="If other, please write how."
              type="textArea"
              textAreaHeight={38}
              maxLength={200}
              value={newAccountDetails.discoveryMethodOther || ''}
              onValueChange={(value) => {
                setNewAccountDetails((curr) => ({
                  ...curr,
                  discoveryMethodOther: value,
                }))
              }}
            />
          </FormField>
        </FormRow>
      )}
      <Button type="submit" className={styles.submitButton}>
        Set up account
      </Button>
    </form>
  )
}

const PasswordStep = ({
  setFormStep,
  newAccountDetails,
  setNewAccountDetails,
  handleError,
  setFormErrorState,
}: CreateAccountStepProps) => {
  const currLoggedInState = useReactiveVar(loggedInState)

  const { createNewClient } = useAuthenticate()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setLoading(true)

    const formIsComplete = createAccountFormValidation(
      2,
      newAccountDetails,
      handleError,
    )

    if (formIsComplete) {
      const createdAccount = await createNewClient(
        {
          signUpMethod: 'password',
          fName: newAccountDetails.fName,
          lName: newAccountDetails.lName,
          email: newAccountDetails.email,
          organisation: newAccountDetails.organisation,
          passW: newAccountDetails.password,
          acceptedMarketing: newAccountDetails.acceptedMarketing,
          planInterest: newAccountDetails.planInterest as string,
          useCases: newAccountDetails.useCases as string[],
          discoveryMethod: newAccountDetails.discoveryMethod as string,
          discoveryMethodOther: newAccountDetails.discoveryMethodOther,
          microsoftActivationToken: newAccountDetails.microsoftActivationToken,
        },
        handleError,
      )

      if (createdAccount) {
        // Accounts created via MS Marketplace should not see the payment screen
        if (newAccountDetails.microsoftActivationToken) {
          loggedInState({
            ...currLoggedInState,
            isNewlyCreated: true,
            authenticated: true,
            checked: true,
          })

          return
        }

        setFormStep(3)
        setFormErrorState((curr) => ({ ...curr, message: '' }))
        loggedInState({
          ...loggedInState(),
          blockLoginCheck: true,
        })
      }
    }

    setLoading(false)
  }

  return (
    <>
      <GoogleSSO
        login={{
          email: '',
          password: '',
          onFail: {
            message: '',
            attempts: 0,
          },
        }}
        state={newAccountDetails}
        action="create"
      />
      <MicrosoftSSO
        login={{
          email: '',
          password: '',
          onFail: {
            message: '',
            attempts: 0,
          },
        }}
        state={newAccountDetails}
        action="create"
      />
      <form className={styles.createAccountForm} onSubmit={handleSubmit}>
        <FormRow className={styles.topFormRow}>
          <FormField className={styles.formField}>
            <InputLabel className={styles.inputLabel}>
              Or log in using a password:
            </InputLabel>
            <Input
              name="password"
              type="password"
              required
              autoComplete="new-password"
              label="Password"
              value={newAccountDetails.password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = e.target as HTMLInputElement

                setNewAccountDetails((curr) => ({ ...curr, password: val }))
              }}
            />

            <Input
              name="passwordConfirmation"
              type="password"
              required
              autoComplete="new-password"
              label="Confirm password"
              value={newAccountDetails.passwordConfirmation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = e.target as HTMLInputElement

                setNewAccountDetails((curr) => ({
                  ...curr,
                  passwordConfirmation: val,
                }))
              }}
            />
          </FormField>
        </FormRow>
        <Button type="submit" className={styles.submitButton} loading={loading}>
          Set password
        </Button>
      </form>
    </>
  )
}

const initialAccountDetails: NewAccountDetails = {
  fName: '',
  lName: '',
  email: '',
  organisation: '',
  botCheckbox: false,
  acceptedMarketing: false,
  terms: false,
}

interface CreateAccountProps {
  microsoftMarketplace?: boolean
}

const CreateAccount = ({ microsoftMarketplace }: CreateAccountProps) => {
  const currentLoggedInState = useReactiveVar(loggedInState)

  const history = useHistory()

  // User may be redirected to end of CreateAccount journey from an OAuthRedirectSSO page
  const location = useLocation()
  const state = location.state as {
    skipToPayment?: boolean
    newAccountInterestLevel?: PlanInterestLevel
  }

  const errorRef = useRef<HTMLElement>(null)

  const [formStep, setFormStep] = useState(state?.skipToPayment ? 3 : 0)
  const [formErrorState, setFormErrorState] = useState<FormErrorState>({
    message: '',
    attempts: 0,
  })
  const [newAccountDetails, setNewAccountDetails] = useState<NewAccountDetails>(
    { ...initialAccountDetails, planInterest: state?.newAccountInterestLevel },
  )
  // Used to control if the email field is editable and if 'who will use' is shown
  const [isMSMarketplace, setIsMSMarketplace] = useState(false)

  // ! Used for testing new MS Marketplace subscriptions
  // useEffect(() => {
  //   if (newAccountDetails.email === 'chris@uplifter.ai') {
  //     setNewAccountDetails(curr => ({...curr, email: `chris+${nanoid()}@uplifter.ai`}))
  //   }
  // }, [newAccountDetails.email])

  /** Set if the user arrives to URL '' via Microsoft Marketplace. THey have already paid so should not see the payment step. */
  const microsoftActivationToken = useMemo(() => {
    if (!microsoftMarketplace) return null

    const params = new URLSearchParams(window.location.search)

    return params.get('token')
  }, [])

  // Resolve Microsoft Marketplace token
  useEffect(() => {
    const resolveToken = async () => {
      if (microsoftActivationToken) {
        const data = await resolveMicrosoftToken(microsoftActivationToken)

        if (data && data.success) {
          // If account already exists, direct to settings page
          if (data.alreadyActiveLicense) {
            // Update login state to newlyCreated
            loggedInState({
              ...currentLoggedInState,
              authenticated: true,
              checked: true,
            })

            history.push('/settings')
            return
          }

          // Preset email field
          if (data.email) {
            setNewAccountDetails((curr) => ({
              ...curr,
              email: data.email,
              microsoftActivationToken,
              planInterest: 'Team',
            }))
            setIsMSMarketplace(true)
          }
        } else {
          // If token is invalid, redirect to normal create account journey
          history.push('/create-account')
        }
      }
    }

    resolveToken()
  }, [microsoftActivationToken])

  // Preselect correct subscription tier based on URL parameters (from uplifter.ai)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const tier = params.get('tier')

    if (tier) {
      switch (tier) {
        case 'startup':
          setNewAccountDetails((curr) => ({
            ...curr,
            planInterest: 'Individual',
          }))
          break
        case 'business':
          setNewAccountDetails((curr) => ({ ...curr, planInterest: 'Team' }))
          break
        default:
          break
      }
    }
  }, [])

  const handleError = (message: string) => {
    setFormErrorState((curr) => ({
      message,
      attempts: curr.attempts + 1,
    }))

    if (newAccountDetails.botCheckbox) {
      Sentry.captureMessage('Signup form bot detected')
    }
  }

  // Scroll to error message on error
  useEffect(() => {
    if (!formErrorState.message || !errorRef.current) return

    errorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [errorRef.current, formErrorState.message])

  return (
    <>
      <SiteWrapper alwaysAllow>
        <Layout>
          <TrackCreateIntro showWorkspaceInfo={false} />
          <TwoColumns>
            <Column main>
              <ButtonTabs
                selected={0}
                isTopOfBox
                tabsLabels={Object.values(trackCreateTabs).slice(0, 1)}
                type="tabs"
              >
                <TrackCreateFormWeb
                  generatedStructure={defaultUAGenerator}
                  formValues={defaultFormData.web}
                  updateFormValues={() => null}
                  formSubmissionState={{
                    softDisable: false,
                    fieldsWithErrors: [],
                    showErrorMessages: false,
                  }}
                  setFormSubmissionState={() => null}
                  setNewlyCreatedLinks={() => null}
                  setLatestVCard={() => null}
                  setLatestVEvent={() => null}
                  switchToBulkCsv={() => null}
                  qrData={{
                    fgColour: defaultFgColour,
                    bgColour: defaultBgColour,
                    logoImage: defaultLogoImage,
                  }}
                  onChangeQrData={() => null}
                />
                <div />
              </ButtonTabs>
            </Column>
            <Column side fixed>
              <TrackCreateRecentUserLinks isStatic />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      <Modal
        setIsOpen={() => null}
        className={styles.createAccountModal}
        width="superNarrow"
        hideCloseButton
        hideFooter
      >
        <SiteLogo className={styles.siteLogo} />
        <LogoTagline className={styles.tagLine} />
        <DisplayError
          ref={errorRef}
          className={styles.errorMessage}
          attempts={formErrorState.attempts}
        >
          {formErrorState.message}
        </DisplayError>
        <p className={styles.progress}>
          <span className={styles.complete}>About you</span>{' '}
          <span className={classNames({ [styles.complete]: formStep >= 2 })}>
            &gt; Set password
          </span>
          {!isMSMarketplace && (
            <>
              {' '}
              <span
                className={classNames({ [styles.complete]: formStep >= 3 })}
              >
                &gt; Free trial
              </span>
            </>
          )}
        </p>
        <Carousel
          containerClassName={styles.carousel}
          currentProgress={formStep}
          items={[
            {
              key: 0,
              content: (
                <YourDetailsStep
                  emailIsDisabled={isMSMarketplace}
                  setFormStep={setFormStep}
                  newAccountDetails={newAccountDetails}
                  setNewAccountDetails={setNewAccountDetails}
                  handleError={handleError}
                  setFormErrorState={setFormErrorState}
                />
              ),
            },
            {
              key: 1,
              content: (
                <OptimiseAccountStep
                  showWhoWillUse={!isMSMarketplace}
                  setFormStep={setFormStep}
                  newAccountDetails={newAccountDetails}
                  setNewAccountDetails={setNewAccountDetails}
                  handleError={handleError}
                  setFormErrorState={setFormErrorState}
                />
              ),
            },
            {
              key: 2,
              content: (
                <PasswordStep
                  setFormStep={setFormStep}
                  newAccountDetails={newAccountDetails}
                  setNewAccountDetails={setNewAccountDetails}
                  handleError={handleError}
                  setFormErrorState={setFormErrorState}
                />
              ),
            },
            {
              key: 3,
              content: <PaddleInitialPayment />,
            },
          ]}
        />
        {formStep < 3 && (
          <p className={styles.accountText}>
            <span>Already have an account?</span>{' '}
            <Link type="arrowForward" href="/login" newTab={false}>
              Login here
            </Link>
          </p>
        )}
        {formStep > 0 && formStep < 3 && (
          <NavigateButton
            back
            style={{ marginBottom: 0 }}
            onPress={() => setFormStep((curr) => curr - 1)}
          >
            Back
          </NavigateButton>
        )}
      </Modal>
    </>
  )
}

export default CreateAccount
