import { gql } from '../../__gql-types__/gql'

// ! Not used
// Originally used to check if 'Edit dropdowns' nav option should be shown
// Not significantly faster than getCampaignCodeGenerator (full request)
// So that is used instead
export const getCampaignCodeGeneratorFieldTypes = gql(`
	query GetCampaignCodeGeneratorFieldTypes {
		campaignCodeGenerator {
			accountID @client
			paramDefs {
				fieldID
				fieldType
			}
		}
	}
`)

export const getCampaignCodeGenerator = gql(`
	query GetCampaignCodeGenerator {
		campaignCodeGenerator {
			accountID @client
			defaultPardotBusinessUnit
			existingParametersAddedToStart
			isLocked
			masterPrefix
			paramSeparator
			paramDefs {
				copyFromField {
					copyFromID
					truncateToLength
				}
				dateFormat
				emailDefault {
					optionID
					optionName
					optionValue
					parameterID
				}
				fieldAvailable
				fieldID
				fieldName
				fieldType
				fixedValue
				forceLowerCase
				helpText
				hideInTrackView
				lengthLimit
				isCampaignField
				isCreativeField
				metaParameter
				uniqueIDEtag
				uniqueIDTotal
				parameterDependsOn {
					parentFieldID
					parentOptionIDs
				}
				prefix
				required
				selectFields {
					hide
					optionFilter {
						parentFieldID
						parentOptionIDs
					}
					optionID
					optionName
					optionValue
				}
			}
			validationChecks {
				enabled
				name
				value
			}
		}
	}
`)

export const getShortLinkCustomDomains = gql(`
  query GetShortLinkCustomDomains {
		currentCompany {
			companyID
			availableShortLinkCustomDomains {
				customDomainID
				customDomainName
			}
		}
	}
`)

/** This is also used to get deepLinkCandidates */
export const getShortLinkCandidates = gql(`
	query GetShortLinkCandidates (
		$nLinks: Int!
		$customDomainID: String
		$deepLinkServiceID: String
	) {
  shortLinkCandidates(
		nLinks: $nLinks,
		customDomainID: $customDomainID
		deepLinkServiceID: $deepLinkServiceID
	) {
    ... on ShortLinkCandidates {
      __typename
      availableLinkIDs
    }
    ... on ShortLinkBatchCandidates {
      __typename
      bulkStart
      nLinks
    }
  }
}
`)

/** This is also used to validate deepLinkCandidates */
export const validateShortLinkCandidate = gql(`
	query ValidateShortLinkCandidate(
		$testCandidate: String!
		$customDomainID: String
		$deepLinkServiceID: String
	) {
		track {
			validateShortLinkCandidate(
				testCandidate: $testCandidate
				customDomainID: $customDomainID
				deepLinkServiceID: $deepLinkServiceID
			) {
				availableUntil
				isAvailable
				shortLinkID
			}
		}
	}
`)

export const createNewLink = gql(`
	mutation CreateNewLink(
			$customDomainID: String
			$codeList: [AddCode!]!
			$bulkStart: String
			$deepLinkConfig: DeepLinkInput
	) {
		addCodes(
			codeList: $codeList,
			customDomainID: $customDomainID,
			bulkStart: $bulkStart,
			deepLinkConfig: $deepLinkConfig
		) {
			author
			codeDef
			codeID
			createdTime
			minGenDef {
				paramID
				paramName
			}
			fullLink
			shortLink
			totalCodes
			versionNumber
		}
	}
`)

export const sendGeneratorUpdateRequest = gql(`
	mutation sendUpdateRequest(
		$changeRequests: [ChangeRequestInput!]!
		$requestNote: String!
	) {
		track {
			sendUpdateRequest(
				requestNote: $requestNote
				updateRequestList: $changeRequests
			) {
				requestID
			}
		}
	}
`)

export const getUniqueParamCurrentTotal = gql(`
	query GetUniqueParamCurrentTotal (
		$fieldType: String!
	) {
		track {
			currentSequentialCodeID(
				fieldType: $fieldType
			) {
				accountID @client
				currentTotal
				acctPrefix
				etag
				fieldID
				isEnabled
				prefix
				updatedTotal
			}
		}
	}
`)

export const updateUniqueParamCurrentSequentialCodeID = gql(`
	mutation UpdateUniqueParamCurrentSequentialCodeID (
		$fieldType: String!
		$currentTotal: String!
		$etag: String!
		$newTotal: String!
	) {
		track {
			updateCurrentSequentialCodeID (
				fieldType: $fieldType,
				currentTotal: $currentTotal,
				etag: $etag,
				newTotal: $newTotal
			) {
				acctPrefix
				currentTotal
				etag
				fieldID
				isEnabled
				prefix
				updatedTotal
			}
		}
	}
`)

/** Get all ad creatives associated with a specific parameter value */
export const getCreativeList = gql(`
	query GetCreativeList (
		$parameterID: String!
	) {
		report {
			getCreativeList(
				parameterID: $parameterID
			) {
				accountID
				blobURL
				createdBy
				createdTime
				creativeID
				creativeLink
				creativeType
				optionID
				optionName
				parameterID
			}
		}
	}
`)

export const deleteCreativeLink = gql(`
	mutation deleteCreativeLink (
		$creativeID: String!
	) {
		track {
			deleteCreativeLink(
				creativeID: $creativeID
			)
		}
	}
`)
